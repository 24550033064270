import { useRef, useState } from "react";
import { Button, FileButton, Loader, Group, Stack, Text, Flex, Box } from "@mantine/core";
import uploadImage from '../../assets/images/upload.svg'
import classes from "../Form/form.module.css"

const ProfileUpload = ({ updateImage, setUploading }) => {

    const [showError, setShowError] = useState(false)
    const fileInputRef = useRef();

    return (
        <Stack gap={5} w='max-content'>
            <div key='image_uploader'>
                <div className={classes.fileUploadContainer}>
                    <FileButton
                        ref={fileInputRef}
                        onChange={(event) => {
                            setUploading(true)
                            setTimeout(() => {
                                setUploading(false)
                                const fileSize = event?.size / 1000000 ?? 0
                                if (fileSize <= 1 && fileSize > 0) {
                                    updateImage(event)
                                    setShowError(false)
                                }
                                else if (fileSize > 1) {
                                    setShowError(true)
                                }
                                else {
                                    setShowError(false)
                                }
                                if (fileInputRef.current) {
                                    fileInputRef.current.value = null;
                                }
                            }, 3000);
                        }}
                        accept="image/png,image/jpeg, image/jpg">
                        {(props) => (
                            <Flex {...props} direction="column" align="center" justify="center">
                                <img src={uploadImage} alt="" />
                                <Text w={280} ta="center" c="#696969"> <span style={{ fontWeight: "600", color: "#5C5F66" }}> Drag and drop file </span> here <span style={{ fontWeight: "600", color: "#5C5F66" }}> or click here </span> and select a file from your computer</Text>
                                <Flex align="center" gap={8} mt={12}>
                                    <Text c="#696969">Max size - 80MB</Text>
                                    <Box style={{ border: "1px solid #696969", backgroundColor: "#696969", width: "4px", height: "4px", borderRadius: "100%" }}></Box>
                                    <Text c="#696969">Format: jpg, png, webp</Text>
                                </Flex>
                            </Flex>
                        )}
                    </FileButton>
                </div>
            </div>
            {showError ? <Text fz={12} c={'red'}>*Image size exceeds <strong>1MB</strong>.</Text> : <></>}
        </Stack>
    )
}

export default ProfileUpload;