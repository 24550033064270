import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    countries: [],
    cities: [],
    error: {
        status: false,
        message: ""
    },
    loading: false,
};

const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setCountries: (state, { payload }) => {
            state.countries = payload.map((country) => {
                return { 'value': country.code, 'label': country.name }
            })
        },
        setCities: (state, { payload }) => {
            state.cities = payload.map((country) => {
                return { 'value': country.name, 'label': country.name }
            })
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
    },
});

export const { setCountries, setCities, setLoading, setError } = commonSlice.actions;
export default commonSlice.reducer;