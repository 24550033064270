import { Flex, Box, Text, Image, Group, ActionIcon, Burger, TextInput } from "@mantine/core"
import { Link, useLocation } from "react-router-dom"
import { Dropdown } from "../EmployeeManagement/Dropdown"
import { useDispatch, useSelector } from 'react-redux';
import { IconBell, IconHeart, IconLogout, IconShoppingCart } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { renderTabs } from "../../lib/routes";
import { BsSearch } from "react-icons/bs";
import { setPrescriptionError, setPrescriptions } from "../../store/employess/prescription";
import { setEmployees, setEmployeesError } from "../../store/employess/employeesSlice";
import { clearOrganizations, clearOrganizations2, setOrganization, setOrganizationList, setSelectedOrganization } from "../../store/organization/organizationSlice";
import { setMeasurements } from "../../store/employess/measurementSlice";
import { useMediaQuery } from "@mantine/hooks";
import { setError } from "../../store/tiers/tierSlice";
import { clearCart, setAddress, setSelectCartItem, setSelectedPrescription } from "../../store/employess/cartSlice";
import Badge from "../ui/badge/badge";

const BaseHeader = ({ opened, toggle }) => {

    const { pathname } = useLocation()
    const isSmallScreen = useMediaQuery("(max-width: 768px)");
    const navigate = useNavigate()
    const user = useSelector((state) => state.login?.user)
    const cart = useSelector((state) => state.cart.shippingDetails.cart)
    const dispatch = useDispatch()

    const headerTitle = () => {
        const activeTab = renderTabs(user?.role).find((tab) => tab.link === pathname)
        return (user?.role === "partner" && activeTab === undefined) ? "Company Management" : activeTab?.name
    }

    const logoutUser = () => {
        dispatch(setPrescriptions([]))
        dispatch(setEmployees([]))
        dispatch(clearOrganizations())
        dispatch(clearOrganizations2())
        dispatch(setOrganization(null))
        dispatch(setSelectedOrganization(null))
        dispatch(setMeasurements([]))
        dispatch(setEmployeesError(null))
        dispatch(setPrescriptionError(null))
        dispatch(setError(null))
        dispatch(clearCart())
        dispatch(setAddress(null))
        dispatch(setSelectedPrescription(null))
        dispatch(setSelectCartItem([]))
        localStorage.clear()
        navigate('/')
    }

    const boxStyle = {
        borderRadius: "6px",
        border: "2px solid #ADB5BD",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        width: "32px",
        height: "32px"
    };

    function renderHeader() {
        return (
            <>
                <Burger
                    opened={opened}
                    onClick={toggle}
                    hiddenFrom="sm"
                    size="sm"
                />
                <Text span c="#909296" fz={14}>{headerTitle()}</Text>
                {pathname === "/employee/cart" || pathname === "/employee/checkout" || pathname.includes("/employee/product") ? (
                    <>
                        <Group gap={12} justify="flex-end">
                            <TextInput styles={{ input: { borderRadius: "10px", border: "2px solid #CED4DA" } }} leftSection={<BsSearch />} placeholder="Search" />
                            <Link to="/employee/cart" style={{ margin: 0, padding: 0 }}>
                                <Box style={boxStyle} aria-label="Cart">
                                    <IconShoppingCart color="#868E96" size={20} />
                                </Box>
                            </Link>
                            <Link to="/employee/cart" style={{ margin: 0, padding: 0 }}>
                                <Box style={boxStyle} aria-label="Favorites">
                                    <IconHeart color="#868E96" size={20} />
                                </Box>
                            </Link>
                            <Link to="/employee/cart" style={{ margin: 0, padding: 0 }}>
                                <Box style={boxStyle} aria-label="Notifications">
                                    <IconBell color="#868E96" size={20} />
                                </Box>
                            </Link>
                        </Group>
                        <ActionIcon variant="transparent" color="#3354F4" onClick={() => { logoutUser() }}>
                            <IconLogout style={{ width: '36px', height: '36px' }} />
                        </ActionIcon>
                    </>
                ) : (
                    !isSmallScreen && <Group gap={12} justify="flex-end">
                        {user?.role !== "employee" && (
                            <>
                                <Text style={{ color: "#343A40", fontWeight: "500" }}>{user?.profile?.first_name + " " + user?.profile?.last_name}</Text>
                                <Image src={user?.profile?.image} alt="" w={32} h={32} styles={{ root: { borderRadius: "8px" } }} />
                            </>
                        )}
                        <Dropdown />
                        <ActionIcon variant="transparent" color="#3354F4" onClick={() => { logoutUser() }}>
                            <IconLogout style={{ width: '36px', height: '36px' }} />
                        </ActionIcon>
                    </Group>
                )}
            </>
        )
    }

    return (
        <Flex align="center" h={60} justify='space-between'>
            {renderHeader()}
        </Flex>
    )
}

export default BaseHeader;