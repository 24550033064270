import { Modal } from '@mantine/core';

function ModalComp({ open, setOpen, title, children, ...rest }) {
  return (
    <Modal
      {...rest}
      radius={8}
      opened={open}
      onClose={() => setOpen(false)}
      title={title}
      centered
    >
      {children}
    </Modal>
  );
}

export default ModalComp;