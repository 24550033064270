import { CiMobile4 } from "react-icons/ci";
import productImage from "../../assets/images/product.png"

export const CATEGORIES = [
    { id: 1, icon: <CiMobile4 size={20} fontWeight={900} color="#A6A7AB" />, category: "Lenses", active: false },
    { id: 2, icon: <CiMobile4 size={20} fontWeight={900} color="#A6A7AB" />, category: "Protect", active: false },
    { id: 3, icon: <CiMobile4 size={20} fontWeight={900} color="#A6A7AB" />, category: "Smart Watches", active: false },
    { id: 4, icon: <CiMobile4 size={20} fontWeight={900} color="#A6A7AB" />, category: "Cameras", active: false },
    { id: 5, icon: <CiMobile4 size={20} fontWeight={900} color="#A6A7AB" />, category: "Headphones", active: false },
];

export const TAB_LABELS = [
    {
        name: 'New Arrival',
        value: 'userInfo',
    },
    {
        name: 'Bestseller',
        value: 'bestseller',
    },
    {
        name: 'Featured Products',
        value: 'featuredProducts',
    },
];

export const PRODUCTS = [
    { id: 1, image: productImage, name: "DEWALT DPG82-11 Concealer Clear Anti-Fog Dual Mold Safety Goggle, Clear Lens, 1 Pair", price: 900, liked: false, },
    { id: 2, image: productImage, name: "DEWALT DPG82-11 Concealer Clear Anti-Fog Dual Mold Safety Goggle, Clear Lens, 1 Pair", price: 900, liked: false, },
    { id: 3, image: productImage, name: "DEWALT DPG82-11 Concealer Clear Anti-Fog Dual Mold Safety Goggle, Clear Lens, 1 Pair", price: 900, liked: false, },
    { id: 4, image: productImage, name: "DEWALT DPG82-11 Concealer Clear Anti-Fog Dual Mold Safety Goggle, Clear Lens, 1 Pair", price: 900, liked: false, },
    { id: 5, image: productImage, name: "DEWALT DPG82-11 Concealer Clear Anti-Fog Dual Mold Safety Goggle, Clear Lens, 1 Pair", price: 900, liked: false, },
    { id: 6, image: productImage, name: "DEWALT DPG82-11 Concealer Clear Anti-Fog Dual Mold Safety Goggle, Clear Lens, 1 Pair", price: 900, liked: false, },
    { id: 7, image: productImage, name: "DEWALT DPG82-11 Concealer Clear Anti-Fog Dual Mold Safety Goggle, Clear Lens, 1 Pair", price: 900, liked: false, },
    { id: 8, image: productImage, name: "DEWALT DPG82-11 Concealer Clear Anti-Fog Dual Mold Safety Goggle, Clear Lens, 1 Pair", price: 900, liked: false, },
];

export const GLASS_SIZE = {
    1: 'S',
    2: 'M',
    3: 'L',
    4: 'XL',
    5: 'Universal',
}

export const FILTER_LABELS = ['Price', 'Brand', 'Protection class', 'Screen diagonal', 'Screen type', 'Battery capacity'];