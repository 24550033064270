import { Box, Button, TextInput, Grid, PasswordInput, Stack, Select, Group, Loader, Text, Flex } from '@mantine/core'
import { useForm } from '@mantine/form';
import { IconEdit, IconEye, IconEyeOff, IconChevronDown } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrganizations, updateOrganization, fetchCountries } from '../../middlewares/organization/organization';
import ProfileImageUploader from '../BaseComponents/ProfileImageUploader';
import { notifications } from '@mantine/notifications';
import { INDUSTRY_SERVICED, ORGANIZATION_TYPE_CHOICES } from '../../choices/organizationChoices';
import { IoIosArrowDown } from 'react-icons/io';
import { asyncErrorHandler } from '../../helpers/errorHandler';
import { useMediaQuery, useViewportSize } from '@mantine/hooks';
import { PrimaryButton } from '../ui/Button/Button';

const InputBox = ({ rightIcon, leftIcon, value, label, placeHolder, isPassword, onChange, error }) => {
    return (
        <>
            {isPassword ?
                <PasswordInput
                    label={label}
                    placeholder={placeHolder}
                    visibilityToggleIcon={({ reveal }) =>
                        reveal ? (
                            <IconEyeOff style={{ width: '16px', height: '16px' }} />
                        ) : (
                            <IconEye style={{ width: '16px', height: '16px' }} />
                        )
                    }
                    radius="6px"
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                    error={error}
                    styles={() => ({
                        input: {
                            marginTop: '10px',
                        },
                        label: {
                            font: 'Roboto',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#495057'

                        }
                    })
                    }
                />
                :
                <TextInput
                    label={label}
                    placeholder={placeHolder}
                    rightSection={rightIcon}
                    leftSection={leftIcon}
                    leftSectionWidth={32}
                    rightSectionWidth={32}
                    radius="6px"
                    onChange={(event) => onChange(event.target.value)}
                    value={value}
                    error={error}
                    styles={() => ({
                        input: {
                            marginTop: '10px',
                            height: '32px'
                        },
                        label: {
                            font: 'Roboto',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#495057'

                        }
                    })}
                />
            }
        </>

    )
}

const CompanyProfile = () => {

    // initializing icon
    const selectIcon = <IconChevronDown style={{ width: '16px', height: 'auto', marginRight: '2px' }} />
    const editIcon = <IconEdit style={{ width: '16px', height: '16px', cursor: 'pointer' }} />
    const arrowDropdown = <IoIosArrowDown style={{ width: '16px', height: 'auto', marginRight: '2px' }} />

    const organization = useSelector(state => state.organizations.organization)
    const countries = useSelector(state => state.common.countries)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [profileImageURL, setProfileImageURL] = useState(null)
    const [imageError, setImageError] = useState("")

    // initializing form
    const form = useForm({
        initialValues: {
            name: '',
            company_type: '',
            zip_code: '',
            city: '',
            address: '',
            country: ''
        },
        validate: {
            name: (val) => (/^\s*$/.test(val) ? 'Name can not be null' : null),
            company_type: (val) => (/^(?:[1-9]|[1-2][0-9]|30)$/.test(val) ? null : "Please select one of the company types"),
            country: (val) => (/^[A-Z]{2}$/.test(val) ? null : 'Please select a country')
        },
    });

    async function fetchOrganizationData() {
        await dispatch(fetchOrganizations())
    }

    useEffect(() => {
        if (!organization) {
            fetchOrganizationData()
        }
        dispatch(fetchCountries())
    }, [])

    // update image url and form initial values
    useEffect(() => {
        if (organization) {
            setProfileImageURL(organization?.logo);
            form.setValues({
                name: organization?.name,
                company_type: organization?.company_type,
                zip_code: organization.zip_code ? organization.zip_code : '',
                city: organization.city ? organization.city : '',
                address: organization.address ? organization.address : '',
                country: organization?.country
            })
        }
    }, [organization]);

    function updateFormValue(value, formField) {
        if (formField === 'logo') {
            setProfileImageURL(value ? URL.createObjectURL(value) : null)
            form.setFieldValue(formField, value)
        } else {
            form.setFieldValue(formField, value)
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        if (form.validate().hasErrors) {
            console.log(form.errors);
            form.validate()
        }
        else {
            const formData = new FormData()
            Object.entries(form.values).forEach(([key, value]) => {
                formData.append(key, value)
            })
            setLoading(true)
            let res = await dispatch(updateOrganization(formData))
            if (updateOrganization.fulfilled.match(res)) {
                form.clearErrors();
                setImageError("")
                setLoading(false)
                window.toast('Company details have been updated')
            }
            else if (updateOrganization.rejected.match(res)) {
                setLoading(false)
                asyncErrorHandler(res, form, setImageError)
            }
        }
    }

    const commonStyle = {
        label: {
            fontSize: '16px',
            marginBottom: "10px",
            lineHeight: "18px",
            fontWeight: "600",
            color: "#424242"
        },
        input: {
            height: "40px",
            fontSize: "14px",
            padding: "12px",
            lineHeight: "16px",
            borderRadius: "6px",
            backgroundColor: "transparent",
        },
        error: {
            fontSize: '14px',
            marginTop: "5px",
            lineHeight: "16px",
        },
        required: {
            color: '#3354F4'
        }
    }

    const isMediumScreen = useMediaQuery("(max-width: 991px)")
    const isLaptopMediumScreen = useMediaQuery("(max-width: 1024px)")
    const height = useViewportSize()

    return (
        <Box radius="md" px={12} py={24} mb={50} h={height - 60} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <Text fw="bold" mb={16}>Company logo</Text>
            <form onSubmit={handleSubmit}>
                <Stack gap={40}>
                    <Box maw={isMediumScreen ? '100%' : '446px'}>
                        <Stack gap={20}>
                            <ProfileImageUploader
                                profileImageURL={profileImageURL}
                                updateImage={(image) => {
                                    updateFormValue(image, 'logo')
                                    setImageError("")
                                }}
                                deleteImage={() => {
                                    updateFormValue('', 'logo')
                                    setImageError("")
                                }}
                            />
                            {imageError && <p style={{ fontSize: "14px", color: "#FA5252", marginTop: "5px", width: "400px" }}>{imageError}</p>}
                            <TextInput
                                styles={{ ...commonStyle }}
                                rightSection={editIcon}
                                label="Company name"
                                key={form.key("name")}
                                {...form.getInputProps('name')}
                                placeHolder="Enter company name"
                            />
                            <Select
                                label="Company type"
                                placeholder="Select company type"
                                key={form.key("company_type")}
                                {...form.getInputProps('company_type')}
                                data={INDUSTRY_SERVICED}
                                rightSection={arrowDropdown}
                                styles={{ ...commonStyle }}
                            />
                            <TextInput
                                styles={{ ...commonStyle }}
                                label="Address"
                                key={form.key('address')}
                                {...form.getInputProps('address')}
                                placeHolder="Address"
                            />
                            <Flex gap={20} styles={{ root: { justifyContent: "space-between", flexDirection: isLaptopMediumScreen ? "column" : "row", width: "100%" } }}>
                                <TextInput
                                    label="Zip / Postal code"
                                    key={form.key('zip_code')}
                                    {...form.getInputProps('zip_code')}
                                    placeHolder="Enter zip / postal code"
                                    radius="md"
                                    styles={{ ...commonStyle, root: { width: isMediumScreen && "100%" } }}
                                />
                                <TextInput
                                    label="City / District"
                                    key={form.key('city')}
                                    {...form.getInputProps('city')}
                                    placeHolder="Enter city / district"
                                    radius="md"
                                    styles={{ ...commonStyle, root: { width: isMediumScreen && "100%" } }}
                                />
                            </Flex>
                            <Select
                                data={countries}
                                label="Country"
                                key={form.key('country')}
                                {...form.getInputProps('country')}
                                placeholder="Please select a country"
                                rightSection={arrowDropdown}
                                styles={{ ...commonStyle }}
                                defaultValue={null}
                            />
                            <Group justify='flex-end'>
                                {/* <Button disabled={loading} fw={500} ml="auto" type='submit' color="#3354F4" size="md" radius="6px" >
                                    <Group spacing='xs'>
                                        <span>Update Details</span>
                                        {loading && <Loader size="sm" color="#adb5c1" type='dots' />}
                                    </Group>
                                </Button> */}
                                <PrimaryButton
                                    type="submit"
                                    title="Update Details"
                                    disabled={loading}
                                    fallback={loading ? <Loader size="sm" color="white" type='dots' /> : null}
                                />
                            </Group>
                        </Stack>
                    </Box>
                </Stack>
            </form>
        </Box>
    )
}

export default CompanyProfile