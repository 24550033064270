import React from 'react'

import { Card, Group, Menu, Table, Text } from '@mantine/core';
import { BALANCE_HISTORY } from '../../../mock/employees/safetyAwards';
import { IconMessage, IconPhone, IconSettings } from '@tabler/icons-react';
import classes from '../../Dashboard/card.module.css'

const BalanceHistory = () => {

  const rows = BALANCE_HISTORY.map((element) => (
    <Table.Tr key={element.id}>
      <Table.Td pl={24} w={240}>
        <Group>
          <img src={element.image} alt="" />
          <Text fw={500} fz={20}>{element.company}</Text>
        </Group>
      </Table.Td>
      <Table.Td fw={500} fz={20} c="#516778" w={170}>{element.type}</Table.Td>
      <Table.Td fw={500} fz={20} c="#516778" w={128}>{element.date}</Table.Td>
      <Table.Td fw={500} fz={20} c="#516778" w={150}>{element.balance}</Table.Td>
      <Table.Td w={68}>
        <Menu shadow="md" width={200} position="bottom-end">
          <Menu.Target>
            {element.actions}
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Application</Menu.Label>
            <Menu.Item leftSection={<IconSettings />}>Settings</Menu.Item>
            <Menu.Item leftSection={<IconMessage />}>Messages</Menu.Item>
            <Menu.Item leftSection={<IconPhone />}>Gallery </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Table.Td>
    </Table.Tr>
  ))

  return (
    <Card width="100%" p={0} radius={16} style={{ border: "1px solid #DEE2E6" }} className={classes.card}>
      <Text fz={18} fw={600} p={24}>Balance History</Text>
      <Table.ScrollContainer minWidth={1000}>
        <Table verticalSpacing="md">
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </Card>
  )
}

export default BalanceHistory;