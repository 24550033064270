import React from 'react'
import { Grid, Divider, Box, Stack, Group, Text, Button, Avatar, Image, Title, Progress, Card } from '@mantine/core';
import classes from '../Dashboard/card.module.css'
import { ACTIVITY } from '../../mock/employees/activity';

const MyActivity = () => {
    return (
        <Card className={classes.card} px={24} py={16} radius={12} styles={{ root: { border: "2px solid #DEE2E6" } }}>
            <Stack gap="5px">
                <Title mb={30} order={3}>Your activity</Title>
                <Stack gap='1px'>
                    {ACTIVITY.map((act, index) => {
                        return (
                            <Box style={{ display: "flex" }} mb={16}>
                                <img src={act.image} alt="" />
                                <Box ml={20} w="100%">
                                    <Group justify="space-between">
                                        <Text>{act.title}</Text>
                                        <Text c="#828690">{act.date}</Text>
                                    </Group>
                                    <Text c="#828690">{act.message}</Text>
                                </Box>
                            </Box>
                        )
                    })}
                </Stack>
            </Stack>
        </Card>
    )
}

export default MyActivity