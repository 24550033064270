import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../services/api";
import { setUser } from "../../store/auth/authSlice";
import { setInviteLoading, setLoading, setOrganization, setOrganizationAdmins, setOrganizationList, setOrganizationList2 } from "../../store/organization/organizationSlice"

const apiInstance = apiClient()
const apiFormInstance = apiClient(true)

export const registerPartner = createAsyncThunk('registerPartner', async (data, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.post('/api/auth/registration/partner/', data)
        return res
    } catch (err) {
        return rejectWithValue(err)
    }

})

export const registerParterViaGoogleAuth = createAsyncThunk('registerPartnerViaGoogleLogin', async (data, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.post('/api/google/partner', data)
        dispatch(setUser(res.data.user))
        localStorage.setItem('access_token', res.data.access)
        localStorage.setItem('refresh_token', res.data.refresh)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const registerPartnerOrganization = createAsyncThunk('registerPartnerOrganization', async (data, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiFormInstance.post('/api/partner-organization/create/', data)
        dispatch(setOrganization(res.data))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const updatePartnerOrganization = createAsyncThunk('updatePartnerOrganization', async (data, { getState, dispatch, rejectWithValue }) => {
    try {
        const res = await apiFormInstance.put(`/api/partner-organization/${getState().organizations.organization?.id}/update/`, data)
        dispatch(setOrganization(res.data))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const addAdminToOrganizations = createAsyncThunk('addAdminToOrganization', async (data, { dispatch, rejectWithValue, getState }) => {
    try {
        const res = await apiInstance.post('/api/organizations/bulk/create/', data)
        dispatch(setOrganizationList([...getState().organizations.organizationList, { ...res.data.organizations[0] }]))
        dispatch(setOrganizationList2([...getState().organizations.organizationList2, { ...res.data.organizations[0] }]))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const fetchPartnerOrganization = createAsyncThunk('fetchPartnerOrganization', async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true))
    try {
        const res = await apiInstance.get('/api/partner-organization/')
        if (res.status === 200 || res.status === 201) {
            dispatch(setOrganization(res.data))
            dispatch(setLoading(false))
        }
        return res
    }
    catch (err) {
        dispatch(setLoading(false))
        return rejectWithValue(err)
    }
})

export const createSubContractor = createAsyncThunk("createSubContractor", async (body, { getState, dispatch, rejectWithValue }) => {
    dispatch(setInviteLoading(true))
    try {
        const res = await apiFormInstance.post('/api/organizations/subcontractors/create/', body.payload)
        const updatedSubContractors = getState().organizations.organizationList2.map((org) => {
            if (org.id === body.payload.parent) {
                return {
                    ...org,
                    subcontractor: [
                        ...org.subcontractor,
                        res.data
                    ]
                }
            } else {
                return org
            }
        })
        dispatch(setInviteLoading(false))
        dispatch(setOrganizationList2(updatedSubContractors))
        return res
    } catch (error) {
        dispatch(setInviteLoading(false))
        return rejectWithValue(error)
    }
})

export const updateSubContractor = createAsyncThunk("updateSubContractor", async (body, { getState, dispatch, rejectWithValue }) => {
    dispatch(setInviteLoading(true))
    try {
        const res = await apiFormInstance.patch(`/api/organizations/subcontractors/${body.id}/update/`, body.payload)
        const updatedSubContractors = getState().organizations.organizationList2.map((org) => {
            return {
                ...org,
                subcontractor: org.subcontractor?.map((subcontractor) => {
                    if (subcontractor.id === res.data.id) {
                        return res.data
                    } else {
                        return subcontractor
                    }
                })
            }
        })
        dispatch(setInviteLoading(false))
        dispatch(setOrganizationList2(updatedSubContractors))
        return res
    } catch (error) {
        dispatch(setInviteLoading(false))
        return rejectWithValue(error)
    }
})

export const deleteSubContractor = createAsyncThunk("deleteSubContractor", async (id, { getState, dispatch, rejectWithValue }) => {
    dispatch(setInviteLoading(true))
    try {
        const res = await apiFormInstance.delete(`/api/organizations/subcontractors/${id}/delete/`)
        const updatedSubContractors = getState().organizations.organizationList2.map((org) => {
            return {
                ...org,
                subcontractor: org.subcontractor?.filter((subcontractor) => subcontractor.id !== id)
            }
        })
        dispatch(setInviteLoading(false))
        dispatch(setOrganizationList2(updatedSubContractors))
        return res
    } catch (error) {
        dispatch(setInviteLoading(false))
        return rejectWithValue(error)
    }
})

// Org admins

export const createOrganizationAdmin = createAsyncThunk("createOrganizationAdmin", async (body, { getState, dispatch, rejectWithValue }) => {
    dispatch(setInviteLoading(true))
    try {
        const res = await apiFormInstance.post('/api/organizations/admins/create/', body)
        const data = {
            ...getState()?.organizations?.organizationAdmins,
            results: [...getState()?.organizations?.organizationAdmins?.results, { ...res.data }], 
        }
        dispatch(setOrganizationAdmins(data))
        dispatch(setInviteLoading(false))
        return res
    }
    catch (err) {
        dispatch(setInviteLoading(false))
        return rejectWithValue(err)
    }
})

export const updateOrganizationAdmin = createAsyncThunk("updateOrganizationAdmin", async (body, { getState, dispatch, rejectWithValue }) => {
    dispatch(setInviteLoading(true))
    try {
        const res = await apiFormInstance.put(`/api/organizations/admins/${body.id}/update/`, body.payload)
        const updatedOrgAdmin = getState().organizations.organizationAdmins?.results?.map((orgAdmin) => {
            if (orgAdmin.id === body.id) {
                return res.data
            } else {
                return orgAdmin
            }
        })
        dispatch(setOrganizationAdmins({
            ...getState().organizations.organizationAdmins,
            results: updatedOrgAdmin
        }))
        dispatch(setInviteLoading(false))
        return res
    }
    catch (err) {
        dispatch(setInviteLoading(false))
        return rejectWithValue(err)
    }
})

export const fetchOrganizationAdmins = createAsyncThunk('fetchOrganizationAdmins', async (_, { dispatch, rejectWithValue }) => {
    dispatch(setInviteLoading(true))
    try {
        const res = await apiInstance.get('/api/organizations/admins/list/all/')
        dispatch(setOrganizationAdmins(res.data))
        dispatch(setInviteLoading(false))
        return res
    }
    catch (err) {
        dispatch(setInviteLoading(false))
        return rejectWithValue(err)
    }
})

export const fetchOrganizationAdminsById = createAsyncThunk('fetchOrganizationAdminsById', async (organization_id, { dispatch, rejectWithValue }) => {
    dispatch(setInviteLoading(true))
    try {
        const res = await apiInstance.get(`/api/organizations/admins/${organization_id}/list/`)
        console.log(res);
        dispatch(setOrganizationAdmins(res.data))
        dispatch(setInviteLoading(false))
        return res
    }
    catch (err) {
        dispatch(setInviteLoading(false))
        return rejectWithValue(err)
    }
})