import React, { useEffect, useRef, useState } from 'react';
import CreatePushNotification from '../../components/Notification/createPushNotification';
import ScheduleNotification from '../../components/Notification/ScheduleNotification';
import TabBaseLayout from '../../components/Layout/TabBaseLayout/TabBaseLayout';
import { useMediaQuery } from '@mantine/hooks';

const Notification = () => {

    const tabList = [
        { value: 'createNotification', label: 'Create notification' },
        { value: 'scheduledNotifications', label: 'Scheduled Notifications' },
        { value: 'pastNotifications', label: 'Past Notifications' },
    ];

    const [width, setWidth] = useState(window.innerWidth);
    const [notificationType, setNotificationType] = useState("Create notification");
    const [notificationSelected, setNotificationSelected] = useState("application")
    const [openScheduleNotification, setOpenScheduleNotification] = useState(false)
    const [applyNotificationSetting, setApplyNotificationSetting] = useState(false)
    const [sendNotification, setSendNotification] = useState(false)

    function handleResize() {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [value, setValue] = useState(null);

    const commonProps = {
        width: width,
        value: value,
        setValue: setValue,
    }

    return (
        <>
            <div style={{ overflow: 'hidden' }}>
                <TabBaseLayout tabList={tabList}>
                    <CreatePushNotification
                        openScheduleNotification={openScheduleNotification}
                        setOpenScheduleNotification={setOpenScheduleNotification}
                        sendNotification={sendNotification}
                        setSendNotification={setSendNotification}
                        applyNotificationSetting={applyNotificationSetting}
                    />
                    <ScheduleNotification {...commonProps} />
                    <ScheduleNotification {...commonProps} />
                </TabBaseLayout>
            </div>
        </>
    );
};

export default Notification;