import { Box, Card, Flex, Grid, Group, Select, Text } from '@mantine/core'
import React, { useState } from 'react'
import Categories from '../../../components/Employess/RFXNStore/categories'
import { PRODUCTS } from '../../../mock/employees/RFXNStore'
import Product from '../../../components/Employess/RFXNStore/product'
import { IconChevronDown, IconEyeSearch } from '@tabler/icons-react'
import Filters from '../../../components/Employess/RFXNStore/filters'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addToFavourites, deleteFromFavourites, fetchEyewearDetailById } from '../../../middlewares/employee/employee.service'
import { Skeleton } from '@mantine/core';
import { setProducts } from '../../../store/employess/productSlice'

const RFXNStore = () => {

  const products = useSelector((state) => state.product.products.results)
  const product = useSelector((state) => state.product.products)
  const loading = useSelector((state) => state.product.loading)
  const productCount = products?.length
  const navigate = useNavigate()
  const dispatch = useDispatch()

  async function handleAddToFavourite(item) {
    const updatedProducts = products.map((prod) => prod.id === item.id ? { ...prod, liked: !prod.liked } : prod);
    dispatch(setProducts({ ...product, results: updatedProducts }));
    if (item?.liked === false) {
      const res = await dispatch(addToFavourites({ glass_variant: item.id }))
      if (res.type === "addToFavourites/fulfilled") {
        window.toast(`Added to favourites successfully`)
      }
      console.log(res);
    } else {
      const res = await dispatch(deleteFromFavourites(item.id))
      if (res.type === "deleteFromFavourites/fulfilled") {
        window.toast(`Deleted from favourites successfully`)
      }
      console.log(res);
    }
  }

  function renderContent() {
    if (loading) {
      return (
        <>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((_, index) => (
            <Grid.Col span={{ md: 4, lg: 4 }} key={index}>
              <Card p={0} radius={24} styles={{ root: { height: "342px", width: "392px" } }}>
                <Skeleton height="342px" width="392px" />
              </Card>
            </Grid.Col>
          ))}
        </>
      );
    }
    if ((products?.length === 0 || products === undefined) && loading === false) {
      return (
        <>
          <Grid.Col span={{ md: 12, lg: 12 }}>
            <Flex justify="center" align="center" direction="column" styles={{ root: { height: "60vh" } }}>
              <IconEyeSearch size={100} color='#3354F4' />
              <Text mt={20} c="dark.3" fz={30} fw={500} ff="Roboto-regular">No <Text span fw={600} fz={30} style={{ color: '#3354F4' }}>Eyewear</Text> found</Text>
            </Flex>
          </Grid.Col>
        </>
      )
    }
    else {
      return (
        <>
          {products?.map((item) => (
            <Grid.Col span={{ md: 4, lg: 4 }} key={item.id}>
              <Product
                item={item}
                onClick={() => handleAddToFavourite(item)}
                navigate={() => {
                  navigate(`/employee/product/${item.id}`);
                  dispatch(fetchEyewearDetailById({ id: item.id, type: "0" }));
                }}
              />
            </Grid.Col>
          ))}
        </>
      );
    }
  }

  return (
    <Box>
      <Categories />
      <Box p={24}>
        <Grid gutter={44}>
          <Grid.Col span={{ md: 12, lg: 3 }} pb={24}>
            <Filters />
          </Grid.Col>
          <Grid.Col span={{ md: 12, lg: 9 }} pb={24}>
            <Grid gutter={12}>
              {/* <Grid.Col span={{ lg: 6 }}>
            <HeaderCard title="AWARDS" bg="linear-gradient(90deg, #3354F4 0%, #0997FE 100%)" />
          </Grid.Col>
          <Grid.Col span={{ lg: 6 }}>
            <HeaderCard title="TIERS" bg="#373A40" />
          </Grid.Col>
          <Grid.Col span={{ md: 12, lg: 12 }}>
            <Tab tabsArray={TAB_LABELS} />
          </Grid.Col> */}
              <Grid.Col span={{ md: 12, lg: 12 }} pb={24}>
                <Group justify="space-between">
                  <Text c="#6C6C6C" fw={600}>Selected Products: <span style={{ color: "black", fontWeight: "600" }}>{productCount || 0}</span></Text>
                  <Box>
                    <Group>
                      <Select
                        rightSection={<IconChevronDown size={24} color="#909296" />}
                        placeholder="By rating"
                        data={['React', 'Angular', 'Vue', 'Svelte']}
                        styles={{ root: { backgroundColor: "transparent" }, input: { width: "256px", height: "40px", borderRadius: "8px" } }}
                      />
                    </Group>
                  </Box>
                </Group>
              </Grid.Col>
              {renderContent()}
            </Grid>
          </Grid.Col>
        </Grid>
      </Box>
    </Box>
  )
}

export default RFXNStore;