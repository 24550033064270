import React from 'react'
import { Box, Button, Flex, Group, Skeleton, Text } from '@mantine/core';
import { notifications } from "@mantine/notifications";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { addToCart } from '../../../store/employess/cartSlice';
import { GLASS_SIZE } from '../../../mock/employees/RFXNStore';
import { setProductDetail } from '../../../store/employess/productSlice';

const ProductDetails = () => {

  const { id } = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const cart = useSelector((state) => state.cart.shippingDetails.cart)
  const variantDetail = useSelector((state) => state.product.productDetail);
  const loading = useSelector((state) => state.product.loading)

  const handleAddProduct = (addType) => {
    if (addType === "ADD_TO_CART") {
      window.toast("Product Added To Cart")
      dispatch(addToCart({
        ...variantDetail,
        isSelected: cart?.length === 0 ? true : false
      }))
    }
    else {
      window.toast("Product Added To Whishlist")
    }
  }

  return (
    <Box>

      <Text fz={24} mb={24} fw="bold" styles={{ root: { fontFamily: "SF-pro-display-medium" } }}>
        {variantDetail?.glass_brand?.frame_name}
      </Text>

      <Group gap={16} mb={24} styles={{ root: { fontFamily: "SF-pro-display-medium" } }}>
        <Text fz={20} fw="bold" c="black">
          ${variantDetail?.price}
        </Text>
        <Text fz={20} fw="bold" c="#A0A0A0" td="line-through">
          ${variantDetail?.price}
        </Text>
      </Group>

      <Text fz={20} fw={500} c="black" mb={20} styles={{ root: { fontFamily: "SF-pro-display-medium" } }}>Choose style</Text>
      <Group gap={16} mb={15}>
        {variantDetail?.variants?.map((variant) => {
          return (
            <Box
              px={10}
              id={variant.id}
              style={{
                borderRadius: "10px",
                cursor: "pointer",
                border: variant.id === id && '2px solid #f1c40f',
                padding: "20px 10px"
              }}
              onClick={() => {
                navigate(`/employee/product/${variant.id}`);
              }}
            >
              <img style={{ width: "70px", objectFit: "cover", margin: "auto", display: "block" }} src={variant.frame_image} alt="" />
            </Box>
          )
        })}
      </Group>

      <Text fz={16} c="#6C6C6C" mb={10} styles={{ root: { fontFamily: "SF-pro-display-bold" } }}>Frame Color: <span style={{ color: "black" }}> {variantDetail?.frame_color === 'none' ? 'Not available' : variantDetail?.frame_color}</span> </Text>
      <Text fz={16} c="#6C6C6C" mb={20} styles={{ root: { fontFamily: "SF-pro-display-bold" } }}>Lens Color: <span style={{ color: "black" }}> {variantDetail?.lens_color === 'none' ? 'Not available' : variantDetail?.lens_color}</span> </Text>

      <Group gap={16} mb={24}>
        <Text fz={20} fw={500} c="black">Size: {variantDetail?.size?.length === 0 ? <span style={{ color: "black", fontFamily: "SF-pro-display-bold", fontSize: "16px" }}>Not available</span> : ""} </Text>
        {variantDetail?.size?.map((size, index) => {
          const glassSize = GLASS_SIZE[size]
          return (
            <Box
              onClick={() => {
                dispatch(setProductDetail({
                  ...variantDetail,
                  activeSize: size
                }))
              }}
              id={`${size}_${index}`}
              bd={`2px solid ${size === variantDetail?.activeSize ? "#3354F4" : "#D5D5D5"}`}
              w="80px"
              h="50px"
              style={{ display: "flex", borderRadius: "6px", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
              <Text fz={16} c="#6C6C6C" styles={{ root: { fontFamily: "SF-pro-display-bold" } }}>
                {glassSize}
              </Text>
            </Box>
          )
        })}
      </Group>

      <Group gap={20} align="center" mb={24} styles={{ root: { fontFamily: "SF-pro-display-medium" } }}>
        <Box bg="#F4F4F4" w="200px" h="64px" style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "10px" }}>You can buy it</Box>
        <Box bg="#F4F4F4" w="200px" h="64px" style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "10px" }}>Pay for rewards</Box>
        <Box bg="#F4F4F4" w="200px" h="64px" ta="center" style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "10px" }}>Get by "Tiers program"</Box>
      </Group>

      <Text styles={{ root: { fontFamily: "SF-pro-display-medium" } }} c="#6C6C6C" mb={32}>
        {variantDetail?.description === "['None']" ? '' : variantDetail?.description}
      </Text>

      <Flex styles={{ root: { fontFamily: "SF-pro-display-medium" } }} gap={20}>
        <Button
          styles={{ root: { maxWidth: "100%", width: "300px" } }}
          h={56}
          variant="outline"
          onClick={() => handleAddProduct("ADD_TO_WHISHLIST")}
          fw={500}
        >
          <Text fw={500} fz={18}>Add to Whishlist</Text>
        </Button>
        <Button
          styles={{ root: { maxWidth: "100%", width: "300px" } }}
          bg={cart?.find((item) => item.id === variantDetail.id) && "#F1F3F5"}
          c={cart?.find((item) => item.id === variantDetail.id) && '#ADB5BD'}
          disabled={cart?.find((item) => item.id === variantDetail.id) ? true : false}
          h={56}
          onClick={() => handleAddProduct("ADD_TO_CART")}
        >
          <Text fw={500} fz={18}>Add to cart</Text>
        </Button>
      </Flex>

    </Box>
  )
}

export default ProductDetails;