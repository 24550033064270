import { Box, Flex, Grid, Text } from '@mantine/core';
import React, { useMemo, useState } from 'react'
import CartItems from '../../../components/Employess/cart/cartItems';
import OrderSummary from '../../../components/Employess/cart/orderSummary';
import { useSelector } from 'react-redux';
import { HiOutlineShoppingBag } from "react-icons/hi2";

const Cart = () => {

    const cartItems = useSelector((state) => state.cart.shippingDetails.cart)
    const [paymentTypes, setPaymentTypes] = useState([
        { id: 1, selected: true, value: "Reward payment", points: "Insufficient points" },
        { id: 2, selected: false, value: "Tiers program ", points: "Products ineligible" },
        { id: 3, selected: false, value: "Buy", points: "Buy at own expense" }
    ])

    const totalPrice = useMemo(() => {
        return cartItems?.filter((items) => items?.isSelected)?.reduce((a, b) => a + (b.totalPrice || 0), 0) || 0;
    }, [cartItems]);

    return (
        <Box p={24}>
            <Text fz={24} fw="bold">Shopping Cart</Text>
            <Grid gutter={12}>
                {cartItems?.length > 0 ? (
                    <>
                        <Grid.Col span={{ lg: 7 }}>
                            <CartItems />
                        </Grid.Col>
                        <Grid.Col span={{ lg: 5 }} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {cartItems?.length > 0 && <OrderSummary paymentTypes={paymentTypes} setPaymentTypes={setPaymentTypes} totalPrice={totalPrice} />}
                        </Grid.Col>
                    </>
                ) : (
                    <Grid.Col span={{ lg: 12 }}>
                        <Flex align="center" justify="center" direction="column" styles={{ root: { height: "70vh" } }}>
                            <Box mb={10} bg="#FDFDFD" p={20} style={{ borderRadius: "100%" }}>
                                <HiOutlineShoppingBag size={120} color='#3354F4' />
                            </Box>
                            <Text fz={30} mb={10} ff="SF-pro-display-bold">Your cart is empty</Text>
                            <Text fz={20} c="#6C6C6C" ff="SF-pro-display-medium">Must add items on the cart before your proceed to checkout</Text>
                        </Flex>
                    </Grid.Col>
                )}
            </Grid>
        </Box>
    )
}

export default Cart;