import { useState } from "react";
import { Box, Stack, Text, Title, Flex, Group, Image, Button, Modal, Loader, Grid, Divider } from "@mantine/core"
import { IconEdit } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { CITIES, EMPLOYEE_STATUS_BLOCK, ETHNICITY } from "../../../../choices/employee.choice";
import TableComp from "../../../Table/Table";
import { PrimaryButton } from '../../../ui/Button/Button'
import { useForm } from "@mantine/form";
import { DateInputField, InputField, SelectInputField } from "../../../ui/Input/Input";
import { IoIosArrowDown } from "react-icons/io";
import { useParams } from "react-router-dom";
import ProfileImageUploader from "../../../BaseComponents/ProfileImageUploader";
import { asyncErrorHandler } from "../../../../helpers/errorHandler";
import { CommonDrawer } from "../../../Drawer/Drawer";
import { FaRegBuilding } from "react-icons/fa";
import { createOrganizationAdmin, updateOrganizationAdmin } from "../../../../middlewares/partner/partners.services";
import { GENDER_CHOICES } from "../../../../choices/common.choice";
import { ORGANIZATION_ADMIN } from "../../../../mock/partners/tables/headers/tableHeader";
import { setOrganizationAdmins } from "../../../../store/organization/organizationSlice";

const Admin = () => {

    const organizationAdmins = useSelector(state => state.organizations.organizationAdmins)
    const organizations = useSelector(state => state.organizations.organizationList2)
    const partnerOrg = useSelector(state => state.organizations.organization)
    const formValues = useSelector(state => state.organizations.organizationAdminForm)
    const inviteLoading = useSelector(state => state.organizations.inviteLoading)

    const [companyId, setCompanyId] = useState(null)
    const [editOrgAdmin, setEditOrgAdmin] = useState(null)
    const [imageError, setImageError] = useState("")

    const { id } = useParams()

    const dispatch = useDispatch()

    const form = useForm({
        initialValues: { ...formValues },
        validate: {
            organization_id: (val) => (val === null ? 'Please Select Organization' : null),
            email: (val) => {
                if (!val) return 'Email is required';
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailRegex.test(val) ? null : 'Please enter a valid email address';
            },
            first_name: (val) => (!val ? 'First name is required' : null),
            last_name: (val) => (!val ? 'Last name is required' : null),
            ethnicity: (val) => (!val ? 'Ethnicity is required' : null),
            job_title: (val) => (!val ? 'Job title is required' : null),
            office_phone_number: (val) => (!val ? 'Office phone number is required' : null),
            mobile_phone_number: (val) => (!val ? 'Mobile phone number is required' : null),
            age: (val) => (val === null ? 'Age is required' : null),
            // location: (val) => (!val ? 'Location is required' : null),
            gender: (val) => (!val ? 'Gender is required' : null),
            address: (val) => (!val ? 'Address is required' : null),
            date_of_birth: (val) => (!val ? 'Date of birth is required' : null),
            comments: (val) => (!val ? 'Comments are required' : null),
        }
    });

    const serializeOrgAdminData = () => {
        return organizationAdmins?.results?.map((orgAdmin) => {
            const profileImage = orgAdmin.image?.includes("http") ? orgAdmin.image : `https://api.refraxion.com${orgAdmin.image}`;
            return {
                ...orgAdmin,
                status: EMPLOYEE_STATUS_BLOCK[orgAdmin?.profile.status],
                actions: (
                    <Group
                        onClick={() => {
                            setEditOrgAdmin(orgAdmin)
                            form.setValues({
                                image: profileImage,
                                organization_id: organizations?.find((org) => org.name === orgAdmin?.organization_name)?.id,
                                email: orgAdmin?.email,
                                first_name: orgAdmin?.profile?.first_name,
                                last_name: orgAdmin?.profile?.last_name,
                                ethnicity: orgAdmin?.profile?.ethnicity,
                                job_title: orgAdmin?.profile?.job_title,
                                office_phone_number: orgAdmin?.profile?.office_phone_number,
                                mobile_phone_number: orgAdmin?.profile?.mobile_phone_number,
                                age: orgAdmin?.profile?.age,
                                location: orgAdmin?.profile?.location,
                                gender: orgAdmin?.profile?.gender,
                                address: orgAdmin?.profile?.address,
                                date_of_birth: new Date(orgAdmin?.profile?.date_of_birth),
                                comments: orgAdmin?.profile?.comments,
                            })
                        }}
                        gap={8}
                        justify='center'
                        align='center'
                        c='#868E96'
                    >
                        <IconEdit style={{ width: '16px', height: '16px' }} />
                        <p>Edit</p>
                    </Group>
                )
            }
        })
    }

    const handleSubmit = async () => {
        const organization_name = organizations?.find((org) => org.id === form?.values?.organization_id)
        if (form.validate().hasErrors) {
            form.validate()
            return;
        }
        else {
            const payload = { ...form.values, organization_name: organization_name?.name }
            const formData = new FormData()
            Object.entries(payload).forEach(([key, value]) => {
                if (key === "image" && (typeof value === "string" || value === null)) {
                    return;
                }
                if (key === "location" && (typeof value === "string" || value === null)) {
                    return;
                }
                if (key === "date_of_birth") {
                    const date = new Date(value);
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                    formData.append(key, `${year}-${month}-${day}`)
                }
                else {
                    formData.append(key, value)
                }
            })
            if (editOrgAdmin?.email) {
                const res = await dispatch(updateOrganizationAdmin({ id: editOrgAdmin.pk, payload: formData }))
                if (res.type === "updateOrganizationAdmin/rejected") {
                    asyncErrorHandler(res, form, setImageError)
                } else {
                    setEditOrgAdmin(null)
                    window.toast("Organization admin updated successfully")
                }
            } else {
                const res = await dispatch(createOrganizationAdmin(formData))
                if (res.type === "createOrganizationAdmin/rejected") {
                    asyncErrorHandler(res, form, setImageError)
                } else {
                    setEditOrgAdmin(null)
                    form.reset()
                    window.toast(`Admin created successfully`)
                }
            }
        }
    }

    const handleDelete = async () => {
        // const res = await dispatch(deleteSubContractor(editOrgAdmin?.id))
        // if (res.type === "deleteSubContractor/rejected") {
        //     asyncErrorHandler(res, form)
        // } else {
        //     close()
        //     form.reset()
        //     setEditOrgAdmin(null)
        //     window.toast(`Sub-contractor deleted successfully`)
        // }
    }

    const selectOrganizationAdmin = async (id, pk, parentId) => {
        const updatedOrganizationAdmins = {
            ...organizationAdmins, 
            results: organizationAdmins?.results?.map((organizationAdmin) => {
                if (organizationAdmin.pk === pk) {
                    return {
                        ...organizationAdmin,
                        selected: !organizationAdmin.selected
                    };
                }
                return organizationAdmin;
            })
        };
        dispatch(setOrganizationAdmins(updatedOrganizationAdmins))
    };

    const selectAllOrganizationAdmins = (checked) => {
        const updatedOrganizationAdmins = {
            ...organizationAdmins, 
            results: organizationAdmins?.results?.map((organizationAdmin) => {
                return {
                    ...organizationAdmin,
                    selected: checked
                };
            })
        };
        dispatch(setOrganizationAdmins(updatedOrganizationAdmins));
    };

    return (
        <Box>
            <CommonDrawer
                type=""
                open={editOrgAdmin !== null ? true : false}
                onClose={() => setEditOrgAdmin(null)}
                withCloseButton={false}
            >
                <Box px={24} py={12}>
                    <Group justify="space-between" align="center">
                        <Title order={4}>{editOrgAdmin?.email ? "Edit Admins" : "Create Admins"}</Title>
                        <Flex>
                            <Button mr={10} h={35} size="sm" radius={10} bg="#E9ECEF" c="black" fw="lighter" bd="2px solid #ADB5BD" onClick={() => setEditOrgAdmin(null)}>
                                <Text fz={16} fw={500}>Cancel</Text>
                            </Button>
                            <Button onClick={handleSubmit} h={35} size="sm" radius={10} disabled={inviteLoading} type='submit' bg="#3354F4">
                                <Group>
                                    <Text fz={16} fw={500} c="white">Save Changes</Text>
                                    {inviteLoading && <Loader size="sm" type='dots' color='#ffff' />}
                                </Group>
                            </Button>
                        </Flex>
                    </Group>
                </Box>
                <Divider orientation="horizontal" />
                <Box p={24}>
                    <Stack gap={20} mt={10}>
                        <Grid gutter={20}>
                            {Object.entries(form.values)?.map(([key, value]) => {
                                const formattedKey = key === "organization_id" ? "Organization" : key.split('_').map((k) => k.charAt(0).toUpperCase() + k.slice(1)).join(" ")
                                const dropdownKeys = ['organization_id', 'gender', 'ethnicity', 'location'];
                                const numericKeys = ['office_phone_number', 'mobile_phone_number', 'age']
                                return (
                                    <>
                                        {key === "image" ? (
                                            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                                <ProfileImageUploader
                                                    mb={20}
                                                    profileImageURL={
                                                        value && typeof value === "string" ? value :
                                                            value && value instanceof File ?
                                                                URL.createObjectURL(value) : null
                                                    }
                                                    updateImage={(image) => form.setFieldValue('image', image)}
                                                    deleteImage={() => form.setFieldValue('image', '')}
                                                />
                                            </Grid.Col>
                                        ) : dropdownKeys.includes(key) ? (
                                            <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                                                <SelectInputField
                                                    checkIconPosition="left"
                                                    data={
                                                        key === "location" ? CITIES :
                                                            key === "ethnicity" ? ETHNICITY :
                                                                key === "organization_id" ? organizations?.length === 0 ? [] :
                                                                    organizations.map((f) => ({ value: f.id, label: f.name })) : GENDER_CHOICES
                                                    }
                                                    label={<Text fz={16} fw={600} c="#495057">{formattedKey}</Text>}
                                                    key={form.key(key)}
                                                    {...form.getInputProps(key)}
                                                    placeholder={`Enter your ${formattedKey}`}
                                                    rightSection={<IoIosArrowDown style={{ width: '22px', height: 'auto', marginRight: '5px' }} />}
                                                    defaultValue={value}
                                                />
                                            </Grid.Col>
                                        ) : numericKeys.includes(key) ? (
                                            <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                                                <InputField
                                                    type="number"
                                                    label={<Text fz={16} fw={600} c="#495057">{formattedKey}</Text>}
                                                    placeholder={`Enter your ${formattedKey}`}
                                                    {...form.getInputProps(key)}
                                                    key={form.key(key)}
                                                    size="sm"
                                                />
                                            </Grid.Col>
                                        ) : key === "date_of_birth" ? (
                                            <Grid.Col span={{ base: key === "company_id" ? 12 : 6, md: key === "company_id" ? 12 : 6, lg: key === "company_id" ? 12 : 6 }}>
                                                <DateInputField
                                                    label={<Text fz={16} fw={600} c="#495057">{formattedKey}</Text>}
                                                    key={form.key('date_of_birth')}
                                                    {...form.getInputProps('date_of_birth')}
                                                    placeholder={`Enter your ${formattedKey}`}
                                                />
                                            </Grid.Col>
                                        ) : (
                                            <Grid.Col span={{ base: key === "company_id" ? 12 : 6, md: key === "company_id" ? 12 : 6, lg: key === "company_id" ? 12 : 6 }}>
                                                <InputField
                                                    label={<Text fz={16} fw={600} c="#495057">{formattedKey}</Text>}
                                                    key={form.key(key)}
                                                    {...form.getInputProps(key)}
                                                    placeHolder={`Please Enter ${formattedKey}`}
                                                />
                                            </Grid.Col>
                                        )}
                                    </>
                                )
                            })}
                        </Grid>
                        {editOrgAdmin?.id && (
                            <Group justify="end" mt={10}>
                                <Button onClick={handleDelete} h={35} variant="light" radius={10} style={{ background: '#FFC9C9' }}>
                                    <Text fz={16} fw={500} c="#C92A2A">Delete</Text>
                                </Button>
                            </Group>
                        )}
                    </Stack>
                </Box>
            </CommonDrawer>
            <Flex
                direction="column"
                justify="flex-start"
                gap={24}
                mr={24}
                my={24}
            >
                <Stack gap={12}>
                    <Title fz={24} fw={700}>Manage and Create Admins</Title>
                    <Text fz={16} fw={400}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
                </Stack>
                <Stack>
                    <Stack gap={12} mb={24}>
                        <Group grow justify="space-between" align="center" wrap='nowrap'>
                            <Group>
                                {(partnerOrg?.logo === "" || partnerOrg?.logo === null) ? (
                                    <FaRegBuilding size={30} color="grey" />
                                ) : (
                                    <Image src={partnerOrg?.logo} w={36.98} h={36.98} fit='contain' />
                                )}
                                <Title order={3}>{partnerOrg?.name}</Title>
                            </Group>
                            <PrimaryButton
                                onClick={() => {
                                    setEditOrgAdmin(true)
                                    form.reset()
                                    form.setValues({
                                        image: null || "",
                                        email: "",
                                        first_name: "",
                                        last_name: "",
                                        ethnicity: "",
                                        job_title: "",
                                        office_phone_number: "",
                                        mobile_phone_number: "",
                                        age: null,
                                        location: "",
                                        gender: null,
                                        address: "",
                                        date_of_birth: null,
                                        comments: "",
                                    })
                                }}
                                maw={100}
                                color="teal.8"
                                radius={6}
                                title="Create"
                                h={42}
                                fw={600}
                            />
                        </Group>
                        <TableComp
                            tableHeaders={ORGANIZATION_ADMIN}
                            data={serializeOrgAdminData()}
                            totalRecords={serializeOrgAdminData()?.length}
                            totalPages={1}
                            onChange={selectOrganizationAdmin}
                            onChange2={selectAllOrganizationAdmins}
                            showPagination={true}
                            isCheckBox={true}
                            height='375px'
                            errorMessage2={
                                <>
                                    {organizationAdmins && organizationAdmins?.length === 0 ? (
                                        <Stack gap={1} ta={'center'}>
                                            <Title order={4}>No Organization Admin Added </Title>
                                            <Text c="dimmed" fz={14}>Press the create button to add a admin to organization</Text>
                                        </Stack>
                                    ) : null}
                                </>
                            }
                        />
                    </Stack>
                </Stack>
            </Flex>
        </Box>
    )
}

export default Admin;