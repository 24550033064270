import {
    IconLayoutDashboard,
    IconUsers,
    IconAdjustments,
    IconBell,
    IconChartPie2,
    IconWallet,
    IconShieldHalf,
    IconShoppingBag,
    IconSettings2,
    IconBadge,
    IconMail
} from '@tabler/icons-react';

export const ADMIN_ROUTES = [
    {
        name: 'Dashboard',
        icon: <IconLayoutDashboard size={16} />,
        link: '/admin/dashboard',
        subMenus: [],
    },
    {
        name: 'Employee Management',
        icon: <IconUsers size={16} />,
        link: '/admin/employee-management',
        subMenus: [],
    },
    {
        name: 'Notifications',
        icon: <IconBell size={16} />,
        link: '/admin/notification',
        subMenus: [],
    },
    {
        name: 'Reporting and Analytics',
        icon: <IconChartPie2 size={16} />,
        link: '/admin/reporting-analytics',
        subMenus: [],
    },
    {
        name: 'Settings',
        icon: <IconSettings2 size={16} />,
        link: '/admin/admin-settings',
        subMenus: [],
    },
    {
        name: 'Tier Management',
        icon: <IconAdjustments size={16} />,
        link: '/admin/tier-management',
        subMenus: [],
    },
    {
        name: 'Contact us',
        icon: <IconMail size={16} />,
        link: '/admin/contact-us',
        subMenus: [],
    },
    // {
    //     name: 'Orders',
    //     icon: <IconShoppingBag size={16} />,
    //     link: '/admin/orders',
    //     subMenus: [],
    // },
    // {
    //     name: 'Billing and Payments',
    //     icon: <IconWallet size={16} />,
    //     link: '/admin/billing',
    //     subMenus: [],
    // },
    {
        name: 'Safety Awards',
        icon: <IconShieldHalf size={16} />,
        link: '/admin/safety-awards',
        subMenus: [],
    },
    // {
    //     name: 'Company Settings',
    //     icon: <IconSettings2 size={16} />,
    //     link: '/admin/company-settings',
    //     subMenus: [],
    // },
];

export const PARTNER_ROUTES = [
    {
        name: 'Dashboard',
        icon: <><IconLayoutDashboard size={16} /></>,
        link: '/partner/dashboard',
        subMenus: [],
    },
    {
        name: 'Company Management',
        icon: <><IconUsers size={16} /></>,
        link: '/partner/company-management',
        subMenus: [],
    },
    {
        name: 'Notifications',
        icon: <><IconBell size={16} /></>,
        link: '/partner/notifications',
        subMenus: [],
    },
    {
        name: 'Reporting and Analytics',
        icon: <><IconChartPie2 size={16} /></>,
        link: '/partner/reporting-and-analytics',
        subMenus: [],
    },
    {
        name: 'Settings',
        icon: <><IconSettings2 size={16} /></>,
        link: '/partner/settings',
        subMenus: [],
    },
    {
        name: 'Tier Management',
        icon: <><IconBadge size={16} /></>,
        link: '/partner/tier-management',
        subMenus: [],
    },
    {
        name: 'Contact us',
        icon: <><IconMail size={16} /></>,
        link: '/partner/contact-us',
        subMenus: [],
    },
];

export const EMPLOYEE_ROUTES = [
    {
        name: 'Dashboard',
        icon: <><IconLayoutDashboard size={16} /></>,
        link: '/employee/dashboard',
        subMenus: [],
    },
    {
        name: 'My Prescriptions',
        icon: <><IconUsers size={16} /></>,
        link: '/employee/prescription',
        subMenus: [],
    },
    {
        name: 'RFXN Store',
        icon: <><IconBell size={16} /></>,
        link: '/employee/store',
        subMenus: [],
    },
    {
        name: 'My orders',
        icon: <><IconChartPie2 size={16} /></>,
        link: '/employee/my-orders',
        subMenus: [],
    },
    {
        name: 'Safety Awards',
        icon: <><IconSettings2 size={16} /></>,
        link: '/employee/safety-awards',
        subMenus: [],
    },
    {
        name: 'Settings',
        icon: <><IconBadge size={16} /></>,
        link: '/employee/settings',
        subMenus: [],
    },
    {
        name: 'Contact us',
        icon: <><IconBadge size={16} /></>,
        link: '/employee/contact-us',
        subMenus: [],
    },
];

export function renderTabs(role) {
    if (role) {
        if (role === "admin") {
            return ADMIN_ROUTES;
        } else if (role === "partner") {
            return PARTNER_ROUTES;
        } else if (role === "employee") {
            return EMPLOYEE_ROUTES;
        } else {
            return null;
        }
    } else {
        return [];
    }
}