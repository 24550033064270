import React, { useEffect, useState } from 'react'
import SimpleSlider from '../../components/PartnerSignup/slider.js'
import { Box, Flex, Grid, Text } from "@mantine/core";
import PartnerSignUpForm from '../../components/PartnerSignup/signUpForm.js';
import PartnerOrganizationSignUpForm from '../../components/PartnerSignup/PartnerOrganizationSignUp.js';
import PartnerAdminAssignForm from '../../components/PartnerSignup/PartnerAdmin/PartnerAdminAssignForm.js';
import { useLocation, useNavigate } from 'react-router-dom';
import Steps from '../../components/Steps/Steps.js';

import { LuUser } from "react-icons/lu";
import { FaCheck } from "react-icons/fa6";
import { FiUserPlus } from "react-icons/fi";
import { RiBuilding4Line } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { IoRocketOutline } from "react-icons/io5";
import vector1 from '../../assets/images/vector-group.svg'

import classes from '../pages.module.css'
import PersonalDetails from '../../components/PartnerSignup/PersonalDetails.js';
import PartnerSteps from '../../components/Steps/PartnerSteps.js';
import SplashScreen from '../splashScreen.js';

const PatnerSignup = () => {

    const location = useLocation()
    const [isMobile, setIsMobile] = useState(false)
    const [stepper, setStepper] = useState(location.state?.step ? location.state?.step : 1)
    const navigate = useNavigate()
    const [width, setWidth] = useState(window.innerWidth)
    const [showSplashScreen, setShowSplashScreen] = useState(false);

    const [steps, setSteps] = useState([
        { id: 1, Icon: LuUser, active: false, step: "Personal details", desc: "" },
        { id: 2, Icon: FiUserPlus, active: false, step: "Partner branding ", desc: "" },
        { id: 3, Icon: RiBuilding4Line, active: false, step: "Invite company", desc: "" },
        { id: 4, Icon: FiUsers, active: false, step: "Welcome to RFXN!", desc: "" },
    ]);

    useEffect(() => {
        window.addEventListener('resize', function (event) {
            if (window.innerWidth <= 992) {
                setIsMobile(true)
            }
            else {
                setIsMobile(false)
            }
            setWidth(window.innerWidth)
        }, true);
    }, [])

    useEffect(() => {
        if (stepper > 1) {
            window.history.pushState({ stepper }, '', window.location.href);
        }
    }, [stepper]);

    useEffect(() => {
        window.history.replaceState({ stepper: 1 }, '', window.location.href);
        const handlePopState = (event) => {
            if (event.state?.stepper) {
                if (event.state.stepper < stepper) {
                    if (stepper > 1) {
                        navigate("/")
                    }
                } else {
                    setStepper(event.state.stepper);
                }
            }
        };
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [stepper]);

    const renderForm = () => {
        if (showSplashScreen) {
            return <SplashScreen setShowSplashScreen={setShowSplashScreen} />
        }
        if (stepper === 1) {
            return (
                <Grid h="100vh">
                    {isMobile ? (
                        <Grid.Col span={{ base: 12 }} h="100vh" py={'32px'}>
                            <PartnerSignUpForm setStepper={setStepper} setShowSplashScreen={setShowSplashScreen} />
                        </Grid.Col>
                    ) : (
                        <>
                            <Grid.Col span={{ base: 12, sm: 6, md: 5.5, lg: 5.5 }} h="100vh">
                                <SimpleSlider />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, sm: 6, md: 6.5, lg: 6.5 }} h="100vh">
                                <PartnerSignUpForm setStepper={setStepper} setShowSplashScreen={setShowSplashScreen} />
                            </Grid.Col>
                        </>
                    )}
                </Grid>
            )
        } else {
            return !showSplashScreen && <Flex display="flex" h="100%" direction={width < 768 ? "column" : "row"}>
                <Box
                    className={classes.bg}
                    p={0}
                    h="100%"
                    w={width < 768 ? "100%" : "396px"}
                    mih={width < 991 ? "fit-content" : "100vh"}
                    style={{
                        backgroundImage: `url(${vector1})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <Box w={width < 768 ? "100%" : width < 1025 ? "655px" : "396px"} p={50} pos="relative" h={width < 768 ? "fit-content" : "100vh"}>
                        <Text w={width < 1025 ? "296px" : "100%"} ff="Helvetica-bold" c="white" fz={24}>Set up your partner account in a few clicks.</Text>
                        <Text w={width < 1025 ? "296px" : "100%"} mt={20} mb={50} style={{ fontFamily: '"Inter", sans-serif' }} c="#E9EAEA" fz={16}>Simplified Eyewear Solutions Powered by Intelligent Technology</Text>
                        <PartnerSteps activeStep={stepper} steps={steps} setSteps={setSteps} width={width} />
                    </Box>
                </Box>
                <Flex
                    w="100%"
                    h={width < 768 ? '100%' : '100vh'}
                    pt="54px"
                    pb="25px"
                    gap={0}
                    pos="relative"
                    align="center"
                    direction="column"
                    mb={width < 768 ? "60px" : "0"}
                    style={{ overflowY: "auto" }}
                >
                    {stepper === 2 && <PersonalDetails setStepper={setStepper} isMobile={isMobile} width={width} />}
                    {stepper === 3 && <PartnerOrganizationSignUpForm setStepper={setStepper} isMobile={isMobile} width={width} />}
                    {stepper === 4 && <PartnerAdminAssignForm setStepper={setStepper} stepper={stepper} width={width} />}
                </Flex>
            </Flex>
        }
    }

    return renderForm()

}

export default PatnerSignup;