import { Box, Divider, Group, Stack, Button, Loader, Grid, Flex, Title, TextInput, Select, Text } from "@mantine/core"
import { useForm } from "@mantine/form"
import { useEffect, useState } from "react"
import { useViewportSize } from '@mantine/hooks';
import { useSelector, useDispatch } from "react-redux"
import { updatePartnerOrganization } from "../../middlewares/partner/partners.services"
import { notifications } from "@mantine/notifications"
import { INDUSTRY_SERVICED, ORGANIZATIONS_EMPLOYEES_AT_SITE_SIZE_CHOICES } from "../../choices/organizationChoices"
import ProfileImageUploader from "../BaseComponents/ProfileImageUploader"
import ImageResizer from "../ImageResizer/ImageResizer"
import { asyncErrorHandler } from "../../helpers/errorHandler";
import { PrimaryButton } from "../ui/Button/Button";

const PartnerCompanyProfile = () => {

    const [loading, setLoading] = useState(false)
    const [imageError, setImageError] = useState("")
    const { height, width } = useViewportSize();
    const dispatch = useDispatch()
    const organization = useSelector(state => state.organizations.organization)

    const form = useForm({
        initialValues: {
            name: '',
            company_type: '',
            site_name: '',
            employees_at_site: '',
            logo: null || '',
        },
        validate: {
            name: (val) => (/^(?=.*[A-Za-z])(?!^[\d\s-]+$)(?!^[^\w\s-]+$)[A-Za-z\d\s-]+$/.test(val) ? null : 'Please enter a valid name'),
            company_type: (val) => (/^(?:[1-9]|[1-2][0-9]|30)$/.test(val) ? null : "Please select one of the company types"),
            employees_at_site: (val) => (/\b[12345]\b$/.test(val) ? null : 'Number of employees is required'),
            site_name: (val) => (/^(https?|ftp):\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$|^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/i.test(val) ? null : 'Please enter a valid site url'),
        }
    })

    useEffect(() => {
        form.setValues({
            logo: organization?.logo,
            name: organization?.name,
            company_type: organization?.company_type,
            site_name: organization?.site_name,
            employees_at_site: organization?.employees_at_site,
        })
    }, [organization])

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (form.validate().hasErrors) {
            form.validate()
        }
        if (!form.validate().hasErrors && Object.hasOwn(organization, 'id')) {
            const formData = new FormData()
            Object.entries(form.values).forEach(([key, value]) => {
                if (key === "logo" && typeof value === "string") {
                    return;
                } if (key === "logo" && value instanceof File) {
                    formData.append(key, value);  // Append the File to formData
                } if (key === "site_name") {
                    formData.append("site_name", value.includes("https://") ? value : `https://${value}`)
                } else {
                    formData.append(key, value);  // Append other fields normally
                }
            });
            setLoading(true)
            const res = await dispatch(updatePartnerOrganization(formData))
            if (updatePartnerOrganization.fulfilled.match(res)) {
                setLoading(false)
                window.toast('Organization details updated successfully')
            }
            else if (updatePartnerOrganization.rejected.match(res)) {
                setLoading(false)
                asyncErrorHandler(res, form, setImageError)
                // notifications.show({ message: 'Error occured while updating organization details', autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            }
        }
    }

    const commonStyle = {
        label: {
            fontSize: '16px',
            marginBottom: "10px",
            lineHeight: "18px",
            fontWeight: "600",
            color: "#424242"
        },
        input: {
            height: "40px",
            fontSize: "14px",
            padding: "12px",
            lineHeight: "16px",
            borderRadius: "6px",
            backgroundColor: "transparent",
        },
        error: {
            fontSize: '14px',
            marginTop: "5px",
            lineHeight: "16px",
        },
        required: {
            color: '#3354F4'
        }
    }

    function setResizedImage(file) {
        form.setFieldValue('logo', file)
    }

    return (
        <Box px={20} h={height - 60} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <form onSubmit={handleSubmit}>
                <Grid columns={12} h={{ md: 'fit-content', lg: height - 60, }}>
                    <Grid.Col span={{ sm: 12, md: 12, lg: 6, xl: 6 }} style={{ borderRight: width < 1200 ? 'none' : '1px solid rgb(222, 226, 230)' }} h={{ md: 'fit-content', lg: height - 52, }}>
                        <Box pr={{ xs: 19, sm: 19, md: 19, xl: 82 }} pt={24}>
                            <Stack gap={40} style={{ height: '100%' }}>
                                <Stack gap={2}>
                                    <Title order={3}>Enter your company info</Title>
                                    <Text span c="dimmed" fz={16} w={width < 768 ? "100%" : 400}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
                                </Stack>
                                <Stack>
                                    <Group grow>
                                        <TextInput
                                            label={'Company name'}
                                            placeholder="Please enter company name"
                                            key={form.key("name")}
                                            {...form.getInputProps('name')}
                                            style={{ position: 'relative' }}
                                            styles={{ ...commonStyle }}
                                        />
                                        <TextInput
                                            label='Company Website'
                                            placeholder="Please enter site url"
                                            key={form.key("site_name")}
                                            {...form.getInputProps('site_name')}
                                            style={{ position: 'relative' }}
                                            styles={{ ...commonStyle }}
                                        />
                                    </Group>
                                    <Select
                                        label="Company Type"
                                        placeholder="Please select company size"
                                        data={INDUSTRY_SERVICED}
                                        key={form.key("company_type")}
                                        {...form.getInputProps('company_type')}
                                        style={{ position: 'relative' }}
                                        styles={{ ...commonStyle }}
                                    />
                                    <Select
                                        label="Number of employees at site"
                                        placeholder="Please select number of employees"
                                        key={form.key("employees_at_site")}
                                        {...form.getInputProps('employees_at_site')}
                                        data={ORGANIZATIONS_EMPLOYEES_AT_SITE_SIZE_CHOICES}
                                        style={{ position: 'relative' }}
                                        styles={{ ...commonStyle }}
                                    />
                                </Stack>
                            </Stack>
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={{ sm: 12, md: 12, lg: 6, xl: 6 }} >
                        <Box pr={{ xs: 19, sm: 19, md: 19, xl: 61 }} pl={width < 768 ? 0 : 19} py={24}>
                            <Box mb={40}>
                                <Title order={3} mb={12}>Company appearance</Title>
                                <Text span c="dimmed" fz={16} w={400}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
                            </Box>
                            <ProfileImageUploader
                                labelText="Upload logo company"
                                isCompany={true}
                                // profileImageURL={form.values.logo ? URL.createObjectURL(form.values.logo) : profileImageURL}
                                profileImageURL=
                                {
                                    form.values.logo && typeof form.values.logo === "string" ? form.values.logo :
                                        form.values.logo && form.values.logo instanceof File ?
                                            URL.createObjectURL(form.values.logo) : null
                                }
                                updateImage={(image) => {
                                    form.setFieldValue('logo', image)
                                    setImageError("")
                                }}
                                deleteImage={() => {
                                    form.setFieldValue('logo', '')
                                    setImageError("")
                                }}
                            />
                            {imageError && <p style={{ fontSize: "14px", color: "#FA5252", marginTop: "5px" }}>{imageError}</p>}
                            <ImageResizer
                                image={form.values.logo && form.values.logo instanceof File ? form.values.logo : null} // Pass the file to the resizer, ensure it's a file
                                setFile={setResizedImage}
                            />
                            {/* <Button disabled={loading} type='submit' bg="#3354F4" size="md" fullWidth>
                                <Group>
                                    <span>Save Changes</span>
                                    {loading && <Loader size="sm" type='dots' color='#ffff' />}
                                </Group>
                            </Button> */}
                            <PrimaryButton
                                type="submit"
                                title="Save Changes"
                                disabled={loading}
                                fallback={loading ? <Loader size="sm" color="white" type='dots' /> : null}
                                fullWidth
                            />
                        </Box>
                    </Grid.Col>
                </Grid>
            </form>
        </Box >
    )
}

export default PartnerCompanyProfile