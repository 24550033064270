import { Carousel } from '@mantine/carousel';
import { Text, Image, Box } from '@mantine/core'

import logo2 from "../../assets/icons/safety-vision.svg";
import srxLogo from "../../assets/icons/srx-logo.svg";
import bechtelLogo from "../../assets/icons/bechtel-corp-new.svg";

import hippaLogo from '../../assets/icons/hippa.svg'
import awsLogo from '../../assets/icons/aws-logo.svg'
import sbaLogo from '../../assets/icons/sba-logo.svg'

import '@mantine/carousel/styles.css';
import { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';

function CarouselSlider() {
    const autoplay = useRef(Autoplay({ delay: 2000 }));
    return (
        <Carousel plugins={[autoplay.current]} withIndicators={false} height={100} withControls={false} loop slideGap="xl">
            <Carousel.Slide>
                <Image src={logo2} w="200px" style={{ objectFit: "contain", display: "block", margin: "0 auto" }} />
            </Carousel.Slide>
            <Carousel.Slide>
                <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Box>
                        <Text style={{ color: "#5C5F66" }}>Powered By</Text>
                        <img src={srxLogo} width="200px" alt='' style={{ objectFit: "contain" }} />
                    </Box>
                </Box>
            </Carousel.Slide>
            <Carousel.Slide>
                <img src={bechtelLogo} width="100px" alt='' style={{ display: "block", margin: "0 auto", objectFit: "contain" }} />
            </Carousel.Slide>
        </Carousel>
    );
}

function CarouselSlider2() {
    const autoplay = useRef(Autoplay({ delay: 2000 }));
    return (
        <Carousel plugins={[autoplay.current]} withIndicators={false} height={100} w="100%" withControls={false} loop slideGap="xl">
            <Carousel.Slide>
                <img src={hippaLogo} width="150px" alt="" style={{ objectFit: "contain", display: "block", margin: "0 auto" }} />
            </Carousel.Slide>
            <Carousel.Slide>
                <img src={awsLogo} width="100px" alt="" style={{ objectFit: "contain", display: "block", margin: "0 auto" }} />
            </Carousel.Slide>
            <Carousel.Slide>
                <img src={sbaLogo} width="100px" alt="" style={{ objectFit: "contain", display: "block", margin: "0 auto" }} />
            </Carousel.Slide>
        </Carousel>
    );
}

export { CarouselSlider, CarouselSlider2 };