import { Popover } from '@mantine/core'
import React from 'react'

const PopoverMenu = ({ displayMenu, children }) => {
    return (
        <Popover
            position="bottom"
            withArrow
        >
            <Popover.Target>
                {children}
            </Popover.Target>
            <Popover.Dropdown>
                {displayMenu}
            </Popover.Dropdown>
        </Popover>
    )
}

export default PopoverMenu