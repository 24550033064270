import { combineReducers, configureStore } from "@reduxjs/toolkit";
import commonReducer from "./common/commonSlice";
import authReducer from "./auth/authSlice";
import storage from 'redux-persist/lib/storage';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import employeesSlice from "./employess/employeesSlice";
import tierSlice from "./tiers/tierSlice";
import notificationSlice from "./notifications/notificationSlice";
import reportingSlice from "./reporting/reportingSlice";
import billingSlice from "./billing/billingSlice";
import safetyAwardsSlice from "./safetyAwards/safetyAwardsSlice";
import settingSlice from "./settings/settingSlice";
import organizationSlice from "./organization/organizationSlice";
import filterSlice from './filters/filterSlice'
import cartSlice from './employess/cartSlice';
import productSlice from './employess/productSlice';
import prescriptionSlice from './employess/prescription';
import addressSlice from './employess/addressSlice';
import summarySlice from './employess/summarySlice';
import measurementSlice from './employess/measurementSlice'
import statisticsSlice from "./admin/StatisticsSlice";
import stepSlice from "./employess/stepSlice";
import orderSlice from './employess/ordersSlice'

const allreducers = combineReducers({
  common: commonReducer,
  login: authReducer,
  statistics: statisticsSlice,
  employee: employeesSlice,
  cart: cartSlice,
  orders: orderSlice,
  product: productSlice,
  tiers: tierSlice,
  notifications: notificationSlice,
  reporting: reportingSlice,
  billing: billingSlice,
  safetyAwards: safetyAwardsSlice,
  settings: settingSlice,
  organizations: organizationSlice,
  prescription: prescriptionSlice,
  summary: summarySlice,
  address: addressSlice,
  filters: filterSlice,
  steps: stepSlice,
  measurements: measurementSlice
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: [
    'login',
    'user',
    'common',
    'orders',
  ]
};

const persistedReducer = persistReducer(persistConfig, allreducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      serializableCheck: false
    }),
});

export const persistor = persistStore(store);