import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAddress, setFetchingAddress, setLoading } from "../../store/employess/addressSlice";
import apiClient from "../../services/api";

let apiInstance = apiClient()

export const getAddresses = createAsyncThunk(
    "get/address",
    async (_, { getState, dispatch, rejectWithValue }) => {
        dispatch(setFetchingAddress(true))
        try {
            const res = await apiInstance.get('/api/address/list/')
            dispatch(setAddress(res.data))
            dispatch(setFetchingAddress(false))
            return res
        }
        catch (err) {
            dispatch(setFetchingAddress(false))
            return rejectWithValue(err)
        }
    }
)

export const addNewAddress = createAsyncThunk(
    "add/addNewAddress",
    async (body, { getState, dispatch, rejectWithValue }) => {
        dispatch(setLoading(true))
        try {
            const res = await apiInstance.post('/api/address/create/', body)
            const updatedAddress = [...getState().address.address, res.data]
            dispatch(setAddress(updatedAddress))
            dispatch(setLoading(false))
            return res
        }
        catch (err) {
            dispatch(setLoading(false))
            return rejectWithValue(err)
        }
    }
)

export const updateAddress = createAsyncThunk(
    "update/updateAddress",
    async (body, { getState, dispatch, rejectWithValue }) => {
        dispatch(setLoading(true))
        try {
            const res = await apiInstance.patch(`/api/address/${body.id}/update/`, body.payload);
            const updatedAddress = getState().address.address.map((item) => (item.id === res.data.id ? res.data : item));
            dispatch(setAddress(updatedAddress))
            dispatch(setLoading(false))
            return res.data;
        } catch (err) {
            dispatch(setLoading(false))
            return rejectWithValue(err.response?.data || err.message);
        }
    }
);

export const deleteAddress = createAsyncThunk(
    "address/deleteAddress",
    async (id, { getState, dispatch, rejectWithValue }) => {
        dispatch(setLoading(true))
        try {
            const res = await apiInstance.delete(`/api/address/${id}/delete/`);
            const updatedAddress = getState().address.address.filter((item) => item.id !== id);
            dispatch(setAddress(updatedAddress))
            dispatch(setLoading(false))
            window.toast(`Address deleted successfully`)
            return res.data;
        } catch (err) {
            dispatch(setLoading(false))
            return rejectWithValue(err.response?.data || err.message);
        }
    }
);