import React, { useState } from 'react';
import classes from './notification.module.css'
import notificationIcon from '../../assets/icons/notification-icon.png'
import { Button, Grid, Input, Menu, Popover } from '@mantine/core';
import { CiCalendarDate } from "react-icons/ci";
import { IoSearch } from 'react-icons/io5';
import { BsThreeDots } from "react-icons/bs";
import { DatePicker } from '@mantine/dates';

const ScheduleNotificationCard = ({ width, handleDelete }) => {
    return (
        <div className={classes.scheduleNotificationCard} style={{
            marginBottom: width < 991 ? "20px" : ""
        }}>
            <div style={{
                padding: "12px"
            }}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
                    <img src={notificationIcon} alt='' />
                    <p className={classes.scheduleNotificationCardTitle}>Bechtel Corp</p>
                </div>
                <p className={classes.scheduleNotificationCardTitle2}>Confirm your choose!</p>
                <p className={classes.scheduleNotificationCardDesc}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
            </div>
            <div className={classes.recipient}>
                <p style={{ fontSize: "14px", color: "#868E96", fontWeight: "400" }}> Recipients: <span style={{ color: '#101113' }}>All new users</span> </p>
            </div>
            <div className={classes.type}>
                <p style={{ fontSize: "14px", color: "#868E96", fontWeight: "400" }}> Type: <span style={{ color: '#101113' }}>All new users</span> </p>
            </div>
            <div className={classes.type}>
                <p style={{ fontSize: "14px", color: "#FD7E14", fontWeight: "400" }}>Tue Februrary 13th 2024</p>
                <p style={{ fontSize: "14px", color: "#868E96", fontWeight: "400" }}> 10:21 AM </p>

                <Menu shadow="md" width={73}>
                    <Menu.Target>
                        <button style={{
                            backgroundColor: "transparent",
                            margin: 0,
                            padding: 0,
                            border: "none"
                        }}>
                            <BsThreeDots color="#868E96" />
                        </button>
                    </Menu.Target>

                    <Menu.Dropdown>
                        <Menu.Item fw={600} color="#495057">
                            Edit
                        </Menu.Item>
                        <Menu.Item fw={600} color="#495057" onClick={handleDelete}>
                            Delete
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>

            </div>
        </div>
    )
}

const ScheduleNotification = ({ width, value, setValue }) => {

    const [scheduledNotifications, setScheduledNotifications] = useState([
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 },
        { id: 6 },
    ])

    function handleEdit(value) { }
    
    function handleDelete(id) {
        setScheduledNotifications((prevScheduledNotifications) => {
            return prevScheduledNotifications.filter((val) => val.id !== id)
        })
    }

    return (
        <div className={classes.scheduleNotification}>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}>
                <p className={classes.scheduleNotificationTitle}>All Scheduled Notifications</p>
                <div style={{ display: "flex", position: "relative" }}>
                    <IoSearch size={17} style={{ position: "absolute", top: "25%", zIndex: 1000, left: 10, color: "#ADB5BD" }} />
                    <Input placeholder="Search" className={classes.searchNotifications} />
                    <Popover
                        position="bottom"
                        withArrow
                    >
                        <Popover.Target>
                            <Button
                                style={{
                                    backgroundColor: '#E9ECEF',
                                    border: "2px solid #ADB5BD",
                                    borderRadius: "6px",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    color: "#343A40",
                                    marginLeft: "10px",
                                }}
                            >
                                <CiCalendarDate style={{ marginRight: 10, color: "#ADB5BD" }} />
                                Choose Date
                            </Button>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <DatePicker value={value} onChange={setValue} />
                        </Popover.Dropdown>
                    </Popover>
                </div>
            </div>
            <Grid gutter={12} mt={24}>
                {scheduledNotifications.map((value, index) => {
                    return (
                        <Grid.Col key={value.id} span={{ lg: 4, md: 6, sm: 12 }}>
                            <ScheduleNotificationCard width={width} handleDelete={() => handleDelete(value.id)} handleEdit={handleEdit} />
                        </Grid.Col>
                    )
                })}
            </Grid>
        </div>
    )
}

export default ScheduleNotification;