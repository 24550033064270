import PartnerCompanyProfile from "../../components/Settings/PartnerOrganizationProfile";
import TabBaseLayout from "../../components/Layout/TabBaseLayout/TabBaseLayout";

const PartnerSettings = () => {

    const tabList = [
        { value: 'company_profile', label: 'Company Profile' },
        { value: 'security_settings', label: 'Security Settings' },
    ]

    return (
        <TabBaseLayout tabList={tabList}>
            <PartnerCompanyProfile />
        </TabBaseLayout>
    )
}

export default PartnerSettings;