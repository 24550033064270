import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.css";
import slide1 from "../../assets/images/group.png";
import slide2 from "../../assets/images/slide1.png";
import slide3 from "../../assets/images/group2.png";
import srxLogo from '../../assets/icons/srx-logo.svg';

import vector1 from '../../assets/images/vector-group.svg'

import { Box, Center, Flex, Group, Text } from "@mantine/core";

export default function SimpleSlider() {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
    };
    return (
        <div className="slider-container" style={{ height: "100%" }}>
            <Slider {...settings}>
                <div style={{ height: "100vh" }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            background: '#16191C',
                            backgroundImage: `url(${vector1})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            height: "100vh",
                            padding: "75px 20px 80px",
                        }}
                    >
                        <Flex justify="center" direction="column" align="center">
                            <img width={154} src={srxLogo} alt="" />
                            <Text ff="Helvetica" size={'24px'} fw={400} c={'#FFFFFF'} mt={12}>Integrated Eyewear CRM System</Text>
                        </Flex>
                        <img src={slide1} />
                        <Text w={490} ff="Helvetica" size={'20px'} fw={400} c={'#BBBCBD'} mb={'63px'} lh={1.17} ta={'center'}>
                            Simplified Eyewear Solutions Powered by Intelligent Technology
                        </Text>
                    </div>
                </div>
                <div style={{ height: "100vh" }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            background: '#16191C',
                            backgroundImage: `url(${vector1})`,
                            height: "100vh",
                            padding: "75px 20px 80px",
                        }}
                    >
                        <Flex justify="center" direction="column" align="center" ta={'center'}>
                            <img width={154} src={srxLogo} alt="" />
                            <Text w={490} ff="Helvetica" size={'24px'} fw={400} c={'#FFFFFF'} mt={12}>Provides an Adaptive Eyewear CRM Solution to Simplify Complex Services</Text>
                        </Flex>
                        <img src={slide2} />
                        <Text w={490} ff="Helvetica" size={'20px'} fw={400} c={'#BBBCBD'} mb={'63px'} lh={1.17} ta={'center'}>
                            Intelligently manage, communicate and track all services provided to your partners, customers and employees
                        </Text>
                    </div>
                </div>
                <div style={{ height: "100vh" }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            background: '#16191C',
                            backgroundImage: `url(${vector1})`,
                            height: "100vh",
                            padding: "75px 20px 80px",
                        }}
                    >
                        <Flex justify="center" direction="column" align="center" ta={'center'}>
                            <img width={154} src={srxLogo} alt="" />
                            <Text w={490} ff="Helvetica" size={'24px'} fw={400} c={'#FFFFFF'} mt={12}>Optimize, Synchronize, and Promote Your Products with Our Eyewear CRM Solution</Text>
                        </Flex>
                        <img src={slide3} />
                        <Text w={490} ff="Helvetica" size={'20px'} fw={400} c={'#BBBCBD'} mb={'63px'} lh={1.17} ta={'center'}>
                            Effortlessly manage, collaborate, and provide reporting with upmost confidence.
                        </Text>
                    </div>
                </div>
            </Slider>
        </div>
    );
}