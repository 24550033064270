import React, { useState } from 'react'
import { Grid, Divider, Box, Stack, Group, Text, Button, Avatar, Image, Title, Progress, Tabs, Loader, Card } from '@mantine/core';
import DashboardCard from '../../components/Dashboard/card';
import Tab from '../Tabs/Tabs';
import { PrescripitionTabs } from '../../mock/employees/tabs/tabs';
import TableComp from "../../components/Table/Table"
import { EMPLOYEE_PRESCRIPTIONS_TABLE_HEADERS, EMPLOYEE_MEASUREMENT_TABLE_HEADERS } from '../../mock/employees/table/header';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton2, OutlineButton, FileUploadButton } from '../ui/Button/Button';
import classes from '../Dashboard/card.module.css'
import { exportCSVHandler, importCSVHandler } from '../../helpers/CSVHandler';
import { setPrescriptions } from '../../store/employess/prescription';
import * as XLSX from "xlsx";

const Prescription = () => {

    const measurementsData = useSelector((state) => state.measurements.measurements)
    const isLoading = useSelector((state) => state.measurements.isLoading)
    const prescriptions = useSelector((state) => state.prescription.prescriptionList)
    const prescriptionLoading = useSelector((state) => state.prescription.prescriptionLoading)
    const dispatch = useDispatch()

    const renderTable = (loading, data, tableHeaders, message, measurementData) => {
        return (
            loading ? (
                <Box p={40}>
                    <Loader size="xl" color="#BABABA" type='dots' m="auto" />
                </Box>
            ) : loading === false && data?.length > 0 ? (
                <TableComp
                    tableHeaders={tableHeaders}
                    data={data?.length > 0 ? data : []}
                    isCheckBox={true}
                    measurementData={measurementData}
                    setExportCSVData={setExportCSVData}
                    onChange2={onChange2}
                    type="employee"
                />
            ) : loading === false && data?.length === 0 ? (
                <Box py={60}>
                    <Stack align='center' justify='center' gap={6}>
                        <Title size={20}>{message}</Title>
                        {message === "No prescriptions found" && <Text c="dark.3" fz={16} fw={400} align="center">To add prescription, click on <Text span fw={600} style={{ color: '#3354F4' }}>create prescription</Text> button</Text>}
                    </Stack>
                </Box>
            ) : null
        )
    }

    const renderContent = () => renderTable(isLoading, measurementsData, EMPLOYEE_MEASUREMENT_TABLE_HEADERS, "No measurements found", true)
    const renderPrescriptions = () => renderTable(prescriptionLoading, prescriptions, EMPLOYEE_PRESCRIPTIONS_TABLE_HEADERS, "No prescriptions found", false)

    const handleImport = async (file, headers) => {
        // try {
        //     const importedData = await importCSVHandler(file, headers);
        //     console.log('Imported Data:', importedData);
        //     dispatch(setPrescriptions([...prescriptions, { ...importedData }]))
        // } catch (error) {
        //     console.error('Error importing CSV:', error.message);
        // }
        console.log(file);
    };

    const handleExport = () => {
        if (!prescriptions || prescriptions.length === 0) {
            console.error("No data available to export");
            return;
        }

        // Extract headers and data dynamically
        const headers = EMPLOYEE_PRESCRIPTIONS_TABLE_HEADERS.map((header) => header.label);
        const data = prescriptions?.filter((prescription) => prescription.selected).map((prescription) => {
            return EMPLOYEE_PRESCRIPTIONS_TABLE_HEADERS.map((header) => prescription[header.value] ?? "");
        });

        // console.log("DATA", data)
        // Combine headers and data
        const worksheetData = [headers, ...data];

        // Create a new worksheet and workbook
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Prescriptions");

        // Style headers
        const headerRange = XLSX.utils.decode_range(worksheet["!ref"]);
        for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
            if (worksheet[cellAddress]) {
                worksheet[cellAddress].s = {
                    font: { bold: true, sz: 14 }, // Bold font with larger size
                    fill: { fgColor: { rgb: "D3D3D3" } }, // Light gray background
                    alignment: { horizontal: "center", vertical: "center" }, // Center alignment
                };
            }
        }

        // Style data rows (optional: add borders or alignment)
        for (let row = 1; row <= headerRange.e.r; row++) {
            for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                if (worksheet[cellAddress]) {
                    worksheet[cellAddress].s = {
                        font: { sz: 12 }, // Regular font size for data
                        alignment: { horizontal: "left", vertical: "center" }, // Left alignment for data
                    };
                }
            }
        }

        // Write the workbook and trigger download
        XLSX.writeFile(workbook, "Prescriptions.xlsx");
    };

    function setExportCSVData(prescription = {}) {
        if (prescriptions?.length > 0) {
            const selectedPres = prescriptions.map((pres) => {
                if (pres.id === prescription.id) {
                    return {
                        ...pres,
                        selected: !pres.selected
                    }
                } else {
                    return pres
                }
            })
            dispatch(setPrescriptions(selectedPres))
        }
    }

    function onChange2(checked) {
        if (prescriptions?.length > 0) {
            const selectedPres = prescriptions.map((pres) => {
                if (checked && (prescriptions?.some((p) => p.selected) || !prescriptions?.some((p) => p.selected))) {
                    return {
                        ...pres,
                        selected: true
                    }
                }
                else {
                    return {
                        ...pres,
                        selected: false
                    }
                }
            })
            dispatch(setPrescriptions(selectedPres))
        }
    }

    return (
        <Card className={classes.card} bg="#FDFEFE" py={20} px={20} radius={12} styles={{ root: { border: "2px solid #DEE2E6" } }}>
            <Tab tabsArray={PrescripitionTabs} defaultValue="myPrescription">
                <Tabs.Panel value="myPrescription">
                    <Stack gap={20} mt={22}>
                        <Group justify="space-between">
                            <Box>
                                <Title order={3}>Prescription info</Title>
                                <Text c="#868E96">Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
                            </Box>
                            <Box>
                                <Group>
                                    <FileUploadButton
                                        title="Import"
                                    // onChange={({ file }) => handleImport(file, prescriptions, EMPLOYEE_PRESCRIPTIONS_TABLE_HEADERS)}
                                    />
                                    <OutlineButton
                                        title="Export"
                                        // onClick={() => exportCSVHandler(prescriptions, EMPLOYEE_PRESCRIPTIONS_TABLE_HEADERS)}
                                        onClick={handleExport}
                                    />
                                </Group>
                            </Box>
                        </Group>
                        {renderPrescriptions()}
                    </Stack>
                </Tabs.Panel>
                <Tabs.Panel value="frame&Lens">
                    <Stack gap={20} mt={22}>
                        <Group justify="space-between">
                            <Box>
                                <Title order={3}>Prescription info</Title>
                                <Text c="#868E96">Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
                            </Box>
                            <Box>
                                <Group>
                                    <OutlineButton title="Import" />
                                    <OutlineButton title="Export" />
                                </Group>
                            </Box>
                        </Group>
                        {renderContent()}
                    </Stack>
                </Tabs.Panel>
                <Tabs.Panel value="safetyAwards">
                    <Stack gap={20} mt={22}>
                        <Group justify="space-between">
                            <Box>
                                <Title order={3}>Prescription info</Title>
                                <Text c="#868E96">Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
                            </Box>
                            <Box>
                                <Group>
                                    <OutlineButton title="Import" />
                                    <OutlineButton title="Export" />
                                </Group>
                            </Box>
                        </Group>
                        {renderContent()}
                    </Stack>
                </Tabs.Panel>
                <Tabs.Panel value="measurements">
                    <Stack gap={20} mt={22}>
                        <Group justify="space-between">
                            <Box>
                                <Title order={3}>Measurements</Title>
                                <Text c="#868E96">Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
                            </Box>
                            <Box>
                                <Group>
                                    <OutlineButton title="Import" />
                                    <OutlineButton title="Export" />
                                </Group>
                            </Box>
                        </Group>
                        {renderContent()}
                    </Stack>
                </Tabs.Panel>
            </Tab>
        </Card>
    )
}

export default Prescription;