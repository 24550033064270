import { Select, Text, TextInput, PasswordInput, Textarea } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import React from 'react'

export const InputField = (props) => {
    return (
        <TextInput
            label={props.label}
            placeholder={props.placeholder}
            rightSection={props.rightIcon}
            radius={6}
            styles={{ root: { width: props.width }, input: { height: "40px", backgroundColor: props.backgroundColor, width: props.width }, label: { display: "flex", alignItems: 'baseline', fontSize: "20px", marginBottom: "10px" }, required: { color: '#3354F4' } }}
            {...props}
        />
    )
}

export const PasswordInputField = (props) => {
    return (
        <PasswordInput
            label={props.label}
            placeholder={props.placeholder}
            rightSection={props.rightIcon}
            radius={6}
            styles={{ input: { height: "40px" }, label: { display: "flex", alignItems: 'baseline', fontSize: "20px", marginBottom: "10px" }, required: { color: '#3354F4' } }}
            {...props}
        />
    )
}

export const SelectInputField = (props) => {
    return (
        <Select
            data={props.data}
            label={props.label}
            placeholder={props.placeholder}
            radius={6}
            styles={{ input: { height: "40px", backgroundColor: props.backgroundColor, borderRadius: "8px" }, label: { display: "flex", alignItems: 'baseline', fontSize: "20px", marginBottom: "10px" }, required: { color: '#3354F4' } }}
            {...props}
        />
    )
}

export const TextAreaField = (props) => {
    return (
        <Textarea
            label={props.label}
            placeholder={props.placeholder}
            rightSection={props.rightIcon}
            radius={6}
            {...props}
        />
    )
}

export const DateInputField = (props) => {
    return (
        <DateInput
            label={props.label}
            placeholder={props.placeholder}
            rightSection={props.rightIcon}
            radius={6}
            styles={{ input: { height: "40px", backgroundColor: props.backgroundColor }, label: { display: "flex", alignItems: 'baseline', fontSize: "20px", marginBottom: "10px" }, required: { color: '#3354F4' } }}
            {...props}
        />
    )
}