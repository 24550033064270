import React, { useEffect, useState } from 'react';
import { Button, Stack, Text, Box, Flex, Card, Checkbox } from '@mantine/core'
import { IconEdit, IconEye } from '@tabler/icons-react';
import { PrimaryButton } from '../../../components/ui/Button/Button';
import { AWARD_REQUEST_HEADER, AWARDS_REQUEST } from '../../../mock/admin/safetyAwards';
import { EMPLOYEE_STATUS_BLOCK } from '../../../choices/employee.choice';
import ModalComp from '../../../components/Modal/Modal';
import TableComp from '../../../components/Table/Table';
import { InputField } from '../../../components/ui/Input/Input';
import userImg from '../../../assets/images/woman-avatar.jpg'
import { FilterDropdown2 } from '../../../components/EmployeeManagement/Dropdown';
import { IoIosArrowDown } from 'react-icons/io';
import { IoSearch } from 'react-icons/io5';
import classes from '../../../components/Button/button.module.css'
import { useForm } from '@mantine/form';

const AwardRequests = () => {

    const [openAddEmployeeDrawer, setOpenAddEmployeeDrawer] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false)

    const data = AWARDS_REQUEST.map((award) => {
        return {
            ...award,
            status: EMPLOYEE_STATUS_BLOCK[award.status],
            actions: (
                <Flex>
                    <IconEye />
                    <Text ml={5}>View</Text>
                </Flex>
            )
        }
    })

    const [results, setResults] = useState([])
    const [searchParticipants, setSearchParticipants] = useState([])
    const [participants, setParticipants] = useState([
        { id: 1, name: "Jennifer Lopez", role: "Product Designer, Integrations", selected: false, },
        { id: 2, name: "Tassy Omah", role: "Developer Relations, Payments", selected: false, },
        { id: 3, name: "Emeto winner", role: "Backend Developer, Payments", selected: false, },
        { id: 4, name: "Jane mena", role: "Technical specialist, Enterprise", selected: false, },
        { id: 5, name: "Kate Magnamay", role: "Product Manger, Dashboard", selected: false, },
    ])

    useEffect(() => {
        const a = searchParticipants?.length > 0 ? searchParticipants : participants
        const searchResults = a.map((result) => {
            const searchParticipant = results.find((res) => res.id === result.id)
            return { ...result, selected: searchParticipant?.selected }
        })
        setResults(searchResults)
    }, [searchParticipants, participants])

    return (
        <>
            <ModalComp
                size="lg"
                withCloseButton={false}
                open={openAddModal}
                setOpen={setOpenAddModal}
                title={
                    <>
                        <Text fz={20} fw={600} mb={8}>Add employee to safety program</Text>
                        <Text fz={16} c="#495057">Select one or multiple employees to connect to the "Gold Safety Award" program</Text>
                    </>
                }
            >
                <Flex mt={16} gap={8} styles={{ root: { width: "100%" } }}>
                    <InputField
                        onChange={(e) => {
                            const searchQuery = e.target.value
                            setResults(() => results.filter((p) => p.name.toLowerCase().includes(searchQuery.toLowerCase())))
                        }}
                        rightIcon={<IconEdit />}
                        placeholder="Please enter name"
                        radius={6}
                        width="100%"
                    />
                    <PrimaryButton title="Add" w={100} h={40} />
                </Flex>
                <Card styles={{ root: { border: "1px solid #DEE2E6" } }} p={12} radius={12} mt={16}>
                    {results.map((participant) => {
                        return (
                            <Card
                                onClick={() => {
                                    setResults((prevParticipants) => {
                                        return prevParticipants.map((p) => {
                                            if (p.id === participant.id) {
                                                return {
                                                    ...p,
                                                    selected: !p.selected
                                                }
                                            } else {
                                                return p
                                            }
                                        })
                                    })
                                }}
                                styles={{ root: { backgroundColor: participant.selected && "#E7F5FF", border: "1px solid #DEE2E6", cursor: "pointer" } }}
                                p={16}
                                radius={12}
                                mb={8}
                                key={participant.id}
                            >
                                <Flex align="flex-start" justify="space-between">
                                    <Box display="flex">
                                        <img width={40} height={40} src={userImg} style={{ borderRadius: "100%" }} alt="" />
                                        <Box ml={12}>
                                            <Text c="#141517" fz={18} fw={600}>{participant.name}</Text>
                                            <Text c="#3354F4" fz={18}>{participant.role}</Text>
                                        </Box>
                                    </Box>
                                    <Checkbox aria-label="Select row" checked={participant.selected} />
                                </Flex>
                            </Card>
                        )
                    })}
                </Card>
            </ModalComp>
            {/* <CommonDrawer
                size={740}
                open={openAddEmployeeDrawer}
                onClose={() => {
                    setOpenAddEmployeeDrawer(false)
                    setUserProfileImageUrl('')
                    form.reset()
                    form.clearErrors()
                    form.clearFieldError()
                }}
                withCloseButton={false}
            >
                <Box px={24} py={12}>
                    <Group justify="space-between" align="center">
                        <Title order={4}>{employeeId ? "Edit Employee" : "Create New Employee"}</Title>
                        <Group gap={10}>
                            <SecondaryButton
                                title="Cancel"
                                onClick={() => {
                                    setOpenAddEmployeeDrawer(false)
                                    setUserProfileImageUrl('')
                                    form.reset()
                                    form.clearErrors()
                                    form.clearFieldError()
                                }} />
                            <PrimaryButton
                                title={isEditProfile ? "Save changes" : "Add user and invite"}
                                fallback={loading ? <Loader size="sm" color="white" type='dots' /> : null}
                                id={classes.upload}
                                onClick={() => {
                                    if (isEditProfile) {
                                        editEmployeeData()
                                    } else {
                                        addEmployee()
                                    }
                                }}
                            />
                        </Group>
                    </Group>
                </Box>
                <Divider orientation="horizontal" />
                <Box pt={12}>
                    <Tab defaultValue="userInfo" tabsArray={tabs} style={{ justifyContent: "center", alignItems: "center" }}>
                        <Tabs.Panel value="userInfo">
                            <Stack gap={20} p={24}>
                                <ProfileImageUpload mb={20} profileImageURL={userProfileImageUrl} updateImage={(image) => { updateFormValues('image', image) }} deleteImage={() => { updateFormValues('image', '') }} />
                                <Grid gutter={20}>
                                    <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                                        <InputField
                                            label={<Text fz={16} fw={500}>Name</Text>}
                                            rightIcon={editIcon}
                                            placeholder="Please enter name"
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}
                                            key={form.key("name")}
                                            {...form.getInputProps("name")}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                                        <SelectInputField
                                            data={ETHNICITY}
                                            label={<Text fz={16} fw={500}>Ethnicity</Text>}
                                            placeholder="Please enter ethnicity"
                                            rightSection={selectIcon}
                                            key={form.key("ethnicity")}
                                            {...form.getInputProps("ethnicity")}
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}
                                            defaultValue={null}
                                        />
                                    </Grid.Col>
                                </Grid>
                                <Grid gutter={20}>
                                    <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                                        <InputField
                                            label={<Text fz={16} fw={500}>Age</Text>}
                                            rightSection={editIcon}
                                            placeHolder="Please enter age"
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}

                                            key={form.key("age")}
                                            {...form.getInputProps("age")}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                                        <SelectInputField
                                            data={GENDER_CHOICES}
                                            label={<Text fz={16} fw={500}>Gender</Text>}
                                            rightSection={selectIcon}
                                            placeHolder="Please select gender"
                                            defaultValue={form.values.gender}
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}

                                            key={form.key("gender")}
                                            {...form.getInputProps("gender")}
                                        />
                                    </Grid.Col>
                                </Grid>
                                <Grid>
                                    <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                        <InputField
                                            label={<Text fz={16} fw={500}>Email</Text>}
                                            rightIcon={editIcon}
                                            placeHolder="Please enter email"
                                            key={form.key("email")}
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}

                                            {...form.getInputProps("email")}
                                        />
                                    </Grid.Col>
                                </Grid>
                                <Grid>
                                    <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                        <SelectInputField
                                            data={tierList?.length > 0 ? tierList.map((tiers) => ({ value: tiers.id, label: tiers.name })) : [{ value: "No Tiers Found Please Create Tiers" }]}
                                            label={<Text fz={16} fw={500}>Tier assignment</Text>}
                                            placeHolder="Please select tier"
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}
                                            key={form.key("tier")}
                                            {...form.getInputProps("tier")}
                                        />
                                    </Grid.Col>
                                </Grid>
                                <Grid>
                                    <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                        <InputField
                                            label={<Text fz={16} fw={500}>Site/Location</Text>}
                                            placeHolder="Please enter location"
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}

                                            key={form.key("address")}
                                            {...form.getInputProps("address")}
                                        />
                                    </Grid.Col>
                                </Grid>
                                {isEditProfile ?
                                    <>
                                        <Grid >
                                            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                                <Stack gap={10}>
                                                    <Text
                                                        style={{
                                                            font: 'Roboto',
                                                            fontSize: '14px',
                                                            fontWeight: '600',
                                                            color: '#495057'
                                                        }}>
                                                        Status
                                                    </Text>
                                                    <Group
                                                        justify='flex-around'
                                                        align="center"
                                                        px={12}
                                                        style={{
                                                            width: '100%',
                                                            height: '38px',
                                                            borderRadius: '6px',
                                                            border: '1px',
                                                            borderColor: '#CED4DA',
                                                            borderStyle: 'solid'
                                                        }}>
                                                        <Box mr='auto'>
                                                            {EMPLOYEE_STATUS_BLOCK[form.values.status]}
                                                        </Box>
                                                        <Group ml='auto' color="#3354F4" gap={8} justify='flex-end' onClick={sendInvitation} style={{ cursor: 'pointer' }}>
                                                            <IconReload color="#3354F4" style={{ width: '14px', height: '14px' }} />
                                                            <Text c="#3354F4" style={{ fontSize: '14px' }}>
                                                                <strong>Resend invite</strong>
                                                            </Text>
                                                        </Group>
                                                    </Group>
                                                </Stack>
                                            </Grid.Col>
                                        </Grid>
                                        <Grid >
                                            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                                <Group justify='flex-end' align='center'>
                                                    <Button variant="light" color="#C92A2A" radius="md" style={{ background: '#FFC9C9' }} onClick={deleteEmployee}>Delete User</Button>
                                                    <Button color="#3354F4" radius="md" onClick={toggleMessageEmployeeFlag}>Message employee</Button>
                                                </Group>
                                            </Grid.Col>
                                        </Grid>
                                    </>
                                    : null
                                }
                            </Stack>
                        </Tabs.Panel>
                    </Tab>
                </Box>
            </CommonDrawer> */}
            <Box py={24} pr={24}>
                <Text fz={24} fw={600} c={'#212529'} mb={24}>Award Requests</Text>
                <Flex justify="space-between" align="center" mb={24}>
                    <Box style={{ display: "flex", position: "relative" }}>
                        <IoSearch size={17} style={{ position: "absolute", top: "25%", zIndex: 1000, left: 10, color: "#ADB5BD" }} />
                        <InputField placeholder="Search" styles={{ input: { padding: "0 20px 0 35px", backgroundColor: "transparent" } }} mr={10} width={207} />
                        <FilterDropdown2 />
                        <Button styles={{ root: { display: "flex", alignItems: "center" } }} id={classes.defaultDark} ml={10}>
                            <Text mr={10}>Sort by</Text>
                            <IoIosArrowDown />
                        </Button>
                    </Box>
                </Flex>
                <Stack gap={20} style={{ height: '100%' }}>
                    <Stack gap={120}>
                        <TableComp
                            openAddEmployeeDrawer={openAddEmployeeDrawer}
                            setOpenAddEmployeeDrawer={setOpenAddEmployeeDrawer}
                            tableHeaders={AWARD_REQUEST_HEADER}
                            data={data}
                            height='250px'
                            // isFilterApplied={isFilterApplied}
                            // fetchEmployeeLoader={employeeLoading}
                            // setExportCSVData={setExportCSVData}
                            totalRecords={data?.length}
                            totalPages={1}
                            // changePage={changePage}
                            // onChange={selectEmployee}
                            // onChange2={(checked) => selectAllEmployees(checked)}
                            isCheckBox={true}
                            showPagination={true}
                        />
                    </Stack>
                </Stack>
            </Box>
        </>
    )
}

export default AwardRequests;