import { memo, useState } from 'react';
import { Accordion } from '@mantine/core';
import { Box, Checkbox, RangeSlider, TextInput } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { FILTER_LABELS } from '../../../mock/employees/RFXNStore';

function Filters() {

    const [activeFilters, setActiveFilters] = useState(null)
    const [activeMenu, setActiveMenu] = useState([])
    const [brands, setBrands] = useState([
        { id: 1, selected: false, label: "AIRRAGE" },
        { id: 2, selected: false, label: "WX BOSS" },
        { id: 3, selected: false, label: "WX BOSS RIM" },
        { id: 4, selected: false, label: "WX BREACH" },
        { id: 5, selected: false, label: "WX DEFEND" },
    ])
    const filters = {
        'Price': (
            <>
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                    <Box>
                        <TextInput
                            onChange={(e) => applyFilters("to", e.target.value)}
                            styles={{
                                label: { fontSize: "18px", color: "#A7A7A7", marginBottom: "8px" },
                                input: { height: "40px", borderRadius: "8px" }
                            }}
                            w={160}
                            label="From"
                            placeholder={1299}
                        />
                    </Box>
                    <Box bg="#E7E7E7" w={30} h={2} mb={18}></Box>
                    <Box>
                        <TextInput
                            onChange={(e) => applyFilters("from", e.target.value)}
                            styles={{
                                label: { fontSize: "18px", color: "#A7A7A7", marginBottom: "8px" },
                                input: { height: "40px", borderRadius: "8px" }
                            }}
                            w={160}
                            label="To"
                            placeholder={1299}
                        />
                    </Box>
                </Box>
                <RangeSlider
                    thumbSize={20}
                    styles={{ thumb: { backgroundColor: "#000000", borderColor: "#000000" }, bar: { backgroundColor: "#414141", height: "7px" } }}
                    mt={16}
                    min={0}
                    max={1000}
                    defaultValue={[0, 1000]}
                    onChange={(value) => {
                        setActiveFilters((prev) => ({
                            ...prev,
                            price: {
                                from: value[0],
                                to: value[1],
                            },
                        }));
                    }}
                />
            </>
        ),
        'Brand': (
            <Box>
                <TextInput mb={16} width="100%" variant="filled" placeholder="Search" size="md" leftSection={<IconSearch />} />
                {brands.map((brand) => {
                    return (
                        <Checkbox
                            onChange={(e) => {
                                applyFilters("brand", e.target.checked)
                                setBrands((prevBrands) => {
                                    return prevBrands.map((bra) => {
                                        if (bra.id === brand.id) {
                                            return {
                                                ...bra,
                                                selected: e.target.checked
                                            }
                                        } else {
                                            return bra
                                        }
                                    })
                                })
                            }}
                            checked={brand.selected}
                            label={brand.label}
                            mb={8}
                            color="black"
                            styles={{
                                label: { fontSize: "18px" },
                                input: { cursor: "pointer" }
                            }}
                        />
                    )
                })}
            </Box>
        ),
    }

    const items = FILTER_LABELS.map((item) => (
        <Accordion.Item key={item} value={item}>
            <Accordion.Control>{item}</Accordion.Control>
            <Accordion.Panel>{filters[item]}</Accordion.Panel>
        </Accordion.Item>
    ));

    function applyFilters(key, value) {
        setActiveFilters((prevFilters) => {
            if (key === "to" || key === "from") {
                return {
                    ...prevFilters,
                    price: {
                        ...prevFilters?.price,
                        [key]: Number(value),
                    },
                };
            }
            if (key === "brand") {
                return {
                    ...prevFilters,
                    brands: value,
                };
            }
            return { ...prevFilters, [key]: value };
        });
    }

    function handleExpand(e) {
        setActiveMenu(e)
    }

    console.log(activeFilters);

    return (
        <Accordion value={activeMenu} multiple={true} onChange={handleExpand}>
            {items}
        </Accordion>
    );
}

export default memo(Filters);