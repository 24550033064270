import React, { useEffect } from 'react'
import { Box, Divider, Flex, Grid, Loader, Text } from '@mantine/core';
import { PrimaryButton } from '../ui/Button/Button';
import ProfileImageUploader from '../BaseComponents/ProfileImageUploader';
import { useForm } from '@mantine/form';
import { DateInputField, InputField, SelectInputField, TextAreaField } from '../ui/Input/Input';
import { SAFETY_PROGRAM_TYPE_CHOICES } from '../../mock/admin/safetyAwards';
import { IconChevronDown } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateSafetyAwards } from '../../middlewares/admin/safetyAwards';
import { asyncErrorHandler } from '../../helpers/errorHandler';

const Form = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const safetyAwardList = useSelector((state) => state.safetyAwards.safetyAwards)
    const loading = useSelector((state) => state.safetyAwards.loading2)
    const formValues = safetyAwardList?.find((safetyAward) => safetyAward.id === id)

    const form = useForm({
        initialValues: {
            icon: null,
            name: '',
            start_date: null,
            end_date: null,
            program_type: '',
            description: '',
        },
        validate: {
            icon: (val) => !val ? 'Icon is required' : null,
            name: (val) => {
                if (!val) {
                    return 'Program name is required';
                }
                if (!/^[A-Za-z\d\s-]+$/.test(val)) {
                    return 'Program name must contain only letters, numbers, spaces, and dashes';
                }
                return null;
            },
            start_date: (val) => !val ? 'Start Date is required' : null,
            end_date: (val) => !val ? 'End Date is required' : null,
            program_type: (val) => !val ? "Program Type is required" : null,
            description: (val) => val && val.length >= 10 ? null : 'Description should be at least 10 characters long.',
        },
    })

    const handleSubmit = async () => {
        const formData = new FormData()
        Object.entries(form.values).forEach(([key, value]) => {
            if (key === "icon" && (typeof value === "string" || typeof value === null)) {
                return;
            }
            if (key === "start_date" || key === "end_date") {
                const formattedDate = new Intl.DateTimeFormat("en-CA").format(new Date(value));
                formData.append(key, formattedDate);
            }
            else {
                formData.append(key, value)
            }
        })
        const res = await dispatch(updateSafetyAwards({ id: id, formData: formData }))
        if (res.type === "updateSafetyAwards/rejected") {
            asyncErrorHandler(res, form)
        }
        else {
            window.toast("Safety awards updated successfully")
            setTimeout(() => navigate("/admin/safety-awards"), 1000);
        }
    }

    useEffect(() => {
        form.setValues({
            icon: formValues?.icon,
            name: formValues?.name,
            start_date: formValues?.start_date ? new Date(formValues?.start_date) : null,
            end_date: formValues?.end_date ? new Date(formValues?.end_date) : null,
            program_type: formValues?.program_type,
            description: formValues?.description,
        })
    }, [id, safetyAwardList])

    return (
        <Box py={24} pr={24}>
            <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                    <Text fz={24} fw={600} c={'#212529'} mb={24}>Program info</Text>
                    <Text fw={600} c={'#424242'} mb={10}>Icon</Text>
                    <Grid gutter={20}>
                        <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                            <ProfileImageUploader
                                mb={20}
                                profileImageURL={
                                    form.values.icon && typeof form.values.icon === "string" ? form.values.icon :
                                    form.values.icon && form.values.icon instanceof File ?
                                    URL.createObjectURL(form.values.icon) : null
                                }
                                updateImage={(image) => form.setFieldValue('icon', image)}
                                deleteImage={() => form.setFieldValue('icon', '')}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                            <InputField backgroundColor="transparent" label={<Text fz={16} fw={500}>Program Name</Text>} {...form.getInputProps('name')} key={form.key("name")} placeHolder="Program name" />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                            <DateInputField backgroundColor="transparent" placeHolder="Start Date" label={<Text fz={16} fw={500}>Start Date</Text>} {...form.getInputProps('start_date')} key={form.key('start_date')} />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                            <DateInputField backgroundColor="transparent" placeHolder="End Date" label={<Text fz={16} fw={500}>End Date</Text>} {...form.getInputProps('end_date')} key={form.key('end_date')} />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                            <SelectInputField
                                {...form.getInputProps('program_type')}
                                key={form.key('program_type')}
                                data={SAFETY_PROGRAM_TYPE_CHOICES}
                                rightSection={<IconChevronDown style={{ width: '16px', height: 'auto', marginRight: '2px' }} />}
                                label={<Text fz={16} fw={500}>Type</Text>}
                                placeHolder="Select program type"
                                style={{ position: 'relative' }}
                                backgroundColor="transparent"
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                            <TextAreaField
                                maxLength={500}
                                {...form.getInputProps("description")}
                                key={form.key("description")}
                                label={<Text fz={16} fw={500}>Description</Text>}
                                placeHolder="Enter your message here"
                                styles={{
                                    label: {
                                        marginBottom: "10px"
                                    },
                                    input: {
                                        height: "93px",
                                        backgroundColor: "transparent"
                                    },
                                }}
                            />
                            <p style={{ marginTop: '5px', marginBottom: '15px', fontSize: '14px', color: '#999' }}>
                                {form.values?.description?.length}/{500} characters
                            </p>
                        </Grid.Col>
                    </Grid>
                    <Flex>
                        <PrimaryButton
                            onClick={handleSubmit}
                            title="Save update"
                            disabled={loading}
                            fallback={loading ? <Loader size="sm" color="white" type='dots' /> : null}
                        />
                    </Flex>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}></Grid.Col>
            </Grid>
        </Box>
    )
}

export default Form;