import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../services/api";
import { setError, setTierLoading, setTierLoading2, setTiers } from "../../store/tiers/tierSlice";

const apiInstance = apiClient()

// Tier management

export const createTier = createAsyncThunk('createTier', async (body, { dispatch, rejectWithValue, getState }) => {
    dispatch(setTierLoading(true))
    try {
        const res = await apiInstance.post('/api/tiers/create/', body)
        dispatch(setTierLoading(false))
        dispatch(setTiers([
            ...getState().tiers.tierList,
            { ...res.data }
        ]))
        return res
    }
    catch (err) {
        dispatch(setTierLoading(false))
        if (err?.response?.data) {
            dispatch(setError(err?.response?.data))
        } else {
            dispatch(setError({ error: { message: err.message } }))
        }
        return rejectWithValue(err)
    }
})

export const updateTier = createAsyncThunk('updateTier', async (body, { dispatch, rejectWithValue, getState }) => {
    dispatch(setTierLoading(true))
    try {
        const res = await apiInstance.patch(`/api/tiers/${body.id}/update/`, body.payload)
        const updatedTiers = getState().tiers.tierList.map((tier, index) => {
            if (tier.id === res.data.id) {
                return { ...res.data }
            } else {
                return tier
            }
        })
        dispatch(setTierLoading(false))
        dispatch(setTiers(updatedTiers))
        return res
    }
    catch (err) {
        dispatch(setTierLoading(false))
        if (err?.response?.data) {
            dispatch(setError(err?.response?.data))
        } else {
            dispatch(setError({ error: { message: err.message } }))
        }
        return rejectWithValue(err)
    }
})

export const deleteTier = createAsyncThunk('deleteTier', async (id, { dispatch, rejectWithValue, getState }) => {
    dispatch(setTierLoading2(true))
    try {
        const res = await apiInstance.delete(`/api/tiers/${id}/delete/`)
        if (res.status === 200 || res.status === 201 || res.status === 204) {
            const updatedTiers = getState().tiers.tierList.filter((tier, index) => tier.id !== id)
            dispatch(setTiers(updatedTiers))
            dispatch(setTierLoading2(false))
        }
        return res
    }
    catch (err) {
        dispatch(setTierLoading2(false))
        if (err?.response?.data) {
            dispatch(setError(err?.response?.data))
        } else {
            dispatch(setError({ error: { message: err.message } }))
        }
        return rejectWithValue(err)
    }
})

export const getTiersList = createAsyncThunk('getTiersList', async (body, { dispatch, rejectWithValue }) => {
    dispatch(setTierLoading(true))
    try {
        const res = await apiInstance.get(`/api/tiers/list-tiers/${body.organization_id}/`)
        if (res.status === 200 || res.status === 201) {
            dispatch(setTiers(res.data))
            dispatch(setTierLoading(false))
            if (res.data?.length === 0) {
                dispatch(setError({ error: "No tiers found" }))
            }
        }
        return res
    }
    catch (err) {
        console.log(err);
        dispatch(setTiers([]))
        dispatch(setTierLoading(false))
        if (err?.response?.data) {
            dispatch(setError(err?.response?.data))
        } else {
            dispatch(setError({ error: { message: err.message } }))
        }
        return rejectWithValue(err)
    }
})