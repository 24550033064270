import { Box, Text, Image, Card, BackgroundImage, Stack, Title, TextInput, PasswordInput, Group, Divider } from "@mantine/core"

import loginBackground from "../../assets/images/login-background.svg"
import safetyVisionLogo from "../../assets/icons/safety-vision.svg";
import usVeteranLogo from "../../assets/icons/us-veteran-logo.svg";
import srxLogo from "../../assets/icons/srx-logo.svg";
import bechtelLogo from "../../assets/icons/bechtel-corp-new.svg";
import hippaLogo from '../../assets/icons/hippa.svg'
import awsLogo from '../../assets/icons/aws-logo.svg'
import sbaLogo from '../../assets/icons/sba-logo.svg'
import partnersLogo from '../../assets/icons/sitrap-logo.svg'

import { useForm } from "@mantine/form";
import { yupResolver } from "@mantine/form";
import * as yup from 'yup';
import { loginUser, googleLogin, resetPassword, handleSetPassword, verifyOTP } from "../../middlewares/auth/auth";
import { useDispatch, useSelector } from "react-redux";

import { GoogleButton, SocialButton } from "../../components/Button/Button"
import { notifications } from "@mantine/notifications";
import facebookIcon from '../../assets/icons/fb.svg'

import { useGoogleLogin } from '@react-oauth/google';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useViewportSize } from '@mantine/hooks';
import PrimaryButton from "../../components/BaseComponents/Buttons/Button";
import { CarouselSlider, CarouselSlider2 } from "../../components/Carousol/carousol";
import axios from "axios";
import { asyncErrorHandler } from "../../helpers/errorHandler";

function GoogleIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid"
            viewBox="0 0 256 262"
            style={{ width: '1.2rem', height: '1.2rem' }}
            {...props}
        >
            <path
                fill="#4285F4"
                d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
            />
            <path
                fill="#34A853"
                d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
            />
            <path
                fill="#FBBC05"
                d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
            />
            <path
                fill="#EB4335"
                d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
            />
        </svg>
    );
}

const Login = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [passwordReset, setPasswordReset] = useState(false)
    const [loginButtonLoading, setLoginButtonLoading] = useState()
    const [forgotPassword, setForgotPassword] = useState(false)
    const [loginRole, setLoginRole] = useState(0);
    const [timer, setTimer] = useState(30);
    const [sendVerificationCode, setSendVerificationCode] = useState(false)
    const { width } = useViewportSize()

    const commonLoading = useSelector((state) => state.common.loading)

    const [searchParams] = useSearchParams();
    const params = Object.fromEntries(searchParams.entries());
    const hasParams = Object.keys(params).length > 0;

    const loginValidation = yup.object().shape({
        email: yup.string().required("Email is required").email("Invalid email"),
        password: yup.string().required("Password is required")
    })
    const passwordValidation = yup.object().shape({
        password1: yup.string().required("Password 1 is required"),
        password2: yup.string().required("Password 2 is required")
    })
    const loginForm = useForm({
        initialValues: {
            email: '',
            password: ''
        },
        validate: yupResolver(loginValidation)
    })

    const verificationCodeForm = useForm({
        initialValues: {
            verification_code: '',
        },
        validate: {
            verification_code: (val) => (/^\d{4}$/.test(val) ? null : 'Verification code must be 4 digits'),
        }
    })
    const passwordForm = useForm({
        initialValues: {
            password1: '',
            password2: '',
            employee_id: ''
        },
        validate: yupResolver(passwordValidation)
    })

    const inputStyles = {
        label: {
            fontSize: width > 786 ? '16px' : '14px',
            marginBottom: "10px",
            lineHeight: "18px",
            fontWeight: "600",
            color: "#393B42"
        },
        input: {
            height: "40px",
            fontSize: width > 786 ? '14px' : '12px',
            padding: "12px",
            lineHeight: "16px",
            borderRadius: "6px",
            backgroundColor: '#F1F3F5'
        },
        error: {
            fontSize: width > 786 ? '14px' : '10px',
            marginTop: "5px",
            lineHeight: "16px",
        },
        required: {
            color: '#3354F4'
        }
    }

    function storeCredientials(res) {
        setLoginButtonLoading(false)
        setSendVerificationCode(false)
        localStorage.setItem('access_token', res?.payload?.data?.access)
        localStorage.setItem('referesh_token', res?.payload?.data?.refresh)
    }

    const login = async () => {
        if (sendVerificationCode === true) {
            if (verificationCodeForm.validate().hasErrors) {
                verificationCodeForm.validate();
                return;
            }
            setLoginButtonLoading(true)
            const res = await dispatch(verifyOTP({ email: loginForm.values.email, verification_code: verificationCodeForm.values.verification_code }))
            if (res.type === "verifyOTP/fulfilled") {
                const userRole = res?.payload?.data?.user?.role;
                const has_personal_org = res?.payload?.data.has_personal_org;
                if (userRole === "admin" || userRole === "partner") {
                    if (has_personal_org) {
                        storeCredientials(res)
                        navigate(`/${userRole}/dashboard`)
                    }
                    else {
                        localStorage.setItem("inProcess", "true");
                        storeCredientials(res)
                        navigate(`/${userRole}/signup`, { state: { step: 3 } });
                    }
                } else {
                    storeCredientials(res)
                    navigate(`/${userRole}/dashboard`)
                }
            } else {
                setLoginButtonLoading(false)
                setSendVerificationCode(true)
                asyncErrorHandler(res, verificationCodeForm)
            }
        }
        else {
            if (loginForm.validate().hasErrors) {
                loginForm.validate()
                return;
            }
            setLoginButtonLoading(true)
            const res = await dispatch(loginUser(loginForm.values))
            if (loginUser.fulfilled.match(res)) {
                setLoginButtonLoading(false)
                setSendVerificationCode(true)
                setTimer(30)
                notifications.show({ message: res.payload.data.message, autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            } else if (loginUser.rejected.match(res)) {
                setLoginButtonLoading(false)
                setSendVerificationCode(false)
                asyncErrorHandler(res, loginForm)
            }
        }
    }

    const handleSubmit = async () => {
        if (forgotPassword) {
            resetUserPassword()
            return;
        }
        if (hasParams || passwordReset) {
            let queryParam = Object.entries(params).map(([key, value]) => {
                let queryParams = `${key}=${value}`
                return queryParams
            }).join("&")
            const res = await dispatch(handleSetPassword({
                body: passwordForm.values,
                params: queryParam
            }))
            if (res.type === "setPassword/fulfilled") {
                passwordForm.reset()
                navigate("/")
            }
            return;
        }
        else {
            login()
            return;
        }
    }

    const loginViaGoogle = useGoogleLogin({
        onSuccess: async (googleRes) => {
            const res = await dispatch(googleLogin({ access_token: googleRes.access_token }))
        },
        onError: (error) => notifications.show({ message: error, autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, })
    });

    const resetUserPassword = async () => {
        if (loginForm.validate().errors.email) {
            loginForm.validate()
        }
        else {
            setLoginButtonLoading(loginButtonLoading => !loginButtonLoading)
            const res = await dispatch(resetPassword({ email: loginForm.values.email }))
            if (resetPassword.fulfilled.match(res)) {
                setLoginButtonLoading(loginButtonLoading => !loginButtonLoading)
                notifications.show({ message: 'Mail sent successfully', autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            } else if (resetPassword.rejected.match(res)) {
                setLoginButtonLoading(loginButtonLoading => !loginButtonLoading)
                notifications.show({ message: 'Could not sent the email', autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            }
        }
    }

    useEffect(() => {
        if (hasParams && params?.employee_id) {
            passwordForm.setFieldValue("employee_id", params?.employee_id);
        }
    }, [hasParams]);

    const renderHeader = () => {
        if (loginRole === 0 && width < 768) {
            return <CarouselSlider />
        } else {
            return (
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box>
                        {loginRole === 0 ? (
                            <>
                                <Image src={safetyVisionLogo} w="100%" style={{ objectFit: "contain" }} />
                                <Box mt={20}>
                                    <Text style={{ color: "#5C5F66" }}>Powered By</Text>
                                    <img src={srxLogo} width="100%" alt='' style={{ marginBottom: '65.58px' }} />
                                </Box>
                            </>
                        ) : <img src={partnersLogo} width="100%" alt='' />}
                    </Box>
                    <Box>
                        <img src={loginRole === 0 ? bechtelLogo : safetyVisionLogo} width="100%" alt='' style={{ marginBottom: '65.58px' }} />
                    </Box>
                </Box>
            )
        }
    }

    const renderTitle = () => {
        if (loginRole === 1) {
            return "Securely login to your partner dashboard";
        }
        if ((hasParams) || passwordReset) {
            return "Create Password";
        } else if (forgotPassword) {
            return "Reset Password";
        } else {
            return "Log in to your business account!";
        }
    }

    const renderContent = () => {
        if (hasParams) {
            return (
                <>
                    <PasswordInput
                        withAsterisk={true}
                        label={"Enter your password"}
                        placeholder="Please enter your password"
                        key={passwordForm.key("password1")}
                        {...passwordForm.getInputProps('password1')}
                        styles={inputStyles}
                        size="sm"
                    />
                    <PasswordInput
                        withAsterisk={true}
                        label={"Confirm Password"}
                        placeholder="Please re-enter your password"
                        key={passwordForm.key("password2")}
                        {...passwordForm.getInputProps('password2')}
                        styles={inputStyles}
                        size="sm"
                    />
                    <PrimaryButton type='submit' size='md' loading={commonLoading} h={48} text='Create' />
                </>
            )
        } else {
            return (
                <>
                    <TextInput
                        label="Email"
                        placeholder="Please enter your email"
                        key={loginForm.key("email")}
                        {...loginForm.getInputProps('email')}
                        styles={inputStyles}
                        disabled={sendVerificationCode}
                        withAsterisk={true}
                        size="sm"
                    />
                    {(forgotPassword || sendVerificationCode) ? null : (
                        <>
                            <PasswordInput
                                withAsterisk={true}
                                label={"Enter your password"}
                                placeholder="Please enter your password"
                                key={passwordForm.key("password")}
                                {...loginForm.getInputProps('password')}
                                styles={inputStyles}
                                size="sm"
                            />
                            <a
                                style={{ color: '#495057', fontSize: '16px', fontWeight: "600", cursor: 'pointer', textAlign: "right" }}
                                onClick={() => setForgotPassword(forgotPassword => !forgotPassword)}
                            >Forgot Password?</a>
                        </>
                    )}
                    {sendVerificationCode && (
                        <>
                            <TextInput
                                withAsterisk={true}
                                type="number"
                                styles={inputStyles}
                                label="Login Code"
                                placeholder="Paste login code"
                                {...verificationCodeForm.getInputProps('verification_code')}
                                size="sm"
                                onChange={(e) => {
                                    const value = e.target.value.replace(/\D/g, '');
                                    if (value.length <= 4) {
                                        verificationCodeForm.setFieldValue('verification_code', value);
                                    }
                                }}
                            />
                            <Text fz={width > 786 ? 16 : 12} c={'#5C5F66'}>
                                We sent a code to your inbox * <b>Resend in {timer}s</b>
                            </Text>
                        </>
                    )}
                    <PrimaryButton
                        type='submit'
                        styles={{ root: { backgroundColor: !verificationCodeForm.values.verification_code && sendVerificationCode ? "#E5E5E5" : "", color: !verificationCodeForm.values.verification_code && sendVerificationCode ? "#ADB5BD" : "" } }}
                        loading={loginButtonLoading}
                        disabled={!verificationCodeForm.values.verification_code && sendVerificationCode}
                        h={45}
                        text='Continue'
                        fz={'lg'}
                        size={'lg'}
                        variant='filled'
                    />
                </>
            )
        }
    }

    useEffect(() => {
        if (sendVerificationCode) {
            let interval = null;
            if (timer > 0) {
                interval = setInterval(() => {
                    setTimer((prevTimer) => prevTimer - 1);
                }, 1000);
            } else {
                clearInterval(interval);
            }
            return () => clearInterval(interval);
        }
    }, [timer, sendVerificationCode])

    useEffect(() => {
        localStorage.setItem("inProcess", "false")
        localStorage.removeItem('access_token')
        localStorage.removeItem('referesh_token')
    }, [])

    return (
        <Box bg={"#212529"} h={'100vh'} w={'100vw'}>
            <BackgroundImage src={loginBackground} h={'100vh'} w={'100vw'} fit="contain" px={width > 786 ? 50 : 30} pt={30} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                <Stack gap={12} pos={'relative'} h="auto" mih="100%" justify="center">
                    {renderHeader()}
                    <form onSubmit={(e) => {
                        e.preventDefault(); // Prevent default form submission
                        handleSubmit(e)
                    }}
                        style={{ marginTop: width > 991 ? loginRole === 1 ? '-90px' : '-160px' : '0' }}
                    >
                        <Stack>
                            <Card w={width > 768 ? 520 : "100%"} mx="auto" bg={'#F1F3F5'} px={width > 786 ? 40 : 30} py={width > 786 ? 35 : 30} radius={12} mb={30}>
                                <Stack gap={25}>
                                    <Stack gap={5} key="card_header">
                                        <Title order={width > 786 ? 3 : 4} >
                                            {renderTitle()}
                                        </Title>
                                        <Text fz={width > 786 ? 16 : 12} c={'#5C5F66'}>
                                            {forgotPassword ? 'Securing Your Account with a New Password' : loginRole === 1 ? "Protecting sensitive information starts here" : 'Protecting your information starts here'}
                                        </Text>
                                    </Stack>
                                    <Stack key="card_body" gap={width > 786 ? 18 : 10}>
                                        {renderContent()}
                                    </Stack>

                                    {!sendVerificationCode && (
                                        <Stack gap={width > 786 ? 20 : 10}>
                                            <Divider label="Or Continue With" labelPosition="center" color={'#ADB5BD'} />
                                            <div style={{ width: "100%", display: "flex" }}>
                                                <GoogleButton w="100%" onClick={loginViaGoogle} radius={6} h={45} leftSection={<GoogleIcon />} />
                                                <SocialButton w="100%" ml={8} h={45} leftSection={<img src={facebookIcon} alt="" />} color="#0866FF" />
                                            </div>
                                        </Stack>
                                    )}

                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Link to="/admin/signup" style={{ textDecoration: "none" }}>
                                            <Text c="black" fz={16}>
                                                Not registered? <span><b>Sign up</b></span>
                                            </Text>
                                        </Link>
                                        <Text c="black" fz={16} styles={{ root: { cursor: "pointer" } }} onClick={() => setLoginRole(loginRole === 1 ? 0 : 1)}>
                                            {loginRole === 1 ? (
                                                <>
                                                    <b>Login</b> as a admin
                                                </>
                                            ) : (
                                                <>
                                                    <b>Login</b> as a partner
                                                </>
                                            )}
                                        </Text>
                                    </div>
                                </Stack>
                            </Card>
                        </Stack>
                    </form>
                    <Group
                        w={'100%'}
                        justify={"center"}
                        pb={30}
                        mt={width > 768 ? "auto" : 20}
                        gap={40}
                        c={'#ADB5BD'}
                        style={{ marginTop: 'auto' }}
                    >
                        {loginRole === 1 ?
                            (
                                <>
                                    <img src={usVeteranLogo} alt="" />
                                    <Group w="100%" justify="center" gap={width > 768 ? 100 : 20}>
                                        <Link style={{ color: "#909296", textDecoration: "none" }} fz={14}>About us</Link>
                                        <Link style={{ color: "#909296", textDecoration: "none" }} fz={14}>Terms of use</Link>
                                        <Link style={{ color: "#909296", textDecoration: "none" }} fz={14}>Privacy Policy</Link>
                                        <Link style={{ color: "#909296", textDecoration: "none" }} fz={14}>Help</Link>
                                    </Group>
                                </>
                            ) : (
                                width > 991 ? (
                                    <>
                                        <Link style={{ color: "#909296", textDecoration: "none" }} fz={16} >About us</Link>
                                        <Link style={{ color: "#909296", textDecoration: "none" }} fz={16} >Terms of use</Link>
                                        <img src={hippaLogo} alt="" />
                                        <img src={awsLogo} alt="" />
                                        <img src={sbaLogo} alt="" />
                                        <Link style={{ color: "#909296", textDecoration: "none" }} fz={16} >Privacy Policy</Link>
                                        <Link style={{ color: "#909296", textDecoration: "none" }} fz={16} >Help</Link>
                                    </>
                                ) : (
                                    <>
                                        <CarouselSlider2 />
                                        <Group w="100%" justify="space-around">
                                            <Link style={{ color: "#909296", textDecoration: "none" }} fz={14}>About us</Link>
                                            <Link style={{ color: "#909296", textDecoration: "none" }} fz={14}>Terms of use</Link>
                                            <Link style={{ color: "#909296", textDecoration: "none" }} fz={14}>Privacy Policy</Link>
                                            <Link style={{ color: "#909296", textDecoration: "none" }} fz={14}>Help</Link>
                                        </Group>
                                    </>
                                )
                            )
                        }
                    </Group>
                </Stack>
            </BackgroundImage>
        </Box>
    )
}

export default Login