import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: null,
    success: null,
    tierLoading: false,
    tierLoading2: false,
    tierList: [],
};

const tierSlice = createSlice({
    name: "Tiers",
    initialState,
    reducers: {
        setError: (state, { payload }) => {
            state.error = payload;
        },
        setSuccess: (state, { payload }) => {
            state.success = payload;
        },
        setTierLoading: (state, { payload }) => {
            state.tierLoading = payload;
        },
        setTierLoading2: (state, { payload }) => {
            state.tierLoading2 = payload;
        },
        setTiers: (state, { payload }) => {
            state.tierList = payload;
        },
    },
});

export const { setError, setTierLoading, setTierLoading2, setSuccess, setTiers } = tierSlice.actions;
export default tierSlice.reducer;