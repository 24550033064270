import { useEffect, useMemo, useState } from 'react';
import { Stepper, Button, Group, Box, Grid } from '@mantine/core';
import { IconMapPinFilled } from '@tabler/icons-react';
import ShippingIcon from '../../../assets/icons/shipping.svg'
import PaymentIcon from '../../../assets/icons/payment.svg'
import { useSelector } from 'react-redux';
import { PrimaryButton } from '../../../components/ui/Button/Button';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

function Checkout() {

    const activeStep = useSelector((state) => state.steps.step)
    const navigate = useNavigate()
    const shippingDetails = useSelector((state) => state.cart.shippingDetails)
    const location = useLocation()
    const routes = useMemo(() => [
        "/employee/checkout/select-prescription",
        "/employee/checkout/select-address",
        "/employee/checkout/summary"
    ], []);
    const currentIndex = routes.indexOf(location.pathname);

    const nextStep = () => {
        if (prescriptionNotSelected || addressNotSelected) return;
        if (currentIndex !== -1 && currentIndex < routes.length - 1) {
            navigate(routes[currentIndex + 1]);
        }
    };

    const prevStep = () => {
        if (currentIndex === 0) {
            navigate("/employee/cart");
        } else if (currentIndex > 0) {
            navigate(routes[currentIndex - 1]);
        }
    };

    const prescriptionNotSelected = currentIndex >= 0 && !shippingDetails?.prescription;
    const addressNotSelected = currentIndex >= 1 && !shippingDetails?.address;
    const isNextDisabled = prescriptionNotSelected || addressNotSelected;

    console.log(shippingDetails)

    return (
        <Box style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }} bg="white" h={"92vh"}>
            <Box>
                <Box bg="#F8F9FA" p={24}>
                    <Stepper color="#C6C7C8" active={currentIndex + 1} allowNextStepsSelect={false}>
                        <Stepper.Step
                            color={currentIndex + 1 <= 3 ? "#000000" : "#C6C7C8"}
                            styles={{
                                stepLabel: { color: "#000000", fontWeight: "600" },
                                stepDescription: { color: "#000000", fontWeight: "900", fontSize: "18px" },
                                stepIcon: { border: "none" }
                            }}
                            label="Step 1"
                            description="Prescription"
                            icon={<IconMapPinFilled />}
                            completedIcon={<IconMapPinFilled />}
                        />
                        <Stepper.Step
                            color={currentIndex + 1 <= 3 && currentIndex + 1 > 0 ? "#000000" : "#C6C7C8"}
                            styles={{
                                stepLabel: { color: "#000000", fontWeight: "600" },
                                stepDescription: { color: "#000000", fontWeight: "900", fontSize: "18px" },
                                stepIcon: { border: "none" },
                            }}
                            label="Step 2"
                            description="Prescription"
                            icon={<img src={ShippingIcon} alt="" />}
                            completedIcon={<img src={ShippingIcon} alt="" />}
                        />
                        <Stepper.Step
                            color={currentIndex + 1 === 3 ? "#000000" : "#C6C7C8"}
                            styles={{
                                stepLabel: { color: "#000000", fontWeight: "600" },
                                stepDescription: { color: "#000000", fontWeight: "900", fontSize: "18px" },
                                stepIcon: { border: "none" },
                            }}
                            label="Step 3"
                            description="Send request"
                            icon={<img src={PaymentIcon} alt="" />}
                            completedIcon={<img src={PaymentIcon} alt="" />}
                        />
                    </Stepper>
                </Box>
                <Box mt={24} px={24}>
                    <Outlet />
                </Box>
            </Box>
            {location.pathname !== "/employee/checkout/summary" && (
                <Box p={24}>
                    <Group justify="flex-end" mt="xl">
                        <PrimaryButton
                            w={205}
                            h={54}
                            variant="outline"
                            onClick={prevStep}
                            title="Back"
                        />
                        <PrimaryButton
                            w={205}
                            h={54}
                            bg={isNextDisabled ? "#F1F3F5" : ""}
                            c={isNextDisabled ? "#ADB5BD" : ""}
                            disabled={isNextDisabled}
                            onClick={nextStep}
                            title="Next step"
                        />
                    </Group>
                </Box>
            )}
        </Box>
    );
}

export default Checkout;