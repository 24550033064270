import React from 'react'
import DashboardCard from '../Dashboard/card'
import { Button, Card, Group, Radio, Table, Text } from '@mantine/core'
import { FaEdit, FaEye } from 'react-icons/fa'
import { IconEdit, IconEye } from '@tabler/icons-react'
import { EMPLOYEE_STATUS_BLOCK } from '../../choices/employee.choice'
import { PRESCRIPTION_STATUS, PRESCRIPTIONS_LENSES_TYPES } from '../../mock/employees/prescriptions'
import { EMPLOYEE_PRESCRIPTIONS_TABLE_HEADERS } from '../../mock/employees/table/header'
import { OutlineButton } from '../ui/Button/Button'
import * as XLSX from 'xlsx'

const PrescriptionCard = ({ prescription, handleEditPrescription, exportCSV, isGroup, isCheckbox, bg }) => {

    const handleExport = () => {
        const arr = [{ ...prescription }]
        // Extract headers and data dynamically
        const headers = EMPLOYEE_PRESCRIPTIONS_TABLE_HEADERS.map((header) => header.label);
        const data = arr?.map((prescription) => {
            return EMPLOYEE_PRESCRIPTIONS_TABLE_HEADERS.map((header) => prescription[header.value] ?? "");
        });

        // console.log("DATA", data)
        // Combine headers and data
        const worksheetData = [headers, ...data];

        // Create a new worksheet and workbook
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Prescriptions");

        // Style headers
        const headerRange = XLSX.utils.decode_range(worksheet["!ref"]);
        for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
            if (worksheet[cellAddress]) {
                worksheet[cellAddress].s = {
                    font: { bold: true, sz: 14 }, // Bold font with larger size
                    fill: { fgColor: { rgb: "D3D3D3" } }, // Light gray background
                    alignment: { horizontal: "center", vertical: "center" }, // Center alignment
                };
            }
        }

        // Style data rows (optional: add borders or alignment)
        for (let row = 1; row <= headerRange.e.r; row++) {
            for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                if (worksheet[cellAddress]) {
                    worksheet[cellAddress].s = {
                        font: { sz: 12 }, // Regular font size for data
                        alignment: { horizontal: "left", vertical: "center" }, // Left alignment for data
                    };
                }
            }
        }

        // Write the workbook and trigger download
        XLSX.writeFile(workbook, "Prescriptions.xlsx");
    };

    return (
        <DashboardCard bg={bg ? bg : ""}>
            <Group justify="space-between">
                {isCheckbox ? <Group justify="space-between" align="center">
                    <Text fz={22} fw="bold">{prescription.name}</Text>
                    <Radio checked={prescription?.isSelected} />
                </Group> : <Text fz={22} fw="bold">{prescription.name}</Text>}
                {isGroup && <Group>
                    <OutlineButton
                        onClick={handleExport}
                        title="Export"
                        radius="10px"
                        styles={{ root: { borderWidth: "2px" } }}
                    />
                    <Button
                        onClick={handleEditPrescription}
                        variant="outline"
                        bd="2px solid #3354F4"
                        radius="10px"
                        w={50}
                        p={0}
                    >
                        <IconEdit size={25} />
                    </Button>
                    <Button variant="outline"
                        bd="2px solid #3354F4"
                        radius="10px"
                        w={50}
                        p={0}
                    >
                        <IconEye size={25} />
                    </Button>
                </Group>}
            </Group>
            <Table fontSize="sm" mt={23}>
                <Table.Tbody>
                    <Table.Tr bd="2px solid #F1F3F5">
                        <Table.Td bd="2px solid #F1F3F5" w={140}>
                            <Text fw={600} fz={16}>Usage</Text>
                        </Table.Td>
                        <Table.Td py={8} px={24}>{prescription.usage}</Table.Td>
                    </Table.Tr>
                    <Table.Tr bd="2px solid #F1F3F5">
                        <Table.Td bd="2px solid #F1F3F5" w={140}>
                            <Text fw={600} fz={16}>Lenses Type</Text>
                        </Table.Td>
                        <Table.Td py={8} px={24}>{PRESCRIPTIONS_LENSES_TYPES[prescription.lens_type]}</Table.Td>
                    </Table.Tr>
                    <Table.Tr bd="2px solid #F1F3F5">
                        <Table.Td bd="2px solid #F1F3F5" fw="bold" w={140}>
                            <Text fw={600} fz={16}>Status</Text>
                        </Table.Td>
                        <Table.Td py={8} px={24}>
                            <Text c="#37B24D">Active</Text>
                        </Table.Td>
                    </Table.Tr>
                    <Table.Tr bd="2px solid #F1F3F5">
                        <Table.Td bd="2px solid #F1F3F5" fw="bold" w={140}>
                            <Text fw={600} fz={16}>Exp date:</Text>
                        </Table.Td>
                        <Table.Td py={8} px={24}>{new Date(prescription.start_date).toLocaleDateString()}</Table.Td>
                    </Table.Tr>
                </Table.Tbody>
            </Table>
        </DashboardCard>
    )
}

const PrescriptionCard2 = ({ prescription }) => {
    return (
        <Card
            styles={{
                root: {
                    backgroundImage: "linear-gradient(to bottom, #FFFFFF,rgb(243, 243, 243))", // White to light gray gradient
                    padding: "14px 24px",
                    borderRadius: "16px",
                    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                    fontFamily: "Arial, sans-serif",
                    border: "1px solid #E0E0E0",
                    cursor: "pointer"
                }
            }}
        >
            <Group justify="space-between" align="center">
                <Text fz={22} fw="bold">{prescription.name}</Text>
                <Radio checked={prescription?.isSelected} />
            </Group>
            <Table fontSize="sm" mt={23}>
                <Table.Tbody>
                    <Table.Tr bd="2px solid #F1F3F5">
                        <Table.Td bd="2px solid #F1F3F5" w={140}>
                            <Text fw={600} fz={16}>Usage</Text>
                        </Table.Td>
                        <Table.Td py={8} px={24}>{prescription.usage}</Table.Td>
                    </Table.Tr>
                    <Table.Tr bd="2px solid #F1F3F5">
                        <Table.Td bd="2px solid #F1F3F5" w={140}>
                            <Text fw={600} fz={16}>Lenses Type</Text>
                        </Table.Td>
                        <Table.Td py={8} px={24}>{PRESCRIPTIONS_LENSES_TYPES[prescription.lens_type]}</Table.Td>
                    </Table.Tr>
                    <Table.Tr bd="2px solid #F1F3F5">
                        <Table.Td bd="2px solid #F1F3F5" fw="bold" w={140}>
                            <Text fw={600} fz={16}>Status</Text>
                        </Table.Td>
                        <Table.Td py={8} px={24}>
                            <Text c="#37B24D">Active</Text>
                        </Table.Td>
                    </Table.Tr>
                    <Table.Tr bd="2px solid #F1F3F5">
                        <Table.Td bd="2px solid #F1F3F5" fw="bold" w={140}>
                            <Text fw={600} fz={16}>Exp date:</Text>
                        </Table.Td>
                        <Table.Td py={8} px={24}>{new Date(prescription.start_date).toLocaleDateString()}</Table.Td>
                    </Table.Tr>
                </Table.Tbody>
            </Table>
        </Card>
    )
}

export { PrescriptionCard, PrescriptionCard2 }