import { Accordion, Checkbox, Stack } from '@mantine/core';
import { EMPLOYEE_TIER_WITH_ICONS, EMPLOYEE_PRESCRIPTION, EMPLOYEE_STATUS } from '../../choices/employee.choice';
// import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

function AccordionComp({ createEmployeeFilter, employeeFilterState }) {

    const [employeeFilter, setEmployeeFilter] = useState({
        tier:[],
        rx_non_rx:[],
        status:[]
    })

    useEffect(()=>{
        setEmployeeFilter(employeeFilterState)
    },[employeeFilterState])

    const updateEmployeeFilter = (category, categoryValue, isChecked)=>{
        setEmployeeFilter(employeeFilter => {
            let employeeFilterObject = { ...employeeFilter }
            if (isChecked) {
                const exsistingFilter = employeeFilterObject[category].find(filterValue => filterValue === categoryValue)
                if (!exsistingFilter) {
                    employeeFilterObject[category] = [...employeeFilterObject[category], categoryValue]
                }
            }
            else {
                employeeFilterObject[category] = employeeFilterObject[category].filter(filterValue => filterValue !== categoryValue)
            }
            createEmployeeFilter(employeeFilterObject)
            return employeeFilterObject
        })
        
    }

    const filters = [
        {
            value: 'Tier assignment',
            description:
                <>
                    <Checkbox.Group value={employeeFilter['tier']}>
                        <Stack>
                            {Object.entries(EMPLOYEE_TIER_WITH_ICONS).map(([key, value]) => {
                                return <Checkbox size="sm" value={key} label={value} checked={true} onChange={(event) => { updateEmployeeFilter('tier', event.target.value, event.currentTarget.checked) }} />
                            })}
                        </Stack>
                    </Checkbox.Group>
                </>,
        },
        {
            value: 'Rx/Non-Rx',
            description:
                <>
                    <Checkbox.Group value={employeeFilter['rx_non_rx']}>
                        <Stack>
                            {Object.entries(EMPLOYEE_PRESCRIPTION).map(([key, value]) => {
                                return (
                                    <Checkbox
                                        size="sm"
                                        value={key}
                                        label={<><p style={{ fontSize: '0.8rem', fontWeight: '400' }}>{value}</p></>}
                                        onChange={(event) => { updateEmployeeFilter('rx_non_rx', event.target.value, event.currentTarget.checked) }}
                                    />
                                )
                            })}
                        </Stack>

                    </Checkbox.Group>
                </>
        },
        {
            value: 'Frames & lens',
            description: '',
        },
        {
            value: 'Status',
            description:
                <>
                    <Checkbox.Group value={employeeFilter['status']}>
                        <Stack>
                            {Object.entries(EMPLOYEE_STATUS).map(([key, value]) => {
                                return (
                                    <Checkbox
                                        size="sm"
                                        value={key}
                                        label={<><p style={{ fontSize: '0.8rem', fontWeight: '400' }}>{value}</p></>}
                                        onChange={(event) => { updateEmployeeFilter('status', event.target.value, event.currentTarget.checked) }}
                                    />
                                )
                            })}
                        </Stack>
                    </Checkbox.Group>
                </>
        },
    ];

    const filterItems = filters.map((item) => (
        <Accordion.Item key={item.value} value={item.value}>
            <Accordion.Control c="#495057">{item.value}</Accordion.Control>
            <Accordion.Panel>{item.description}</Accordion.Panel>
        </Accordion.Item>
    ));

    return (
        <Accordion defaultValue="Apples">
            {filterItems}
        </Accordion>
    );
}

export default AccordionComp;