import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "orders",
    initialState: {
        orders: [],
    },
    reducers: {
        setOrders: (state, { payload }) => {
            state.orders = [...state.orders, payload]
        },
    }
})

export const { setOrders } = cartSlice.actions;
export default cartSlice.reducer;