export const PARTNER_USERS = [
    { value: 'invoice', label: 'Invoice' },
    { value: 'frameLens', label: 'Frame & Lens' },
    { value: 'status', label: 'Status' },
    { value: 'actions', label: 'Actions' },
]

export const ORGANIZATION_ADMIN =  [
    { value: 'organization_name', label: 'Company Name' },
    { value: 'email', label: 'Email Admin Member' },
    { value: 'profile.first_name', label: 'First Name' },
    { value: 'profile.last_name', label: 'Last Name' },
    { value: 'profile.mobile_phone_number', label: 'Phone Number' },
    { value: 'status', label: 'Status' },
    { value: 'actions', label: 'Actions' },
]