import { Box, Stack, Text, Title, Flex, Group, Image, Button, Modal, Loader, Grid, Divider } from "@mantine/core"
import { IconEdit } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { EMPLOYEE_STATUS_BLOCK } from "../../../../choices/employee.choice";
import { SUBCONTRACTOR_TABLE_HEADER } from "../../../../choices/subcontractor.choices";
import sonyLogo from '../../../../assets/images/sony.svg'
import TableComp from "../../../Table/Table";
import { PrimaryButton } from '../../../ui/Button/Button'
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { InputField, SelectInputField } from "../../../ui/Input/Input";
import { COMPANY_SIZE_CHOICES, INDUSTRY_SERVICED, ORGANIZATIONS_EMPLOYEES_AT_SITE_SIZE_CHOICES } from "../../../../choices/organizationChoices";
import { IoIosArrowDown } from "react-icons/io";
import { createSubContractor, deleteSubContractor, updateSubContractor } from "../../../../middlewares/partner/partners.services";
import { useParams } from "react-router-dom";
import ProfileImageUploader from "../../../BaseComponents/ProfileImageUploader";
import { asyncErrorHandler } from "../../../../helpers/errorHandler";
import { CommonDrawer } from "../../../Drawer/Drawer";
import { FaRegBuilding } from "react-icons/fa";
import { setOrganizationList2 } from "../../../../store/organization/organizationSlice";

const SubContractor = () => {

    const organizations = useSelector(state => state.organizations.organizationList2)
    const formValues = useSelector(state => state.organizations.subContractorForm)
    const inviteLoading = useSelector(state => state.organizations.inviteLoading)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [companyId, setCompanyId] = useState(null)
    const [editSubContractor, setEditSubContractor] = useState(null)
    const [imageError, setImageError] = useState("")

    const { id } = useParams()

    const dispatch = useDispatch()
    const [opened, { open, close }] = useDisclosure()

    const form = useForm({
        initialValues: { ...formValues },
        validate: {
            name: (val) => (val === null || val === "") ? 'Name is required' : null,
            size: (val) => (val === null || val === "") ? 'Size is required' : null,
            country: (val) => (val === null || val === "") ? 'Country is required' : null,
            site_name: (val) => (/^(https?|ftp):\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$|^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/i.test(val) ? null : 'Please enter a valid site URL'),
            employees_at_site: (val) => (val === null || val === "") ? 'Employees at site is required' : null,
            address: (val) => (val === null || val === "") ? 'Address is required' : null,
            company_type: (val) => (val === null || val === "") ? 'Company type is required' : null,
            zip_code: (val) => (val === null || val === "") ? 'Zip code is required' : null,
            phone_number: (val) => (val === null || val === "") ? 'Phone number is required' : null,
            admin_email: (val) => (val === null || val === "") ? 'Admin email is required' : null,
        }
    })

    const serializeSubContractorData = (subcontractor = []) => {
        return subcontractor.map((subcontractor) => {
            const logo = subcontractor.logo?.includes("http") ? subcontractor.logo : `https://api.refraxion.com${subcontractor.logo}`;
            return {
                ...subcontractor,
                status: EMPLOYEE_STATUS_BLOCK[subcontractor?.status],
                actions: (
                    <Group
                        onClick={() => {
                            setEditSubContractor(subcontractor)
                            setOpenDrawer(true)
                            form.setValues({
                                logo,
                                name: subcontractor.name,
                                company_id: subcontractor.company_id,
                                size: subcontractor.size,
                                country: subcontractor.country,
                                site_name: subcontractor.site_name,
                                employees_at_site: subcontractor.employees_at_site,
                                company_type: subcontractor.company_type,
                                address: subcontractor.address,
                                zip_code: subcontractor.zip_code,
                                phone_number: subcontractor.phone_number,
                                admin_email: subcontractor.admin_profile.email,
                            })
                        }}
                        gap={8}
                        justify='center'
                        align='center'
                        c='#868E96'
                    >
                        <IconEdit style={{ width: '16px', height: '16px' }} />
                        <p>Edit</p>
                    </Group>
                )
            }
        })
    }

    const selectSubContractor = async (id, parentId) => {
        const updatedOrganizations = organizations.map((organization) => {
            if (organization.id === parentId) {
                return {
                    ...organization,
                    subcontractor: organization?.subcontractor?.map((subCon) => {
                        if (subCon.id === id) {
                            return {
                                ...subCon,
                                selected: !subCon.selected
                            }
                        } else {
                            return subCon
                        }
                    }),
                };
            }
            return organization;
        });
        dispatch(setOrganizationList2(updatedOrganizations))
    };

    const selectAllSubContractors = (checked, id) => {
        const updatedOrganizations = organizations.map((organization) => {
            if (organization.id === id) {
                return {
                    ...organization,
                    subcontractor: organization.subcontractor.map((subCon) => ({
                        ...subCon,
                        selected: checked,
                    })),
                };
            } else {
                return organization
            }
        });
        dispatch(setOrganizationList2(updatedOrganizations));
    };

    const organizationList = organizations.map((organization) => {
        return (
            <Stack key={organization?.id} gap={12} mb={24}>
                <Group grow justify="space-between" align="center" wrap='nowrap'>
                    <Group>
                        {(organization?.logo === "" || organization?.logo === null) ? (
                            <FaRegBuilding size={30} color="grey" />
                        ) : (
                            <Image src={organization.logo} w={36.98} h={36.98} fit='contain' />
                        )}
                        <Title order={3}>{organization?.name}</Title>
                    </Group>
                    <PrimaryButton
                        onClick={() => {
                            setCompanyId(organization?.id)
                            setOpenDrawer(true)
                            setEditSubContractor(null)
                            form.reset()
                            form.setValues({
                                logo: null || "",
                                name: "",
                                company_id: "",
                                size: null,
                                country: "",
                                site_name: "",
                                employees_at_site: null,
                                company_type: null,
                                address: "",
                                zip_code: "",
                                phone_number: "",
                                admin_email: organization.admin_profile.email,
                            })
                        }}
                        maw={100}
                        color="teal.8"
                        radius={6}
                        title="Create"
                        h={42}
                        fw={600}
                    />
                </Group>
                <TableComp
                    tableHeaders={SUBCONTRACTOR_TABLE_HEADER}
                    data={serializeSubContractorData(organization?.subcontractor ?? [])}
                    isCheckBox={true}
                    type="employee"
                    onChange={selectSubContractor}
                    height='370px'
                    onChange2={(checked) => selectAllSubContractors(checked, organization.id)}
                    errorMessage2={
                        <>
                            {organization?.subcontractor && organization?.subcontractor.length === 0 ? (
                                <Stack gap={1} ta={'center'}>
                                    <Title order={4}> No Sub-Contractor Added </Title>
                                    <Text c="dimmed" fz={14}>Press the create button to add a sub-contractor</Text>
                                </Stack>
                            ) : null}
                        </>
                    }
                />
            </Stack>
        )
    })

    const handleSubmit = async () => {        
        if (form.validate().hasErrors) {
            form.validate()
            return;
        }
        else {
            if (editSubContractor?.id) {
                const formData = new FormData()
                Object.entries(form.values).forEach(([key, value]) => {
                    if (key === "logo" && (typeof value === "string" || value === null)) {
                        return;
                    }
                    else {
                        formData.append(key, value)
                    }
                })
                const res = await dispatch(updateSubContractor({ id: editSubContractor.id, payload: formData }))
                if (res.type === "updateSubContractor/rejected") {
                    asyncErrorHandler(res, form, setImageError)
                } else {
                    setEditSubContractor(null)
                    setOpenDrawer(false)
                    window.toast("Sub-contractor updated successfully")
                }
            } else {
                const payload = {
                    ...form.values,
                    site_name: form?.values?.site_name.includes("https://") ? form?.values?.site_name : `https://${form?.values?.site_name}`,
                    parent: id ? id : companyId,
                }
                const res = await dispatch(createSubContractor({ payload }))
                if (res.type === "createSubContractor/rejected") {
                    asyncErrorHandler(res, form, setImageError)
                } else {
                    const findSubContractorCompany = organizations.find((org) => org.id === companyId)
                    form.reset()
                    setOpenDrawer(false)
                    window.toast(`${findSubContractorCompany?.name} Sub-contractor created successfully`)
                }
            }
        }
    }

    const handleDelete = async () => {
        const res = await dispatch(deleteSubContractor(editSubContractor?.id))
        if (res.type === "deleteSubContractor/rejected") {
            asyncErrorHandler(res, form)
        } else {
            close()
            form.reset()
            setEditSubContractor(null)
            setOpenDrawer(false)
            window.toast(`Sub-contractor deleted successfully`)
        }
    }

    console.log(companyId);

    return (
        <Box>
            <CommonDrawer
                type=""
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                withCloseButton={false}
            >
                <Box px={24} py={12}>
                    <Group justify="space-between" align="center">
                        <Title order={4}>{editSubContractor === null ? "Create SubContractor" : "Edit SubContractor"}</Title>
                        <Flex>
                            <Button mr={10} h={35} size="sm" radius={10} bg="#E9ECEF" c="black" fw="lighter" bd="2px solid #ADB5BD" onClick={() => setOpenDrawer(false)}>
                                <Text fz={16} fw={500}>Cancel</Text>
                            </Button>
                            <Button onClick={handleSubmit} h={35} size="sm" radius={10} disabled={inviteLoading} type='submit' bg="#3354F4">
                                <Group>
                                    <Text fz={16} fw={500} c="white">Save Changes</Text>
                                    {inviteLoading && <Loader size="sm" type='dots' color='#ffff' />}
                                </Group>
                            </Button>
                        </Flex>
                    </Group>
                </Box>
                <Divider orientation="horizontal" />
                <Box p={24}>
                    <Stack gap={20} mt={20}>
                        <Grid gutter={20}>
                            {Object.entries(form.values)?.map(([key, value]) => {
                                const formattedKey = key.split('_').map((k) => k.charAt(0).toUpperCase() + k.slice(1)).join(" ")
                                const keys = ['admin_profile', 'company_id', 'id', 'parent', 'subcontractor'];
                                const dropdownKeys = ['company_type', 'employees_at_site', 'size'];
                                return (
                                    <>
                                        {!keys.includes(key) && (
                                            key === "logo" ? (
                                                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                                    <ProfileImageUploader
                                                        mb={20}
                                                        profileImageURL={
                                                            value && typeof value === "string" ? value :
                                                                value && value instanceof File ?
                                                                    URL.createObjectURL(value) : null
                                                        }
                                                        updateImage={(image) => form.setFieldValue('logo', image)}
                                                        deleteImage={() => form.setFieldValue('logo', '')}
                                                    />
                                                </Grid.Col>
                                            ) : dropdownKeys.includes(key) ? (
                                                <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                                                    <SelectInputField
                                                        checkIconPosition="left"
                                                        data={key === "employees_at_site" ? ORGANIZATIONS_EMPLOYEES_AT_SITE_SIZE_CHOICES : key === "size" ? COMPANY_SIZE_CHOICES : INDUSTRY_SERVICED}
                                                        label={<Text fz={16} fw={500}>{formattedKey}</Text>}
                                                        key={form.key(key)}
                                                        {...form.getInputProps(key)}
                                                        placeholder={formattedKey}
                                                        rightSection={<IoIosArrowDown style={{ width: '22px', height: 'auto', marginRight: '5px' }} />}
                                                        defaultValue={value}
                                                    />
                                                </Grid.Col>
                                            ) : key === "zip_code" ? (
                                                <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                                                    <InputField
                                                        type="number"
                                                        label={<Text fz={16} fw={500}>{formattedKey}</Text>}
                                                        placeholder="Enter your zip code"
                                                        {...form.getInputProps('zip_code')}
                                                        size="sm"
                                                        onChange={(e) => {
                                                            const value = e.target.value.replace(/\D/g, '');
                                                            if (value.length <= 5) {
                                                                form.setFieldValue('zip_code', value);
                                                            }
                                                        }}
                                                    />
                                                </Grid.Col>
                                            ) : (
                                                <Grid.Col span={{ base: key === "company_id" ? 12 : 6, md: key === "company_id" ? 12 : 6, lg: key === "company_id" ? 12 : 6 }}>
                                                    <InputField
                                                        label={<Text fz={16} fw={600} c="#495057">{formattedKey}</Text>}
                                                        key={form.key(key)}
                                                        {...form.getInputProps(key)}
                                                        placeHolder={`Please Enter ${formattedKey}`}
                                                        disabled={key === "admin_email"}
                                                    />
                                                </Grid.Col>
                                            )
                                        )}
                                    </>
                                )
                            })}
                        </Grid>
                        <Group justify="end" mt={10}>
                            <Button onClick={handleDelete} h={35} variant="light" radius={10} style={{ background: '#FFC9C9' }}>
                                <Text fz={16} fw={500} c="#C92A2A">Delete</Text>
                            </Button>
                        </Group>
                    </Stack>
                </Box>
            </CommonDrawer>
            <Flex
                direction="column"
                justify="flex-start"
                gap={24}
                mr={24}
                my={24}
            >
                <Stack gap={12}>
                    <Title fz={24} fw={700}>Manage and Create Sub-Contractors</Title>
                    <Text fz={16} fw={400} c="#5C5F66">Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
                </Stack>
                <Stack>
                    {organizationList}
                </Stack>
            </Flex>
        </Box>
    )
}

export default SubContractor;