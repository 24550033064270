export const EMPLOYEE_PRESCRIPTIONS_TABLE_HEADERS = [
    { value: 'name', label: 'Name' },
    { value: 'start_date', label: 'Start Date' },
    { value: 'end_date', label: 'End Date' },
    { value: 'lens_type', label: 'Lens Type' },
    { value: 'usage', label: 'Usage' },
    { value: 'right_sphere', label: 'Right Sphere' },
    { value: 'left_sphere', label: 'Left Sphere' },
    { value: 'right_cylinder_astigmatism', label: 'Right Cylinder Astigmatism' },
    { value: 'left_cylinder_astigmatism', label: 'Left Cylinder Astigmatism' },
    { value: 'right_astigmatism_axis', label: 'Right Astigmatism Axis' },
    { value: 'left_astigmatism_axis', label: 'Left Astigmatism Axis' },
    { value: 'right_prism', label: 'Right Prism' },
    { value: 'left_prism', label: 'Left Prism' },
    { value: 'right_add', label: 'Right Add' },
    { value: 'left_add', label: 'Left Add' },
    { value: 'right_pupil', label: 'Right Pupil' },
    { value: 'left_pupil', label: 'Left Pupil' },
];

export const EMPLOYEE_MEASUREMENT_TABLE_HEADERS = [
    { value: 'frame_width', label: 'Frame Width' },
    { value: 'frontal_ratio', label: 'Frontal Ratio' },
    { value: 'ipd_width', label: 'Ipd Width' },
    { value: 'lens_width', label: 'Lens Width' },
    { value: 'temple_length', label: 'Temple Length' },
    { value: 'nose_bridge', label: 'Nose Bridge' },
    { value: 'created_at', label: 'Scanned Date' },
    { value: 'fit_size', label: 'Fit Size' },
]

export const EMPLOYEE_TABLE_HEADERS = [
    { value: 'name', label: 'Name' },
    { value: 'email', label: 'Email' },
    { value: 'tier', label: 'Tier Assignment' },
    { value: 'address', label: 'Site/Location' },
    { value: 'rx_non_rx', label: 'Rx/ Non-Rx' },
    { value: 'frame_and_lens', label: 'Frames & lens' },
    { value: 'status', label: 'Status' },
    { value: 'actions', label: 'Actions' },
]

export const MY_ORDERS_HEADERS = [
    { value: 'id', label: 'Id' },
    { value: 'orderId', label: 'Order Id' },
    { value: 'createdAt', label: 'Created At' },
    { value: 'count', label: 'Items' },
    { value: 'paymentMethod', label: 'Payment Method' },
    { value: 'price', label: 'Price' },
    { value: 'status', label: 'Status' },
    { value: 'action', label: 'Action' },
]