import React, { useMemo, useState } from 'react';
import { Button, Container, Stack, TextInput, Group, Text, Box, Flex, Title, Divider, Textarea } from '@mantine/core'

import { MY_ORDERS_HEADERS } from '../../../mock/employees/table/header';
import TableComp from '../../../components/Table/Table';
import { IconEye } from '@tabler/icons-react';
import { IoSearch } from 'react-icons/io5';
import { FilterDropdown } from '../../../components/EmployeeManagement/Dropdown';
import { CommonDrawer } from '../../../components/Drawer/Drawer';
import ModalComp from '../../../components/Modal/Modal';
import { useSelector } from 'react-redux';
import { PrimaryButton } from '../../../components/ui/Button/Button';

const MyOrders = () => {

  const [viewOrder, setViewOrder] = useState(null);
  const [openModal, setOpenModal] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const myOrders = useSelector((state) => state.orders.orders)

  const mapItems = myOrders.map((order) => {
    return {
      ...order,
      action: (
        <Box style={{ display: "flex", alignItems: "center" }}
          onClick={() => setSelectedOrder(order)}
        >
          <IconEye style={{ width: '20px', height: '20px' }} />
          <p style={{ marginLeft: "8px" }}>View</p>
        </Box>
      )
    }
  });

  const handleCancelOrder = async () => {
    // const res = await dispatch(cancelOrder(selectedOrder?.id))
    setOpenModal(false)
  }

  const totalPrice = useMemo(() => {
    return selectedOrder?.items?.reduce((a, b) => a + (b.totalPrice || 0), 0) || 0;
  }, [selectedOrder]);

  console.log(selectedOrder);

  return (
    <>
      <ModalComp title={<Text fz={18} fw={600}>Do you really want to cancel the order ?</Text>} open={openModal} setOpen={setOpenModal}>
        <Text c="#5C5F66" mt={14} mb={24} lh={1.2}>Please specify the reason, and we will send a notification to your employees by email.</Text>
        <Text c="#5C5F66" mb={10}>Reason*</Text>
        <Textarea placeholder='Specify the reason' rows={3} mb={24} />
        <Flex justify="end">
          <PrimaryButton title="Decline order" ml='auto' bg="#C92A2A" c="white" onClick={handleCancelOrder} />
        </Flex>
      </ModalComp>
      <CommonDrawer
        open={selectedOrder}
        onClose={() => setSelectedOrder(null)}
        withCloseButton={false}
        size={750}
      >
        <Box px={24} py={12}>
          <Group justify="space-between" align="center">
            <Title order={4}>Order information</Title>
            <Button h={32} size="sm" bg="#E9ECEF" c="black" fw="lighter" bd="2px solid #ADB5BD" radius={6} onClick={() => setSelectedOrder(null)}>Cancel</Button>
          </Group>
        </Box>
        <Divider orientation="horizontal" />
        <Box px={24} py={14}>
          <Title c="#344054" mb={12} order={3}>Order ID: {selectedOrder?.orderId}</Title>
          <Title c="#667085" order={4} fw={500}>Order date: Feb 16, 2022</Title>
        </Box>
        <Divider orientation="horizontal" />
        <Box px={24} py={14}>
          <Group justify="space-between">
            <Box>
              <Title c="#667085" order={5} fw={500}>Name</Title>
              <Title c="#344054" order={4}>Brooklyn Simmons</Title>
            </Box>
            <Box>
              <Title c="#667085" order={5} fw={500}>Email</Title>
              <Title c="#344054" order={4}>danghoang87hl@gmail.com</Title>
            </Box>
            <Box>
              <Title c="#667085" order={5} fw={500}>Prescriprion</Title>
              <Title c="#344054" order={4}>{selectedOrder?.prescription?.name}</Title>
            </Box>
          </Group>
        </Box>
        <Divider orientation="horizontal" />
        <Box mx={24}>
          {selectedOrder?.items?.map((item, index) => {
            return (
              <Box bg="#F8F9FA" p={16} style={{ borderRadius: "20px", cursor: "pointer" }} mt={index === 0 && 24} mb={index < index + 1 && 12}>
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 20
                }}>
                  <img style={{
                    border: "2px solid #D0D5DD", borderRadius: "10px", width: "70px", height: "70px", objectFit: "contain"
                  }} src={item?.frame_image} alt="" />
                  <Box w="100%">
                    <Group justify="space-between">
                      <Text fw={700} fz={20} c="#344054">{item?.glass_brand?.frame_name}</Text>
                      <Text fw={700} fz={18} c="#141517">${item?.totalPrice}</Text>
                    </Group>
                    <Group justify="space-between">
                      <Box>
                        <Group>
                          <Text fw={500} fz={16} c="#667085">Color</Text>
                          <Text fw={500} fz={16} c="#D0D5DD">|</Text>
                          <Text fw={500} fz={16} c="#667085">Size</Text>
                          <Text fw={500} fz={16} c="#D0D5DD">|</Text>
                          <Text fw={500} fz={16} c="#667085">Name</Text>
                        </Group>
                      </Box>
                      <Text fw={700} fz={16} c="#667085">Oty: {item?.qty}</Text>
                    </Group>
                  </Box>
                </div>
              </Box>
            )
          })}
          <Text c="#545454" fw={600} fz={18} mb={20}>Address</Text>
          <Text fw={600} fz={20}>{selectedOrder?.address?.address}</Text>
          <Group justify="space-between" mt={28} mb={12}>
            <Text c="#545454" fw={600} fz={18} mb={20}>Subtotal</Text>
            <Text fw={600} fz={18} mb={20}>${totalPrice}</Text>
          </Group>
          <Group justify="space-between" mb={12}>
            <Text c="#545454" fw={600} fz={18} mb={20}>Estimated Tax</Text>
            <Text fw={600} fz={18} mb={20}>$0</Text>
          </Group>
          <Group justify="space-between" mb={12}>
            <Text c="#545454" fw={600} fz={18} mb={20}>Estimated shipping & Handling</Text>
            <Text fw={600} fz={18} mb={20}>$0</Text>
          </Group>
          <Group justify="space-between">
            <Text fw={600} fz={18} mb={20}>Total</Text>
            <Text fw={600} fz={18} mb={20}>${totalPrice}</Text>
          </Group>
          <Group justify="flex-end" my={35}>
            <PrimaryButton 
            title="Cancel order" h={32} size="sm" bg="#C92A2A" radius={6} 
            onClick={() => {
              setOpenModal(true)
              setSelectedOrder(null)
            }} />
            <PrimaryButton 
            title="Push changes" h={32} size="sm" bg="#3354F4" radius={6} onClick={() => setSelectedOrder(null)} />
          </Group>
        </Box>
      </CommonDrawer>
      <Container fluid style={{ height: '100%' }}>
        <Stack gap={20} style={{ height: '100%' }}>
          <Group justify='space-between' mt={24}>
            <Group gap={10}>
              <TextInput
                leftSection={<IoSearch color="#ADB5BD" />}
                placeholder="Search"
              />
              <FilterDropdown />
            </Group>
          </Group>
          <Stack>
            <TableComp
              openAddEmployeeDrawer={viewOrder}
              setOpenAddEmployeeDrawer={setViewOrder}
              tableHeaders={MY_ORDERS_HEADERS}
              height='250px'
              totalRecords={myOrders?.length}
              totalPages={1}
              data={mapItems}
              isCheckBox={true}
              showPagination={true}
            />
          </Stack>
        </Stack>
      </Container>
    </>
  )
}

export default MyOrders;