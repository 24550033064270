import React from 'react'
import { Box, Button, Card, Divider, Group, Text } from '@mantine/core';
import classes from '../../Dashboard/card.module.css'
import { OFFERS } from '../../../mock/employees/safetyAwards';

const Offers = () => {
  return (
    <Card maxHeight="100vh" radius={16} className={classes.card} p={24} styles={{ root: { border: "2px solid #DEE2E6" } }}>
      <Group justify="space-between" mb={24}>
        <Text fz={18} fw={600}>Offers for you</Text>
        <Button variant="outline" radius={10} bd="2px solid electric">View all</Button>
      </Group>
      {OFFERS.map((item) => {
        return (
          <Box w="100%" mb={16}>
            <Group align="flex-start" noWrap style={{ fontFamily: "Inter-regular" }}>
              <img
                src={item.image}
                alt={item.product}
                style={{
                  width: '80px',
                  height: '80px',
                  objectFit: 'contain',
                  borderRadius: '8px',
                }}
              />
              <Box style={{ flex: 1 }}>
                <Text
                  fw={600}
                  fz={16}
                  mb={4}
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    maxWidth: '200px',
                  }}
                >
                  {item.product}
                </Text>
                <Text c="#71727A" fz={14} mb={21}>Details</Text>
                <Group position="apart" align="center" justify="space-between">
                  <Text fw={700} fz={16}>
                    {item.price}
                  </Text>
                  <button
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={item.icon}
                      alt="Favorite"
                      style={{ width: '20px', height: '20px' }}
                    />
                  </button>
                </Group>
              </Box>
            </Group>
            <Divider orientation="horizontal" my={16} />
          </Box>
        )
      })}
    </Card>
  )
}

export default Offers;