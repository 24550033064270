import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: null,
    success: null,
    loading: false,
    loading2: false,
    safetyAwards: [],
    participants: [],
};

const safetyAwardSlice = createSlice({
    name: "SafetyAwards",
    initialState,
    reducers: {
        setSafetyAwards: (state, { payload }) => {
            state.safetyAwards = payload
        },
        setError: (state, { payload }) => {
            state.error = payload;
        },
        setSuccess: (state, { payload }) => {
            state.success = payload;
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setLoading2: (state, { payload }) => {
            state.loading2 = payload;
        },
    },
});

export const { setError, setLoading, setLoading2, setSuccess, setSafetyAwards } = safetyAwardSlice.actions;
export default safetyAwardSlice.reducer;