import { IconCheck, IconMinus } from "@tabler/icons-react"
import { Group } from "@mantine/core"
import EmployStatus from "../components/EmployeeManagement/EmployStatus"

export const EMPLOYEE_TIER = {
    '1': 'Silver tier',
    '2': 'Bronze tier',
    '3': 'Gold tier'
}

export const EMPLOYEE_TIER_WITH_ICONS = {
    '1':
        <>
            <Group gap="6px" align="center" justify="center">
                <div style={{
                    width: "14px",
                    height: '14px',
                    background: 'linear-gradient(137.95deg, #7A96AC 2.28%, #EAEFF3 19.8%, #C2D4E1 32.94%, #FFFFFF 50.16%, #D4DEE5 62.15%, #ABBDC8 78.69%, #BCCAD7 95.24%)',
                    borderRadius: '4px',
                }}
                />
                <p style={{ fontSize: '0.8rem', fontWeight: '400' }}>Silver tier</p>
            </Group>
        </>
    ,
    '2':
        <>
            <Group gap="6px" align="center" justify="center">
                <div style={{
                    width: "14px",
                    height: '14px',
                    background: 'linear-gradient(135.31deg, #9E8976 15.43%, #7A5E50 30.62%, #F6D0AB 47.37%, #9D774E 62.96%, #C99B70 82.05%, #795F52 93.35%)',
                    borderRadius: '4px',
                }}
                />
                <p style={{ fontSize: '0.8rem', fontWeight: '400' }}>Bronze tier</p>
            </Group>
        </>
    ,
    '3':
        <>
            <Group gap="6px" align="center" justify="center">
                <div style={{
                    width: "14px",
                    height: '14px',
                    background: 'linear-gradient(250.62deg, #8C421D 13.98%, #FBE67B 37.66%, #FCFBE7 52.96%, #F7D14E 70.02%, #D4A041 86.77%)',
                    borderRadius: '4px',
                }}
                />
                <p style={{ fontSize: '0.8rem', fontWeight: '400' }}>Gold tier</p>
            </Group>
        </>
}

export const EMPLOYEE_TIER_CHOICES = [
    { value: '1', label: 'Silver tier' },
    { value: '2', label: 'Bronze tier' },
    { value: '3', label: 'Gold tier' },
]

export const EMPLOYEE_PRESCRIPTION = {
    '1': 'Prescription',
    '2': 'Non-Prescription'
}

export const EMPLOYEE_STATUS = {
    '1': 'Invited',
    '2': 'Error',
    '3': 'Active'
}

export const EMPLOYEE_STATUS_BLOCK = {
    '1':
        <>
            <EmployStatus status="Invited" className="invited" />
        </>,
    '2':
        <>
            <EmployStatus status="Error" className="error" />
        </>,
    '3':
        <>
            <EmployStatus status="Active" className="active" />
        </>
}

export const EMPLOYEE_FRAME_AND_LENS = {
    true:
        <>
            <IconCheck />
        </>,
    false:
        <>
            <IconMinus color="#ADB5BD" />
        </>
}

export const ETHNICITY = [
    { value: '1', label: 'Native American' },
    { value: '2', label: 'African American / Black' },
    { value: '3', label: 'Asian' },
    { value: '4', label: 'Hispanic / Latino' },
    { value: '5', label: 'Middle Eastern / North African' },
    { value: '6', label: 'Native Hawaiian / Pacific Islander' },
    { value: '7', label: 'White / Caucasian' },
    { value: '8', label: 'Multiracial / Mixed' },
    { value: '9', label: 'Other' },
    { value: '10', label: 'Prefer not to say' },
];
export const TIER_ELIGIBILITY = [
    { value: '1', label: '3 Months' },
    { value: '2', label: '6 Months' },
    { value: '3', label: '12 Months' },
];
export const CITIES = [
    { value: "Aabenraa", label: "Aabenraa", },
    { value: "Aachen", label: "Aachen", },
    { value: "Aalborg", label: "Aalborg", },
    { value: "Aalen", label: "Aalen", },
    { value: "Aalsmeer", label: "Aalsmeer", },
    { value: "Aalst", label: "Aalst", },
    { value: "Aalten", label: "Aalten", },
    { value: "Aalter", label: "Aalter", },
    { value: "Aarau", label: "Aarau", },
    { value: "Aarschot", label: "Aarschot", },
    { value: "Aba", label: "Aba", },
    { value: "Abadeh", label: "Abadeh", },
    { value: "Abaetetuba", label: "Abaetetuba", },
    { value: "Abaeté", label: "Abaeté", },
    { value: "Abakaliki", label: "Abakaliki", },
    { value: "Abakan", label: "Abakan", },
    { value: "Abala", label: "Abala", },
    { value: "Abalak", label: "Abalak", },
    { value: "Abancay", label: "Abancay", },
    { value: "Abano", label: "Abano", },
    { value: "Abashiri", label: "Abashiri", },
    { value: "Abasolo", label: "Abasolo", },
    { value: "Abay", label: "Abay", },
    { value: "Abaza", label: "Abaza", },
    { value: "Abbeville", label: "Abbeville", },
]
export const COUNTRIES = [
    { value: "Alabama", label: "Alabama" },
    { value: "Alaska", label: "Alaska" },
    { value: "Arizona", label: "Arizona" },
    { value: "Arkansas", label: "Arkansas" },
    { value: "California", label: "California" },
    { value: "Colorado", label: "Colorado" },
    { value: "Connecticut", label: "Connecticut" },
    { value: "Delaware", label: "Delaware" },
    { value: "Florida", label: "Florida" },
    { value: "Georgia", label: "Georgia" },
];

export const EMPLOYEE_MEASUREMENT_TABLE_DATA = [
    {
        id: 1,
        frame_width: 130,
        frontal_ratio: 18,
        ipd_width: 60,
        lens_width: 52,
        temple_length: 140,
        nose_bridge: 150,
        created_at: new Date().toLocaleDateString(),
        fit_size: "Small",
    },
    {
        id: 2,
        frame_width: 130,
        frontal_ratio: 18,
        ipd_width: 60,
        lens_width: 52,
        temple_length: 140,
        nose_bridge: 150,
        created_at: new Date().toLocaleDateString(),
        fit_size: "Small",
    },
    {
        id: 3,
        frame_width: 130,
        frontal_ratio: 18,
        ipd_width: 60,
        lens_width: 52,
        temple_length: 140,
        nose_bridge: 150,
        created_at: new Date().toLocaleDateString(),
        fit_size: "Small",
    },
]
