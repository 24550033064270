import React from 'react';
import classes from './billing.module.css';

const PaymentCard = ({ payment, selectPaymentMethod, setSelectPaymentMethod }) => {
    return (
        <div
            className={classes.paymentMethodCard}
            style={{ border: payment.id === selectPaymentMethod ? "2px solid #1C7ED6" : "2px solid #E3E3E3" }}
            onClick={() => setSelectPaymentMethod(payment)}
        >
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{
                    width: "70px",
                    height: "48px",
                    border: "2px solid #D9D9D9",
                    borderRadius: "6px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <img src={payment.icon} alt="" />
                </div>
                <div style={{ display: "flex" }}>
                    <div className={classes.cardHolderNumber}>
                        <p className={classes.cardTitle}>Card Holder number</p>
                        <p className={classes.cardValue}>{payment.cardNumber}</p>
                    </div>
                    <div className={classes.cardHolderName}>
                        <p className={classes.cardTitle}>Card Holder name</p>
                        <p className={classes.cardValue}>{payment.companyName}</p>
                    </div>
                    <div className={classes.cardExpiryDate}>
                        <p className={classes.cardTitle}>Expiry date</p>
                        <p className={classes.cardValue}>08/24</p>
                    </div>
                </div>
            </div>
            <div className={classes.selectCard}>
                <input type="radio" checked={payment.id === selectPaymentMethod} />
                <p style={{
                    color: "#A6A7AB",
                    fontSize: "14px",
                    fontWeight: "400"
                }}>Default method</p>
            </div>
        </div>
    )
}

export default PaymentCard;