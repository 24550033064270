import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Dashboard from './pages/dashboard/dashboard'
import Registration from './pages/login/Registration'
import EmployeeManagement from './components/EmployeeManagement/EmployeeManagement'
import Notification from './pages/notification/notification'
import Billing from './pages/billing/billing'
import SafetyAwards from './pages/safety-awards/safetyAwards'
import Settings from './pages/settings/settings'
import TierManagement from './pages/tier-management/tierManagement'
import ReportingAndAnalytics from './pages/reporting-analytics/ReportingAndAnalytics'
import PatnerSignup from "./pages/signup/patnerSignup"
import PartnerDashboard from './pages/dashboard/PartnerDashboard'
import PartnerNotification from './pages/notification/PartnerNotification'
import PartnerSettings from './pages/settings/PartnerSettings'
import PartnerReportingAndAnalytics from './pages/reporting-analytics/PartnerReportingAndAnalytics'
import PartnerCompanyManagement from './pages/company-management/PartnerCompanyManagement'
import BaseLayout from './components/Layout/BaseLayout'
import Login from './pages/login/Login'
import { PrivateRoutes, PublicRoutes } from './router/RouterGuard'

// Employess pages
import EmployeeDashboard from './pages/employess/dashboard/employeeDasboard'
import MyPrescription from './pages/employess/prescription/prescription'
import RFXNStore from './pages/employess/store/store'
import MyOrders from './pages/employess/myOrders/myOrders'
import EmployeeSafetyAwards from './pages/employess/safetyAwards/safetyAwards'
import EmployessSettings from './pages/employess/settings/EmployessSettings'
import ContactUs from './pages/employess/contactUs/contactUs'
import ProductDetail from './pages/employess/productDetail/productDetail'
import Checkout from './pages/employess/checkout/checkout'
import Cart from './pages/employess/cart/cart'
import ResetPassword from './pages/auth/resetPassword'
import EditSafetyAwards from './pages/safety-awards/layout'
import SelectPrescription from './components/Employess/checkout/selectPrescription'
import Summary from './components/Employess/checkout/summary'
import SelectAddress from './components/Employess/checkout/selectAddress'
import FavouriteItems from './pages/employess/favouriteItems/favouriteItems'

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route element={<PublicRoutes allowedRoles={['admin']} />}>
                    <Route path='/' element={<Login />} />
                    <Route path='/reset-password' element={<ResetPassword />} />
                    <Route path='/admin/signup' element={<Registration />} />
                </Route>

                <Route element={<PrivateRoutes allowedRoles={['admin']} />}>
                    <Route path='/admin' element={<BaseLayout />}>
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path='employee-management' element={<EmployeeManagement />} />
                        <Route path='notification' element={<Notification />} />
                        <Route path='reporting-analytics' element={<ReportingAndAnalytics />} />
                        <Route path='tier-management' element={<TierManagement />} />
                        <Route path="contact-us" element={<ContactUs />} />
                        <Route path='admin-settings' element={<Settings />} />
                        <Route path='billing' element={<Billing />} />
                        <Route path='safety-awards' element={<SafetyAwards />} />
                        <Route path='safety-awards/edit/:id' element={<EditSafetyAwards />} />
                        {/* <Route path='orders' element={<Billing />} /> */}
                        {/* <Route path='company-settings' element={<Settings />} /> */}
                    </Route>
                </Route>

                <Route element={<PublicRoutes allowedRoles={['partner']} />}>
                    <Route path='/' element={<Login />} />
                    <Route path='/partner/signup'>
                        <Route index element={<PatnerSignup />} />
                    </Route>
                </Route>

                <Route element={<PrivateRoutes allowedRoles={['partner']} />}>
                    <Route path='/partner' element={<BaseLayout />}>
                        <Route path="dashboard" element={<PartnerDashboard />} />
                        <Route path='company-management/:id?' element={<PartnerCompanyManagement />} />
                        <Route path='notifications' element={<PartnerNotification />} />
                        <Route path='reporting-and-analytics' element={<PartnerReportingAndAnalytics />} />
                        <Route path='settings' element={<PartnerSettings />} />
                        <Route path='tier-management' element={<TierManagement />} />
                        <Route path="contact-us" element={<ContactUs />} />
                    </Route>
                </Route>

                <Route element={<PrivateRoutes allowedRoles={['employee']} />}>
                    <Route path='/employee' element={<BaseLayout />}>
                        <Route path="dashboard" element={<EmployeeDashboard />} />
                        <Route path="prescription" element={<MyPrescription />} />
                        <Route path="cart" element={<Cart />} />
                        <Route path="checkout" element={<Checkout />}>
                            <Route path='select-prescription' element={<SelectPrescription />} />
                            <Route path='select-address' element={<SelectAddress />} />
                            <Route path='summary' element={<Summary />} />
                        </Route>
                        <Route path="store" element={<RFXNStore />} />
                        <Route path="product/:id" element={<ProductDetail />} />
                        <Route path="my-orders" element={<MyOrders />} />
                        <Route path="safety-awards" element={<EmployeeSafetyAwards />} />
                        <Route path="settings" element={<EmployessSettings />} />
                        <Route path="contact-us" element={<ContactUs />} />
                        <Route path="favourite-items" element={<FavouriteItems />} />
                    </Route>
                </Route>

            </Routes>

        </Router>
    )
}

export default AppRouter;