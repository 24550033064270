import { Title, Text, Button, Group, Divider, Flex, Box, Stack, TextInput, Loader, Grid } from "@mantine/core"
import { useViewportSize } from '@mantine/hooks';
import { useForm } from "@mantine/form"
import { yupResolver } from "mantine-form-yup-resolver";
import * as yup from 'yup';
import { IconEdit, IconEye, IconReload, IconSearch, IconX } from "@tabler/icons-react"
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import { bulkUpdateOrganizations, deleteOrganization, deleteOrganizations, sendOrganizationAdminInvite, updateOrganization, updateOrganizations } from "../../../../middlewares/organization/organization";
import { EMPLOYEE_STATUS_BLOCK } from "../../../../choices/employee.choice";
import AddCompanyModal from "./Modal/AddCompanyModal";
import { useDisclosure } from "@mantine/hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TableComp from "../../../Table/Table";
import { PARTNER_COMPANIES_TABLE_HEADER } from "../../../../choices/organizationChoices";
import { CommonDrawer } from "../../../Drawer/Drawer";
import { PrimaryButton } from "../../../ui/Button/Button";
import ProfileImageUploader from "../../../BaseComponents/ProfileImageUploader";
import "./Company.css"
import { asyncErrorHandler } from "../../../../helpers/errorHandler";
import { InputField } from "../../../ui/Input/Input";
import { setOrganizationList2 } from "../../../../store/organization/organizationSlice";

const Company = () => {

    const id = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const [openDrawer, setOpenDrawer] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [deleteCompanyButtonLoading, setDeleteCompanyButtonLoading] = useState(false)
    const isLoading = useSelector((state) => state.organizations.isLoading)
    const organizationList1 = useSelector((state) => state.organizations.organizationList);
    const organizationList = useSelector((state) => state.organizations.organizationList2);
    const labels = {
        first_name: "Admin first name",
        last_name: "Admin last name",
        name: "Company name",
        site_name: "Site name",
        admin_email: "Email admin member",
        phone_number: "Phone number",
        company_id: "Company ID",
        status: "Status"
    }

    const searchIcon = <IconSearch width={16} h={16} color="#ADB5BD" />
    const clearSearchIcon = <IconX width={16} h={16} color="#ADB5BD" />

    const form = useForm({
        initialValues: {
            id: null,
            name: "",
            first_name: "",
            last_name: "",
            site_name: "",
            admin_email: "",
            phone_number: "",
            company_id: "",
            status: null,
        },
        validate: {
            name: (val) => (/^(?=.*[A-Za-z])(?!^[\d\s-]+$)(?!^[^\w\s-]+$)[A-Za-z\d\s-]+$/.test(val) ? null : 'Please enter a valid company name'),
        },
    })

    const validateOrganizations = yup.object().shape({
        organizations: yup.array().of(
            yup.object().shape({
                admin_email: yup.string().required('Email is required').email('Invalid email'),
                name: yup.string('Company name is required').required('Company name is required').matches(/^(?=.*[A-Za-z])(?!^[\d\s-]+$)(?!^[^\w\s-]+$)[A-Za-z\d\s-]+$/, 'Please enter valid name'),
                site_name: yup.string().required('Site url is required').matches(/^(https?|ftp):\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$|^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/i, 'Please enter a valid url'),
                company_id: yup.string().required('Company id is required').matches(/^[a-zA-Z0-9_]+$/, 'Please enter a valid company id'),
                phone_number: yup.string().required('Phone number is required').matches(/^\+?[1-9]\d{0,2}(021|\d{1,4})\d{1,9}$/, 'Please enter a valid contact'),
            })
        )
    })
    const [opened, { open, close }] = useDisclosure()

    const existingOrganizationListForm = useForm({
        initialValues: {
            organizations: [
                { id: '', admin_email: '', name: '', site_name: '', company_id: '', phone_number: '', selected: false },
            ]
        },
        validate: yupResolver(validateOrganizations)
    })

    useEffect(() => {
        existingOrganizationListForm.reset()
        existingOrganizationListForm.removeListItem('organizations', 0);
        organizationList.forEach((organization) => {
            existingOrganizationListForm.insertListItem('organizations', {
                id: organization?.id,
                logo: organization?.logo ? organization?.logo : null,
                name: organization?.name,
                first_name: organization?.admin_profile?.profile?.first_name,
                last_name: organization?.admin_profile?.profile?.last_name,
                admin_email: organization?.admin_profile?.email,
                site_name: organization?.site_name,
                company_id: organization?.company_id,
                phone_number: organization?.phone_number,
                status: EMPLOYEE_STATUS_BLOCK[organization?.admin_profile?.profile?.status],
                selected: false,
                action: (
                    <Box
                        style={{ display: "flex", alignItems: "center" }}
                        onClick={() => {
                            setOpenDrawer(true)
                            form.setValues({
                                id: organization?.id,
                                logo: organization?.logo ? organization?.logo : null,
                                name: organization.name,
                                first_name: organization.admin_profile.profile.first_name,
                                last_name: organization.admin_profile.profile.last_name,
                                admin_email: organization.admin_profile.email,
                                site_name: organization.site_name,
                                phone_number: organization.phone_number,
                                company_id: organization.company_id,
                                status: organization.admin_profile.profile.status,
                            })
                        }}
                    >
                        <IconEdit color="#868E96" style={{ width: '20px', height: '20px' }} />
                        <p style={{ marginLeft: "8px", color: "#868E96" }}>Edit</p>
                    </Box>
                )
            })
        })
    }, [organizationList])

    const updateOrganizationList = async () => {
        if (form.validate().hasErrors) {
            form.validate()
            return;
        }
        else {
            setLoading(true)
            const formData = new FormData()
            Object.entries({
                ...form.values,
                admin_email: form.values.admin_email,
                id: form.values.id
            }).forEach(([key, value]) => {
                if (key === "admin_email") {
                    return;
                }
                if (key === "logo" && (typeof value === "string" || value === null)) {
                    return;
                } else {
                    formData.append(key, value);
                }
            })
            const res = await dispatch(updateOrganizations({ id: form.values.id, formData }))
            if (updateOrganizations.fulfilled.match(res)) {
                setOpenDrawer(false)
                setLoading(false)
                window.toast("Organizations' details updated successfully")
            }
            else if (updateOrganizations.rejected.match(res)) {
                setLoading(false)
                asyncErrorHandler(res, form)
            }
        }
    }

    const deleteSelectedOrganization = async () => {
        const selectedOrganization = existingOrganizationListForm.values.organizations
            .filter((organization) => organization.selected)
            .map((organization) => organization.id)
        if (selectedOrganization) {
            setDeleteCompanyButtonLoading(true)
            const res = selectedOrganization?.length > 1 ? await dispatch(deleteOrganizations({ organization_ids: selectedOrganization })) : await dispatch(deleteOrganization(selectedOrganization[0]))
            if (deleteOrganization.fulfilled.match(res)) {
                setDeleteCompanyButtonLoading(false)
                navigate("/partner/company-management")
                window.toast("Organization deleted successfully")
            } else if (deleteOrganization.rejected.match(res)) {
                setDeleteCompanyButtonLoading(false)
                navigate("/partner/company-management")
                window.toast("Error occured while deleting organization", 'error')
            }
        }
    }

    const selectOrganization = async (id) => {
        const updatedOrganizations = existingOrganizationListForm.values.organizations.map((organization) => {
            if (organization.id === id) {
                return {
                    ...organization,
                    selected: !organization.selected,
                };
            }
            return organization;
        });
        existingOrganizationListForm.setFieldValue('organizations', updatedOrganizations);
    };

    const selectAllOrganizations = (checked) => {
        const updatedOrganizations = existingOrganizationListForm.values.organizations.map((organization) => {
            return {
                ...organization,
                selected: checked,
            };
        });
        existingOrganizationListForm.setFieldValue('organizations', updatedOrganizations);
    }

    const isOrganizationSelected = () => {
        return existingOrganizationListForm.values.organizations.some((organization) => organization.selected)
    }

    const openAddCompanyModal = () => {
        open()
    }

    const resendInvitation = async () => {
        setLoading2(true)
        const res = await dispatch(sendOrganizationAdminInvite({
            organization_id: id.id ? id.id : existingOrganizationListForm.values.organizations.find((f) => f.selected)?.id,
            admin_email: id?.id ? organizationList[0].admin_profile.email : existingOrganizationListForm.values.organizations.find((f) => f.selected)?.email
        }))
        if (sendOrganizationAdminInvite.fulfilled.match(res)) {
            setLoading2(false)
            window.toast("Invitation send successfully")
        }
        else if (sendOrganizationAdminInvite.rejected.match(res)) {
            setLoading2(false)
            window.toast("Error occured while resending invite.", 'error')
        }
    }

    // useEffect(() => {
    //     if (id?.id) {
    //         dispatch(setOrganizationList2(organizationList1.filter((org) => org.id === id?.id)))
    //     } else {
    //         dispatch(setOrganizationList2(organizationList1))
    //     }
    // }, [location.pathname])

    return (
        <>
            <AddCompanyModal modalOpened={opened} modalClose={close} />
            <CommonDrawer
                type=""
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                withCloseButton={false}
            >
                <Box px={24} py={12}>
                    <Group justify="space-between" align="center">
                        <Title order={4}>Edit Company</Title>
                        <Flex>
                            <Button mr={10} h={35} size="sm" radius={10} bg="#E9ECEF" c="black" fw="lighter" bd="2px solid #ADB5BD" onClick={() => setOpenDrawer(false)}>
                                <Text fz={16} fw={500}>Cancel</Text>
                            </Button>
                            <Button onClick={updateOrganizationList} h={35} size="sm" radius={10} disabled={loading} type='submit' bg="#3354F4">
                                <Group>
                                    <Text fz={16} fw={500} c="white">Save Changes</Text>
                                    {loading && <Loader size="sm" type='dots' color='#ffff' />}
                                </Group>
                            </Button>
                        </Flex>
                    </Group>
                </Box>
                <Divider orientation="horizontal" />
                <Box p={24}>
                    <Stack gap={20} mt={10}>
                        <Grid gutter={20}>
                            {Object.entries(form.values)
                                .sort(([keyA], [keyB]) => (keyA === 'logo' ? -1 : keyB === 'logo' ? 1 : 0))
                                .map(([key, value]) => {
                                    const keys = ['id', 'logo', 'status']
                                    return (
                                        <>
                                            {key === "logo" ? (
                                                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                                    <ProfileImageUploader
                                                        profileImageURL={
                                                            form.values.logo && typeof form.values.logo === "string" ? form.values.logo :
                                                                form.values.logo && form.values.logo instanceof File ?
                                                                    URL.createObjectURL(form.values.logo) : null
                                                        }
                                                        updateImage={(image) => form.setFieldValue('logo', image)}
                                                        deleteImage={() => form.setFieldValue('logo', '')}
                                                    />
                                                </Grid.Col>
                                            ) : null}
                                            {key === "status" ? (
                                                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                                    <Stack gap={10}>
                                                        <Text fz={16} fw={600} c="#495057">{labels[key]}</Text>
                                                        <Group
                                                            justify='flex-around'
                                                            align="center"
                                                            px={12}
                                                            style={{
                                                                width: '100%',
                                                                height: '45px',
                                                                borderRadius: '10px',
                                                                border: "1px solid #CED4DA",
                                                                borderColor: '#CED4DA',
                                                                borderStyle: 'solid'
                                                            }}>
                                                            <Box mr='auto'>
                                                                {EMPLOYEE_STATUS_BLOCK[form.values.status]}
                                                            </Box>
                                                            <Group ml='auto' color="#3354F4" gap={8} justify='flex-end' onClick={resendInvitation} style={{ cursor: 'pointer' }}>
                                                                <IconReload color="#3354F4" style={{ width: '14px', height: '14px' }} />
                                                                <Text c="#3354F4" style={{ fontSize: '14px' }}>
                                                                    <strong>Resend invite</strong>
                                                                </Text>
                                                            </Group>
                                                        </Group>
                                                    </Stack>
                                                </Grid.Col>
                                            ) : !keys.includes(key) && (
                                                <Grid.Col span={{ base: key === "company_id" ? 12 : 6, md: key === "company_id" ? 12 : 6, lg: key === "company_id" ? 12 : 6 }}>
                                                    <InputField
                                                        label={<Text fz={16} fw={600} c="#495057">{labels[key]}</Text>}
                                                        key={form.key(key)}
                                                        {...form.getInputProps(key)}
                                                        placeHolder={`Please Enter ${labels[key]}`}
                                                        disabled={key === "admin_email"}
                                                    />
                                                </Grid.Col>
                                            )}
                                        </>
                                    )
                                })}
                        </Grid>
                        <Group justify="end" mt={10}>
                            <Group justify='flex-end' align='center'>
                                <Button h={35} variant="light" radius={10} style={{ background: '#FFC9C9' }}>
                                    <Text fz={16} fw={500} c="#C92A2A">Delete User</Text>
                                </Button>
                                <Button h={35} bg="#3354F4" c="#ffffff" radius={10}>
                                    <Text fz={16} fw={500} c="white">Message employee</Text>
                                </Button>
                            </Group>
                        </Group>
                    </Stack>
                </Box>
            </CommonDrawer>
            <Flex
                direction="column"
                justify="flex-start"
                gap={24}
            >
                <Stack gap={24} mr={24} mt={24}>
                    <Stack gap={12}>
                        <Title fz={24} fw={700}>Manage and invite to new company</Title>
                        <Text fz={16} fw={400} c="#5C5F66">You can invite upto 10 companies</Text>
                    </Stack>
                    <Group justify="space-between" align="center">
                        <TextInput
                            rightSection={clearSearchIcon}
                            leftSection={searchIcon}
                            placeholder="Search"
                            w={332}
                            bg="transparent"
                            styles={{
                                input: {
                                    height: "40px",
                                    fontSize: "14px",
                                    padding: "12px 35px",
                                    lineHeight: "16px",
                                    borderRadius: "10px",
                                    backgroundColor: "transparent",
                                    border: "2px solid #CED4DA",
                                },
                            }}
                        />
                        <Group>
                            <PrimaryButton
                                title="Resend invite"
                                onClick={resendInvitation}
                                className={isOrganizationSelected() ? 'primary-button' : 'light-button'}
                                disabled={isOrganizationSelected() === false ? true : false}
                                fallback={loading2 && <Loader size="sm" color="white" type='dots' />}
                            />
                            <PrimaryButton
                                title="Delete company"
                                className={isOrganizationSelected() ? 'danger-button' : 'light-button'}
                                disabled={isOrganizationSelected() === false ? true : false}
                                onClick={deleteSelectedOrganization}
                                fallback={deleteCompanyButtonLoading && <Loader size="sm" color="white" type='dots' />}
                            />
                            {id?.id === undefined && (
                                <PrimaryButton
                                    title="Add company"
                                    onClick={openAddCompanyModal}
                                />
                            )}
                        </Group>
                    </Group>
                </Stack>
                <Stack gap={24} mr={24}>
                    <Box>
                        {isLoading === true ? (
                            <Flex h={250} display="flex" justify="center" align="center">
                                <Loader size="xl" color="#BABABA" type='dots' m="auto" />
                            </Flex>
                        ) : isLoading === false && organizationList?.length === 0 ? (
                            <Flex h={250} display="flex" justify="center" align="center">
                                <Stack gap={1} ta={'center'}>
                                    <Title order={4}> No Companies Added </Title>
                                    <Text c="dimmed" fz={14}>Press the add company button to add a companies</Text>
                                </Stack>
                            </Flex>
                        ) : isLoading === false && organizationList?.length > 0 ? (
                            // organizationListTemplate
                            <TableComp
                                tableHeaders={PARTNER_COMPANIES_TABLE_HEADER}
                                data={existingOrganizationListForm.values.organizations}
                                onChange={selectOrganization}
                                onChange2={selectAllOrganizations}
                                totalRecords={existingOrganizationListForm.values.organizations?.length}
                                totalPages={1}
                                // totalRecords={totalEmployees}
                                // totalPages={totalPages}
                                // changePage={changePage}
                                isCheckBox={true}
                                showPagination={true}
                                height='340px'
                            />
                        ) : null}
                    </Box>
                    {/* <Divider /> */}
                    {/* <Button ml="auto" mr={24} mb={24} type='submit' bg="#3354F4" color="#ffffff" radius={6}>
                            <Group spacing="xs" align='flex-end'>
                                <Text span> Save Changes</Text>
                                {loading && <Loader size="sm" color="white" type='dots' />}
                            </Group>
                        </Button> */}
                </Stack>
            </Flex>
        </>
    )
}

export default Company