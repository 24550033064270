import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        whishlist: [],
        shippingDetails: {
            cart: [],
            selectedCartItems: [],
            address: null,
            prescription: null
        }
    },
    reducers: {
        setSelectCartItem: (state, { payload }) => {
            state.shippingDetails.selectedCartItems = payload
        },
        selectCartItem: (state, { payload }) => {
            let findItem = state.shippingDetails.cart.findIndex((item) => item.id === payload)
            if (findItem !== -1) {
                let checked = !state.shippingDetails.cart[findItem].isSelected
                state.shippingDetails.cart[findItem].isSelected = checked
            }
        },
        addToCart: (state, action) => {
            const inCart = state.shippingDetails.cart.find((item) => item.id === action.payload.id)
            if (inCart) {
                const mapItems = state.shippingDetails.cart.map((item, index) => {
                    if (item.id === action.payload.id) {
                        const qty = !item?.qty ? 1 : item?.qty + 1;
                        const totalPrice = item?.totalPrice * qty;
                        return {
                            ...item,
                            qty,
                            totalPrice: totalPrice
                        }
                    }
                    else {
                        return item
                    }
                })
                return {
                    ...state,
                    cart: mapItems
                }
            }
            else {
                const id = state.shippingDetails.cart[state.shippingDetails.cart.length - 1]?.id ? state.shippingDetails.cart[state.shippingDetails.cart.length - 1]?.id + 1 : 1
                return {
                    ...state,
                    shippingDetails: {
                        ...state.shippingDetails,
                        cart: [
                            ...state.shippingDetails.cart,
                            {
                                id: id,
                                qty: 1,
                                totalPrice: action.payload.price,
                                ...action.payload
                            },
                        ]
                    }
                }
            }
        },
        incQty: (state, { payload }) => {
            let findItem = state.shippingDetails.cart.findIndex((item) => item.id === payload)
            if (findItem !== -1) {
                let qty = state.shippingDetails.cart[findItem].qty + 1
                state.shippingDetails.cart[findItem].qty = qty
                state.shippingDetails.cart[findItem].totalPrice = qty * state.shippingDetails.cart[findItem].price
            }
        },
        decQty: (state, { payload }) => {
            let findItem = state.shippingDetails.cart.findIndex((item) => item.id === payload)
            if (findItem !== -1) {
                if (state.shippingDetails.cart[findItem].qty > 1) {
                    let qty = state.shippingDetails.cart[findItem].qty - 1
                    state.shippingDetails.cart[findItem].qty = qty
                    state.shippingDetails.cart[findItem].totalPrice = qty * state.shippingDetails.cart[findItem].price
                }
            }
        },
        removeFromCart: (state, action) => {
            state.shippingDetails.cart = state.shippingDetails.cart.filter((cart) => cart.id !== action.payload)
        },
        clearCart: (state, action) => {
            state.shippingDetails.cart = []
        },
        addToWhishlist: (state, action) => {
            state.whishlist = action.payload
        },
        removeFromWhishlist: (state, action) => {
            state.whishlist = action.payload
        },
        setAddress: (state, { payload }) => {
            state.shippingDetails.address = payload
        },
        setSelectedPrescription: (state, { payload }) => {
            state.shippingDetails.prescription = payload
        },
        setShippingDetails: (state, { payload }) => {
            state.shippingDetails = payload
        },
    }
})

export const {
    addToWhishlist,
    removeFromWhishlist,
    addToCart,
    removeFromCart,
    clearCart,
    incQty,
    decQty,
    setAddress,
    setSelectedPrescription,
    setShippingDetails,
    selectCartItem,
    setSelectCartItem
} = cartSlice.actions;
export default cartSlice.reducer;