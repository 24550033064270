import { Card } from '@mantine/core';
import classes from '../Dashboard/card.module.css'

function DashboardCard({ width, maxHeight, bg, children }) {
    return (
        <Card padding="xl" radius={25} withBorder bg={bg} style={{ border: "1px solid #DEE2E6", minWidth: width, height: maxHeight, maxHeight: maxHeight, backgroundColor: bg }} className={classes.card}>
            {children}
        </Card>
    );
}

export default DashboardCard;