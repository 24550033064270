import { createAsyncThunk } from "@reduxjs/toolkit";
// import { callApi } from '../../services/api'
import apiClient from "../../services/api";
import { setEmployees, updateEmployee, addEmployee, updateEmployeeStatusToInvited, sliceEmployee, updateEmployeePagination, setEmployeesLoading, setEmployeesError } from "../../store/employess/employeesSlice";
import { setLoading, setMeasurements } from "../../store/employess/measurementSlice";
import { setFeedbackLoading, setProductDetail, setProductLoading, setProducts } from "../../store/employess/productSlice";

const apiFormInstance = apiClient(true)
const apiInstance = apiClient()


export const uploadEmlpoyeeTemplate = createAsyncThunk("uploadEmployeeTemplate", async (data, { dispatch, rejectWithValue }) => {

    try {
        const res = await apiFormInstance.post('/api/uploadapi/', data)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }

})


export const addNewEmployee = createAsyncThunk('addNewEmployee', async (payload, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiFormInstance.post('/api/employees/create/', payload)
        dispatch(addEmployee(res.data))
        dispatch(setEmployeesError(null))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const fetchAllEmployees = createAsyncThunk("fetchAllEmployees", async (page, { dispatch, getState, rejectWithValue }) => {
    dispatch(setEmployeesLoading(true))
    try {
        let url = `/api/employees/filter/`

        // applying filters, search and pagination query
        const filters = getState().filters.employeeFilters
        const search = getState().filters.searchEmployeeByName
        const currentPage = page
        const isFilterApplied = Object.values(filters).some((category) => category.length > 0)

        //applying filters
        if (isFilterApplied) {
            Object.entries(filters).forEach(([category, categoryValue], index) => {
                if (filters[category].length > 0) {
                    if (url.includes('?'))
                        url = url + `&${category}=${categoryValue.join(',')}`
                    else
                        url = url + `?${category}=${categoryValue.join(',')}`
                }
            })
        }

        //applying search
        if (search) {
            if (url.includes('?')) {
                url = url + `&name=${search}`
            } else {
                url = url + `?name=${search}`
            }
        }

        //applying pagination
        if (url.includes('?')) {
            url = url + `&page=${currentPage}`
        } else {
            url = url + `?page=${currentPage}`
        }

        const res = await apiInstance.get(url)
        const results = res.data.results || [];
        dispatch(setEmployeesLoading(false))
        dispatch(setEmployees(results))
        dispatch(updateEmployeePagination(res.data))
        dispatch(setEmployeesError(null))
        if (results.length === 0) {
            const errorMessage = search === "" ? "No Employees Found" : "No Search Employees Found";
            dispatch(setEmployeesError({ error: { message: errorMessage } }));
        }
        return res
    }
    catch (err) {
        console.log(err);
        dispatch(setEmployeesLoading(false))
        if (err.code === "ERR_NETWORK") {
            dispatch(setEmployeesError({ error: { message: err.message } }))
            dispatch(setEmployees([]))
        }
        return rejectWithValue(err)
    }
})

export const editEmployee = createAsyncThunk('editEmployee', async (payload, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiFormInstance.put(`/api/employees/${payload.employeeId}/update/`, payload.data)
        dispatch(updateEmployee(res.data))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const inviteEmployee = createAsyncThunk('inviteEmployee', async (payload, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.post(`/api/employees/invite/`, payload)
        dispatch(updateEmployeeStatusToInvited(payload))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const deleteAEmployee = createAsyncThunk('deleteEmployee', async (payload, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.delete(`/api/employees/${payload}/delete/`)
        dispatch(sliceEmployee(payload))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const getMyMeasurements = createAsyncThunk('getMyMeasurements', async (payload, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true))
    try {
        const res = await apiFormInstance.get('/api/employees/create/', payload)
        if (res.status === 200 || res.status === 201) {
            dispatch(setLoading(false))
            dispatch(setMeasurements(res.data))
        }
        dispatch(setLoading(false))
        return res
    }
    catch (err) {
        dispatch(setLoading(false))
        return rejectWithValue(err)
    }
})

export const getMeasurementByEmployee = createAsyncThunk('getMyMeasurementByEmployee', async (payload, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true))
    try {
        const res = await apiFormInstance.get(`/api/employees/scanned-measurements-list/?user_id=${payload}`)
        if (res.status === 200 || res.status === 201) {
            dispatch(setLoading(false))
            dispatch(setMeasurements(res.data))
        }
        dispatch(setLoading(false))
        return res
    }
    catch (err) {
        dispatch(setLoading(false))
        return rejectWithValue(err)
    }
})

export const fetchEyewearLists = createAsyncThunk("fetchEyewearLists", async (page, { dispatch, getState, rejectWithValue }) => {
    dispatch(setProductLoading(true))
    try {
        const res = await apiInstance.get(`/api/eye_wear/products/list/?page=1&page_size=8`)
        dispatch(setProductLoading(false))
        dispatch(setProducts({ ...res.data, results: res.data.results.map((result) => ({ ...result, liked: false })) }))
        return res
    }
    catch (err) {
        console.log(err);
        dispatch(setProductLoading(false))
        return rejectWithValue(err)
    }
})

export const fetchEyewearDetailById = createAsyncThunk("fetchEyewearDetailById", async (body, { dispatch, getState, rejectWithValue }) => {
    dispatch(setProductLoading(body.type === "0" ? true : false))
    try {
        const res = await apiInstance.get(`/api/eye_wear/glass-variant/${body.id}/retrieve/`)
        dispatch(setProductLoading(false))
        const related_items = [...res.data.results?.recommend_by_frame_color, ...res.data.results?.recommend_by_price]
        dispatch(setProductDetail({ ...res.data.results, related_items }))
        return res
    }
    catch (err) {
        console.log(err);
        dispatch(setProductLoading(true))
        return rejectWithValue(err)
    }
})

export const addToFavourites = createAsyncThunk("addToFavourites", async (body, { dispatch, getState, rejectWithValue }) => {
    try {
        const res = await apiInstance.post(`/api/eye_wear/favorites/create/`, body)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const deleteFromFavourites = createAsyncThunk("deleteFromFavourites", async (id, { dispatch, getState, rejectWithValue }) => {
    try {
        const res = await apiInstance.delete(`/api/eye_wear/favorites/${id}/delete/`, id)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

// Feedbacks

export const createFeedback = createAsyncThunk("createFeedback", async (body, { dispatch, getState, rejectWithValue }) => {
    dispatch(setFeedbackLoading(true))
    try {
        const res = await apiInstance.post(`/api/eye_wear/glass-variant/feedback/create/`, body)
        const updatedFeedbacks = [
            ...getState().product?.productDetail?.feedbacks,
            res.data
        ]
        dispatch(setProductDetail({
            ...getState().product?.productDetail,
            feedbacks: updatedFeedbacks
        }))
        dispatch(setFeedbackLoading(false))
        return res
    }
    catch (err) {
        dispatch(setFeedbackLoading(false))
        return rejectWithValue(err)
    }
})

export const updateFeedback = createAsyncThunk("updateFeedback", async (body, { dispatch, getState, rejectWithValue }) => {
    dispatch(setFeedbackLoading(true))
    try {
        const res = await apiInstance.patch(`/api/eye_wear/glass-variant/feedback/${body?.id}/update/`, body.payload)
        const updatedFeedbacks = getState().product?.productDetail?.feedbacks.map((feedback) => {
            if (feedback.id === res.data.id) {
                return res.data
            } else {
                return feedback
            }
        })
        dispatch(setProductDetail({
            ...getState().product?.productDetail,
            feedbacks: updatedFeedbacks
        }))
        dispatch(setFeedbackLoading(false))
        return res
    }
    catch (err) {
        dispatch(setFeedbackLoading(false))
        return rejectWithValue(err)
    }
})

export const deleteFeedback = createAsyncThunk("deleteFeedback", async (id, { dispatch, getState, rejectWithValue }) => {
    try {
        const res = await apiInstance.delete(`/api/eye_wear/glass-variant/feedback/${id}/delete/`)
        const updatedFeedbacks = getState().product?.productDetail?.feedbacks.filter((feedback) => feedback.id !== id)
        dispatch(setProductDetail({
            ...getState().product?.productDetail,
            feedbacks: updatedFeedbacks
        }))
        window.toast(`Feedback deleted successfully`)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})