import { IconEdit } from '@tabler/icons-react';
import safetyAwardIcon from '../../assets/icons/safety-award-icon.svg'

export const SAFETY_PROGRAM_TYPE_CHOICES = [
    { value: "1", label: "Gold Safety Award" },
    { value: "2", label: "Silver Safety Award" },
    { value: "3", label: "Bronze Safety Award" }
]

export const AWARD_REQUEST_HEADER = [
    { value: 'name', label: 'Name' },
    { value: 'email', label: 'Email' },
    { value: 'tier_assigment', label: 'Tier assignment' },
    { value: 'created_at', label: 'Order date' },
    { value: 'amount', label: 'Amount' },
    { value: 'status', label: 'Status' },
    { value: 'actions', label: 'Actions' },
]

export const MANAGE_PARTICIPANTS_HEADER = [
    { value: 'name', label: 'Customer' },
    { value: 'email', label: 'Email' },
    { value: 'tier', label: 'Tier Assignment' },
    { value: 'status', label: 'Status' },
    { value: 'actions', label: 'Actions' },
]

export const MANAGE_PARTICIPANTS_DATA = [
    { customer: "Biffco Enterprises Ltd", email: "thuhang.nute@gmail.com", tierAssigment: "", status: '1' },
    { customer: "Abstergo Ltd", email: "tienlapspktnd@gmail.com", tierAssigment: "", status: '1' },
    { customer: "Binford Ltd", email: "nvt.isst.nute@gmail.com", tierAssigment: "", status: '1' },
    { customer: "Barone LLC", email: "danghoang87hl@gmail.com", tierAssigment: "", status: '1' },
    { customer: "Acme Co", email: "binhan628@gmail.com", tierAssigment: "", status: '1' },
];

export const SAFETY_AWARDS = [
    {
        id: 1,
        icon: safetyAwardIcon,
        name: "Gold Safety Award",
        description: "Responsible for driving revenue growth by identifying and pursuing new business opportunities, as well as maintaining relationships with existing clients. working closely with customers to understand their needs and provide solutions that meet",
        start_date: "2025-02-04T11:10:39.296Z",
        end_date: "2025-02-04T11:10:39.296Z",
        total_employees: 3
    },
    {
        id: 2,
        icon: safetyAwardIcon,
        name: "Gold Safety Award",
        description: "Responsible for driving revenue growth by identifying and pursuing new business opportunities, as well as maintaining relationships with existing clients. working closely with customers to understand their needs and provide solutions that meet",
        start_date: "2025-02-04T11:10:39.296Z",
        end_date: "2025-02-04T11:10:39.296Z",
        total_employees: 3
    },
    {
        id: 3,
        icon: safetyAwardIcon,
        name: "Gold Safety Award",
        description: "Responsible for driving revenue growth by identifying and pursuing new business opportunities, as well as maintaining relationships with existing clients. working closely with customers to understand their needs and provide solutions that meet",
        start_date: "2025-02-04T11:10:39.296Z",
        end_date: "2025-02-04T11:10:39.296Z",
        total_employees: 3
    },
    {
        id: 4,
        icon: safetyAwardIcon,
        name: "Gold Safety Award",
        description: "Responsible for driving revenue growth by identifying and pursuing new business opportunities, as well as maintaining relationships with existing clients. working closely with customers to understand their needs and provide solutions that meet",
        start_date: "2025-02-04T11:10:39.296Z",
        end_date: "2025-02-04T11:10:39.296Z",
        total_employees: 3
    },
]

export const AWARDS_REQUEST = [
    {
        id: 1,
        name: "Dianne Russell",
        email: "thuhang.nute@gmail.com",
        tier_assignment: safetyAwardIcon,
        created_at: "28/10/2023",
        amount: "$406.27",
        status: '1'
    },
    {
        id: 2,
        name: "Dianne Russell",
        email: "thuhang.nute@gmail.com",
        tier_assignment: safetyAwardIcon,
        created_at: "28/10/2023",
        amount: "$406.27",
        status: '1'
    },
    {
        id: 3,
        name: "Dianne Russell",
        email: "thuhang.nute@gmail.com",
        tier_assignment: safetyAwardIcon,
        created_at: "28/10/2023",
        amount: "$406.27",
        status: '1'
    },
    {
        id: 4,
        name: "Dianne Russell",
        email: "thuhang.nute@gmail.com",
        tier_assignment: safetyAwardIcon,
        created_at: "28/10/2023",
        amount: "$406.27",
        status: '1'
    },
]