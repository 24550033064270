import React, { useState, useEffect } from 'react';
import { Flex, Slider } from '@mantine/core';
import { IoMdImage } from "react-icons/io";

const ImageResizer = ({ image, setFile }) => {

    const [zoom, setZoom] = useState(1);
    const [selectedFile, setSelectedFile] = useState(image);
    const [imageSrc, setImageSrc] = useState(null);
    
    useEffect(() => {
        if (image) {
            setSelectedFile(image)
            setImageSrc(URL.createObjectURL(image))
        }
        else {
            setSelectedFile(null)
            setImageSrc('')
        }
    }, [image])

    const handleZoomChange = (value) => {
        const newZoom = 1 + (value / 100);
        setZoom(newZoom);
        handleUpload()
    };

    const handleUpload = () => {
        if (!selectedFile) return;
        const canvas = document.createElement('canvas');
        const img = new Image();
        img.src = imageSrc;
        img.onload = () => {
            const width = 243
            const height = 262
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d')
            ctx.translate(width / 2, height / 2);
            ctx.scale(zoom, zoom);
            ctx.translate(-width / 2, -height / 2);
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob((blob) => {
                const resizedFile = new File([blob], selectedFile.name, {
                    type: selectedFile.type,
                    lastModified: Date.now(),
                });
                setFile(resizedFile)
            }, selectedFile.type);
        };
    };

    return (
        <div style={{ position: "relative", width: "100%", maxWidth: "287px", marginTop: "20px" }}>
            <Flex align="center">
                <IoMdImage size={16} color="rgb(0 0 0 / 50%)" />
                <Slider
                    style={{ width: "100%", margin: "0 10px" }}
                    onChangeEnd={handleZoomChange}
                    color="#3354F4"
                    min={1}
                    max={100}
                    size="sm"
                    label={null}
                    thumbSize={23}
                />
                <IoMdImage size={24} color="rgb(0 0 0 / 50%)" />
            </Flex>
        </div>
    );
};

export default ImageResizer;