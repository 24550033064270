import { Tabs, Indicator, Text } from '@mantine/core';

function Tab({ tabsArray, children, style, defaultValue }) {

  return (
    <Tabs defaultValue={defaultValue}>
      <Tabs.List>
        <div style={{ display: "flex", width: "100%", ...style }}>
          {tabsArray.map(tab =>
          (<Tabs.Tab className='employeeTab' fw="normal" fz={16} value={tab.value} key={tab.value} disabled={tab.disabled}
            styles={(theme) => ({
              tab: {
                backgroundColor: tab.disabled && "transparent",
                color: tab.disabled && theme.colors.gray[5],
                cursor: tab.disabled && "not-allowed",
              },
            })}>
            {tab.icon}
            <Text>{tab.name}</Text>
            {tab.indicator ? <Indicator inline processing color="#3354F4" size={12} style={{ position: "absolute", right: 3, top: 4, zIndex: 10000 }} /> : null}
          </Tabs.Tab>)
          )}
        </div>
      </Tabs.List>

      {/* adding table panels here */}
      {children}
    </Tabs>

  );
}

export default Tab;