import { Box, Flex, Skeleton } from '@mantine/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const ImageMagnifier = () => {

    const frameImages = useSelector((state) => state.product?.productDetail?.frame_image);
    const loading = useSelector((state) => state.product.loading)
    const [activeImage, setActiveImage] = useState(null);

    return (
        <Flex gap={8}>
            <Flex direction="column">
                {loading ? [1, 2, 3, 4, 5].map(() => <Skeleton height="132px" width="120px" styles={{ root: { borderRadius: "4px", marginBottom: "5px" } }} />) : [
                    { id: 1, image: frameImages },
                    { id: 2, image: frameImages },
                    { id: 3, image: frameImages },
                    { id: 4, image: frameImages },
                    { id: 5, image: frameImages },
                ].map((image) => (
                    <img
                        key={image.id}
                        src={image.image}
                        alt={`Thumbnail ${image.id}`}
                        style={{
                            cursor: 'pointer',
                            border: activeImage === image.id ? '2px solid #f1c40f' : 'none',
                            borderRadius: '4px',
                            objectFit: "contain",
                            width: "120px",
                            height: "120px",
                        }}
                        onClick={() => setActiveImage(image.id)} // Update active image on click
                    />
                ))}
            </Flex>
            <Box style={{ width: "100%" }}>
                <Box style={{ height: "100%", backgroundColor: "white", width: "100%" }}>
                    {loading ? (
                        <Skeleton height="678.53px" width="100%" />
                    ) : (
                        <img
                            src={frameImages}
                            alt="Main Product"
                            style={{
                                width: "100%",
                                maxWidth: "582px",
                                height: "100%",
                                objectFit: 'contain',
                                borderRadius: '6px',
                            }}
                        />
                    )}
                </Box>
            </Box>
        </Flex>
    );
};

export default ImageMagnifier;
