import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../services/api";

const apiInstance = apiClient();

export const fetchTotalEmployeesStats = createAsyncThunk(
    'fetchTotalEmployees',
    async (id, { rejectWithValue }) => {
        try {
            const response = await apiInstance.get(`/api/organizations/employee-stats/${id}/`)
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message || 'Something went wrong');
        }
    }
)