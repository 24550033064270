import { createAsyncThunk } from "@reduxjs/toolkit";
import { setOrganization, updateOrganizationDetail, setOrganizationList, deleteOrganizationFromList, setOrganizationList2, setOrganizationAdmins, setLoading } from "../../store/organization/organizationSlice";
import { setCountries, setCities } from "../../store/common/commonSlice";
import apiClient from "../../services/api";

const apiFormInstance = apiClient(true)
const apiInstance = apiClient()

export const updateOrganization = createAsyncThunk("updateOrganization", async (data, { getState, dispatch, rejectWithValue }) => {
   try {
      const res = await apiFormInstance.put(`/api/organizations/${getState().organizations.organization.id}/update/`, data)
      dispatch(updateOrganizationDetail(res.data))
      return res
   }
   catch (err) {
      return rejectWithValue(err)
   }
})

export const createOrganization = createAsyncThunk("createOrganization", async (data, { getState, dispatch, rejectWithValue }) => {
   try {
      const res = await apiFormInstance.post('/api/organizations/create/', data)
      dispatch(setOrganization(res.data))
      return res
   }
   catch (err) {
      return rejectWithValue(err)
   }
})

export const fetchOrganizations = createAsyncThunk('fetchOrganization', async (_, { dispatch, rejectWithValue }) => {
   try {
      const res = await apiInstance.get('/api/organizations/')
      dispatch(setOrganization(res.data))
      return res
   }
   catch (err) {
      return rejectWithValue(err)
   }
})

export const fetchCountries = createAsyncThunk('fetchCountries', async (_, { dispatch, rejectWithValue }) => {
   try {
      const res = await apiInstance.get('/api/countries/')
      dispatch(setCountries(res.data))
      return res
   }
   catch (err) {
      return rejectWithValue(err)
   }
})

export const fetchCities = createAsyncThunk(
   'fetchCities',
   async (_, { dispatch, rejectWithValue }) => {
      try {
         const res = await apiInstance.get('/api/cities/');
         const uniqueCities = new Set(res.data.map(city => city.name));
         dispatch(setCities(Array.from(uniqueCities).map(name => ({ name }))));
         return res;
      } catch (err) {
         return rejectWithValue(err);
      }
   }
);

export const fetchOrganizationList = createAsyncThunk('fetchPartnerOrganization', async (_, { dispatch, rejectWithValue }) => {

   try {
      const res = await apiInstance.get('/api/organizations/list/')
      dispatch(setOrganizationList(res.data))
      dispatch(setOrganizationList2(res.data))
      return res
   }
   catch (err) {
      return rejectWithValue(err)
   }

})

export const bulkUpdateOrganizations = createAsyncThunk('updateOrganizationList', async (data, { dispatch, rejectWithValue }) => {

   try {
      const res = await apiInstance.put('/api/organizations/bulk/update/', data)
      dispatch(setOrganizationList(res.data.organizations))
      return res
   }
   catch (err) {
      return rejectWithValue(err)
   }
})

export const updateOrganizations = createAsyncThunk("updateOrganizations", async (data, { getState, dispatch, rejectWithValue }) => {
   try {
      const res = await apiFormInstance.put(`/api/organizations/${data.id}/update/`, data.formData)
      const updatedOrg = getState().organizations.organizationList2.map((org) => {
         if (org.id === data.id) {
            return res.data
         } else {
            return org
         }
      })
      dispatch(setOrganizationList2(updatedOrg))
      return res
   }
   catch (err) {
      return rejectWithValue(err)
   }
})

export const deleteOrganization = createAsyncThunk('deleteOrganization', async (organizationId, { getState, dispatch, rejectWithValue }) => {
   try {
      const res = await apiInstance.delete(`/api/organizations/${organizationId}/delete/`)
      if (res.status === 200 || res.status === 201 || res.status === 204) {
         dispatch(deleteOrganizationFromList(organizationId))
      }
      return res
   }
   catch (err) {
      return rejectWithValue(err)
   }
})

export const deleteOrganizations = createAsyncThunk('deleteOrganizations', async (data, { getState, dispatch, rejectWithValue }) => {
   try {
      const res = await apiInstance.delete(`/api/organizations/bulk/delete/`, {
         data: {
            organization_ids: data.organization_ids
         },
      });
      if (res.status === 200 || res.status === 201 || res.status === 204) {
         dispatch(setOrganizationList2(getState().organizations.organizationList2.filter((f) => !data.organization_ids.some((s) => f.id === s))));
         dispatch(setOrganizationList(getState().organizations.organizationList.filter((f) => !data.organization_ids.some((s) => f.id === s))));
      }
      return res
   }
   catch (err) {
      return rejectWithValue(err)
   }
})

export const sendOrganizationAdminInvite = createAsyncThunk('sendOrganizationAdminInvite', async (data, { getState, dispatch, rejectWithValue }) => {
   try {
      const res = await apiInstance.post(`/api/organizations/send-admin-email/`, data)
      return res
   }
   catch (err) {
      return rejectWithValue(err)
   }
})