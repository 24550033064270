import { Box, TextInput, Grid, Stack, Group, Loader, Text, Select } from '@mantine/core'
import { useForm } from '@mantine/form';
import { IconEdit, IconChevronDown } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateUserData } from "../../middlewares/auth/auth"
import ProfileImageUploader from '../BaseComponents/ProfileImageUploader';
import { notifications } from '@mantine/notifications';
import { useMediaQuery } from '@mantine/hooks';
import { ETHNICITY } from '../../choices/employee.choice';
import { asyncErrorHandler } from '../../helpers/errorHandler';
import { PrimaryButton } from '../ui/Button/Button';

const EmployeeProfile = () => {

    // initializing icon
    const editIcon = <IconEdit style={{ width: '16px', height: '16px', cursor: 'pointer' }} />
    const selectIcon = <IconChevronDown style={{ width: '16px', height: 'auto', marginRight: '2px' }} />
    const isSmallScreen = useMediaQuery("(max-width: 768px)");
    const user = useSelector((state) => state.login.user)
    const cities = useSelector((state) => state.common.cities)
    
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [imageError, setImageError] = useState("")
    const [profileImageURL, setProfileImageURL] = useState(null)

    const genders = [
        { value: 'M', label: 'Male' },
        { value: 'F', label: 'Female' },
        { value: 'P', label: 'Not Confirmed' },
    ];

    const ageData = [
        { value: '18', label: '18' },
        { value: '23', label: '23' },
        { value: '26', label: '26' },
        { value: '32', label: '32' },
        { value: '41', label: '41' },
        { value: '50', label: '50' },
        { value: '60', label: '60' },
    ];

    // initializing form
    const form = useForm({
        initialValues: {
            image: null,
            name: "",
            email: "",
            address: "",
            age: "",
            gender: "",
            ethnicity: "",
            location: "",
        },
        validate: {
            email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
            name: (val) => (val ? null : "Name cannot be empty"),
            age: (val) => (val && !isNaN(val) ? null : "Enter a valid age"),
            gender: (val) => (val ? null : "Please select a gender"),
            ethnicity: (val) => (val ? null : "Please select an ethnicity"),
            location: (val) => (val ? null : "Location cannot be empty"),
        },
    });

    const passwordForm = useForm({
        initialValues: {
            password1: '',
            password2: ''
        },
        validate: {
            password1: (val) => (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_\-])[A-Za-z\d@$!%*?&_\-]{8,}$/.test(val) ? null : 'Password must be at least 8 characters long, contain at least one lowercase letter, one uppercase letter, one number, and one special character.'),
            password2: (val) => (val === passwordForm.values.password1 ? null : 'Passwords do not match'),
        }
    })

    function updateFormValue(value, formField) {
        if (formField === 'image') {
            form.setFieldValue(formField, value)
        } else {
            formField === 'password1' || formField === 'password2' ? passwordForm.setFieldValue(formField, value) : form.setFieldValue(formField, value)
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        if (form.validate().hasErrors) {
            form.validate()
        }
        else {
            const formUserData = new FormData()
            Object.entries(form.values).forEach(([key, value]) => {
                if (key === "image" && typeof value === "string") {
                    return;
                }
                formUserData.append(key, value)
            })
            setLoading(true)
            let res = await dispatch(updateUserData(formUserData))
            if (updateUserData.fulfilled.match(res)) {
                setLoading(false)
                window.toast('User details have been updated')
                form.clearErrors();
            }
            else if (updateUserData.rejected.match(res)) {
                setLoading(false)
                asyncErrorHandler(res, form, setImageError)
                // if (res.payload.code === "ERR_NETWORK") {
                //     notifications.show({ message: res.payload.message, autoClose: 5000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
                // } else if (res.payload.response.data.error.details) {
                //     Object.entries(res.payload.response.data.error.details).forEach(([key, value]) => {
                //         const errorMessage = Array.isArray(value) ? value[0] : value;
                //         notifications.show({
                //             message: errorMessage,
                //             autoClose: 5000,
                //             color: 'red',
                //             style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" },
                //         });
                //     });
                // } else {
                //     notifications.show({ message: 'An error occured while updating user details', autoClose: 5000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
                // }
            }
            // if (passwordForm.validate().hasErrors) {
            //     passwordForm.validate()
            //     return;
            // }
            // if (passwordForm.values.password1 && passwordForm.values.password2) {
            //     const passwordPayload = {
            //         new_password1: passwordForm.values.password1,
            //         new_password2: passwordForm.values.password2,
            //     }
            //     let changePasswordResponse = await dispatch(changeUserPassword(passwordPayload))
            //     if (changeUserPassword.fulfilled.match(changePasswordResponse)) {
            //         passwordForm.reset()
            //         notifications.show({ message: 'Password has been changed', autoClose: 5000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" } });
            //         passwordForm.clearErrors();
            //     } else if (changeUserPassword.rejected.match(changePasswordResponse)) {
            //         notifications.show({ message: 'An error occured while updating user password', autoClose: 5000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" } });
            //     }
            // }
        }
    }

    const commonStyle = {
        label: {
            fontSize: '16px',
            marginBottom: "10px",
            lineHeight: "18px",
            fontWeight: "600",
            color: "#424242"
        },
        input: {
            height: "40px",
            fontSize: "14px",
            padding: "12px",
            lineHeight: "16px",
            borderRadius: "6px",
            backgroundColor: "transparent",
        },
        error: {
            fontSize: '14px',
            marginTop: "5px",
            lineHeight: "16px",
        },
        required: {
            color: '#3354F4'
        }
    }

    useEffect(() => {
        form.setValues({
            image: user?.profile?.image,
            name: user?.profile?.name,
            ethnicity: user?.profile?.ethnicity || '',
            email: user?.email,
            age: user?.profile?.age?.toString(),
            gender: user?.profile?.gender,
            location: user?.profile?.location,
        })
    }, [])

    return (
        <Box py={24} pr={!isSmallScreen && 58} pl={24}>
            <Text fw="bold" mb={16}>Your profile picture</Text>
            <form onSubmit={handleSubmit}>
                <Stack>
                    <ProfileImageUploader
                        labelText="Upload profile picture"
                        isCompany={true}
                        profileImageURL={profileImageURL ? URL.createObjectURL(profileImageURL) : form.values?.image || null}
                        updateImage={(image) => {
                            form.setFieldValue('image', image)
                            setProfileImageURL(image)
                            setImageError("")
                        }}
                        deleteImage={() => {
                            form.setFieldValue('image', '')
                            setProfileImageURL(null)
                            setImageError("")
                        }}
                    />
                    {imageError && <p style={{ fontSize: "14px", color: "#FA5252" }}>{imageError}</p>}
                    <Grid>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <TextInput styles={{ ...commonStyle }}
                                rightSection={editIcon}
                                label="Name"
                                value={form.values.name}
                                onChange={(event) => updateFormValue(event.target.value, 'name')}
                                error={form.errors.name}
                                placeHolder="Please enter name"
                            />
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <TextInput styles={{ ...commonStyle }}
                                disabled={true}
                                isPassword={true}
                                label="Password"
                                value={passwordForm.values.password1}
                                onChange={(event) => updateFormValue(event.target.value, 'password1')}
                                error={passwordForm.errors.password1}
                                placeHolder="Set new password"
                            />
                        </Grid.Col>
                    </Grid>
                    <Grid>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <TextInput styles={{ ...commonStyle }}
                                rightSection={editIcon}
                                label="Email"
                                value={form.values.email}
                                onChange={(event) => updateFormValue(event.target.value, 'email')}
                                error={form.errors.email}
                                placeHolder="Please enter email address"
                            />
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <TextInput styles={{ ...commonStyle }}
                                disabled={true}
                                isPassword={true}
                                label="Confirm Password"
                                value={passwordForm.values.password1}
                                onChange={(event) => updateFormValue(event.target.value, 'password2')}
                                error={passwordForm.errors.password2}
                                placeHolder="Confirm new password"
                            />
                        </Grid.Col>
                    </Grid>
                    <Grid>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <Select
                                data={ageData}
                                label="Age"
                                key={form.key("age")}
                                placeholder="Age"
                                rightSection={selectIcon}
                                styles={{ ...commonStyle }}
                                {...form.getInputProps("age")}
                            />
                        </Grid.Col>
                    </Grid>
                    <Grid>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <Select
                                data={genders}
                                label="Gender"
                                key={form.key("gender")}
                                {...form.getInputProps("gender")}
                                placeholder="Gender"
                                rightSection={selectIcon}
                                styles={{ ...commonStyle }}
                            />
                        </Grid.Col>
                    </Grid>
                    <Grid>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <Select
                                styles={{ ...commonStyle }}
                                data={ETHNICITY}
                                label="Ethnicity"
                                placeholder="Ethnicity"
                                rightSection={selectIcon}
                                key={form.key("ethnicity")}
                                {...form.getInputProps("ethnicity")}
                            />
                        </Grid.Col>
                    </Grid>
                    <Grid>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <Select
                                styles={{ ...commonStyle }}
                                data={cities}
                                label="Location"
                                placeholder="Enter your location"
                                rightSection={selectIcon}
                                searchable
                                limit={100}
                                key={form.key("location")}
                                {...form.getInputProps("location")}
                            />
                        </Grid.Col>
                    </Grid>
                    <Grid>
                        <Grid.Col span={12}>
                            <Group justify='flex-end' mt="34px">
                                {/* <Button disabled={loading} type='submit' size="md" ml="auto" color="#3354F4">
                                    <Group spacing="xs">
                                        <span>Update Details</span>
                                        {loading && <Loader size="sm" color="#adb5c1" type='dots' />}
                                    </Group>
                                </Button> */}
                                <PrimaryButton
                                    title="Update Details"
                                    type='submit'
                                    disabled={loading} 
                                    fallback={loading && <Loader size="sm" color="#adb5c1" type='dots' />}
                                />
                            </Group>
                        </Grid.Col>
                    </Grid>
                </Stack>
            </form>
        </Box>
    )
}

export default EmployeeProfile