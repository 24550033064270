import React, { useEffect } from 'react'
import { Box, Flex, Image } from '@mantine/core'
import sitrapLogo from "../assets/images/sitrep-new-logo.svg"

const SplashScreen = ({ setShowSplashScreen }) => {

    useEffect(() => {
        setTimeout(() => setShowSplashScreen(false), 3000);
    }, [])

    return (
        <Flex h="100vh" justify="center" align="center">
            <Image src={sitrapLogo} w={314} mx="auto" display="block" />
        </Flex>
    )
}

export default SplashScreen