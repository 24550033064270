import React from 'react';
import classes from './safetyAwards.module.css';
import { Avatar, Box, Group, Text, Title } from '@mantine/core';
import { FaPlus } from 'react-icons/fa';
import avatarImage from '../../assets/images/avatar.jpeg'

const Card = ({ title, width, icon, type }) => {
    return (
        <>
            {type === "safetyProgram" ? (
                <div className={classes.card} style={{ marginBottom: width < 768 ? "15px" : 0, height: "" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={icon} alt="" />
                        <p className={classes.card_title} style={{ marginLeft: "12px" }}>{title}</p>
                    </div>
                    <p className={classes.card_title}>{title}</p>
                    <div>
                        <p className={classes.card_desc}>Start date: 28/10/2012</p>
                        <p className={classes.card_desc}>End date: 28/10/2013</p>
                    </div>
                </div>
            ) : (
                <div className={classes.card} style={{ marginBottom: width < 768 ? "15px" : 0 }}>
                    <Text fz={16} fw={600}>{title}</Text>
                    <p className={classes.card_desc}>Lorem ipsum dolor sit amet, consectetur </p>
                </div>
            )}
        </>
    )
}

export const SafetyAwardCard = (props) => {
    const formattedStartDate = new Intl.DateTimeFormat("en-CA").format(new Date(props.start_date));
    const formattedEndDate = new Intl.DateTimeFormat("en-CA").format(new Date(props.end_date));
    return (
        <Box p={24} w="100%" bd="2px solid #E3E3E3" bg="white" style={{ borderRadius: "16px" }}>
            <Group justify="space-between" align="center">
                <Group>
                    <img width={24} height={24} src={props.icon} alt="" />
                    <Text fz={22} fw={600}>{props?.name}</Text>
                </Group>
                <Group>
                    <Avatar.Group>
                        {props?.total_employees?.profile_images?.slice(0, 3)?.map((avatar) => <Avatar src={avatar} />)}
                        <Avatar>+{props?.total_employees?.count}</Avatar>
                    </Avatar.Group>
                    <Box onClick={props.handleOpenUpdate} w={40} h={40} bg="white" c="black" fw="lighter" bd="2px dashed #ADB5BD" style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%" }}>
                        <FaPlus size={20} color="#ADB5BD" />
                    </Box>
                </Group>
            </Group>

            <Group>
                <Text my={12} fw={600} c="#5C5F66">Start date:</Text>
                <Text c="grey">{formattedStartDate}</Text>
                <Text fw={600} c="#5C5F66">End date:</Text>
                <Text c="grey">{formattedEndDate}</Text>
            </Group>

            <Text c="#909296">{props?.description}</Text>
        </Box>
    )
}

export const TierCard = (props) => {
    return (
        <Box p={24} w="100%" bd="2px solid #E3E3E3" bg="white" style={{ borderRadius: "16px" }}>
            <Group justify="space-between" align="center">
                <Group>
                    <img src={props.icons[props?.icon].icon} />
                    <Title order={3}>{props?.name}</Title>
                </Group>
                <Group>
                    <Avatar.Group>
                        {props?.total_employees?.profile_images?.length > 0 ? (
                            props.total_employees.profile_images.slice(0, 3).map((src) => <Avatar src={process.env.REACT_APP_BASEURL + src} />)
                        ) : (
                            <>
                                <Avatar src={props?.icon} />
                                <Avatar src={props?.icon} />
                                <Avatar src={props?.icon} />
                            </>
                        )}
                        <Avatar>+{props?.total_employees?.count}</Avatar>
                    </Avatar.Group>
                    <Box onClick={props.handleOpenUpdate} w={40} h={40} bg="white" c="black" fw="lighter" bd="2px dashed #ADB5BD" style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%" }}>
                        <FaPlus size={20} color="#ADB5BD" />
                    </Box>
                </Group>
            </Group>

            <Text my={12}>Start date: {props.startDate} End date: {props.endDate}</Text>
            <Text c="#909296">{props?.description}</Text>
        </Box>
    )
}

export default Card