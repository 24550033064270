import React from 'react';
import { Grid, Box, Stack } from '@mantine/core';
import { HeaderCard, StatisticsCard } from '../../../components/Employess/safetyAwards/card';
import Offers from '../../../components/Employess/safetyAwards/offers';
import BalanceHistory from '../../../components/Employess/safetyAwards/balanceHistory';

// icons

import { IoWallet } from "react-icons/io5";
import earnIcon from '../../../assets/icons/elements.svg';

const SafetyAwards = () => {
  return (
    <Box style={{ height: '100%' }}>
      <Stack justify="center" align="center" px={24} py={27}>
        <Grid w="100%" gutter={12}>
          <Grid.Col span={{ base: 12, md: 4 }}>
            <HeaderCard title="Program Overview" desc="Lorem ipsum dolor sit amet, consectetur " />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4 }}>
            <HeaderCard title="FAQ" desc="Lorem ipsum dolor sit amet, consectetur " />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4 }}>
            <HeaderCard title="Terms" desc="Lorem ipsum dolor sit amet, consectetur " />
          </Grid.Col>
          <Grid.Col span={12}>
            <Grid gutter={12}>
              <Grid.Col span={{ base: 12, md: 8 }}>
                <Grid gutter={12}>
                  <Grid.Col span={{ base: 12, md: 4 }}>
                    <StatisticsCard c="white" c2="white" cardBg="#212529" iconBg="#252E4F" icon={<img src={earnIcon} alt="" />} title="Safety Rewards" value="$1250" />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 4 }}>
                    <StatisticsCard c="#5C5F66" iconBg="#F1F3F5" icon={<IoWallet size={32} />} title="Total Spending" value="$42,040" />
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, md: 4 }}>
                    <StatisticsCard c="#5C5F66" iconBg="#F1F3F5" icon={<IoWallet size={32} />} title="Money Saved" value="$501,074" />
                  </Grid.Col>
                </Grid>
                <Grid.Col span={12} mt={12}>
                  <BalanceHistory />
                </Grid.Col>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 4 }}>
                <Offers />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Stack>
    </Box>
  );
};

export default SafetyAwards;