import { Box, Button, TextInput, Grid, PasswordInput, Stack, Group, Loader, Text, Textarea, Flex } from '@mantine/core'
import { useForm } from '@mantine/form';
import { IconEdit, IconEye, IconAt, IconEyeOff } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, updateUserData, changeUserPassword, asyncUpdateUserProfile } from "../../middlewares/auth/auth"
import ProfileImageUploader from '../BaseComponents/ProfileImageUploader';
import { notifications } from '@mantine/notifications';
import { useMediaQuery, useViewportSize } from '@mantine/hooks';
import { PrimaryButton } from '../ui/Button/Button';
import ImageResizer from '../ImageResizer/ImageResizer';
import { asyncErrorHandler } from '../../helpers/errorHandler';

const InputBox = ({ rightIcon, leftIcon, value, label, placeHolder, isPassword, onChange, error }) => {

    return (
        <>
            {isPassword ?
                <PasswordInput
                    label={label}
                    placeholder={placeHolder}
                    visibilityToggleIcon={({ reveal }) =>
                        reveal ? (
                            <IconEyeOff style={{ width: '16px', height: '16px' }} />
                        ) : (
                            <IconEye style={{ width: '16px', height: '16px' }} />
                        )
                    }
                    radius="6px"
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                    error={error}
                    styles={() => ({
                        input: {
                            marginTop: '10px',
                        },
                        label: {
                            font: 'Roboto',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#495057'

                        }
                    })
                    }
                />
                :
                <TextInput
                    label={label}
                    placeholder={placeHolder}
                    rightSection={rightIcon}
                    leftSection={leftIcon}
                    radius="6px"
                    onChange={(event) => onChange(event.target.value)}
                    value={value}
                    error={error}
                    styles={() => ({
                        input: {
                            marginTop: '10px',
                        },
                        label: {
                            font: 'Roboto',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#495057'

                        }
                    })}
                />
            }
        </>

    )
}
const UserProfile = () => {

    // initializing icon
    const editIcon = <IconEdit style={{ width: '16px', height: '16px', cursor: 'pointer' }} />
    const atIcon = <IconAt style={{ width: '16px', height: '16px' }} />
    const isSmallScreen = useMediaQuery("(max-width: 768px)");

    const user = useSelector(state => state.login.user)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [imageError, setImageError] = useState("")

    // Initializing form
    const form = useForm({
        initialValues: {
            image: "",
            first_name: "",
            last_name: "",
            job_title: "",
            email_address: "",
            office_phone_number: "", // Changed null to ""
            mobile_phone_number: "", // Changed null to ""
            comments: "",
        },
        validate: {
            first_name: (val) =>
                /^[A-Za-z][A-Za-z\s-]*[A-Za-z]?$/.test(val) ? null : 'Please enter a valid first name.',
            last_name: (val) =>
                /^[A-Za-z][A-Za-z\s-]*[A-Za-z]?$/.test(val) ? null : 'Please enter a valid last name.',
            job_title: (val) =>
                val ? null : 'Please enter a valid job title.', // Removed trim
            email_address: (val) =>
                /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val) ? null : 'Please enter a valid email address.',
            office_phone_number: (val) =>
                /^\+?[0-9]{7,15}$/.test(val) ? null : 'Please enter a valid office phone number.',
            mobile_phone_number: (val) =>
                /^\+?[0-9]{7,15}$/.test(val) ? null : 'Please enter a valid mobile phone number.',
            comments: (val) =>
                val && val.length >= 5 ? null : 'Comments should be at least 5 characters long.', // Removed trim
        },
    });


    async function fetchUserData() {
        await dispatch(fetchUser())
    }

    useEffect(() => {
        fetchUserData()
    }, [])

    // update formField state and image url and form initial values
    useEffect(() => {
        if (user && user?.profile) {
            form.setValues({
                image: user?.profile?.image,
                first_name: user?.profile?.first_name,
                last_name: user?.profile?.last_name,
                job_title: user?.profile?.job_title,
                email_address: user?.email,
                office_phone_number: user?.profile?.office_phone_number,
                mobile_phone_number: user?.profile?.mobile_phone_number,
                comments: user?.profile?.comments,
            })
        }
    }, [user]);

    async function handleSubmit(e) {
        e.preventDefault();
        if (form.validate().hasErrors) {
            form.validate()
        }
        else {
            const formData = new FormData()
            Object.entries(form.values).forEach(([key, value]) => {
                if (key === "image" && (value === null || typeof value === "string")) {
                    return;
                } if (key === "image" && value instanceof File) {
                    formData.append(key, value);  // Append the File to formData
                } else {
                    formData.append(key, value);  // Append other fields normally
                }
            });
            setLoading(true)
            const res = await dispatch(asyncUpdateUserProfile(formData))
            if (res.type === "asyncUpdateUserProfile/fulfilled") {
                setLoading(false)
                setImageError("")
                window.toast("Profile updated successfully")
            }
            else {
                setLoading(false)
                asyncErrorHandler(res, form, setImageError)
            }
        }
    }

    function setResizedImage(file) {
        form.setFieldValue("image", file)
    }

    const commonStyle = {
        label: {
            fontSize: '16px',
            marginBottom: "10px",
            lineHeight: "18px",
            fontWeight: "600",
            color: "#424242"
        },
        input: {
            height: "40px",
            fontSize: "14px",
            padding: "12px",
            lineHeight: "16px",
            borderRadius: "6px",
            backgroundColor: "transparent",
        },
        error: {
            fontSize: '14px',
            marginTop: "5px",
            lineHeight: "16px",
        },
        required: {
            color: '#3354F4'
        }
    }

    const isMediumScreen = useMediaQuery("(max-width: 991px)")
    const isLaptopMediumScreen = useMediaQuery("(max-width: 1030px)")
    const height = useViewportSize()

    return (
        <Box radius="md" px={12} py={24} mb={50} h={height - 60} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <form onSubmit={handleSubmit}>
                <Flex gap={40} direction={isMediumScreen && "column"}>
                    <Box maw={isMediumScreen ? "100%" : "267px"}>
                        <ProfileImageUploader labelText="Your profile picture"
                            profileImageURL={
                                form?.values?.image && typeof form?.values?.image === "string" ? form?.values?.image :
                                    form?.values?.image && form?.values?.image instanceof File ?
                                        URL.createObjectURL(form?.values?.image) : null
                            }
                            updateImage={(image) => {
                                form.setFieldValue('image', image)
                                setImageError("")
                            }}
                            deleteImage={() => {
                                form.setFieldValue('image', '')
                                setImageError("")
                            }}
                        />
                        {imageError && <p style={{ fontSize: "14px", color: "#FA5252", marginTop: "5px", width: "265px" }}>{imageError}</p>}
                        <Box mt={12}>
                            <ImageResizer
                                image={form?.values?.image && form?.values?.image instanceof File ? form?.values?.image : null} // Pass the file to the resizer, ensure it's a file
                                setFile={setResizedImage}
                            />
                        </Box>
                    </Box>
                    <Box maw={isMediumScreen ? "100%" : "408px"}>
                        <Stack gap={20}>
                            <Flex gap={isLaptopMediumScreen && 20} styles={{ root: { flexDirection: isLaptopMediumScreen ? "column" : "row" } }}>
                                <TextInput
                                    label="Enter your first name"
                                    placeholder="First name"
                                    key={form.key("first_name")}
                                    {...form.getInputProps('first_name')}
                                    radius="md"
                                    styles={{ ...commonStyle, root: { width: isMediumScreen && "100%" } }}
                                />
                                <TextInput
                                    ml={isLaptopMediumScreen ? 0 : 14}
                                    label="Enter your last name"
                                    placeholder="Last name"
                                    key={form.key("last_name")}
                                    {...form.getInputProps('last_name')}
                                    radius="md"
                                    styles={{ ...commonStyle, root: { width: isMediumScreen && "100%" } }}
                                />
                            </Flex>
                            <Flex gap={isLaptopMediumScreen && 20} styles={{ root: { flexDirection: isLaptopMediumScreen ? "column" : "row" } }}>
                                <TextInput
                                    label="Job title"
                                    placeholder="name"
                                    key={form.key("job_title")}
                                    {...form.getInputProps('job_title')}
                                    radius="md"
                                    styles={{ ...commonStyle, root: { width: isMediumScreen && "100%" } }}
                                />
                                <TextInput
                                    ml={isLaptopMediumScreen ? 0 : 14}
                                    label="Email Address"
                                    placeholder="name"
                                    key={form.key("email_address")}
                                    {...form.getInputProps('email_address')}
                                    radius="md"
                                    styles={{ ...commonStyle, root: { width: isMediumScreen && "100%" } }}
                                />
                            </Flex>
                            <TextInput
                                type="number"
                                label="Office phone"
                                placeholder="(XXX) XXX-XXXX Ext. XXXXX"
                                key={form.key("office_phone_number")}
                                {...form.getInputProps('office_phone_number')}
                                radius="md"
                                styles={{ ...commonStyle, root: { width: isMediumScreen && "100%" } }}
                            />
                            <TextInput
                                type="number"
                                label="Mobile phone"
                                placeholder="(XXX) XXX-XXXX"
                                key={form.key("mobile_phone_number")}
                                {...form.getInputProps('mobile_phone_number')}
                                radius="md"
                                styles={{ ...commonStyle, root: { width: isMediumScreen && "100%" } }}
                            />
                            <Textarea
                                maxLength={500}
                                label="Comments"
                                placeholder="Enter your comment here"
                                key={form.key("comments")} z
                                {...form.getInputProps('comments')}
                                radius="md"
                                styles={{ ...commonStyle, input: { ...commonStyle.input, height: "115px" } }}
                            />
                            <p style={{ marginTop: '5px', fontSize: '14px', color: '#999' }}>
                                {form?.values?.comments?.length}/{500} characters
                            </p>
                            <Group justify="flex-end">
                                {/* <Button w={loading ? "" : 143} h={40} radius={10} type="submit">
                                    <Group justify='center' align="center">
                                        <Text fz={16} fw={500}>Update details</Text>
                                        {loading ? <Loader size="sm" color="white" type='dots' /> : <></>}
                                    </Group>
                                </Button> */}
                                <PrimaryButton
                                    type="submit"
                                    title="Update details"
                                    fallback={loading ? <Loader size="sm" color="white" type='dots' /> : null}
                                />
                            </Group>
                        </Stack>
                    </Box>
                </Flex>
            </form>
        </Box>
    )
}

export default UserProfile