import React, { useState } from 'react';
import { Grid, Divider, Box, Stack, Group, Text, Button, Avatar, GridCol, Card, Popover, Loader } from '@mantine/core';
import { AreaChart, BarChart } from '@mantine/charts';
import { FiSun } from "react-icons/fi";
import { IconPlus, IconUsers, IconCalendarMonth, IconChartLine, IconBuilding, IconTableRow } from '@tabler/icons-react';
import { FaCaretDown } from "react-icons/fa";
import SettingIcon from '../../assets/icons/tunning.svg'
import { DatePicker } from '@mantine/dates';
import PopoverMenu from '../../components/Popover/Popover';
import { useSelector } from 'react-redux';
import { CHART_MARKETING_DATA, CHART_STATISTICS_DATA, EMPLOYEES_STATISTICS_DATA } from '../../mock/admin/dashboard';

const Dashboard = () => {

  const [value, setValue] = useState(null);
  const totalEmployees = useSelector((state) => state.statistics.totalEmployees)
  const loading = useSelector((state) => state.statistics.loading)
  
  const cardLabels = {
    1: totalEmployees?.total_active_employees,
    2: 425,
    3: 425,
    4: 425,
    5: 425,
    6: 425,
  }

  const averageLabels = {
    1: `${totalEmployees?.employees_created_today || 0} new employees added!`,
    2: `-10% Less than yesterday`,
    3: `+3% Increase than yesterday`,
    4: `+3% Increase than yesterday`,
    5: `-10% Less than yesterday`,
    6: `2% Increase than yesterday`,
  }

  const renderCards = () => {
    return EMPLOYEES_STATISTICS_DATA.map((stats, index) => {
      const isLoading = stats.id === 1 && loading;
      return (
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }} key={stats.id} px="22.5px" py="17px">
          <Card radius={15} miw={250} py={19} px={26}>
            <Stack gap="10px" m={isLoading ? "auto" : undefined} h={isLoading ? "100px" : undefined}>
              {isLoading ? (
                <Loader size="lg" color="#3C5BF4" type="dots" />
              ) : (
                <>
                  <Group justify="space-between">
                    <Text fw={500} c="#252C58" fz={38} lh="48px">{cardLabels[stats.id]}</Text>
                    <Avatar w={42} h={42} color="#3354F4">
                      <IconUsers />
                    </Avatar>
                  </Group>
                  <Stack gap="0.5px">
                    <Text c="#252C58" fz={16} lh="20px">{stats.title}</Text>
                    <Group gap="2px" align="center" mt={4}>
                      <Avatar color="#97CE71" size={15}><IconPlus /></Avatar>
                      <Text c="#9295AB" fz={12}>{averageLabels[stats.id]}</Text>
                    </Group>
                  </Stack>
                </>
              )}
            </Stack>
          </Card>
        </Grid.Col>
      )
    })
  }

  return (
    <Box style={{ height: '100%' }} ff="Lexend-light">
      <Group px={32} py={16.5}>
        <Group mr="auto">
          <Group gap={0}>
            <PopoverMenu
              displayMenu={<DatePicker value={value} setValue={setValue} />}
            >
              <Group gap={8}>
                <IconCalendarMonth width={"20px"} />
                <Text fz={14} lh={"18px"}>This Week</Text>
                <FaCaretDown />
              </Group>
            </PopoverMenu>
            <Divider orientation="vertical" ml={26} mr={21} color="#E2E2E2" />
            <PopoverMenu
              displayMenu={<DatePicker value={value} setValue={setValue} />}
            >
              <Group gap={8}>
                <IconChartLine width={"20px"} />
                <Text fz={14} lh={"18px"}>Compare to</Text>
                <FaCaretDown />
              </Group>
            </PopoverMenu>
          </Group>
        </Group>
        <Group ml="auto">
          <Group gap={0}>
            <PopoverMenu
              displayMenu={<DatePicker value={value} setValue={setValue} />}
            >
              <Group gap={8}>
                <IconBuilding width={"20px"} />
                <Text fz={14} lh={"18px"}>Fields to display</Text>
                <FaCaretDown />
              </Group>
            </PopoverMenu>
            <Divider orientation="vertical" ml={26} mr={21} color="#E2E2E2" />
            <PopoverMenu
              displayMenu={<DatePicker value={value} setValue={setValue} />}
            >
              <Group gap={8}>
                <IconTableRow width={"20px"} />
                <FaCaretDown />
              </Group>
            </PopoverMenu>
            {/* <Text>Compare to</Text> */}
          </Group>
        </Group>
      </Group>
      <Divider />
      <Stack justify='center' align='center' gap="45px" px={24} py={30}>
        <Grid w="100%" gutter={{ base: 5, xs: 'md', md: 'xl', xl: 45 }}>
          <Grid.Col span={{ base: 12, md: 5, lg: 4, xl: 3 }} py="17px">
            <Card pt={50} pb={24} px={23} radius={15} h={"100%"} w={"100%"}>
              <Stack gap={0} justify='space-around' align="center">
                <Group gap="9px" align="center">
                  <FiSun style={{ fontSize: '49px', color: '#C8CAD5' }} />
                  <Stack gap="8px" justify='center' align='flex-start'>
                    <Text c='dimmed' fz={24} lh="24px">8:02:09 AM</Text>
                    <Text c='dimmed' fz={12} lh="16px">Realtime Insight</Text>
                  </Stack>
                </Group>
                <Stack mt="49px" mb="44px">
                  <Text fz={18} fw={500} lh={"23px"}>Today: <br /> 18 November 2023</Text>
                </Stack>
                <Button w={180} h={42} radius="md" color="#3354F4" fz={16} fw={500}>View Attendance</Button>
              </Stack>
            </Card>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 7, lg: 8, xl: 9 }} px={0} py="10px">
            <Grid w="100%" grow>
              {renderCards()}
            </Grid>
          </Grid.Col>
        </Grid>
        <Grid w="100%" gutter={{ base: 5, xs: 'md', md: 'xl', xl: 31 }}>
          <Grid.Col span={{ base: 12, md: 8, lg: 8 }}>
            <Card p={26} radius={15}>
              <Group justify="space-between" mb={30}>
                <Text c="#252C58" fz={18}>Attendance Comparison Chart</Text>
                <Group>
                  <Group align="center">
                    <Box style={{ backgroundColor: "#3354F4", borderRadius: "100%" }} w={10} h={10}></Box>
                    <Text>Daily</Text>
                  </Group>
                  <Group align="center">
                    <Box style={{ backgroundColor: "#3354F4", borderRadius: "100%" }} w={10} h={10}></Box>
                    <Text>Daily</Text>
                  </Group>
                  <Group align="center">
                    <Box style={{ backgroundColor: "#3354F4", borderRadius: "100%" }} w={10} h={10}></Box>
                    <Text>Daily</Text>
                  </Group>
                  <img style={{ marginLeft: "45px" }} src={SettingIcon} alt="" />
                </Group>
              </Group>
              <AreaChart
                h={300}
                data={CHART_STATISTICS_DATA}
                dataKey="date"
                yAxisProps={{ domain: [0, 100] }}
                series={[{ name: 'value', color: 'indigo.6' }]}
              />
            </Card>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4, lg: 4 }}>
            <Card p={26} radius={15}>
              <Group justify="space-between" mb={30}>
                <Text c="#252C58" fz={18}>Weekly Attendance</Text>
                <img style={{ marginLeft: "45px" }} src={SettingIcon} alt="" />
              </Group>
              <BarChart
                h={300}
                data={CHART_MARKETING_DATA}
                dataKey="month"
                series={[{ name: 'value', color: 'violet.6' }]}
                tickLine="y"
              />
            </Card>
          </Grid.Col>
        </Grid>
      </Stack>
    </Box>
  );
};

export default Dashboard;