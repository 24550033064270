import { Button, Loader, Text, Group } from "@mantine/core"
import './Button.css'

const PrimaryButton = ({ onClick, styles = {}, w, h, variant = 'filled', size, color, bg, radius = 6, fullWidth, leftSection, rightSection, gradient, disabled = false, text = '', fz, loading, type, className }) => {

    return (
        <Button
            onClick={onClick}
            w={w}
            h={h}
            variant={variant}
            size={size}
            color={color}
            bg={bg}
            radius={radius}
            fullWidth={fullWidth}
            leftSection={leftSection}
            rightSection={rightSection}
            gradient={gradient}
            disabled={loading ? loading : disabled}
            type={type}
            styles={styles}
            className={className}
        >
            <Group justify='center' align="center">
                <Text span fz={fz} fw={600}>{text}</Text>
                {loading ? <Loader size="sm" color="white" type='dots' /> : <></>}
            </Group>
        </Button>

    )
}

export default PrimaryButton