import React, { useEffect, useState } from 'react'
import TableComp from '../Table/Table'
import { IoSearch } from 'react-icons/io5';
import { Box, Button, Input, Text, Title, Group, Divider, Textarea, Flex } from '@mantine/core';
import { FilterDropdown2 } from '../EmployeeManagement/Dropdown';
import classes from '../Button/button.module.css'
import billingClass from './billing.module.css';
import BillingCard from '../Billing/Card';

// Icons

import billingTrend from '../../assets/icons/billing-trend.svg';
import billingNotes from '../../assets/icons/billing-notes.svg';
import awaitingSelection from '../../assets/icons/awaiting-selection.svg';
import { IoIosArrowDown } from "react-icons/io";
import { ORDERS_TABLE_DATA, ORDERS_TABLE_HEADER } from '../../mock/admin/orders';
import { IconEye } from '@tabler/icons-react';
import { CommonDrawer } from '../Drawer/Drawer';
import PrimaryButton from '../BaseComponents/Buttons/Button';
import ModalComp from '../Modal/Modal';

const OrderManagement = () => {

    const [cancelOrder, setCancelOrder] = useState(false)
    const [viewOrder, setViewOrder] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const orders = ORDERS_TABLE_DATA.map((order, index) => {
        return {
            ...order,
            actions: (
                <Box onClick={() => handleViewOrder(order.id)} style={{ display: "flex", alignItems: "center" }}>
                    <IconEye style={{ width: '20px', height: '20px' }} />
                    <p>View</p>
                </Box>
            )
        }
    })

    function handleViewOrder(id) {
        setViewOrder(true)
    }

    function handleResize() {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={billingClass.billingContainer}>
            <ModalComp title={<Text fz={18} fw={600}>Do you really want to cancel the order ?</Text>} open={cancelOrder} setOpen={setCancelOrder}>
                <Text c="#5C5F66" mt={14} mb={24} lh={1.2}>Please specify the reason, and we will send a notification to your employees by email.</Text>
                <Text c="#5C5F66" mb={10}>Reason*</Text>
                <Textarea placeholder='Specify the reason' rows={3} mb={24} />
                <Flex justify="end">
                    <Button ml='auto' bg="#C92A2A" c="white" onClick={() => setCancelOrder(false)}>Decline order</Button>
                </Flex>
            </ModalComp>
            <CommonDrawer
                withCloseButton={false}
                open={viewOrder}
                onClose={() => setViewOrder(false)}
            >
                <Box px={24} py={12}>
                    <Group justify="space-between" align="center">
                        <Title order={2}>Order information</Title>
                        <Button size="sm" bg="#E9ECEF" c="black" fw="lighter" bd="2px solid #ADB5BD" radius={6} onClick={() => setViewOrder(false)}>Cancel</Button>
                    </Group>
                </Box>
                <Divider orientation="horizontal" />
                <Box px={24} py={12}>
                    <Group justify="space-between" align="center">
                        <Title order={3} c="#344054">Order ID: 3354654654526</Title>
                        <Button size="sm" bg="#E9ECEF" c="black" fw="lighter" bd="2px solid #ADB5BD" radius={6}>Export Invoice</Button>
                    </Group>
                    <Title order={5} fw={500} c="#667085" mt={12}>Order date: Feb 16, 2022</Title>
                </Box>
                <Divider orientation="horizontal" />
                <Box px={24} py={12}>
                    <Title order={3} mb={14}>Brooklyn Simmons</Title>
                    <Group gap={32}>
                        <Box>
                            <Title order={5} fw={500}>Email</Title>
                            <Title order={5} fw={600} c="#373A40">danghoang87hl@gmail.com</Title>
                        </Box>
                        <Box>
                            <Title order={5} fw={500}>Location</Title>
                            <Title order={5} fw={600} c="#373A40">London, UK</Title>
                        </Box>
                        <Box>
                            <Title order={5} fw={500}>Gender</Title>
                            <Title order={5} fw={600} c="#373A40">Male</Title>
                        </Box>
                        <Box>
                            <Title order={5} fw={500}>Job title</Title>
                            <Title order={5} fw={600} c="#373A40">Electrician</Title>
                        </Box>
                    </Group>
                </Box>
                <Divider orientation="horizontal" />
                <Box px={24} py={12}>
                    {[1, 2].map((item, index) => (
                        <Group key={index} spacing="lg" align="center" py={16}>
                            <Box bg="#F9FAFB" w={64} h={64} style={{ border: '1px solid #D0D5DD', borderRadius: '10px' }}></Box>
                            <Box style={{ flex: 1 }}>
                                <Text weight={500} mb={10} fz={18} c="#344054">
                                    Frame Name
                                </Text>
                                <Text size="xs" c="dimmed" fz={16}>
                                    Color | Size | Name
                                </Text>
                            </Box>
                            <Box style={{ textAlign: 'right' }}>
                                <Text fw={600} mb={10} fz={18} c="#1D2939">
                                    $2599.00
                                </Text>
                                <Text size="xs" c="dimmed" fz={16}>
                                    Qty: 1
                                </Text>
                            </Box>
                        </Group>
                    ))}
                </Box>
                <Divider orientation="horizontal" />
                <Box px={24} py={12}>
                    <Group spacing="lg" align="center" justify="space-between" py={16}>
                        <Text fz={18} c="#344054">Total</Text>
                        <Text fw={600} fz={18} c="#1D2939">$2599.00</Text>
                    </Group>
                </Box>
                <Divider orientation="horizontal" />
                <Group px={24} py={24} spacing="lg" align="center" justify="flex-end">
                    <Button size="sm" styles={{ root: { backgroundColor: "transparent" } }} c="#A6A7AB" fw="lighter" bd="2px solid #A6A7AB" radius={6} onClick={() => {
                        setCancelOrder(true)
                        setViewOrder(false)
                    }}>Decline</Button>
                    <PrimaryButton text="Confirm order" />
                </Group>
            </CommonDrawer>
            <div style={{ marginBottom: "24px", display: "flex", justifyContent: width < 991 ? "center" : "space-between", flexDirection: width < 991 ? "column" : "row", alignItems: width < 991 ? "center" : "center", overflowX: width < 1414 ? "scroll" : "hidden", width: "100%" }}>
                <div style={{ width: "100%", marginBottom: width < 991 ? 20 : 0 }}>
                    <BillingCard icon={billingTrend} width={width} title={<Text fw={700} fz={24}>$1.210</Text>} desc="Total spent" />
                </div>
                <div style={{ width: "100%", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : "22px", }}>
                    <BillingCard icon={billingNotes} width={width} title={<Text fw={700} fz={24}>12 <span style={{ fontSize: "16px" }}>request</span> </Text>} desc="Awaiting confirmation" />
                </div>
                <div style={{ width: "100%", marginBottom: width < 991 ? 20 : 0, marginLeft: width < 991 ? 5 : "22px", }}>
                    <BillingCard sale={<sup style={{ fontSize: "16px", color: "#1C7ED6", fontWeight: "600" }}>-14%</sup>} icon={awaitingSelection} width={width} title={<Text fw={700} fz={24}>113 <span style={{ fontSize: "16px" }}>users</span> </Text>} desc="Awaiting selection" />
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "24px" }}>
                <div style={{ display: "flex", position: "relative" }}>
                    <IoSearch size={17} style={{ position: "absolute", top: "25%", zIndex: 1000, left: 10, color: "#ADB5BD" }} />
                    <Input placeholder="Search" styles={{ input: { padding: "0 20px 0 35px", backgroundColor: "transparent" } }} mr={10} width={207} />
                    <FilterDropdown2 />
                    <Button styles={{ root: { display: "flex", alignItems: "center" } }} id={classes.defaultDark} ml={10}>
                        <Text mr={10}>Sort by</Text>
                        <IoIosArrowDown />
                    </Button>
                </div>
                <div style={{ display: "flex" }}>
                    <Button id={classes.default}>Decline</Button>
                    <Button id={classes.contained} ml={10}>Сonfirm order</Button>
                    <Button id={classes.contained} ml={10} bg="#099268">Export</Button>
                </div>
            </div>
            <TableComp tableHeaders={ORDERS_TABLE_HEADER} data={orders} isCheckBox={true} />
        </div>
    )
}

export default OrderManagement;