import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    prescriptionList: [],
    prescriptionLoading: false,
    prescriptionLoading2: false,
    prescriptionError: null
};

const prescriptionSlice = createSlice({
    name: "prescription",
    initialState,
    reducers: {
        setPrescriptions: (state, { payload }) => {
            state.prescriptionList = payload
        },
        setPrescriptionError: (state, { payload }) => {
            state.prescriptionError = payload
        },
        setPrescriptionLoading: (state, { payload }) => {
            state.prescriptionLoading = payload
        },
        setPrescriptionLoading2: (state, { payload }) => {
            state.prescriptionLoading2 = payload
        },
    },
});

export const { setPrescriptions, setPrescriptionLoading, setPrescriptionLoading2, setPrescriptionError } = prescriptionSlice.actions;
export default prescriptionSlice.reducer;