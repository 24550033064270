import { Box } from "@mantine/core";
import { IconEye } from "@tabler/icons-react";

export const ORDERS_TABLE_HEADER = [
    { value: 'name', label: 'Name' },
    { value: 'email', label: 'Email' },
    { value: 'tier', label: 'Tier Assignment' },
    { value: 'orderDate', label: 'Order Date' },
    { value: 'amount', label: 'Amount' },
    { value: 'status', label: 'Status' },
    { value: 'actions', label: 'Action' },
];

export const ORDERS_TABLE_DATA = [
    {
        id: 1,
        name: "Dianne Russell",
        email: "thuhang.nute@gmail.com",
        tier: "Bronze tier",
        orderDate: "28/10/2023",
        amount: "$406.27",
        status: "3",
    }
];