import React, { useEffect, useState } from 'react';
import Card, { SafetyAwardCard } from '../../components/SafetyAwards/Card';
import { Button, Group, Text, Grid, Box, Loader, Stack, Title, Divider } from '@mantine/core';
import Tab from '../../components/SafetyAwards/Tabs';
import { PrimaryButton, SecondaryButton } from '../../components/ui/Button/Button';
import { SAFETY_PROGRAM_TYPE_CHOICES } from '../../mock/admin/safetyAwards';
import { useForm } from '@mantine/form';
import { DateInputField, InputField, SelectInputField, TextAreaField } from '../../components/ui/Input/Input';
import { IconChevronDown } from '@tabler/icons-react';
import { CommonDrawer } from '../../components/Drawer/Drawer';

import { useDispatch, useSelector } from 'react-redux';
import { createSafetyAwards, deleteSafetyAwards } from '../../middlewares/admin/safetyAwards';
import ProfileImageUploader from '../../components/BaseComponents/ProfileImageUploader';
import { asyncErrorHandler } from '../../helpers/errorHandler';
import { useNavigate } from 'react-router-dom';

const SafetyAwards = () => {

    const safetyAwardList = useSelector((state) => state.safetyAwards.safetyAwards)
    const loading = useSelector((state) => state.safetyAwards.loading)
    const loading2 = useSelector((state) => state.safetyAwards.loading2)
    const [width, setWidth] = useState(window.innerWidth);
    const [openAddSafetyProgram, setOpenAddSafetyProgram] = useState(false);
    const [editSafetyAwards, setEditSafetyAwards] = useState(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const form = useForm({
        initialValues: {
            icon: null,
            name: '',
            start_date: null,
            end_date: null,
            program_type: "",
            description: '',
        },
        validate: {
            icon: (val) => !val ? 'Icon is required' : null,
            name: (val) => {
                if (!val) {
                    return 'Program name is required';
                }
                if (!/^[A-Za-z\d\s-]+$/.test(val)) {
                    return 'Program name must contain only letters, numbers, spaces, and dashes';
                }
                return null;
            },
            start_date: (val) => !val ? 'Start Date is required' : null,
            end_date: (val) => !val ? 'End Date is required' : null,
            program_type: (val) => !val ? "Program Type is required" : null,
            description: (val) => val && val.length >= 10 ? null : 'Description should be at least 10 characters long.',
        },
    })

    function handleResize() {
        setWidth(window.innerWidth)
    }

    const handleSubmit = async () => {
        if (form.validate().hasErrors) {
            form.validate()
        }
        else {
            const formData = new FormData()
            Object.entries(form.values).forEach(([key, value]) => {
                if (key === "icon" && (typeof value === "string" || typeof value === null)) {
                    return;
                }
                if (key === "start_date" || key === "end_date") {
                    const formattedDate = new Intl.DateTimeFormat("en-CA").format(new Date(value));
                    formData.append(key, formattedDate);
                }
                else {
                    formData.append(key, value)
                }
            })
            const res = await dispatch(createSafetyAwards(formData))
            if (res.type === "createSafetyAwards/rejected") {
                asyncErrorHandler(res, form)
            }
            else {
                setOpenAddSafetyProgram(false)
                window.toast("Safety awards updated successfully")
            }
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleOpenUpdate = (obj) => {
        setOpenAddSafetyProgram(true)
        setEditSafetyAwards(obj)
        form.setValues({
            icon: obj.icon,
            name: obj.name,
            start_date: obj.start_date,
            end_date: obj.end_date,
            program_type: obj.program_type,
            description: obj.description,
        })
    }

    const renderContent = () => {
        if (loading) {
            return (
                <Stack align='center' justify='center' gap={12}>
                    <Loader size="xl" color="#3354F4" type='dots' />
                </Stack>
            )
        }
        if (!loading && safetyAwardList?.length > 0) {
            return (
                <Grid gutter={24} mt={24}>
                    {safetyAwardList?.map((safety) => (
                        <Grid.Col key={safety.id} span={{ base: 12, md: 12, lg: 6 }}>
                            <SafetyAwardCard
                                handleOpenUpdate={() => {
                                    handleOpenUpdate(safety)
                                    navigate(`/admin/safety-awards/edit/${safety.id}`, { replace: true })
                                }}
                                {...safety}
                            />
                        </Grid.Col>
                    ))}
                </Grid>
            )
        }
    }

    return (
        <>
            {/* <CommonDrawer
                type=""
                open={openAddSafetyProgram}
                onClose={() => setOpenAddSafetyProgram(false)}
                withCloseButton={false}
            >
                <Box px={24} py={12}>
                    <Group justify="space-between" align="center">
                        <Title order={4}>Create New Safety Awards</Title>
                        <SecondaryButton title="Cancel" onClick={() => setOpenAddSafetyProgram(false)} />
                    </Group>
                </Box>
                <Divider orientation="horizontal" />
                <Box p={24}>
                    <Stack gap={20}>
                        <Grid gutter={20}>
                            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                <Text fz={16} mb={10}>Image</Text>
                                <ProfileImageUploader
                                    mb={20}
                                    profileImageURL={
                                        form.values.icon && typeof form.values.icon === "string" ? form.values.icon :
                                        form.values.icon && form.values.icon instanceof File ?
                                        URL.createObjectURL(form.values.icon) : null
                                    }
                                    updateImage={(image) => form.setFieldValue('icon', image)}
                                    deleteImage={() => form.setFieldValue('icon', '')}
                                />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                <InputField label={<Text fz={16} fw={500}>Program Name</Text>} {...form.getInputProps('name')} key={form.key("name")} placeHolder="Program name" />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                                <DateInputField placeHolder="Start Date" label={<Text fz={16} fw={500}>Start Date</Text>} {...form.getInputProps('start_date')} key={form.key('start_date')} />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                                <DateInputField placeHolder="End Date" label={<Text fz={16} fw={500}>End Date</Text>} {...form.getInputProps('end_date')} key={form.key('end_date')} />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                <SelectInputField
                                    {...form.getInputProps('program_type')}
                                    key={form.key('program_type')}
                                    data={SAFETY_PROGRAM_TYPE_CHOICES}
                                    rightSection={<IconChevronDown style={{ width: '16px', height: 'auto', marginRight: '2px' }} />}
                                    label={<Text fz={16} fw={500}>Type</Text>}
                                    placeHolder="Select program type"
                                    style={{ position: 'relative' }}
                                />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                <TextAreaField
                                    maxLength={500}
                                    {...form.getInputProps("description")}
                                    key={form.key("description")}
                                    label={<Text fz={16} fw={500}>Description</Text>}
                                    placeHolder="Enter your message here"
                                    styles={{
                                        label: {
                                            marginBottom: "10px"
                                        },
                                        input: {
                                            height: "93px"
                                        },
                                    }}
                                />
                                <p style={{ marginTop: '5px', fontSize: '14px', color: '#999' }}>
                                    {form.values.description.length}/{500} characters
                                </p>
                            </Grid.Col>
                        </Grid>
                        <Group justify="end">
                            {editSafetyAwards?.id && (
                                <Button onClick={() => dispatch(deleteSafetyAwards(editSafetyAwards?.id))} variant="light" color="#C92A2A" radius="md" style={{ background: '#FFC9C9' }}>
                                    <Group justify='center' align="center">
                                        <Text fw={600}>Delete</Text>
                                        {loading2 ? <Loader size="sm" color="black" type='dots' /> : null}
                                    </Group>
                                </Button>
                            )}
                            <PrimaryButton
                                onClick={handleSubmit}
                                title={editSafetyAwards?.id ? "Save changes" : "Create"}
                                disabled={loading2}
                                fallback={loading2 ? <Loader size="sm" color="white" type='dots' /> : null}
                            />
                        </Group>
                    </Stack>
                </Box>
            </CommonDrawer> */}
            <Box style={{ overflow: 'hidden' }}>
                <Box style={{ padding: "24px" }}>
                    <Grid>
                        <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
                            <Card width={width} title="Program Overview" />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
                            <Card width={width} title="FAQ" />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
                            <Card width={width} title="Terms" />
                        </Grid.Col>
                    </Grid>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "30px 0 20px 0" }}>
                        <Text fz={24} fw={600}>Safety programs</Text>
                        <PrimaryButton
                            onClick={() => {
                                setOpenAddSafetyProgram(true)
                                form.reset()
                                form.clearErrors()
                            }}
                            title="Create new program"
                        />
                    </div>
                    <Tab />
                    {renderContent()}
                </Box>
            </Box>
        </>
    );
};

export default SafetyAwards;