import TabBaseLayout from "../../components/Layout/TabBaseLayout/TabBaseLayout"
import Company from "../../components/CompanyManagement/Partner/Company/Company"
import SubContractor from "../../components/CompanyManagement/Partner/SubContractor/SubContractor"
import Admin from '../../components/CompanyManagement/Partner/Admin/admin'

const PartnerCompanyManagement = () => {

    const tabList = [
        { value: 'companys', label: 'Companys' },
        { value: 'sub_contractors', label: 'Sub-Contractors' },
        { value: 'admin', label: 'Admin' },
    ]

    return (
        <TabBaseLayout tabList={tabList}>
            <Company />
            <SubContractor />
            <Admin />
        </TabBaseLayout>
    )
}

export default PartnerCompanyManagement