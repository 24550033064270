import React, { useEffect } from 'react'
import { Box, Checkbox, Divider, Group, Text } from '@mantine/core'
import { IconMinus, IconPlus, IconX } from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCart, incQty, decQty, selectCartItem, setSelectCartItem } from '../../../store/employess/cartSlice'
import { FaCartArrowDown } from 'react-icons/fa';
// import { removeFromCart, incQty, decQty } from '../../../middlewares/employee/cart.service'

const CartItems = () => {

    const cart = useSelector((state) => state.cart.shippingDetails.cart)
    const dispatch = useDispatch()

    const handleDelete = (id) => {
        // async functions
        // dispatch(removeFromCart(id))
        dispatch(removeFromCart(id))
    }

    useEffect(() => {
        dispatch(setSelectCartItem(cart.filter((items) => items.isSelected)))
    }, [cart])

    return (
        <Box>
            {cart?.map((item) => {
                return (
                    <>
                        <Box mt={40}>
                            <Group align="center">
                                <Checkbox styles={{ input: { cursor: "pointer" } }} onChange={() => dispatch(selectCartItem(item.id))} checked={item?.isSelected} />
                                <Box style={{ borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center" }} bg="#EDF2FF" w={90} h={90} bd="2px solid #DEE2E6">
                                    <img style={{ width: "90px", objectFit: "cover" }} src={item?.frame_image} alt="" />
                                </Box>
                                <Box w={300}>
                                    <Text mb={8} fw="bold">{item?.glass_brand?.frame_name}</Text>
                                    <Group>
                                        <Text>Color: {item?.frame_color}</Text>
                                        <Text>Size: XL</Text>
                                    </Group>
                                </Box>
                                <Group align="center">
                                    <IconMinus onClick={() => {
                                        // const qty = item?.qty + 1;
                                        // dispatch(decQty({qty, id: item.id}))
                                        dispatch(decQty(item.id))
                                    }} cursor="pointer" />
                                    <Box bd="2px solid #DEE2E6" w={50} h={32} style={{ borderRadius: "6px", display: "flex", justifyContent: "center", alignItems: "center" }}>{item?.qty || 0}</Box>
                                    <IconPlus onClick={() => {
                                        // const qty = item?.qty - 1;
                                        // dispatch(incQty({qty, id: item.id}))
                                        dispatch(incQty(item.id))
                                    }} cursor="pointer" />
                                </Group>
                                <Text fw="bold" fz={30} mx={24}>${item?.price}</Text>
                                <IconX cursor="pointer" onClick={() => handleDelete(item.id)} />
                            </Group>
                        </Box>
                        <Divider orientation="horizontal" my={36} />
                    </>
                )
            })}
        </Box>
    )
}

export default CartItems