import glasses from '../../assets/images/glasses.png';
import carambola from '../../assets/icons/carambola.svg'
import dotsVerticle from '../../assets/icons/dots-vertical.svg'
import rfxn from '../../assets/icons/rfxn.svg'
import bachtelCorp from '../../assets/icons/bechtel-corp.svg'

export const OFFERS = [
    { id: 1, image: glasses, product: "DEWALT DPG82-11 Concealer Clear Anti-Fog Dual Mold Safety Goggle, Clear Lens, 1 Pair", price: "€ 102.00", like: false, icon: carambola },
    { id: 2, image: glasses, product: "DEWALT DPG82-11 Concealer Clear Anti-Fog Dual Mold Safety Goggle, Clear Lens, 1 Pair", price: "€ 102.00", like: false, icon: carambola },
    { id: 3, image: glasses, product: "DEWALT DPG82-11 Concealer Clear Anti-Fog Dual Mold Safety Goggle, Clear Lens, 1 Pair", price: "€ 102.00", like: false, icon: carambola },
    { id: 4, image: glasses, product: "DEWALT DPG82-11 Concealer Clear Anti-Fog Dual Mold Safety Goggle, Clear Lens, 1 Pair", price: "€ 102.00", like: false, icon: carambola },
    { id: 5, image: glasses, product: "DEWALT DPG82-11 Concealer Clear Anti-Fog Dual Mold Safety Goggle, Clear Lens, 1 Pair", price: "€ 102.00", like: false, icon: carambola },
    { id: 6, image: glasses, product: "DEWALT DPG82-11 Concealer Clear Anti-Fog Dual Mold Safety Goggle, Clear Lens, 1 Pair", price: "€ 102.00", like: false, icon: carambola },
]

export const BALANCE_HISTORY = [
    { id: 1, image: bachtelCorp, company: "Bechtel Corp", type: "Funds receipt", date: "2024/04/01", balance: "+$750.00", actions: <img src={dotsVerticle} alt="" /> },
    { id: 2, image: rfxn, company: "RFXN", type: "Debit", date: "2024/04/01", balance: "+$750.00", actions: <img src={dotsVerticle} alt="" /> },
    { id: 3, image: rfxn, company: "RFXN", type: "Debit", date: "2024/04/01", balance: "+$750.00", actions: <img src={dotsVerticle} alt="" /> },
    { id: 4, image: rfxn, company: "RFXN", type: "Funds receipt", date: "2024/04/01", balance: "+$750.00", actions: <img src={dotsVerticle} alt="" /> },
    { id: 5, image: rfxn, company: "RFXN", type: "Debit", date: "2024/04/01", balance: "+$750.00", actions: <img src={dotsVerticle} alt="" /> },
    { id: 6, image: rfxn, company: "RFXN", type: "Debit", date: "2024/04/01", balance: "+$750.00", actions: <img src={dotsVerticle} alt="" /> },
]