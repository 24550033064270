import React, { useState } from 'react'
import { Badge, Box, Grid, Group, Radio, Text, Title, Stack, Divider, Loader, Skeleton } from '@mantine/core'
import { IconChevronDown, IconPencil, IconX } from '@tabler/icons-react'
import { useDispatch, useSelector } from 'react-redux'
import { CommonDrawer } from '../../Drawer/Drawer'
import { useForm } from '@mantine/form'
import InputBox from '../../BaseComponents/InputBox'
import { setSummary } from '../../../store/employess/summarySlice'
import { PrimaryButton } from '../../ui/Button/Button'
import { setAddress } from '../../../store/employess/cartSlice'
import { addNewAddress, deleteAddress, updateAddress } from '../../../middlewares/employee/address.service'
import { asyncErrorHandler } from '../../../helpers/errorHandler'
import { SelectInputField } from '../../ui/Input/Input'
import { selectAddress } from '../../../store/employess/addressSlice'

const SelectAddress = () => {

    const [openDrawer, setOpenDrawer] = useState(false)
    const [drawerType, setDrawerType] = useState("")
    const { countries, cities } = useSelector((state) => state.common)
    const { loading, fetchingAddress, address } = useSelector((state) => state.address)
    const user = useSelector((state) => state.login.user)
    const dispatch = useDispatch()

    const form = useForm({
        initialValues: {
            city: "",
            name: "",
            phone_number: null,
            street: "",
            recipient_email: user?.email || "",
            zip_code: null,
            country: ""
        },
        validate: {
            city: (val) => (!val ? 'City is required' : null),
            name: (val) => {
                if (!val) return 'Address name is required';
                if (!/^[A-Za-z\d\s-]+$/.test(val)) {
                    return 'Address name must contain only letters, numbers, spaces, and dashes';
                }
                return null;
            },
            phone_number: (val) => (!val ? 'Phone Number is required' : null),
            street: (val) => (!val ? 'Street is required' : null),
            recipient_email: (val) => (!val ? 'Email is required' : null),
            zip_code: (val) => (!val ? 'Zip Code is required' : null),
            country: (val) => (!val ? 'Country is required' : null),
        },
    });

    const handleChange = (key, value) => {
        form.setFieldValue(key, value)
    }

    const resetForm = () => {
        setOpenDrawer(false)
        form.reset()
        form.clearErrors()
        form.clearFieldError()
    }

    const handleAddAddress = async () => {
        if (form.validate().hasErrors === true) {
            return;
        }
        if (drawerType === "add") {
            const res = await dispatch(addNewAddress(form.values))
            if (res.type === "add/addNewAddress/rejected") {
                asyncErrorHandler(res, form)
            } else {
                window.toast(`Address added successfully`)
                resetForm()
            }
        }
        else {
            const res = await dispatch(updateAddress({ id: form.values.id, payload: form.values }))
            console.log(res);
            if (res.type === "update/updateAddress/rejected") {
                asyncErrorHandler(res, form)
            } else {
                window.toast(`Address updated successfully`)
                resetForm()
            }
        }
    }

    const renderContent = () => {
        return fetchingAddress ? (
            Array.from({ length: 8 }).map((_, index) => (
                <Grid.Col key={index} span={{ md: 6, lg: 6 }}>
                    <Skeleton height="105px" width="100%" />
                </Grid.Col>
            ))
        ) : address?.map((address) => {
            return (
                <Grid.Col
                    span={{ lg: 6 }}
                    onClick={() => {
                        dispatch(setAddress(address))
                        dispatch(selectAddress(address.id))
                        dispatch(setSummary({ key: "address", data: address }))
                    }}
                >
                    <Box bg="#F8F9FA" p={16} style={{ borderRadius: "20px", cursor: "pointer" }}>
                        <Group>
                            <Radio checked={address?.isSelected} />
                            <Text fw={600}>{address.name}</Text>
                            <Badge color="#000000" h={26} styles={{ root: { borderRadius: "5px" } }}>HOME</Badge>
                        </Group>
                        <Box mt={16} ml={35}>
                            <Group justify="space-between">
                                <Text>{address.street}</Text>
                                <Box>
                                    <IconPencil onClick={() => {
                                        setOpenDrawer(true)
                                        setDrawerType("edit")
                                        form.setValues(address)
                                    }} style={{ marginRight: "30px" }} />
                                    <IconX onClick={() => dispatch(deleteAddress(address.id))} />
                                </Box>
                            </Group>
                            <Text>{address.phoneNumber}</Text>
                        </Box>
                    </Box>
                </Grid.Col>
            )
        })
    }

    return (
        <Box>
            <Group mb={32} justify="space-between" align="center">
                <Text fw={600} fz={18}>Select Address</Text>
                <PrimaryButton
                    title="Add Address"
                    h={35}
                    onClick={() => {
                        setOpenDrawer(true)
                        setDrawerType("add")
                    }}
                />
            </Group>
            <CommonDrawer
                open={openDrawer}
                onClose={() => {
                    setOpenDrawer(false)
                    form.reset()
                    form.clearErrors()
                    form.clearFieldError()
                }}
                withCloseButton={false}
            >
                <Box px={24} py={12}>
                    <Group justify="space-between" align="center">
                        <Title order={4}>{drawerType === "add" ? "Add" : "Edit"} address</Title>
                        <Group>
                            <PrimaryButton
                                h={32}
                                size="sm"
                                bg="#E9ECEF"
                                c="black"
                                fw="lighter"
                                bd="2px solid #ADB5BD"
                                radius={6}
                                onClick={resetForm}
                                title="Cancel"
                            />
                            <PrimaryButton
                                h={32}
                                size="sm"
                                bg="#3354F4"
                                c="#ffffff"
                                radius={6}
                                onClick={handleAddAddress}
                                title={drawerType === "add" ? "Add" : "Update"}
                                fallback={loading ? <Loader size="sm" color="white" type='dots' /> : null}
                            />
                        </Group>
                    </Group>
                </Box>
                <Divider orientation="horizontal" />
                <Box p={24}>
                    <Stack gap={20}>
                        <Grid gutter={20}>
                            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                                <InputBox {...form.getInputProps('name')} onChange={(value) => handleChange("name", value)} label="Address name" value={form.values.name} placeHolder="Please enter address name" error={form.errors.name} />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                                <InputBox {...form.getInputProps("street")} onChange={(value) => handleChange("street", value)} label="Street" value={form.values.street} placeHolder="Please enter address" error={form.errors.street} />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                                <InputBox {...form.getInputProps("zip_code")} onChange={(value) => handleChange("zip_code", value)} label="Zip / Postal code" value={form.values.zip_code} placeHolder="Please enter zip code" error={form.errors.zip_code} />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                                <SelectInputField
                                    data={cities}
                                    onChange={(value) => handleChange("city", value)}
                                    value={form.values.city}
                                    placeHolder="Please enter name"
                                    label={<Text fz={14} fw={600} c="#495057">City / District</Text>}
                                    error={form.errors.city}
                                    searchable
                                    limit={100}
                                    rightSection={<IconChevronDown style={{ width: '16px', height: 'auto', marginRight: '2px' }} />}
                                    {...form.getInputProps('city')}
                                />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                <InputBox disabled={true} {...form.getInputProps("recipient_email")} onChange={(value) => handleChange("recipient_email", value)} label="Recipient Email" value={form.values.recipient_email} placeHolder="Please enter email" error={form.errors.recipient_email} />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                <InputBox {...form.getInputProps('phone_number')} onChange={(value) => handleChange('phone_number', value)} label="Phone Number" value={form.values.phone_number} placeHolder="Please enter phone number" error={form.errors.phone_number} />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                <SelectInputField
                                    data={countries}
                                    onChange={(value) => handleChange("country", value)}
                                    value={form.values.country}
                                    placeHolder="Please enter name"
                                    label={<Text fz={14} fw={600} c="#495057">Country</Text>}
                                    error={form.errors.country}
                                    searchable
                                    limit={100}
                                    rightSection={<IconChevronDown style={{ width: '16px', height: 'auto', marginRight: '2px' }} />}
                                    {...form.getInputProps('country')}
                                />
                            </Grid.Col>
                        </Grid>
                    </Stack>
                </Box>
            </CommonDrawer>
            <Grid gutter={12}>
                {renderContent()}
            </Grid>
        </Box>
    )
}

export default SelectAddress;