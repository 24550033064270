import React, { useState } from 'react';
import { Button, Container, Stack, TextInput, Group, Image, Text, Box, Card, Flex, Loader, Title, Divider, Grid, Tabs, Select } from '@mantine/core'
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { useDebouncedCallback } from '@mantine/hooks';
import { IoSearch } from "react-icons/io5";
import classes from './EmployeeManagement.module.css'
import modalClasses from '../Modal/modal.module.css'
import TableComp from '../Table/Table';
import { CommonDrawer } from '../Drawer/Drawer';
import ModalComp from '../Modal/Modal';
import { FilterDropdown } from './Dropdown';
import { useForm } from '@mantine/form';
import { useDispatch, useSelector } from 'react-redux';
import { addNewEmployee, fetchAllEmployees, editEmployee, deleteAEmployee, uploadEmlpoyeeTemplate, getMeasurementByEmployee } from '../../middlewares/employee/employee.service';
import { notifications } from '@mantine/notifications';
import { EMPLOYEE_PRESCRIPTION, EMPLOYEE_FRAME_AND_LENS, EMPLOYEE_STATUS_BLOCK, EMPLOYEE_TIER, EMPLOYEE_STATUS, EMPLOYEE_TIER_CHOICES, ETHNICITY } from '../../choices/employee.choice';
import { EMPLOYEE_TABLE_HEADERS, EMPLOYEE_MEASUREMENT_TABLE_HEADERS } from '../../mock/employees/table/header';
import { setEmployees, updateCurrentPage } from '../../store/employess/employeesSlice';
import { IconTrash, IconEye, IconUser, IconNews, IconStereoGlasses, IconShieldHalfFilled, IconEdit, IconReload, IconChevronDown } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { setEmployeeFilters, setSearchEmployeeByName } from '../../store/filters/filterSlice';
import uploadDocumentIcon from "../../assets/icons/upload-document.svg"
import excelLogo from "../../assets/icons/excel-logo.svg"
import { asyncErrorHandler } from '../../helpers/errorHandler';
import { OutlinedButton, PrimaryButton, PrimaryButton2, SecondaryButton } from '../ui/Button/Button';

import Tab from '../Tabs/Tabs';
import { GENDER_CHOICES } from '../../choices/common.choice';
import ProfileImageUpload from '../BaseComponents/ProfileImageUploader'
import { inviteEmployee } from '../../middlewares/employee/employee.service'
import { InputField, SelectInputField } from '../ui/Input/Input';
import { getTiersList } from '../../middlewares/common/common.services';

const EmployeeManagement = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [openAddEmployeeDrawer, setOpenAddEmployeeDrawer] = useState(false);
    const [openMeasurementDrawer, setOpenMeasurementDrawer] = useState(false);
    const [openCSVModal, setOpenCSVModal] = useState(false)
    const [employeeList, setEmployeeList] = useState([])
    const [loading, setLoading] = useState(false)

    const [isEditProfile, setIsEditProfile] = useState(false)
    const [userProfileImageUrl, setUserProfileImageUrl] = useState(null)
    const [employeeCSVFile, setEmployeeCSVFile] = useState(null)
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [selectedEmployees, setSelectedEmployees] = useState([])
    const [uploadFileLoading, setUploadFileLoading] = useState(false)
    const [searchLoading, setSearchLoading] = useState(false)
    const [messageEmployee, setMessageEmployee] = useState(false)
    const [employeeId, setEmployeeId] = useState(null)

    const { employees, employeeLoading } = useSelector(state => state.employee)
    const totalEmployees = useSelector(state => state.employee.totalNumberOfRecords)
    const totalPages = useSelector(state => state.employee.totalPages)
    const currentPage = useSelector((state) => state.employee.currentPage)
    const measurementsData = useSelector((state) => state.measurements.measurements)
    const organization = useSelector(state => state.organizations.organization)
    const tierList = useSelector((state) => state.tiers.tierList)
    const adminOrganization = useSelector((state) => state.organizations.organization)

    const tabs = [
        {
            name: 'User Info',
            icon: <IconUser size={20} />,
            indicator: false,
            value: 'userInfo',
            disabled: false
        },
        {
            name: 'Prescripition',
            icon: <IconNews size={20} />,
            indicator: false,
            value: 'prescription',
            disabled: true
        },
        {
            name: 'Frames & Lens options',
            icon: <IconStereoGlasses size={20} />,
            indicator: false,
            value: 'framesAndLensOptions',
            disabled: true
        },
        {
            name: 'Safety Awards',
            icon: <IconShieldHalfFilled size={20} />,
            indicator: false,
            value: 'safetyAwards',
            disabled: true
        }
    ]

    const editIcon = <IconEdit />
    const selectIcon = <IconChevronDown style={{ width: '16px', height: 'auto', marginRight: '2px' }} />
    const searchIcon = <IoSearch color="#ADB5BD" />
    const searchLoader = <Loader size='sm' color="#ADB5BD" />

    const form = useForm({

        initialValues: {
            name: '',
            ethnicity: null,
            age: '',
            email: '',
            tier: null,
            address: '',
            gender: null,
            image: '',
            status: ''
        },
        validate: {
            name: (val) => (/^(?=.*[A-Za-z])(?!^[\d\s-]+$)(?!^[^\w\s-]+$)[A-Za-z\d\s-]+$/.test(val) ? null : 'Please enter a valid company name'),
            age: (val) => (/^(1[89]|[2-9][0-9]|100)$/.test(val) ? null : 'Please enter valid age'),
            gender: (val) => (/([M,F,O])/.test(val) ? null : 'Please select appropiate gender'),
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Please enter a valid email'),
            tier: (val) => (/([1-3])/.test(val) ? null : 'Please select a tier for the employee'),
            ethnicity: (val) => val ? null : ('Please select ethnicity for the employee'),
        },

    })

    async function sendInvitation() {
        const payload = { 'employee_id': employeeId, 'organization_id': organization?.id }
        const res = await dispatch(inviteEmployee(payload))
        if (inviteEmployee.fulfilled.match(res)) {
            setOpenAddEmployeeDrawer(false)
            window.toast("Invitation send successfully")
            updateFormValues('status', '1')
        }
    }

    function updateFormValues(key, value) {
        form.setFieldValue(key, value)
        if (key === 'image') {
            setUserProfileImageUrl((userProfileImageUrl) => {
                if (value) {
                    return userProfileImageUrl = URL.createObjectURL(value)
                } else {
                    return userProfileImageUrl = null
                }
            })
        }
    }

    async function addEmployee() {
        validateEmployeeData(async (formData, form) => {
            setLoading(true)
            const res = await dispatch(addNewEmployee(formData))
            if (addNewEmployee.fulfilled.match(res)) {
                setOpenAddEmployeeDrawer(false)
                setIsEditProfile(false)
                setLoading(false)
                window.toast(`Employee added successfully`)
                form.reset()
                setUserProfileImageUrl('')
            }
            else if (addNewEmployee.rejected.match(res)) {
                console.log(res);
                setLoading(false)
                asyncErrorHandler(res, form)
            }
        })
    }

    async function editEmployeeData() {
        validateEmployeeData(async (formData, form) => {
            setLoading(true)
            const res = await dispatch(editEmployee({ data: formData, employeeId: employeeId }))
            if (editEmployee.fulfilled.match(res)) {
                setLoading(false)
                setOpenAddEmployeeDrawer(false)
                setIsEditProfile(false)
                window.toast(`Employee details updated successfully`)
                dispatch(getTiersList({ organization_id: adminOrganization?.id }))
            }
            else if (editEmployee.rejected.match(res)) {
                setLoading(false)
                asyncErrorHandler(res, form)
            }
        })
    }

    async function deleteEmployee() {
        const res = await dispatch(deleteAEmployee(employeeId))
        if (deleteAEmployee.fulfilled.match(res)) {
            setOpenAddEmployeeDrawer(false)
            setIsEditProfile(false)
            window.toast('Employee delete successfully')
            form.reset()
            setUserProfileImageUrl(null)
            navigate(`/admin/employee-management/`)
        }
        else if (deleteAEmployee.rejected.match(res)) {
            window.toast('Employee could not be delete')
        }
    }

    const searchEmployee = useDebouncedCallback(async (searchQuery) => {
        setSearchLoading(searchLoading => !searchLoading)
        dispatch(setSearchEmployeeByName(searchQuery))
        // searchQuery ? setIsFilterApplied(true) : setIsFilterApplied(false)
        await dispatch(fetchAllEmployees(currentPage))
        setSearchLoading(searchLoading => !searchLoading)
    }, 1000)
    const [hovered, setHovered] = useState(null); // Tracks which box is being hovered

    function serailizeEmployeesData() {
        return employees.map((employee, index) => {
            return {
                ...employee,
                rx_non_rx: EMPLOYEE_PRESCRIPTION[employee.rx_non_rx],
                frame_and_lens: EMPLOYEE_FRAME_AND_LENS[employee.frame_and_lens],
                status: EMPLOYEE_STATUS_BLOCK[employee.status],
                actions:
                    <>
                        <Group gap={8} justify="center" align="center">
                            <Box
                                onMouseEnter={() => setHovered("edit" + index)}
                                onMouseLeave={() => setHovered(null)}
                                onClick={() => openEditEmployee(employee.id)}
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <IconEdit
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        color: hovered === "edit" + index ? "#3354F4" : "#868E96"
                                    }}
                                />
                                <p style={{
                                    marginLeft: "8px",
                                    color: hovered === "edit" + index ? "#3354F4" : "#868E96"
                                }}>
                                    Edit
                                </p>
                            </Box>
                            <Box
                                onMouseEnter={() => setHovered("view" + index)}
                                onMouseLeave={() => setHovered(null)}
                                onClick={() => { handleOpenPrescriptionModal(employee.user_pk) }}
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <IconEye
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        marginLeft: "20px",
                                        color: hovered === "view" + index ? "#3354F4" : "#868E96"
                                    }}
                                />
                                <p style={{
                                    marginLeft: "8px",
                                    color: hovered === "view" + index ? "#3354F4" : "#868E96"
                                }}>
                                    View
                                </p>
                            </Box>
                        </Group>
                    </>
            }
        })
    }

    function openEditEmployee(id) {
        setEmployeeId(id)
        const employee = employees.find(employee => employee.id === id)
        if (employee) {
            form.setValues({
                name: employee.name,
                ethnicity: employee.ethnicity ? employee.ethnicity : '',
                age: employee.age,
                email: employee.email,
                tier: employee.tier,
                address: employee.address ? employee.address : '',
                gender: employee.gender,
                status: employee.status
            })
            setUserProfileImageUrl(employee.image)
            setOpenAddEmployeeDrawer(true)
            setIsEditProfile(true)
        }
    }

    function handleOpenPrescriptionModal(id) {
        setOpenMeasurementDrawer(true)
        dispatch(getMeasurementByEmployee(id))
    }

    function openAddEmployee() {
        setOpenAddEmployeeDrawer(true)
        setIsEditProfile(false)
        form.reset()
        navigate('/admin/employee-management')
    }

    function validateEmployeeData(fn) {
        const validation = form.validate()
        if (
            validation.errors.name ||
            validation.errors.age ||
            validation.errors.gender ||
            validation.errors.email ||
            validation.errors.tier ||
            validation.errors.ethnicity
        ) {
            form.validate()
            return;
        }
        else {
            const formData = new FormData()
            Object.entries(form.values).forEach(([key, value]) => {
                if (key !== 'status') {
                    formData.append(key, value)
                }
                else if (key === 'ethnicity') {
                    formData.append(key, Number(value))
                }
            })
            return fn(formData, form)
        }
    }

    function applyFilter(filters) {
        setIsFilterApplied(true)
        if (!Object.values(filters).every(filter => filter.length === 0)) {
            dispatch(setEmployeeFilters(filters))
        }
    }

    function clearFilters(filters) {
        setIsFilterApplied(false)
        dispatch(setEmployeeFilters(filters))
    }

    async function uploadEmployeesCSVFile() {
        if (employeeCSVFile) {
            setUploadFileLoading(uploadFileLoading => !uploadFileLoading)
            const formData = new FormData()
            formData.append('file', employeeCSVFile)
            const res = await dispatch(uploadEmlpoyeeTemplate(formData))
            if (uploadEmlpoyeeTemplate.fulfilled.match(res)) {
                await dispatch(fetchAllEmployees())
                setOpenCSVModal(false)
                setEmployeeCSVFile(null)
                window.toast("Employees updated successfully")
            } else if (uploadEmlpoyeeTemplate.rejected.match(res)) {
                window.toast("Error uploading file", "error")
            }
            setUploadFileLoading(uploadFileLoading => !uploadFileLoading)
        } else {
            window.toast("Please select a file to upload", "error")
        }
    }

    function formatFileSize(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

        return parseFloat((bytes / Math.pow(1024, i)).toFixed(decimals)) + ' ' + sizes[i];
    }

    // function setExportCSVData(employee = {}, isChecked = false, selectAll = false) {
    //     if (!selectAll && Object.keys(employee).length > 0) {
    //         if (isChecked)
    //             setSelectedEmployees(selectedEmployees => {
    //                 return [...selectedEmployees, employee]
    //             })
    //         else
    //             setSelectedEmployees(selectedEmployees => {
    //                 return [...selectedEmployees.filter(selectedEmployee => selectedEmployee.id !== employee?.id)]
    //             })
    //     }
    //     else if (!selectAll && Object.keys(employee).length === 0) {
    //         setSelectedEmployees([])
    //     }
    //     else {
    //         setSelectedEmployees(employees)
    //     }
    // }

    function exportCSV() {
        const headers = Object.values(EMPLOYEE_TABLE_HEADERS).map(headerValue => headerValue.label !== 'Actions' ? headerValue?.label : null).join(',')
        const data = employees.map((selectedEmployee) => {
            if (selectedEmployee.selected) {
                return Object.values(EMPLOYEE_TABLE_HEADERS).map((headerValue) => {
                    if (headerValue.value === 'tier')
                        return selectedEmployee[headerValue.value] ? EMPLOYEE_TIER[selectedEmployee[headerValue.value]] : selectedEmployee[headerValue.value]
                    else if (headerValue.value === 'rx_non_rx')
                        return selectedEmployee[headerValue.value] ? EMPLOYEE_PRESCRIPTION[selectedEmployee[headerValue.value]] : selectedEmployee[headerValue.value]
                    else if (headerValue.value === 'status')
                        return selectedEmployee[headerValue.value] ? EMPLOYEE_STATUS[selectedEmployee[headerValue.value]] : selectedEmployee[headerValue.value]
                    return selectedEmployee[headerValue.value]
                }).join(",")
            }
        }).join("\n")
        const blob = new Blob([`${headers}\n${data}`], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'employees.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    function changePage(page) {
        dispatch(updateCurrentPage(page))
        dispatch(fetchAllEmployees(page))
    }

    function toggleMessageEmployeeFlag() {
        setMessageEmployee(flag => !flag)
    }

    function selectEmployee(id) {
        const updatedEmployees = [...employees].map((emp) => {
            if (emp.id === id) {
                return {
                    ...emp,
                    selected: !emp.selected,
                };
            } else {
                return emp
            }
        });
        dispatch(setEmployees(updatedEmployees));
    }

    function selectAllEmployees(checked) {
        const updatedEmployees = [...employees].map((emp) => {
            return {
                ...emp,
                selected: checked,
            };
        });
        dispatch(setEmployees(updatedEmployees));
    }

    return (
        <>
            <ModalComp
                open={openCSVModal}
                setOpen={setOpenCSVModal}
                title={
                    <>
                        <p className={modalClasses.modalTitle}>Upload CSV</p>
                        <p className={modalClasses.modalDesc}>Description goes here.</p>
                    </>
                }
            >
                <div className={classes.modalContent}>
                    <Stack gap={6}>
                        <Box style={{ borderStyle: 'dashed', borderRadius: '8px', borderColor: '#CED4DA', borderWidth: "2px" }}>
                            <Dropzone
                                onDrop={(file) => { setEmployeeCSVFile(file ? file[0] : null) }}
                                onReject={(files) => console.log('rejected files', files)}
                                maxSize={80 * 1024 ** 2}
                                accept={[MIME_TYPES.csv, MIME_TYPES.xls, MIME_TYPES.xlsx]}
                            >
                                <Group justify="center" gap="xl" mih={220} style={{ pointerEvents: 'none' }}>
                                    <Stack>
                                        <Dropzone.Idle mx="auto" >
                                            <Image src={uploadDocumentIcon} w={90} fit="contain" />
                                        </Dropzone.Idle>

                                        <div>
                                            <Text c="#5C5F66" size="14px" inline align='center'>
                                                Drag and drop file here
                                            </Text>
                                            <Text c="#5C5F66" size="14px" inline mt={7}>
                                                or <strong>select a file from your computer</strong>
                                            </Text>
                                        </div>
                                    </Stack>
                                </Group>
                            </Dropzone>
                        </Box>
                        {employeeCSVFile ?
                            <Card withBorder={true} radius="8" p={12}>
                                <Stack>
                                    <Flex
                                        justify="flex-start"
                                        align="center"
                                    >
                                        <Group justify="flex-start" gap="6px" align='center'>
                                            <Image src={excelLogo} w={36} h={36} fit="contain"></Image>
                                            <Stack gap={6}>
                                                <Text size="10px"><strong>{employeeCSVFile?.name}</strong></Text>
                                                <Text size="9px" c="dimmed"><strong>{formatFileSize(employeeCSVFile?.size)}</strong></Text>
                                            </Stack>
                                        </Group>
                                        <Button ml="auto" variant="filled" color="rgba(241, 243, 245)" onClick={() => { setEmployeeCSVFile(null) }} >
                                            <IconTrash color='#000' size={14} />
                                        </Button>
                                    </Flex>
                                </Stack>
                            </Card>
                            :
                            <></>

                        }
                        <Button disabled={uploadFileLoading} bg="#3354F4" color="#ffffff" size="sm" radius={6} onClick={uploadEmployeesCSVFile}>
                            <Group>
                                Upload
                                {uploadFileLoading ? <Loader size="sm" color="#b0b6bd" type='dots' /> : <></>}
                            </Group>
                        </Button>
                    </Stack>

                </div>
            </ModalComp>
            <CommonDrawer
                size={740}
                open={openAddEmployeeDrawer}
                onClose={() => {
                    setOpenAddEmployeeDrawer(false)
                    setUserProfileImageUrl('')
                    form.reset()
                    form.clearErrors()
                    form.clearFieldError()
                }}
                withCloseButton={false}
            >
                <Box px={24} py={12}>
                    <Group justify="space-between" align="center">
                        <Title order={4}>{employeeId ? "Edit Employee" : "Create New Employee"}</Title>
                        <Group gap={10}>
                            <SecondaryButton
                                title="Cancel"
                                onClick={() => {
                                    setOpenAddEmployeeDrawer(false)
                                    setUserProfileImageUrl('')
                                    form.reset()
                                    form.clearErrors()
                                    form.clearFieldError()
                                }} />
                            <PrimaryButton
                                title={isEditProfile ? "Save changes" : "Add user and invite"}
                                fallback={loading ? <Loader size="sm" color="white" type='dots' /> : null}
                                id={classes.upload}
                                onClick={() => {
                                    if (isEditProfile) {
                                        editEmployeeData()
                                    } else {
                                        addEmployee()
                                    }
                                }}
                            />
                        </Group>
                    </Group>
                </Box>
                <Divider orientation="horizontal" />
                <Box pt={12}>
                    <Tab defaultValue="userInfo" tabsArray={tabs} style={{ justifyContent: "center", alignItems: "center" }}>
                        <Tabs.Panel value="userInfo">
                            <Stack gap={20} p={24}>
                                <ProfileImageUpload mb={20} profileImageURL={userProfileImageUrl} updateImage={(image) => { updateFormValues('image', image) }} deleteImage={() => { updateFormValues('image', '') }} />
                                <Grid gutter={20}>
                                    <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                                        <InputField
                                            label={<Text fz={16} fw={500}>Name</Text>}
                                            rightIcon={editIcon}
                                            placeholder="Please enter name"
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}
                                            key={form.key("name")}
                                            {...form.getInputProps("name")}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                                        <SelectInputField
                                            data={ETHNICITY}
                                            label={<Text fz={16} fw={500}>Ethnicity</Text>}
                                            placeholder="Please enter ethnicity"
                                            rightSection={selectIcon}
                                            key={form.key("ethnicity")}
                                            {...form.getInputProps("ethnicity")}
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}
                                            defaultValue={null}
                                        />
                                    </Grid.Col>
                                </Grid>
                                <Grid gutter={20}>
                                    <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                                        <InputField
                                            label={<Text fz={16} fw={500}>Age</Text>}
                                            rightSection={editIcon}
                                            placeHolder="Please enter age"
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}

                                            key={form.key("age")}
                                            {...form.getInputProps("age")}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                                        <SelectInputField
                                            data={GENDER_CHOICES}
                                            label={<Text fz={16} fw={500}>Gender</Text>}
                                            rightSection={selectIcon}
                                            placeHolder="Please select gender"
                                            defaultValue={form.values.gender}
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}

                                            key={form.key("gender")}
                                            {...form.getInputProps("gender")}
                                        />
                                    </Grid.Col>
                                </Grid>
                                <Grid>
                                    <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                        <InputField
                                            label={<Text fz={16} fw={500}>Email</Text>}
                                            rightIcon={editIcon}
                                            placeHolder="Please enter email"
                                            key={form.key("email")}
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}

                                            {...form.getInputProps("email")}
                                        />
                                    </Grid.Col>
                                </Grid>
                                <Grid>
                                    <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                        <SelectInputField
                                            data={tierList?.length > 0 ? tierList.map((tiers) => ({ value: tiers.id, label: tiers.name })) : [{ value: "No Tiers Found Please Create Tiers" }]}
                                            label={<Text fz={16} fw={500}>Tier assignment</Text>}
                                            placeHolder="Please select tier"
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}
                                            key={form.key("tier")}
                                            {...form.getInputProps("tier")}
                                        />
                                    </Grid.Col>
                                </Grid>
                                <Grid>
                                    <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                        <InputField
                                            label={<Text fz={16} fw={500}>Site/Location</Text>}
                                            placeHolder="Please enter location"
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}

                                            key={form.key("address")}
                                            {...form.getInputProps("address")}
                                        />
                                    </Grid.Col>
                                </Grid>
                                {isEditProfile ?
                                    <>
                                        <Grid >
                                            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                                <Stack gap={10}>
                                                    <Text
                                                        style={{
                                                            font: 'Roboto',
                                                            fontSize: '14px',
                                                            fontWeight: '600',
                                                            color: '#495057'
                                                        }}>
                                                        Status
                                                    </Text>
                                                    <Group
                                                        justify='flex-around'
                                                        align="center"
                                                        px={12}
                                                        style={{
                                                            width: '100%',
                                                            height: '38px',
                                                            borderRadius: '6px',
                                                            border: '1px',
                                                            borderColor: '#CED4DA',
                                                            borderStyle: 'solid'
                                                        }}>
                                                        <Box mr='auto'>
                                                            {EMPLOYEE_STATUS_BLOCK[form.values.status]}
                                                        </Box>
                                                        <Group ml='auto' color="#3354F4" gap={8} justify='flex-end' onClick={sendInvitation} style={{ cursor: 'pointer' }}>
                                                            <IconReload color="#3354F4" style={{ width: '14px', height: '14px' }} />
                                                            <Text c="#3354F4" style={{ fontSize: '14px' }}>
                                                                <strong>Resend invite</strong>
                                                            </Text>
                                                        </Group>
                                                    </Group>
                                                </Stack>
                                            </Grid.Col>
                                        </Grid>
                                        <Grid >
                                            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                                <Group justify='flex-end' align='center'>
                                                    <Button variant="light" color="#C92A2A" radius="md" style={{ background: '#FFC9C9' }} onClick={deleteEmployee}>Delete User</Button>
                                                    <Button color="#3354F4" radius="md" onClick={toggleMessageEmployeeFlag}>Message employee</Button>
                                                </Group>
                                            </Grid.Col>
                                        </Grid>
                                    </>
                                    : null
                                }
                            </Stack>
                        </Tabs.Panel>
                    </Tab>
                </Box>
            </CommonDrawer>
            <CommonDrawer
                open={openMeasurementDrawer}
                onClose={() => setOpenMeasurementDrawer(false)}
                withCloseButton={false}
                size={900}
            >
                <Box px={24} py={12}>
                    <Group justify="space-between" align="center">
                        <Title order={4}>Measurement Detail</Title>
                        <Button h={32} size="sm" bg="#E9ECEF" c="black" fw="lighter" bd="2px solid #ADB5BD" radius={6} onClick={() => setOpenMeasurementDrawer(false)}>Cancel</Button>
                    </Group>
                </Box>
                <Divider orientation="horizontal" />
                <Box p={24}>
                    {measurementsData?.length > 0 ? <TableComp
                        tableHeaders={EMPLOYEE_MEASUREMENT_TABLE_HEADERS}
                        data={measurementsData?.length > 0 ? measurementsData : []}
                        isCheckBox={true}
                    /> : (
                        <Box p={40}>
                            <Title order={3} ta="center" fw="normal">No data found</Title>
                        </Box>
                    )}
                </Box>
            </CommonDrawer>
            <Container fluid style={{ height: '100%' }}>
                <Stack gap={20} style={{ height: '100%' }}>
                    <Group justify='space-between' mt={24}>
                        <Group gap={10}>
                            <TextInput
                                leftSection={searchIcon}
                                placeholder="Search"
                                className={classes.input}
                                rightSection={searchLoading ? searchLoader : ''}
                                onChange={(event) => searchEmployee(event.target.value)}
                                miw={332}
                            />
                            <FilterDropdown applyFilter={applyFilter} clearFilters={clearFilters} />
                        </Group>
                        <Flex gap={10}>
                            {employees?.some((f) => f.selected) > 0 ? (
                                <PrimaryButton2 title="Export CSV" onClick={exportCSV} disabled={employees?.some((f) => f.selected) ? false : true} />
                            ) : (
                                <SecondaryButton title="Export CSV" disabled={true} />
                            )}
                            <PrimaryButton title="Upload CSV" onClick={() => setOpenCSVModal(true)} />
                            <PrimaryButton title="Add new employee" onClick={() => openAddEmployee()} />
                        </Flex>
                    </Group>
                    <Stack gap={120}>
                        <TableComp
                            openAddEmployeeDrawer={openAddEmployeeDrawer}
                            setOpenAddEmployeeDrawer={setOpenAddEmployeeDrawer}
                            tableHeaders={EMPLOYEE_TABLE_HEADERS}
                            data={serailizeEmployeesData()}
                            isFilterApplied={isFilterApplied}
                            fetchEmployeeLoader={employeeLoading}
                            // setExportCSVData={setExportCSVData}
                            totalRecords={totalEmployees}
                            totalPages={totalPages}
                            changePage={changePage}
                            onChange={selectEmployee}
                            onChange2={(checked) => selectAllEmployees(checked)}
                            isCheckBox={true}
                            showPagination={true}
                            height='250px'
                        />
                    </Stack>
                </Stack>
            </Container>
        </>
    )
}

export default EmployeeManagement;