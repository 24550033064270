import { AppShell, Box, Group, Text, Image, Stack, Divider, Burger, CloseButton } from "@mantine/core"
import { Outlet, useLocation, useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import BaseHeader from "./BaseHeader"
import BaseSidebar from "./BaseSidebar"
import { IconChevronDown } from "@tabler/icons-react"
import './BaseSidebar.css'
import OrganizationListMenu from "../Menu/OrganizationListMenu/OrganizationListMenu"
import { useEffect, useState } from "react"
import { fetchOrganizationAdmins, fetchOrganizationAdminsById, fetchPartnerOrganization } from "../../middlewares/partner/partners.services"
import { fetchCities, fetchCountries, fetchOrganizationList, fetchOrganizations } from "../../middlewares/organization/organization"
// import { fetchOrganizations } from './middlewares/organization/organization';
import { fetchUser, getProfileCompletion } from "../../middlewares/auth/auth"
import { useDisclosure, useMediaQuery } from "@mantine/hooks"
import { fetchAllEmployees, fetchEyewearLists, getMeasurementByEmployee } from "../../middlewares/employee/employee.service"

import hoyaFooterImage from '../../assets/images/hoya-sidebar-footer-image.png';
import safeVision from '../../assets/icons/safety-vision-2.svg';
import sideFooterBg from '../../assets/icons/side-footer-bg.svg';
import srxLogo from '../../assets/icons/srx-logo.svg';
import sitrapLogo from '../../assets/icons/sitrap-logo.svg';
import sitrapLogo2 from '../../assets/icons/sitrap-logo-2.svg';
import employeeImage from '../../assets/images/employee.png'
import { getMyPrescriptions } from "../../middlewares/prescriptions/prescriptions.services"
import { getTiersList } from "../../middlewares/common/common.services"
import { fetchTotalEmployeesStats } from "../../middlewares/admin/statistics"
import { fetchSafetyAwards } from "../../middlewares/admin/safetyAwards"
import { getAddresses } from "../../middlewares/employee/address.service"

const BaseLayout = () => {

    const [opened, { toggle }] = useDisclosure();
    const dispatch = useDispatch()

    const [showOrganizations, setShowOrganizations] = useState(false)
    const [showOrganizationListMenu, setShowOrganizationListMenu] = useState(false)

    const isSmallScreen = useMediaQuery("(max-width: 768px)");
    const organization = useSelector(state => state.organizations.organization)
    const user = useSelector(state => state.login.user)
    const organizationList = useSelector(state => state.organizations.organizationList)
    const adminOrganization = useSelector((state) => state.organizations.organization)
    const selectedOrganization = useSelector((state) => state.organizations.selectedOrganization)
    const location = useLocation()
    const { id } = useParams()

    useEffect(() => {
        if (user?.profile?.role === "admin") {
            if (adminOrganization?.id) {
                dispatch(getTiersList({ organization_id: adminOrganization?.id }))
            }
        }
        if (user?.profile?.role === "partners") {
            if (selectedOrganization?.id) {
                dispatch(getTiersList({ organization_id: selectedOrganization?.id }))
            }
        }
    }, [adminOrganization, selectedOrganization])

    useEffect(() => {
        dispatch(fetchUser())
        if (user?.role === 'employee') {
            dispatch(getMyPrescriptions())
            dispatch(getMeasurementByEmployee(user?.pk))
            dispatch(getProfileCompletion())
            dispatch(fetchOrganizations())
            dispatch(fetchEyewearLists())
            dispatch(fetchCities());
            dispatch(fetchCountries())
            dispatch(getAddresses())
        }
        if (user?.role === 'admin') {
            if (adminOrganization?.id) {
                dispatch(fetchTotalEmployeesStats(adminOrganization?.id))
            }
            dispatch(fetchAllEmployees(1))
            dispatch(fetchSafetyAwards())
            dispatch(fetchOrganizations())
            dispatch(fetchOrganizationList())
        }
        if (user?.role === "partner") {
            dispatch(fetchPartnerOrganization())
            dispatch(fetchOrganizationList())
        }
    }, [])

    useEffect(() => {
        if (user?.role === "partner") {
            if (location.pathname === '/partner/company-management') {
                dispatch(fetchOrganizationAdmins())
            } else {
                dispatch(fetchOrganizationAdminsById(id))
            }
        }
    }, [location.pathname])

    function openOrganization() {
        setShowOrganizations(showOrganizations => !showOrganizations)
    }

    function openOrganizationListMenu() {
        setShowOrganizationListMenu(showOrganizationMenuList => !showOrganizationMenuList)
    }

    function renderFooter() {
        return <Box pos="relative" w="100%">
            <Box pos="absolute" top="50%" left="50%" w="100%" ta="center" style={{ transform: "translate(-50%, -50%)" }}>
                {user?.role === "partners" ? (
                    <>
                        <Image w={150} src={sitrapLogo2} alt="" styles={{ root: { margin: "auto", display: "block" } }} />
                        <Image my={10} w={150} src={safeVision} alt="" styles={{ root: { margin: "auto", display: "block" } }} />
                        <Text c="#FFFFFF" fw={500}>v.2.0.1</Text>
                    </>
                ) : user?.role === "admin" ? (
                    <>
                        <Image w={150} mt={64} src={sitrapLogo} alt="" styles={{ root: { margin: "auto", display: "block" } }} />
                        <Text mt={32} c="#FFFFFF" fw={500}>v.2.0.1</Text>
                    </>
                ) : (
                    <>
                        <Image w={150} src={safeVision} alt="" styles={{ root: { margin: "auto", display: "block" } }} />
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }} my={10}>
                            <Text c="#FFFFFF" fw={500}>powered by</Text>
                            <Image src={srxLogo} alt="" ml={6} />
                        </Box>
                        <Text c="#FFFFFF" fw={500}>v.2.0.1</Text>
                    </>
                )}
            </Box>
            <Image src={sideFooterBg} alt="" fit="contain" />
        </Box>
    }

    return (
        <AppShell
            layout="alt"
            header={{ height: 60 }}
            navbar={{
                width: 250,
                breakpoint: 'sm',
                collapsed: { mobile: !opened },
            }}
            style={{ overflow: 'hidden' }}
        >

            <AppShell.Header px={24}>
                <BaseHeader opened={opened} toggle={toggle} />
            </AppShell.Header>

            <AppShell.Navbar bg="#212529" withBorder={false} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <AppShell.Section style={{ flexShrink: 0 }}>
                    {user?.role === "employee" ? (
                        <>
                            {organization && (
                                <Box h={60} mb="auto" px="md" className="company-selection-menu">
                                    <Box display="flex" ml={8}>
                                        <Image src={organization?.logo} fit="contain" w={24} h={24} />
                                        <Text ml={6} c="white" fw={600} fz={16} mr={15} styles={{ root: { fontFamily: "Manrope" } }}>
                                            {organization?.name}
                                        </Text>
                                    </Box>
                                </Box>
                            )}
                            <Box h={60} mb="auto" px="md" className="company-selection-menu">
                                <Box display="flex" ml={8}>
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <Image radius={2} fit="contain" w={24} h={24} src={user?.profile?.image === null ? employeeImage : user?.profile?.image} />
                                        <Text styles={{ root: { fontFamily: "Manrope" } }} ml={6} c="white" fw={500} fz={16} mr={15}>{user?.profile?.name}</Text>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <Box h={60} mb="auto" px="md" className="company-selection-menu">
                            <Box display="flex" ml={8}>
                                <Image src={organization?.logo} fit="contain" w={24} h={24} />
                                <Box style={{ display: "flex", alignItems: "center" }}>
                                    <Text styles={{ root: { fontFamily: "Manrope" } }} ml={6} c="white" fw={500} fz={16} mr={15}>{organization?.name ? organization?.name : user?.profile?.name}</Text>
                                    {user?.role === "partner" && (
                                        <IconChevronDown
                                            size={16}
                                            color="white"
                                            onClick={user?.role === "admin" ? null : openOrganization}
                                            className={showOrganizations ? "icon-chevron-rotated" : "icon-chevron"}
                                        />
                                    )}
                                </Box>
                            </Box>
                            {opened && isSmallScreen ? <CloseButton m={5} onClick={opened && toggle} /> : null}
                        </Box>
                    )}
                </AppShell.Section>

                {
                    user?.role === "partner" ? organizationList?.length > 0 ? (
                        showOrganizations ? (
                            <AppShell.Section mt={20} px='sm' grow>
                                <BaseSidebar />
                            </AppShell.Section>
                        ) : (
                            <AppShell.Section grow style={{ flexGrow: 1, overflowY: "auto" }}>
                                <OrganizationListMenu showOrganizationListMenu={showOrganizationListMenu} openOrganizationListMenu={openOrganizationListMenu} />
                            </AppShell.Section>
                        )
                    ) : (
                        <AppShell.Section mt={20} px='sm' grow>
                            <BaseSidebar />
                        </AppShell.Section>
                    ) : null
                }

                {
                    (user?.role === "admin" || user?.role === "employee") && (
                        <AppShell.Section mt={20} px='sm' grow>
                            <BaseSidebar />
                        </AppShell.Section>
                    )
                }

                <AppShell.Section style={{ flexShrink: 0, marginTop: "auto" }}>
                    {renderFooter()}
                </AppShell.Section>

            </AppShell.Navbar >

            <AppShell.Main style={{ overflow: 'auto' }}>
                <Outlet />
            </AppShell.Main>

        </AppShell >
    )
}

export default BaseLayout;