import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    fetchingAddress: false,
    address: [],
};

const addressSlice = createSlice({
    name: "address",
    initialState,
    reducers: {
        setFetchingAddress: (state, {payload}) => {
            state.fetchingAddress = payload
        },
        setLoading: (state, { payload }) => {
            state.loading = payload
        },
        setAddress: (state, { payload }) => {
            state.address = payload
        },
        addAddress: (state, action) => {
            const idNotExist = !state.address[state.address?.length - 1]?.id
            const id = idNotExist ? 1 : state.address[state.address?.length - 1]?.id + 1
            const myAddress = { ...action.payload, id }
            state.address = [...state.address, myAddress]
        },
        deleteAddress: (state, action) => {
            const newArr = [...state.address];
            const findIndex = state.address.findIndex((address) => address.id === action.payload)
            if (findIndex !== -1) {
                newArr.splice(findIndex, 1)
                state.address = newArr
            }
        },
        selectAddress: (state, action) => {
            state.address = state.address.map((address) => ({ ...address, isSelected: address.id === action.payload }))
        },
        updateAddress: (state, action) => {
            const newArr = [...state.address];
            const findIndex = state.address.findIndex((address) => address.id === action.payload.id)
            if (findIndex !== -1) {
                newArr[findIndex] = action.payload
                state.address = newArr
            }
        },
    },
});

export const {
    setLoading,
    setFetchingAddress,
    addAddress,
    setAddress,
    deleteAddress,
    selectAddress,
    updateAddress
} = addressSlice.actions;
export default addressSlice.reducer;