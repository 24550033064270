import React, { useState } from 'react'
import classes from '../Button/button.module.css'
import billingClass from './billing.module.css';
import { Box, Button, Divider, Grid, Group, Loader, Stack, Title, Text, Flex } from '@mantine/core';
import PaymentCard from './PaymentCard';
import TaxDetailForm from './TaxDetailForm';
import { useMediaQuery } from '@mantine/hooks';
import { CommonDrawer } from '../Drawer/Drawer';
import { useForm } from '@mantine/form';
import { PrimaryButton, SecondaryButton } from '../ui/Button/Button';
import { DateInputField, InputField, SelectInputField } from '../ui/Input/Input';

import { COUNTRIES } from '../../choices/employee.choice';
import { IconChevronDown } from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { setPaymentMethods } from '../../store/billing/billingSlice';

const PaymentMethod = () => {

  const dispatch = useDispatch()
  const [selectPaymentMethod, setSelectPaymentMethod] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false)
  const [loading, setLoading] = useState(false)
  const paymentMethods = useSelector((state) => state.billing.paymentMethods)
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const isLaptopLarge = useMediaQuery("(max-width: 1440px)");

  // const form = useForm({
  //   initialValues: {
  //     expDate: null,
  //     cvv: null,
  //     cardNumber: null,
  //     companyName: '',
  //     phoneNumber: null,
  //     reicipientEmail: '',
  //     address: '',
  //     zipCode: null,
  //     city: '',
  //     country: '',
  //   },
  //   validate: {
  //     expDate: (val) => val ? null : 'Please select exp date',
  //     cvv: (val) => {
  //       const cvvRegex = /^\d{3,4}$/;
  //       return cvvRegex.test(val) ? null : 'Please enter a valid CVV';
  //     },
  //     cardNumber: (val) => {
  //       if (!val) {
  //         return "Please enter card number";
  //       }
  //       if (!/^\d{16}$/.test(val)) {
  //         return 'Card number must be 16 digits'
  //       }
  //       const sanitizedValue = val.replace(/\D/g, "");
  //       if (!/^\d{16}$/.test(sanitizedValue)) {
  //         return "Invalid card number. Only Visa and MasterCard are allowed.";
  //       }
  //       form.setFieldValue('cardNumber', sanitizedValue.replace(/(\d{4})(?=\d)/g, "$1 ").trim())
  //       return null;
  //     },
  //   }
  // })

  const form = useForm({
    initialValues: {
      expDate: null,
      cvv: null,
      cardNumber: '',
      companyName: '',
      phoneNumber: '',
      recipientEmail: '',
      address: '',
      zipCode: null,
      city: '',
      country: '',
    },
    validate: {
      companyName: (val) => (val ? null : "Please enter your company name"),
      expDate: (val) => (val ? null : 'Please select expiration date'),
      cvv: (val) => {
        const cvvRegex = /^\d{3,4}$/;
        return cvvRegex.test(val) ? null : 'Please enter a valid CVV (3 or 4 digits)';
      },
      cardNumber: (val) => {
        if (!val) {
          return "Please enter the card number";
        }
        return null;
      },
      phoneNumber: (val) => {
        const phoneRegex = /^\+351 \d{3} \d{3} \d{3}$/;
        return phoneRegex.test(val) ? null : "Phone number must be in the format +351 911 573 056";
      },
      recipientEmail: (val) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(val) ? null : "Please enter a valid email address";
      },
      address: (val) => (val ? null : "Please enter your address"),
      zipCode: (val) => {
        const zipRegex = /^\d{4,6}$/; // Allows 4 to 6 digits for zip codes
        return zipRegex.test(val) ? null : "Please enter a valid zip code (4 to 6 digits)";
      },
      city: (val) => (val ? null : "Please enter your city"),
      country: (val) => (val ? null : "Please select your country"),
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.validate().hasErrors) {
      form.validate()
    }
    else {
      const payload = {
        id: !paymentMethods[paymentMethods?.length - 1]?.id ? 1 : paymentMethods[paymentMethods?.length - 1]?.id + 1,
        ...form.values
      }
      dispatch(setPaymentMethods(payload))
    }
  };

  return (
    <>
      <CommonDrawer
        size={740}
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
        }}
        withCloseButton={false}
      >
        <Box px={24} py={12}>
          <Group justify="space-between" align="center">
            <Title order={4}>Create new payment method</Title>
            <SecondaryButton
              title="Cancel"
              onClick={() => {
                setOpenDrawer(false)
                form.clearErrors()
                form.reset()
              }}
            />
          </Group>
        </Box>
        <Divider orientation="horizontal" />
        <Box p={24}>
          <form onSubmit={handleSubmit}>
            <Stack gap={20}>
              <Text c="#0B1039" fz={20} fw={600}>Card info</Text>
              <Grid gutter={20}>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <DateInputField
                    radius={6}
                    label={<Text fz={16} fw={500}>Exp.Date</Text>}
                    placeholder="Exp date"
                    key={form.key("expDate")}
                    {...form.getInputProps("expDate")}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <InputField
                    type="number"
                    radius={6}
                    label={<Text fz={16} fw={500}>CVV</Text>}
                    placeholder="***"
                    {...form.getInputProps("cvv")}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, '');
                      if (value.length <= 3) {
                        form.setFieldValue('cvv', value);
                      }
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <InputField
                    type="number"
                    radius={6}
                    label={<Text fz={16} fw={500}>Card Number</Text>}
                    placeholder="xxxx xxxx xxxx xxxx"
                    {...form.getInputProps("cardNumber")}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, '');
                      if (value.length <= 16) {
                        form.setFieldValue('cardNumber', value);
                      }
                    }}
                  />
                </Grid.Col>
              </Grid>
            </Stack>
            <Stack gap={20} mt={24} mb={33}>
              <Text c="#0B1039" fz={20} fw={600}>Tax details</Text>
              <Grid gutter={20}>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <InputField
                    radius={6}
                    label={<Text fz={16} fw={500}>Company Name</Text>}
                    placeholder="Enter your company name"
                    key={form.key("companyName")}
                    {...form.getInputProps("companyName")}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <InputField
                    radius={6}
                    label={<Text fz={16} fw={500}>Phone Number</Text>}
                    placeholder="Enter your phone number"
                    key={form.key("phoneNumber")}
                    {...form.getInputProps("phoneNumber")}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <InputField
                    radius={6}
                    label={<Text fz={16} fw={500}>Recipient Email</Text>}
                    placeholder="Enter your email"
                    key={form.key("recipientEmail")}
                    {...form.getInputProps("recipientEmail")}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <InputField
                    radius={6}
                    label={<Text fz={16} fw={500}>Address</Text>}
                    placeholder="Enter your address"
                    key={form.key("address")}
                    {...form.getInputProps("address")}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <InputField
                    type="number"
                    radius={6}
                    label={<Text fz={16} fw={500}>Zip / Postal code</Text>}
                    placeholder="Enter your zip/postal code"
                    {...form.getInputProps("zipCode")}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, '');
                      if (value.length <= 5) {
                        form.setFieldValue('zipCode', value);
                      }
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                  <InputField
                    radius={6}
                    label={<Text fz={16} fw={500}>City / District</Text>}
                    placeholder="Enter your city"
                    key={form.key("city")}
                    {...form.getInputProps("city")}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <SelectInputField
                    radius={6}
                    data={COUNTRIES}
                    label={<Text fz={16} fw={500}>Country</Text>}
                    rightSection={<IconChevronDown style={{ width: '16px', height: 'auto', marginRight: '2px' }} />}
                    placeholder="Enter your country"
                    key={form.key("country")}
                    {...form.getInputProps("country")}
                  />
                </Grid.Col>
              </Grid>
            </Stack>
            <Flex justify="flex-end">
              <PrimaryButton
                type="submit"
                title="Update details"
                fallback={loading ? <Loader size="sm" color="white" type='dots' /> : null}
              />
            </Flex>
          </form>
        </Box>
      </CommonDrawer>
      <Box className={billingClass.paymentMethodContainer}>
        <Box style={{ width: isLaptopLarge ? "509px" : "700px", padding: "24px 24px 24px 0" }}>
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <p className={billingClass.title} style={{ marginBottom: 0 }}>Payment Method</p>
            <Button id={classes.outline} onClick={() => setOpenDrawer(true)}>Add new</Button>
          </Box>
          <Box style={{ marginTop: "14px" }}>
            {paymentMethods?.map((payment, index) => <PaymentCard payment={payment} selectPaymentMethod={selectPaymentMethod} setSelectPaymentMethod={setSelectPaymentMethod} />)}
          </Box>
        </Box>
        <Divider orientation="vertical" h={isSmallScreen ? "93vh" : "100vh"} />
        <Box style={{ width: `calc(100% - ${isLaptopLarge ? '509px' : '700px'})`, padding: "24px" }}>
          <p className={billingClass.title} style={{ marginBottom: "24px" }}>Tax details</p>
          <TaxDetailForm />
        </Box>
      </Box>
    </>
  )
}

export default PaymentMethod;