import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Grid, Group, Loader, Menu, Skeleton, Text, TextInput } from '@mantine/core'
import user from '../../../assets/images/review-person.png'
import Ratings from '../../rating/rating'
import { useDispatch, useSelector } from 'react-redux'
import ProgressBar from '../../Progress/Progress'
import { IoIosArrowDown } from 'react-icons/io'
import { BsChatLeft } from "react-icons/bs";
import { TextAreaField } from '../../ui/Input/Input'
import { useForm } from '@mantine/form'
import { createFeedback, deleteFeedback, fetchEyewearDetailById, updateFeedback } from '../../../middlewares/employee/employee.service'
import { PrimaryButton } from '../../ui/Button/Button'
import { setProductDetail } from '../../../store/employess/productSlice'
import { TiTrash } from "react-icons/ti";
import { IconEdit, IconTrash, IconDotsCircleHorizontal, IconDotsVertical } from '@tabler/icons-react'
import { useParams } from 'react-router-dom'

const ProductReviews = () => {

  const { id } = useParams()
  const [feedback, setFeedback] = useState({
    comment: "",
    rating: null
  })
  const [editReviewId, setEditReviewId] = useState(null)
  const submitFeedbackLoading = useSelector((state) => state.product.submitFeedbackLoading)
  const loading = useSelector((state) => state.product.loading)
  const user = useSelector((state) => state.login.user)
  const variantDetail = useSelector((state) => state.product.productDetail);
  const dispatch = useDispatch()

  const newObj = { ...variantDetail?.total_ratings_distribution }
  const obj = Object.entries(newObj)
    .map(([key, value]) => ({
      label: key.replace(" ", "_"),
      value: value
    })).reduce((prev, curr) => {
      prev[curr.label] = curr.value;
      return prev;
    }, {});

  const rates = [
    { id: 1, rate: "Excellent", percent: obj?.Excellent || 0, },
    { id: 2, rate: "Good", percent: obj?.Good || 0, },
    { id: 3, rate: "Average", percent: obj?.Above_Average || 0, },
    { id: 4, rate: "Below Average", percent: obj?.Below_Average || 0, },
    { id: 5, rate: "Poor", percent: obj?.Poor || 0, },
  ];

  const handleSubmit = async () => {
    const payload = {
      glass_variant: variantDetail?.id,
      rating: feedback?.rating,
      review: feedback?.comment
    }
    const res = await dispatch(createFeedback(payload))
    if (res.type === "createFeedback/rejected") {
      console.log(res);
    } else {
      window.toast(`Feedback submitted successfully`)
      dispatch(fetchEyewearDetailById({ id: id, type: "1" }))
      setFeedback({
        comment: "",
        rating: null
      })
    }
  }

  const handleEdit = async () => {
    const updatedFeedback = variantDetail?.feedbacks?.find((feedback) => feedback.id === editReviewId.id)
    const payload = {
      user,
      glass_variant: variantDetail?.id,
      rating: updatedFeedback.rating,
      review: updatedFeedback.review
    }
    const res = await dispatch(updateFeedback({ id: editReviewId.id, payload }))
    if (res.type === "updateFeedback/fulfilled") {
      dispatch(fetchEyewearDetailById({ id: id, type: "1" }))
      setEditReviewId(null)
      window.toast(`Feedback updated successfully`)
    }
    console.log(res);
  }

  const handleCancelEdit = () => {
    setEditReviewId(null)
    const updatedFeedbacks = variantDetail?.feedbacks?.map((feedback) => {
      return {
        ...feedback,
        isSelected: false
      }
    })
    dispatch(setProductDetail({
      ...variantDetail,
      feedbacks: updatedFeedbacks
    }))
  }

  function handleSelectReview(obj) {
    setEditReviewId(obj)
    const updatedFeedbacks = variantDetail?.feedbacks?.map((feedback) => {
      if (feedback.id === obj.id) {
        return {
          ...feedback,
          isSelected: true
        }
      } else {
        return {
          ...feedback,
          isSelected: false
        }
      }
    })
    dispatch(setProductDetail({
      ...variantDetail,
      feedbacks: updatedFeedbacks
    }))
  }

  return (
    <Box style={{ fontFamily: "SF-pro-display-medium" }}>
      <Text fw="bold" mb={32}>Reviews</Text>
      <Grid gutter={32} grow="xl">
        <Grid.Col span={{ base: 12, md: 5, lg: 5 }}>
          <Box bg="#F8F9FA" style={{ borderRadius: "20px" }} px={32} py={22} mb={24}>
            <Group justify="space-between">
              <Text fz={50} fw="bold">{variantDetail?.average_rating?.toFixed(1) || 0}</Text>
              <Text c="#AEAEAF">of {variantDetail?.feedbacks?.length} reviews</Text>
              <Ratings readOnly={true} value={variantDetail?.average_rating || 0} fractions={variantDetail?.average_rating ? Math.floor(variantDetail?.average_rating) : 5} />
            </Group>
          </Box>
          <Box mb={48}>
            {rates.map((rate, index) => {
              return (
                <Group id={rate.id} justify="space-between" align="center" mb={24}>
                  <div style={{ width: '116px' }}>
                    <Text w={"auto"} c="black" fw="bold">{rate.rate}</Text>
                  </div>
                  <div style={{ width: 'calc(100% - 132px)', display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <ProgressBar color="#FD7E14" width={"100%"} value={rate.percent} />
                    <Text w={"auto"} c="#999999" fw="bold">{rate.percent}</Text>
                  </div>
                </Group>
              )
            })}
          </Box>
          <Flex justify="space-between" mt={4} mb={10}>
            <Text fz={20} fw={600}>Ratings: </Text>
            <Ratings value={feedback?.rating} onChange={(value) => setFeedback((prevState) => ({ ...prevState, rating: value === 0 ? null : value }))} />
          </Flex>
          <TextAreaField
            value={feedback.comment}
            onChange={(e) => setFeedback((prevState) => ({ ...prevState, comment: e.target.value }))}
            c="#DEE2E6"
            bd="1px solid #FFFFFF"
            placeholder="Leave Comment"
            size="lg"
            styles={{
              input: {
                height: "115px",
                fontSize: "14px",
              },
            }}
          />
          <Flex justify="space-between" mt={4} mb={20}>
            <Text fz={14} c="#999">{0}/{500} characters</Text>
            <PrimaryButton
              onClick={handleSubmit}
              title="Submit"
              disabled={submitFeedbackLoading || (feedback.comment === "" && feedback.rating === null)}
              fallback={submitFeedbackLoading && <Loader size="sm" color="white" type='dots' />}
              mt={10}
              w={120}
            />
          </Flex>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7, lg: 7 }} pos="relative">
          <Box style={{
            minHeight: "580px",
            maxHeight: "580px",
            overflowY: "auto",
            position: "relative"
          }}>
            {loading ? (
              <>
                <Skeleton width="100%" height={102} style={{ borderRadius: "10px" }} mb={5} />
                <Skeleton width="100%" height={102} style={{ borderRadius: "10px" }} mb={5} />
                <Skeleton width="100%" height={102} style={{ borderRadius: "10px" }} mb={5} />
                <Skeleton width="100%" height={102} style={{ borderRadius: "10px" }} mb={5} />
                <Skeleton width="100%" height={102} style={{ borderRadius: "10px" }} mb={5} />
              </>
            ) : variantDetail?.feedbacks?.length === 0 ? (
              <Box bg="#F8F9FA" style={{ borderRadius: "30px" }} py={24} px={16}>
                <Flex direction="column" justify="center" align="center" h={340} gap={10}>
                  <BsChatLeft color='grey' size={70} />
                  <Text c="grey" fz={20}>No Reviews Yet</Text>
                </Flex>
              </Box>
            ) : variantDetail?.feedbacks?.map((review, index) => {
              return (
                <>
                  <Box style={{ borderRadius: "30px", background: "#F8F9FA", backgroundImage: variantDetail?.feedbacks?.length - 1 === index && "linear-gradient(whitesmoke, white)" }} mb={20} py={24} px={16} pos="relative" key={review?.id}>
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                      <img style={{ width: "57px", height: "57px", objectFit: "cover", borderRadius: "100%" }} src={review.user?.profile?.image ? review.user?.profile?.image : user} alt="" />
                      <Box ml={10} w="100%">
                        <Group justify="space-between" mb={8}>
                          <Text fw={800}>{review?.user?.email}</Text>
                          <Text c="#C6C7C8" fw={600}>{review.createdAt}</Text>
                          <Menu shadow="md" width={200} position="bottom-end">
                            <Menu.Target>
                              <IconDotsVertical size={35} color='#212529' />
                            </Menu.Target>
                            <Menu.Dropdown>
                              <Menu.Item
                                onClick={() => handleSelectReview(review)}
                                leftSection={<IconEdit size={14} />}
                              >
                                Edit review
                              </Menu.Item>
                              <Menu.Item
                                onClick={async () => {
                                  const res = await dispatch(deleteFeedback(review.id))
                                  if (res.type === "deleteFeedback/fulfilled") {
                                    dispatch(fetchEyewearDetailById({ id: id, type: "1" }))
                                  }
                                }}
                                color="red"
                                leftSection={<IconTrash size={14} />}
                              >
                                Delete review
                              </Menu.Item>
                            </Menu.Dropdown>
                          </Menu>
                        </Group>
                        <Ratings
                          readOnly={editReviewId ? false : true}
                          value={review?.rating}
                          onChange={(e) => {
                            if (editReviewId) {
                              const updatedFeedbacks = variantDetail?.feedbacks?.map((feedback) => {
                                if (feedback.id === review.id) {
                                  return {
                                    ...feedback,
                                    rating: e
                                  }
                                } else {
                                  return feedback
                                }
                              })
                              dispatch(setProductDetail({
                                ...variantDetail,
                                feedbacks: updatedFeedbacks
                              }))
                            }
                          }}
                          mb={15}
                        />
                        {review?.isSelected ? (
                          <>
                            <TextAreaField
                              value={review?.review}
                              onChange={(e) => {
                                const updatedFeedbacks = variantDetail?.feedbacks?.map((feedback) => {
                                  if (feedback.id === review.id) {
                                    return {
                                      ...feedback,
                                      review: e.target.value
                                    }
                                  } else {
                                    return feedback
                                  }
                                })
                                dispatch(setProductDetail({
                                  ...variantDetail,
                                  feedbacks: updatedFeedbacks
                                }))
                              }}
                              c="#DEE2E6"
                              bd="1px solid #FFFFFF"
                              placeholder="Leave Comment"
                              size="lg"
                              styles={{
                                input: {
                                  height: "115px",
                                  fontSize: "14px",
                                },
                              }}
                            />
                            <Text fz={14} c="#999">{0}/{500} characters</Text>
                            <Flex justify="end" gap={10} mt={4} mb={20}>
                              <PrimaryButton
                                variant="outline"
                                onClick={handleCancelEdit}
                                title="Cancel"
                                mt={10}
                                w={120}
                              />
                              <PrimaryButton
                                onClick={handleEdit}
                                title="Submit"
                                fallback={submitFeedbackLoading && <Loader size="sm" color="white" type='dots' />}
                                mt={10}
                                w={120}
                              />
                            </Flex>
                          </>
                        ) : <Text c="#5C5F66" mt={8}>{review?.review}</Text>}
                      </Box>
                    </div>
                  </Box>
                </>
              )
            })}
          </Box>
          {variantDetail?.feedbacks?.length > 0 && (
            <Box style={{ textAlign: "center" }}>
              <Button w={208} h={48} variant="outline" bd="2px solid #545454" c="black" radius={10}>
                <Text mr={10}>View More</Text>
                <IoIosArrowDown size={20} />
              </Button>
            </Box>
          )}
        </Grid.Col>
      </Grid>
    </Box>
  )
}

export default ProductReviews;