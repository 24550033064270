import { Stack, Group, Image, Text, AppShell } from "@mantine/core"
import { IconBadge, IconChevronDown, IconUsers } from "@tabler/icons-react"
import { useSelector, useDispatch } from "react-redux"
import { useEffect } from "react"
import { clearOrganizations2, setOrganizationList, setOrganizationList2, setSelectedOrganization } from "../../../store/organization/organizationSlice"
import { getTiersList } from "../../../middlewares/common/common.services"
import { NavLink, useParams } from "react-router-dom"
import './OrganizationListMenu.css'
import { FaRegBuilding } from "react-icons/fa"

const OrganizationListMenu = () => {

    const dispatch = useDispatch();
    const { id } = useParams()
    const organizationList = useSelector((state) => state.organizations.organizationList);
    const selectedOrganization = useSelector((state) => state.organizations.selectedOrganization);

    const navLinks = [
        { id: 1, companyId: null, name: 'Tier Management', icon: <IconBadge size={16} />, link: '/partner/tier-management', isActive: false },
        { id: 2, companyId: null, name: 'Company Management', icon: <IconUsers size={16} />, link: `/partner/company-management`, isActive: false },
    ];

    const toggleOrganization = (organization) => {
        const updatedList = organizationList.map((org) => org.id === organization.id ? { ...org, isActive: !org.isActive } : { ...org, isActive: false });
        dispatch(setOrganizationList(updatedList))
        if (!organization.isActive) {
            dispatch(setSelectedOrganization(organization));
            dispatch(getTiersList({ organization_id: organization.id }));
        }
    };

    useEffect(() => {
        if (id !== undefined) {
            const foundOrganization = organizationList.filter((org) => org.id === id);
            console.log(foundOrganization);
            if (foundOrganization) {
                dispatch(clearOrganizations2());
                dispatch(setOrganizationList2(foundOrganization));
            }
        }
    }, [id]);

    return (
        <Stack gap={0} className="organization-menu">
            {organizationList.map((organization) => (
                <div key={organization.id}>
                    <Group
                        onClick={() => toggleOrganization(organization)}
                        justify="space-between"
                        className="menu-item"
                        px={15}
                        py={13}
                        styles={{ root: { borderTop: "1px solid #495057", cursor: "pointer" } }}
                    >
                        <Group gap={6}>
                            {organization?.logo ? (
                                <Image src={organization.logo} w={20} h={20} fit="contain" radius={6} />
                            ) : (
                                <FaRegBuilding size={20} color="grey" />
                            )}
                            <Text span fw={700} fz={12}>{organization.name}</Text>
                        </Group>
                        <IconChevronDown
                            style={{
                                transform: organization.isActive ? "rotate(180deg)" : "rotate(0deg)",
                                transition: "transform 0.3s ease",
                            }}
                            size={16}
                            color="white"
                        />
                    </Group>
                    {organization.isActive && (
                        <AppShell.Section my={10} px="sm" grow>
                            {navLinks.map((navigation) => {
                                return (
                                    <NavLink
                                        key={navigation.id}
                                        to={navigation.id === 2 && selectedOrganization ? `${navigation.link}/${selectedOrganization.id}` : navigation.link}
                                        className={({ isActive }) => isActive ? 'active' : 'navlink'}
                                    >
                                        <Group w={200} h={40} gap={8} py={12} justify="flex-start" align="center">
                                            {navigation.icon}
                                            <Text span fz={14} fw={400}>{navigation.name}</Text>
                                        </Group>
                                    </NavLink>
                                )
                            })}
                        </AppShell.Section>
                    )}
                </div>
            ))}
        </Stack>
    );
};

export default OrganizationListMenu;