import React from 'react'
import { Box, Card, Text } from '@mantine/core'
import classes from '../../Dashboard/card.module.css'

const HeaderCard = ({
    title,
    desc
}) => {
    return (
        <Card radius={16} className={classes.card} p={24} styles={{ root: { border: "2px solid #DEE2E6" } }}>
            <Text fw={600} fz={18}>{title}</Text>
            <Text c="#909296">{desc}</Text>
        </Card>
    )
}

const StatisticsCard = ({ icon, title, value, cardBg, iconBg, c, c2 }) => {
    return (
        <Card radius={16} bg={cardBg} className={classes.card} p={24} styles={{ root: { border: "2px solid #DEE2E6" } }}>
            <Box w={70} h={70} bg={iconBg} style={{
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {icon}
            </Box>
            <Text c={c} my={15} fz={18}>{title}</Text>
            <Text fw={900} fz={28} c={c2}>{value}</Text>
        </Card>
    )
}

export {
    HeaderCard,
    StatisticsCard
}