import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../services/api";
import { setSafetyAwards, setLoading, setLoading2 } from "../../store/safetyAwards/safetyAwardsSlice";

const apiInstance = apiClient(false);
const apiFormInstance = apiClient(true);

export const createSafetyAwards = createAsyncThunk(
    'createSafetyAwards',
    async (body, {
        rejectWithValue,
        dispatch,
        getState
    }) => {
        dispatch(setLoading2(true))
        try {
            const response = await apiFormInstance.post(`/api/safety-awards/programs/create/`, body)
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                dispatch(setSafetyAwards([...getState().safetyAwards.safetyAwards, { ...response.data }]))
            }
            dispatch(setLoading2(false))
            return response.data;
        } catch (error) {
            dispatch(setLoading2(false))
            return rejectWithValue(error);
        }
    }
)

export const updateSafetyAwards = createAsyncThunk(
    'updateSafetyAwards',
    async (body, {
        rejectWithValue,
        dispatch,
        getState
    }) => {
        dispatch(setLoading2(true))
        try {
            const response = await apiFormInstance.patch(`/api/safety-awards/programs/${body.id}/update/`, body.formData)
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                const updatedList = [
                    ...getState().safetyAwards.safetyAwards.map((list) => {
                        if (list.id === body.id) {
                            return response.data
                        }
                        else {
                            return list
                        }
                    })
                ]
                dispatch(setSafetyAwards(updatedList))
            }
            dispatch(setLoading2(false))
            return response.data;
        } catch (error) {
            dispatch(setLoading2(false))
            return rejectWithValue(error);
        }
    }
)

export const updateSafetyAwards2 = createAsyncThunk(
    'updateSafetyAwards',
    async (body, {
        rejectWithValue,
        dispatch,
        getState
    }) => {
        dispatch(setLoading2(true))
        try {
            const response = await apiInstance.patch(`/api/safety-awards/programs/${body.data.id}/update/`, body.data)
            dispatch(setLoading2(false))
            return response.data;
        } catch (error) {
            dispatch(setLoading2(false))
            return rejectWithValue(error);
        }
    }
)

export const deleteSafetyAwards = createAsyncThunk(
    'deleteSafetyAwards',
    async (id, {
        rejectWithValue,
        dispatch,
        getState
    }) => {
        dispatch(setLoading2(true))
        try {
            const response = await apiFormInstance.delete(`/api/safety-awards/programs/${id}/delete/`)
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                const updatedList = [...getState().safetyAwards.safetyAwards.filter((list) => list.id !== id)]
                dispatch(setSafetyAwards(updatedList))
            }
            dispatch(setLoading2(false))
            return response.data;
        } catch (error) {
            dispatch(setLoading2(false))
            return rejectWithValue(error);
        }
    }
)

export const fetchSafetyAwards = createAsyncThunk(
    'fetchSafetyAwards',
    async (_, {
        rejectWithValue,
        dispatch
    }) => {
        dispatch(setLoading(true))
        try {
            const response = await apiFormInstance.get(`/api/safety-awards/programs/list/`)
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                dispatch(setSafetyAwards(response.data))
            }
            dispatch(setLoading(false))
            return response.data;
        } catch (error) {
            dispatch(setLoading(false))
            return rejectWithValue(error);
        }
    }
)