import React, { useEffect } from 'react';
import classes from '../Steps/Steps.module.css';

const Steps = ({ activeStep, steps, setSteps, width }) => {

    useEffect(() => {
        setSteps((prevSteps) => {
            return prevSteps.map((stp) => {
                if (stp.id <= activeStep) {
                    return {
                        ...stp,
                        active: true
                    }
                }
                else {
                    return {
                        ...stp,
                        active: false
                    }
                }
            })
        });
    }, [activeStep]);

    return (
        <div style={{ display: width < 768 && "flex", width: width < 991 && "100%", overflowX: width < 768 && "auto", margin: width < 991 ? "0 0 50px" : "0 0 40px", marginBottom:'40px !important', }}>
            {steps.map((stp, index) => {
                const { id, Icon, active, step, desc } = stp;
                return (
                    <div className={classes.stepContainer} style={{ width: width < 991 ? "max-content" : "", marginRight: width < 991 && "50px" }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <div style={{ backgroundColor: active === true && "#3354F4" }} className={classes.step} key={id}>
                                <Icon color='white' size={20} />
                            </div>
                            {(width > 767 && index !== steps.length - 1) ? (
                                <div style={{ backgroundColor: active === true ? "#3354F4" : "#F8F9FA", width: "3px", height: "20px", margin: "15px 0" }}></div>
                            ) : null}
                        </div>
                        <div className={classes.stepTitleDesc}>
                            <p className={classes.title} style={{ width: width < 991 ? "max-content" : "" }}>{step}</p>
                            <p className={classes.desc} style={{ width: width < 991 ? "max-content" : "" }}>{desc}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

export default Steps;
