import { createSlice } from "@reduxjs/toolkit";
import { fetchTotalEmployeesStats } from "../../middlewares/admin/statistics";

const initialState = {
    totalEmployees: null,
    loading: true,
    error: null
};

export const statisticsSlice = createSlice({
    name: "statistics",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTotalEmployeesStats.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTotalEmployeesStats.fulfilled, (state, action) => {
                state.loading = false;
                state.totalEmployees = action.payload;
            })
            .addCase(fetchTotalEmployeesStats.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export default statisticsSlice.reducer;