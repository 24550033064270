import { createAsyncThunk } from "@reduxjs/toolkit";
import { setProfileCompletion, setUser, updateUserProfile } from "../../store/auth/authSlice";
import apiClient from "../../services/api"
import { setError, setLoading } from "../../store/common/commonSlice";
import { notifications } from "@mantine/notifications";
const apiInstance = apiClient()
const apiFormInstance = apiClient(true)

export const asyncRegister = createAsyncThunk(
    "auth/register",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await apiInstance.post('/api/auth/registration/', { email: data.email, password1: data.password, password2: data.password })
            return res
        }
        catch (err) {
            return rejectWithValue(err)
        }
    }
);

export const updateUserData = createAsyncThunk("updateUserData", async (data, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiFormInstance.put(`/api/profiles/`, data)
        await dispatch(updateUserProfile(res.data))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const asyncUpdateUserProfile = createAsyncThunk("asyncUpdateUserProfile", async (data, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiFormInstance.patch(`/api/profiles/`, data)
        await dispatch(updateUserProfile(res.data))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const googleLogin = createAsyncThunk("googleLogin", async (data, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.post('/api/google/', data)
        dispatch(setUser(res.data.user))
        localStorage.setItem('access_token', res.data.access)
        localStorage.setItem('refresh_token', res.data.refresh)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const loginWithIdMe = createAsyncThunk("idMeLogin", async (data, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.post('/api/id-me/', data)
        dispatch(setUser(res.data.user))
        localStorage.setItem('access_token', res.data.access)
        localStorage.setItem('refresh_token', res.data.refresh)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const loginWithFacebook = createAsyncThunk("loginWithFacebook", async (data, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.post('/api/facebook/', data)
        dispatch(setUser(res.data.user))
        localStorage.setItem('access_token', res.data.access)
        localStorage.setItem('refresh_token', res.data.refresh)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const fetchUser = createAsyncThunk("getUserDetails", async (_, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.get('/api/auth/user/')
        dispatch(setUser(res.data))
    }
    catch (err) {
        rejectWithValue(err)
    }
})

export const getProfileCompletion = createAsyncThunk("getProfileCompletion", async (_, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.get('/api/employees/profile-completion/')
        dispatch(setProfileCompletion(res.data))
    }
    catch (err) {
        rejectWithValue(err)
    }
})

export const changeUserPassword = createAsyncThunk("changeUserPassword", async (data, { rejectWithValue }) => {
    try {
        const res = await apiInstance.post('/api/auth/password/change/', data)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const loginUser = createAsyncThunk("loginUser", async (data, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.post('/api/auth/log-in/', data)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const verifyOTP = createAsyncThunk("verifyOTP", async (data, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.post('/api/auth/verify-code/', data)
        dispatch(setUser(res.data.user))
        if (localStorage.getItem("inProcess") === "true") {
            localStorage.setItem('access_token', res.data.access)
            localStorage.setItem('referesh_token', res.data.refresh)
        }
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const updateEmployeeProfile = createAsyncThunk("updateEmployeeProfile", async (data, { dispatch, rejectWithValue }) => {
    try {
        const res = await apiInstance.put('/api/profiles/', data)
        // dispatch(setUser(res.data.user))
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const handleSetPassword = createAsyncThunk("setPassword", async (body, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true))
    try {
        const res = await apiInstance.put(`/api/set-password/?${body.params}`, body.body)
        if (res.status === 200 || res.status === 204) {
            notifications.show({ message: 'Password created successfully', autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            dispatch(setLoading(false))
            dispatch(setError({
                status: false,
                message: ""
            }))
        }
        return res
    }
    catch (err) {
        console.log(err);
        let errorMessage;
        if (err?.response?.status === 400 && err?.response?.data?.error?.details?.non_field_errors?.length >= 0) {
            errorMessage = err?.response?.data?.error?.details?.non_field_errors
            errorMessage?.forEach((error) => {
                notifications.show({ message: error, autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            })
        } else {
            errorMessage = err?.response?.data?.error?.message
            notifications.show({ message: errorMessage, autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
        }
        dispatch(setError({
            status: true,
            message: errorMessage
        }))
        dispatch(setLoading(false))
        return rejectWithValue(err)
    }
})

export const resetPassword = createAsyncThunk("resetPassword", async (data, { rejectWithValue }) => {
    try {
        const res = await apiInstance.post('/api/auth/password_reset/', data)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})

export const resetPasswordConfirm = createAsyncThunk("resetPasswordConfirm", async (data, { rejectWithValue }) => {
    try {
        const res = await apiInstance.post('/api/auth/password_reset/confirm/', data)
        return res
    }
    catch (err) {
        return rejectWithValue(err)
    }
})