import React from 'react';
import { Grid, Divider, Box, Stack, Group, Text, Button, Avatar, Image, Title, Progress, Card } from '@mantine/core';
import EmployStatus from "../../components/EmployeeManagement/EmployStatus"
import { useMediaQuery } from '@mantine/hooks';
import { useSelector } from 'react-redux';
import { formatCity } from '../../helpers/cityNameFormatter';
import { OutlineButton } from '../ui/Button/Button';
import { useNavigate } from 'react-router-dom';
import classes from '../Dashboard/card.module.css'

const ProfileCard = () => {

    const isSmallScreen = useMediaQuery("(max-width: 1000px)");
    const navigate = useNavigate()
    const user = useSelector((state) => state.login.user)
    const profileCompletion = useSelector((state) => state.login.profileCompletion)
    const percentage = profileCompletion?.overall_profile_completion ? Math.floor(profileCompletion?.overall_profile_completion) : 0

    return (
        <Card className={classes.card} bg="#FDFEFE" py={20} px={20} radius={12} styles={{ root: { border: "2px solid #DEE2E6" } }}>
            <Box style={{ display: isSmallScreen ? "block" : "flex", alignItems: "center" }}>
                <Box>
                    <img width={"100%"} height={165} style={{ objectFit: "cover", borderRadius: "6px" }} src={user?.profile?.image ? user?.profile?.image : ""} alt="" />
                </Box>
                <Box style={{ width: "100%" }}>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} pt={isSmallScreen ? 20 : 0} pl={isSmallScreen ? 0 : 27}>
                        <Box>
                            <Title order={3} fw={600}>{user?.profile?.name}</Title>
                            <Text>{user?.profile?.role}</Text>
                        </Box>
                        <Box>
                            {/* <Button variant="outline">Edit profile</Button> */}
                            <OutlineButton
                                title="Edit profile"
                                onClick={() => navigate("/employee/settings")}
                            />
                        </Box>
                    </Box>
                    <Divider orientation="horizontal" ml={isSmallScreen ? 0 : 10} mt={14} mb={17} />
                    <Box pl={isSmallScreen ? 0 : 27} style={{ display: "flex", justifyContent: "space-between", width: "100%", overflowX: "scroll" }}>
                        <Box>
                            <Text C="#212529">Email</Text>
                            <Text c="#373A40" fz={16} fw={600}>{user?.email}</Text>
                        </Box>
                        <Box>
                            <Text C="#212529">Location</Text>
                            <Text c="#373A40" fz={16} fw={600}>{user?.profile?.location === null ? "-" : formatCity(user?.profile?.location)}</Text>
                        </Box>
                        <Box>
                            <Text C="#212529">Gender</Text>
                            <Text c="#373A40" fz={16} fw={600}>{user?.profile?.gender === "M" ? "Male" : "Female"}</Text>
                        </Box>
                        <Box>
                            <Text C="#212529">Status</Text>
                            <EmployStatus status="Active" className="active" />
                        </Box>
                    </Box>
                    <Box pl={isSmallScreen ? 0 : 27} pt={26} style={{ position: 'relative' }}>
                        <Text c="#70707A" mb={3}>Profile completed</Text>
                        <Progress w="95%" value={percentage} />
                        <Text
                            style={{
                                position: 'absolute',
                                top: '95%',
                                left: '98%',
                                transform: 'translate(-50%, -50%)',
                                fontWeight: 600
                            }}
                            c="#3354F4"
                        >
                            {percentage}%
                        </Text>
                    </Box>
                </Box>
            </Box>
        </Card>
    )
}

export default ProfileCard;