import { Modal } from "@mantine/core";

function ModalComp({ p, padding, opened, onClose, title, children, ...rest }) {
    return (
        <Modal p={p} padding={padding} opened={opened} onClose={onClose} title={title} centered {...rest}>
            <Modal.Body p={0}>{children}</Modal.Body>
        </Modal>
    );
}

export default ModalComp;