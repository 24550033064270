import { Text } from "@mantine/core"

export const PRESCRIPTIONS = [
    {
        id: 1,
        name: 'Prescription #20921',
        usage: 'Test',
        status: '1',
        prescriptionDate: new Date().toLocaleDateString(),
        expiryDate: new Date().toLocaleDateString(),
        lensesType: null,
        sphere: {
            right: null,
            left: null,
        },
        astigmatism: {
            cylinder: {
                right: null,
                left: null,
            },
            axis: {
                right: null,
                left: null,
            },
        },
        pulpDistance: {
            right: null,
            left: null,
        },
    }
]

export const LENSES_TYPES = [
    { id: 1, type: "DISTANCE", selected: false },
    { id: 2, type: "READING", selected: false },
    { id: 3, type: "COMPUTER", selected: false },
    { id: 4, type: "BIFOCAL", selected: false },
    { id: 5, type: "TRIFOCAL", selected: false },
    { id: 6, type: "PROGRESSIVE", selected: false },
]

export const PRESCRIPTIONS_LENSES_TYPES = {
    '1': 'DISTANCE',
    '2': 'READING',
    '3': 'COMPUTER',
    '4': 'BIFOCAL',
    '5': 'TRIFOCAL',
    '6': 'PROGRESSIVE',
}

export const PRESCRIPTION_STATUS = {
    '1': <Text c="#37B24D">Active</Text>,
    '2': <Text c="#E8590C">Need additional info</Text>,
    '3': <Text c="#5C5F66">Expired</Text>,
}