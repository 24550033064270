import React, { useEffect, useState } from 'react';
import { Button, Stack, Group, Text, Box, Flex, Loader, Title, Card, Checkbox } from '@mantine/core'
import { IconEdit, IconEye } from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton, PrimaryButton2, SecondaryButton } from '../../../components/ui/Button/Button';
import { MANAGE_PARTICIPANTS_DATA, MANAGE_PARTICIPANTS_HEADER } from '../../../mock/admin/safetyAwards';
import { EMPLOYEE_STATUS_BLOCK } from '../../../choices/employee.choice';
import ModalComp from '../../../components/Modal/Modal';
import TableComp from '../../../components/Table/Table';
import { InputField } from '../../../components/ui/Input/Input';
import userImg from '../../../assets/images/woman-avatar.jpg'
import { setEmployees } from '../../../store/employess/employeesSlice';
import { useParams } from 'react-router-dom';
import { asyncErrorHandler } from '../../../helpers/errorHandler';
import { fetchSafetyAwards, updateSafetyAwards, updateSafetyAwards2 } from '../../../middlewares/admin/safetyAwards';
import { setSafetyAwards } from '../../../store/safetyAwards/safetyAwardsSlice';

const ManageParticipants = () => {

    const { id } = useParams()

    const [openAddEmployeeDrawer, setOpenAddEmployeeDrawer] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false)
    const [searchParticipants, setSearchParticipants] = useState([])
    const [results, setResults] = useState([])
    const [participants, setParticipants] = useState([])

    const dispatch = useDispatch()
    const safetyAwardList = useSelector((state) => state.safetyAwards.safetyAwards)
    const tierList = useSelector((state) => state.tiers.tierList)
    const employees = useSelector((state) => state.employee.employees)
    const loading = useSelector((state) => state.safetyAwards.loading2)
    const safetyAward = safetyAwardList?.find((safetyAward) => safetyAward.id === id)

    const handleManageParticipants = async (type) => {
        const listIds = type === "delete" ? participants.filter((emp) => !emp.selected).map((emp) => emp.id) : [...employees].filter((emp) => emp.selected).map((emp) => emp.id);
        const data = {
            id: safetyAward?.id,
            employees: type === 'delete' ? listIds : [...listIds, ...safetyAward.employees],
        };
        const res = await dispatch(updateSafetyAwards2({ data, type }))
        if (res.type === "updateSafetyAwards/fulfilled") {
            let message = type === "delete" ? "Participant deleted successfully" : "Participant added successfully"
            if (type === "delete") {
                const filterPartcipants = participants?.filter((participant) => !participant?.selected)
                setParticipants(filterPartcipants)
            } else {
                dispatch(fetchSafetyAwards())
            }
            window.toast(message)
            dispatch(setEmployees(employees.map((p) => ({ ...p, selected: false }))))
            setOpenAddModal(false)
        }
        else {
            return;
        }
    }

    function selectAllParticipants(checked) {
        const updatedParticipants = participants.map((emp) => {
            return {
                ...emp,
                selected: checked,
            };
        });
        setParticipants(updatedParticipants)
    }

    function selectPartcipants(id) {
        const updatedParticipants = participants.map((emp) => {
            if (emp.id === id) {
                return {
                    ...emp,
                    selected: !emp.selected,
                };
            } else {
                return emp
            }
        });
        setParticipants(updatedParticipants);
    }

    // useEffect(() => {
    //     const a = searchParticipants?.length > 0 ? searchParticipants : participants
    //     const searchResults = a.map((result) => {
    //         const searchParticipant = results.find((res) => res.id === result.id)
    //         return { ...result, selected: searchParticipant?.selected }
    //     })
    //     setResults(searchResults)
    // }, [searchParticipants, participants])

    function getParticipants() {
        const filterPartcipants = [...employees].filter((emp) => safetyAward?.employees.some((id) => emp.id === id))
        setParticipants(filterPartcipants)
    }

    function data() {
        return participants?.length > 0 ? participants?.map((participant) => {
            return {
                ...participant,
                status: EMPLOYEE_STATUS_BLOCK[participant.status],
                tier: tierList?.find((tier) => tier.id === participant.tier)?.name,
                actions: (
                    <Flex>
                        <IconEye />
                        <Text ml={5}>View</Text>
                    </Flex>
                )
            }
        }) : [];
    }

    useEffect(() => {
        getParticipants()
    }, [safetyAwardList])

    return (
        <>
            <ModalComp
                size="lg"
                withCloseButton={false}
                open={openAddModal}
                setOpen={setOpenAddModal}
                title={
                    <>
                        <Text fz={20} fw={600} mb={8}>Add employee to safety program</Text>
                        <Text fz={16} c="#495057">Select one or multiple employees to connect to the "Gold Safety Award" program</Text>
                    </>
                }
            >
                <Flex mt={16} gap={8} styles={{ root: { width: "100%" } }}>
                    <InputField
                        onChange={(e) => {
                            const searchQuery = e.target.value
                            setResults(() => employees.filter((p) => p.name.toLowerCase().includes(searchQuery.toLowerCase())))
                        }}
                        rightIcon={<IconEdit />}
                        placeholder="Please enter name"
                        radius={6}
                        width="100%"
                    />
                    <PrimaryButton
                        title="Add"
                        w={100}
                        h={40}
                        onClick={() => handleManageParticipants('create')}
                        disabled={loading}
                        fallback={loading ? <Loader size="sm" color="white" type='dots' /> : null}
                    />
                </Flex>
                <Card styles={{ root: { border: "1px solid #DEE2E6" } }} p={12} radius={12} mt={16}>
                    <Box style={{ height: "500px", overflowY: "auto" }}>
                        {employees?.map((participant) => {
                            return (
                                <Card
                                    onClick={() => {
                                        dispatch(setEmployees(
                                            employees.map((p) => {
                                                if (p.id === participant.id) {
                                                    return {
                                                        ...p,
                                                        selected: !p.selected
                                                    }
                                                } else {
                                                    return p
                                                }
                                            })
                                        ))
                                    }}
                                    styles={{ root: { backgroundColor: participant.selected && "#E7F5FF", border: "1px solid #DEE2E6", cursor: "pointer" } }}
                                    p={16}
                                    radius={12}
                                    mb={8}
                                    key={participant.id}
                                >
                                    <Flex align="flex-start" justify="space-between">
                                        <Box display="flex">
                                            <img width={40} height={40} src={participant?.image} style={{ borderRadius: "100%", objectFit: "cover" }} alt="" />
                                            <Box ml={12}>
                                                <Text c="#141517" fz={18} fw={600}>{participant.name}</Text>
                                                <Text c="#3354F4" fz={18}>{participant.role}</Text>
                                            </Box>
                                        </Box>
                                        <Checkbox aria-label="Select row" checked={participant.selected} />
                                    </Flex>
                                </Card>
                            )
                        })}
                    </Box>
                </Card>
            </ModalComp>
            {/* <CommonDrawer
                size={740}
                open={openAddEmployeeDrawer}
                onClose={() => {
                    setOpenAddEmployeeDrawer(false)
                    setUserProfileImageUrl('')
                    form.reset()
                    form.clearErrors()
                    form.clearFieldError()
                }}
                withCloseButton={false}
            >
                <Box px={24} py={12}>
                    <Group justify="space-between" align="center">
                        <Title order={4}>{employeeId ? "Edit Employee" : "Create New Employee"}</Title>
                        <Group gap={10}>
                            <SecondaryButton
                                title="Cancel"
                                onClick={() => {
                                    setOpenAddEmployeeDrawer(false)
                                    setUserProfileImageUrl('')
                                    form.reset()
                                    form.clearErrors()
                                    form.clearFieldError()
                                }} />
                            <PrimaryButton
                                title={isEditProfile ? "Save changes" : "Add user and invite"}
                                fallback={loading ? <Loader size="sm" color="white" type='dots' /> : null}
                                id={classes.upload}
                                onClick={() => {
                                    if (isEditProfile) {
                                        editEmployeeData()
                                    } else {
                                        addEmployee()
                                    }
                                }}
                            />
                        </Group>
                    </Group>
                </Box>
                <Divider orientation="horizontal" />
                <Box pt={12}>
                    <Tab defaultValue="userInfo" tabsArray={tabs} style={{ justifyContent: "center", alignItems: "center" }}>
                        <Tabs.Panel value="userInfo">
                            <Stack gap={20} p={24}>
                                <ProfileImageUpload mb={20} profileImageURL={userProfileImageUrl} updateImage={(image) => { updateFormValues('image', image) }} deleteImage={() => { updateFormValues('image', '') }} />
                                <Grid gutter={20}>
                                    <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                                        <InputField
                                            label={<Text fz={16} fw={500}>Name</Text>}
                                            rightIcon={editIcon}
                                            placeholder="Please enter name"
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}
                                            key={form.key("name")}
                                            {...form.getInputProps("name")}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                                        <SelectInputField
                                            data={ETHNICITY}
                                            label={<Text fz={16} fw={500}>Ethnicity</Text>}
                                            placeholder="Please enter ethnicity"
                                            rightSection={selectIcon}
                                            key={form.key("ethnicity")}
                                            {...form.getInputProps("ethnicity")}
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}
                                            defaultValue={null}
                                        />
                                    </Grid.Col>
                                </Grid>
                                <Grid gutter={20}>
                                    <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                                        <InputField
                                            label={<Text fz={16} fw={500}>Age</Text>}
                                            rightSection={editIcon}
                                            placeHolder="Please enter age"
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}

                                            key={form.key("age")}
                                            {...form.getInputProps("age")}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={{ base: 6, md: 6, lg: 6 }}>
                                        <SelectInputField
                                            data={GENDER_CHOICES}
                                            label={<Text fz={16} fw={500}>Gender</Text>}
                                            rightSection={selectIcon}
                                            placeHolder="Please select gender"
                                            defaultValue={form.values.gender}
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}

                                            key={form.key("gender")}
                                            {...form.getInputProps("gender")}
                                        />
                                    </Grid.Col>
                                </Grid>
                                <Grid>
                                    <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                        <InputField
                                            label={<Text fz={16} fw={500}>Email</Text>}
                                            rightIcon={editIcon}
                                            placeHolder="Please enter email"
                                            key={form.key("email")}
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}

                                            {...form.getInputProps("email")}
                                        />
                                    </Grid.Col>
                                </Grid>
                                <Grid>
                                    <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                        <SelectInputField
                                            data={tierList?.length > 0 ? tierList.map((tiers) => ({ value: tiers.id, label: tiers.name })) : [{ value: "No Tiers Found Please Create Tiers" }]}
                                            label={<Text fz={16} fw={500}>Tier assignment</Text>}
                                            placeHolder="Please select tier"
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}
                                            key={form.key("tier")}
                                            {...form.getInputProps("tier")}
                                        />
                                    </Grid.Col>
                                </Grid>
                                <Grid>
                                    <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                        <InputField
                                            label={<Text fz={16} fw={500}>Site/Location</Text>}
                                            placeHolder="Please enter location"
                                            radius={6}
                                            styles={{ input: { height: "40px" }, label: { marginBottom: "10px" } }}

                                            key={form.key("address")}
                                            {...form.getInputProps("address")}
                                        />
                                    </Grid.Col>
                                </Grid>
                                {isEditProfile ?
                                    <>
                                        <Grid >
                                            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                                <Stack gap={10}>
                                                    <Text
                                                        style={{
                                                            font: 'Roboto',
                                                            fontSize: '14px',
                                                            fontWeight: '600',
                                                            color: '#495057'
                                                        }}>
                                                        Status
                                                    </Text>
                                                    <Group
                                                        justify='flex-around'
                                                        align="center"
                                                        px={12}
                                                        style={{
                                                            width: '100%',
                                                            height: '38px',
                                                            borderRadius: '6px',
                                                            border: '1px',
                                                            borderColor: '#CED4DA',
                                                            borderStyle: 'solid'
                                                        }}>
                                                        <Box mr='auto'>
                                                            {EMPLOYEE_STATUS_BLOCK[form.values.status]}
                                                        </Box>
                                                        <Group ml='auto' color="#3354F4" gap={8} justify='flex-end' onClick={sendInvitation} style={{ cursor: 'pointer' }}>
                                                            <IconReload color="#3354F4" style={{ width: '14px', height: '14px' }} />
                                                            <Text c="#3354F4" style={{ fontSize: '14px' }}>
                                                                <strong>Resend invite</strong>
                                                            </Text>
                                                        </Group>
                                                    </Group>
                                                </Stack>
                                            </Grid.Col>
                                        </Grid>
                                        <Grid >
                                            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                                <Group justify='flex-end' align='center'>
                                                    <Button variant="light" color="#C92A2A" radius="md" style={{ background: '#FFC9C9' }} onClick={deleteEmployee}>Delete User</Button>
                                                    <Button color="#3354F4" radius="md" onClick={toggleMessageEmployeeFlag}>Message employee</Button>
                                                </Group>
                                            </Grid.Col>
                                        </Grid>
                                    </>
                                    : null
                                }
                            </Stack>
                        </Tabs.Panel>
                    </Tab>
                </Box>
            </CommonDrawer> */}
            <Box py={24} pr={24}>
                <Flex justify="space-between" align="center" mb={24}>
                    <Text fz={24} fw={600} c={'#212529'}>Participants</Text>
                    <Flex gap={20}>
                        <PrimaryButton2
                            title="Delete"
                            onClick={() => handleManageParticipants('delete')}
                            disabled={loading || participants?.some((f) => f.selected) ? false : true}
                            fallback={loading ? <Loader size="sm" color="white" type='dots' /> : null}
                        />
                        <PrimaryButton
                            onClick={() => {
                                setOpenAddModal(true)
                                const updatedEmployees = [...employees].map((emp) => {
                                    return {
                                        ...emp,
                                        selected: false,
                                    };
                                });
                                dispatch(setEmployees(updatedEmployees));
                            }}
                            title="Add to safety program"
                        />
                    </Flex>
                </Flex>
                <Stack gap={20} style={{ height: '100%' }}>
                    <Stack gap={120}>
                        <TableComp
                            openAddEmployeeDrawer={openAddEmployeeDrawer}
                            setOpenAddEmployeeDrawer={setOpenAddEmployeeDrawer}
                            tableHeaders={MANAGE_PARTICIPANTS_HEADER}
                            data={data()}
                            height='250px'
                            // isFilterApplied={isFilterApplied}
                            // fetchEmployeeLoader={employeeLoading}
                            // setExportCSVData={setExportCSVData}
                            totalRecords={data().length}
                            totalPages={3}
                            // changePage={changePage}
                            onChange={selectPartcipants}
                            onChange2={(checked) => selectAllParticipants(checked)}
                            isCheckBox={true}
                            showPagination={true}
                            errorMessage2={
                                <>
                                    {data()?.length === 0 ? (
                                        <Box mx='auto' mt="152px">
                                            <Stack align='center' justify='center' gap={6}>
                                                <Title size={24}>
                                                    No participants
                                                </Title>
                                                <Box>
                                                    <Text c="dark.3" fz={16} fw={400} align="center">To get started, please click <Text span fw={600} style={{ color: '#3354F4' }}>Add to safety program</Text></Text>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    ) : ""}
                                </>
                            }
                        />
                    </Stack>
                </Stack>
            </Box>
        </>
    )
}

export default ManageParticipants;