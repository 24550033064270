import React, { useEffect, useState } from 'react';
import { Grid, Box, Stack, Title, Button, Group, Divider, Text, Loader, Flex } from '@mantine/core';
import { PrescriptionCard } from '../../../components/Employess/prescriptionCard';
import { LENSES_TYPES, PRESCRIPTIONS } from '../../../mock/employees/prescriptions';
import { CommonDrawer } from '../../../components/Drawer/Drawer';
import { useForm } from '@mantine/form';
import InputBox from '../../../components/BaseComponents/InputBox';
import { DateInput } from '@mantine/dates';
import PrescriptionMessage from '../../../components/Employess/prescriptionMessage';
import { notifications } from '@mantine/notifications';
import { useDispatch, useSelector } from 'react-redux';
import { createPrescriptions, deletePrescriptions, getMyPrescriptions, updatePrescriptions } from '../../../middlewares/prescriptions/prescriptions.services';
import { asyncErrorHandler } from '../../../helpers/errorHandler';
import { FaPlus } from 'react-icons/fa';
import { PrimaryButton, PrimaryButton2, SecondaryButton } from '../../../components/ui/Button/Button';

const MyPrescription = () => {

  const [openDrawer, setOpenDrawer] = useState(false)
  const [lensesType, setLensesTypes] = useState(LENSES_TYPES)
  const [prescriptionId, setPrescriptionId] = useState(null)
  const prescriptions = useSelector((state) => state.prescription.prescriptionList)
  const prescriptionLoading = useSelector((state) => state.prescription.prescriptionLoading)
  const prescriptionLoading2 = useSelector((state) => state.prescription.prescriptionLoading2)
  const prescriptionError = useSelector((state) => state.prescription.prescriptionError)

  const [drawerType, setDrawerType] = useState("");

  const dispatch = useDispatch()

  const form = useForm({
    initialValues: {
      name: '',
      start_date: null,
      end_date: null,
      lens_type: null,
      usage: "1",
      right_sphere: null,
      left_sphere: null,
      right_cylinder_astigmatism: null,
      left_cylinder_astigmatism: null,
      right_astigmatism_axis: null,
      left_astigmatism_axis: null,
      right_prism: null,
      left_prism: null,
      right_add: null,
      left_add: null,
      right_pupil: null,
      left_pupil: null,
    },
    validate: {
      name: (val) => {
        if (!val) return 'Name is required';
        if (!/^[A-Za-z\d\s-]+$/.test(val)) {
          return 'Name must contain only letters, numbers, spaces, and dashes';
        }
        return null;
      },
      start_date: (val) => (!val ? 'Start date is required' : null),
      end_date: (val) => (!val ? 'End date is required' : null),
      lens_type: (val) => (!val ? 'Lens type is required' : null),
      usage: (val) => (!val ? 'Usage is required' : null),
      right_sphere: (val) => (!val ? 'Right sphere value is required' : null),
      left_sphere: (val) => (!val ? 'Left sphere value is required' : null),
      right_cylinder_astigmatism: (val) =>
        !val ? 'Right cylinder astigmatism value is required' : null,
      left_cylinder_astigmatism: (val) =>
        !val ? 'Left cylinder astigmatism value is required' : null,
      right_astigmatism_axis: (val) =>
        !val ? 'Right astigmatism axis value is required' : null,
      left_astigmatism_axis: (val) =>
        !val ? 'Left astigmatism axis value is required' : null,
      right_prism: (val) => (!val ? 'Right prism value is required' : null),
      left_prism: (val) => (!val ? 'Left prism value is required' : null),
      right_add: (val) => (!val ? 'Right add value is required' : null),
      left_add: (val) => (!val ? 'Left add value is required' : null),
      right_pupil: (val) => (!val ? 'Right pupil value is required' : null),
      left_pupil: (val) => (!val ? 'Left pupil value is required' : null),
    },
  });

  const handleEditPrescription = (prescription) => {
    setOpenDrawer(true)
    setDrawerType("EDIT")
    setPrescriptionId(prescription.id)
    form.setValues({
      ...form.values,
      name: prescription.name,
      start_date: new Date(prescription.start_date),
      end_date: new Date(prescription.end_date),
      lens_type: Number(prescription.lens_type),
      right_sphere: prescription.right_sphere,
      left_sphere: prescription.left_sphere,
      right_cylinder_astigmatism: prescription.right_cylinder_astigmatism,
      left_cylinder_astigmatism: prescription.left_cylinder_astigmatism,
      right_astigmatism_axis: prescription.right_astigmatism_axis,
      left_astigmatism_axis: prescription.left_astigmatism_axis,
      right_prism: prescription.right_prism,
      left_prism: prescription.left_prism,
      right_add: prescription.right_add,
      left_add: prescription.left_add,
      right_pupil: prescription.right_pupil,
      left_pupil: prescription.left_pupil,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (form.validate().hasErrors === true) {
      return;
    }
    const payload = {
      ...form.values,
      lens_type: form.values.lens_type?.id,
      comments: "Hello this is prescription"
    }
    if (drawerType === "CREATE") {
      const res = await dispatch(createPrescriptions({ payload }))
      if (res.type === "createPrescriptions/fulfilled") {
        setOpenDrawer(false)
        form.reset()
        form.clearErrors()
        form.clearFieldError()
        window.toast("Prescription added successfully")
      } else {
        asyncErrorHandler(res, form)
      }
    }
    else {
      const res = await dispatch(updatePrescriptions({ prescriptionId: prescriptionId, payload }))
      if (res.type === "updatePrescriptions/fulfilled") {
        setOpenDrawer(false)
        form.reset()
        form.clearErrors()
        form.clearFieldError()
        window.toast("Prescription updated successfully")
      } else {
        asyncErrorHandler(res, form)
      }
    }
  }

  const handleDelete = async () => {
    const res = await dispatch(deletePrescriptions({ prescriptionId: prescriptionId }))
    if (res.type === "deletePrescriptions/fulfilled") {
      setOpenDrawer(false)
      form.reset()
      form.clearErrors()
      form.clearFieldError()
      window.toast("Prescription deleted successfully")
    } else {
      window.toast("Something went wrong", "error")
    }
  }

  const importCSV = () => {
    const headers = Object.keys(form.values).join(",");
    const data = prescriptions
      .map((prescription) =>
        Object.values(prescription).join(",")
      )
      .join("\n");
    const blob = new Blob([`${headers}\n${data}`], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'prescriptions.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const exportCSV = (prescription) => {
    const headers = Object.keys(prescription).join(',');
    const data = Object.values(prescription).join(',');
    const csvContent = `${headers}\n${data}`;
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `prescription_${prescription.id}.csv`; // Filename based on ID
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const renderContent = () => {
    if ((!drawerType || prescriptionId === null) && prescriptionLoading) {
      return (
        <Box mx='auto' mt="152px">
          <Stack align='center' justify='center' gap={12}>
            <Loader size="xl" color="#3354F4" type='dots' />
          </Stack>
        </Box>
      );
    }
    if (prescriptions?.length === 0 && (prescriptionError?.error?.message === "No prescription found please add prescriptions" || prescriptionError === null)) {
      return (
        <>
          <Box mx='auto' mt="152px" style={{ height: "calc(100vh - 600px)", justifyContent: "center", alignItems: "center", flexDirection: "column" }} display="flex">
            <Stack align='center' justify='center' gap={6}>
              <Title size={20}>No prescription found please add prescriptions</Title>
              <Text c="dark.3" fz={16} fw={400} align="center">To add prescription, click on <Text span fw={600} style={{ color: '#3354F4' }}>create prescription</Text> button</Text>
              <Group mt={10}>
                <Box w={40} h={40} bg="white" c="black" fw="lighter" bd="2px dashed #ADB5BD" style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%" }}>
                  <FaPlus size={20} color="#ADB5BD" />
                </Box>
              </Group>
            </Stack>
          </Box>
        </>
      );
    }
    return (
      prescriptions?.map((prescription) => (
        <Grid.Col key={prescription.id} span={{ base: 12, md: 6, lg: 4 }}>
          <Stack gap={30}>
            <PrescriptionCard
              prescription={prescription}
              exportCSV={() => exportCSV(prescription)}
              handleEditPrescription={() => handleEditPrescription(prescription)}
              isGroup={true}
            />
          </Stack>
        </Grid.Col>
      )) || null
    );
  };

  return (
    <Box style={{ height: '100%' }} p={27}>
      <CommonDrawer
        size={740}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        withCloseButton={false}
      >
        <Box px={24} py={12}>
          <Group justify="space-between" align="center">
            <Title order={4}>{prescriptionId ? "Edit Prescription" : "Create New Prescription"}</Title>
            <Group>
              <SecondaryButton
                title="Cancel"
                onClick={() => setOpenDrawer(false)}
              />
              <PrimaryButton
                title="Save changes"
                onClick={handleSubmit}
                fallback={prescriptionLoading ? <Loader size="sm" color="white" type='dots' /> : null}
              />
            </Group>
          </Group>
        </Box>
        <Divider orientation="horizontal" />
        <Box p={24}>
          <PrescriptionMessage />
          <Stack gap={20} mt={20}>
            <form onSubmit={handleSubmit}>
              <Grid gutter={20}>

                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <InputBox key={form.key('name')} {...form.getInputProps('name')} label="Name prescriptions" placeHolder="Please enter name" />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                  <DateInput
                    styles={{
                      label: {
                        color: "#424242",
                        marginBottom: "8px",
                        fontWeight: "600"
                      }
                    }}
                    key={form.key('start_date')}
                    {...form.getInputProps('start_date')}
                    label="Prescription date"
                    placeHolder="Please enter prescription date"
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                  <DateInput
                    styles={{
                      label: {
                        color: "#424242",
                        marginBottom: "8px",
                        fontWeight: "600"
                      }
                    }}
                    {...form.getInputProps('end_date')}
                    key={form.key('end_date')}
                    label="Expiry date"
                    placeHolder="Please enter expiry date"
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <Text c="#424242" mb={8} fw={600}>Select lenses type</Text>
                  {lensesType.map((type) => (
                    <Button
                      key={type.id}
                      mr={8}
                      variant="light"
                      bg={form.values?.lens_type?.id === type.id ? "#3354F4" : "#EAF2FF"}
                      c={form.values?.lens_type?.id === type.id ? "white" : "#3354F4"}
                      radius={8}
                      onClick={() => form.setFieldValue("lens_type", { id: type.id, type: type.type })}
                    >
                      {type.type}
                    </Button>
                  ))}
                  {form.errors?.lens_type && (
                    <Text fz={12} c="red" mt={5}>
                      {form.errors.lens_type}
                    </Text>
                  )}
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <Text c="#424242" mb={8} fw={600}>Sphere</Text>
                  <Group align="center" mb={10}>
                    <Text c="#5C5F66" fw={500} w={40} mr={20}>Right</Text>
                    <InputBox
                      style={{ marginTop: 0, width: "225px" }}
                      key={form.key('right_sphere')}
                      {...form.getInputProps('right_sphere')}
                    />
                  </Group>
                  <Group align="center">
                    <Text c="#5C5F66" fw={500} w={40} mr={20}>Left</Text>
                    <InputBox
                      style={{ marginTop: 0, width: "225px" }}
                      key={form.key('left_sphere')}
                      {...form.getInputProps('left_sphere')}
                    />
                  </Group>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <Text c="#424242" mb={8} fw={600}>Astigmatism</Text>
                  <Group align="flex-end" mb={10}>
                    <Text c="#5C5F66" fw={500} w={40} mr={20} mb={6}>Right</Text>
                    <Group align="center">
                      <Box>
                        <Text c="#5C5F66" fw={500} ta="center" mb={10}>Cylinder</Text>
                        <InputBox
                          style={{ marginTop: 0, width: "225px" }}
                          key={form.key('right_cylinder_astigmatism')}
                          {...form.getInputProps('right_cylinder_astigmatism')}
                        />
                      </Box>
                      <Box>
                        <Text c="#5C5F66" fw={500} ta="center" mb={10}>Axis</Text>
                        <InputBox
                          style={{ marginTop: 0, width: "225px" }}
                          key={form.key('right_astigmatism_axis')}
                          {...form.getInputProps('right_astigmatism_axis')}
                        />
                      </Box>
                    </Group>
                  </Group>
                  <Group align="center">
                    <Text c="#5C5F66" fw={500} w={40} mr={20}>Left</Text>
                    <Group>
                      <InputBox style={{ marginTop: 0, width: "225px" }}
                        {...form.getInputProps('left_cylinder_astigmatism')}
                        key={form.key('left_cylinder_astigmatism')}
                      />
                      <InputBox style={{ marginTop: 0, width: "225px" }}
                        {...form.getInputProps('left_astigmatism_axis')}
                        key={form.key('left_astigmatism_axis')}
                      />
                    </Group>
                  </Group>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <Text c="#424242" mb={8} fw={600}>Prism</Text>
                  <Group align="center" mb={10}>
                    <Text c="#5C5F66" fw={500} w={40} mr={20}>Right</Text>
                    <InputBox style={{ marginTop: 0, width: "225px" }}
                      {...form.getInputProps('right_prism')}
                      key={form.key('right_prism')}
                    />
                  </Group>
                  <Group align="center">
                    <Text c="#5C5F66" fw={500} w={40} mr={20}>Left</Text>
                    <InputBox style={{ marginTop: 0, width: "225px" }}
                      {...form.getInputProps('left_prism')}
                      key={form.key('left_prism')}
                    />
                  </Group>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <Text c="#424242" mb={8} fw={600}>Add</Text>
                  <Group align="center" mb={10}>
                    <Text c="#5C5F66" fw={500} w={40} mr={20}>Right</Text>
                    <InputBox style={{ marginTop: 0, width: "225px" }}
                      {...form.getInputProps('right_add')}
                      key={form.key('right_add')}
                    />
                  </Group>
                  <Group align="center">
                    <Text c="#5C5F66" fw={500} w={40} mr={20}>Left</Text>
                    <InputBox style={{ marginTop: 0, width: "225px" }}
                      {...form.getInputProps('left_add')}
                      key={form.key('left_add')}
                    />
                  </Group>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                  <Text c="#424242" mb={8} fw={600}>Pulp distance</Text>
                  <Group align="center" mb={10}>
                    <Text c="#5C5F66" fw={500} w={40} mr={20}>Right</Text>
                    <InputBox style={{ marginTop: 0, width: "225px" }}
                      {...form.getInputProps('right_pupil')}
                      key={form.key('right_pupil')}
                    />
                  </Group>
                  <Group align="center">
                    <Text c="#5C5F66" fw={500} w={40} mr={20}>Left</Text>
                    <InputBox style={{ marginTop: 0, width: "225px" }}
                      {...form.getInputProps('left_pupil')}
                      key={form.key('left_pupil')}
                    />
                  </Group>
                </Grid.Col>
              </Grid>
              {prescriptionId && (
                <Flex justify="flex-end">
                  <Button variant="light" color="#C92A2A" radius="md" style={{ background: '#FFC9C9' }} onClick={handleDelete}>
                    <Group justify='center' align="center">
                      <Text span fw={600}>Delete Prescripition</Text>
                      {prescriptionLoading2 ? <Loader size="sm" color="white" type='dots' /> : null}
                    </Group>
                  </Button>
                </Flex>
              )}
            </form>
          </Stack>
        </Box>
      </CommonDrawer>
      <Group justify="space-between">
        <Title order={3}>My Prescriptions History</Title>
        <Flex gap={10}>
          <PrimaryButton2
            title="Import Prescription"
            onClick={importCSV}
          />
          <PrimaryButton
            title="Create new prescription"
            onClick={() => {
              setOpenDrawer(true)
              form.reset()
              form.clearErrors()
              form.clearFieldError()
              setPrescriptionId(null)
              setDrawerType("CREATE")
            }}
          />
        </Flex>
      </Group>
      <Stack justify='center' align='center' mt={35}>
        <Grid w="100%" gutter={12}>
          {renderContent()}
        </Grid>
      </Stack>
    </Box>
  );
};

export default MyPrescription;