import React, { useEffect } from 'react';
import { Grid, Box, Stack } from '@mantine/core';
import ProfileCard from '../../../components/Employess/profileCard';
import Prescription from '../../../components/Employess/prescription';
import MyActivity from '../../../components/Employess/activity';
import Tiers from '../../../components/Employess/tiers';

const EmployeeDashboard = () => {
  return (
    <Box style={{ height: '100%' }}>
      <Stack justify='center' align='center' px={24} py={27}>
        <Grid w="100%">
          <Grid.Col span={{ base: 12, md: 12, lg: 8 }}>
            <Stack gap={30}>
              <ProfileCard />
              <Prescription />
            </Stack>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
            <Grid w="100%" gutter={30}>
              <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                <Tiers />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                <MyActivity />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Stack>
    </Box>
  );
};

export default EmployeeDashboard;