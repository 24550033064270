import React, { useEffect, useState } from 'react';
import OrderManagement from '../../components/Billing/OrderManagement';
import PaymentMethod from '../../components/Billing/PaymentMethod';
import Invoice from '../../components/Billing/Invoice';
import TabBaseLayout from '../../components/Layout/TabBaseLayout/TabBaseLayout';

const Billing = () => {

    const tabList = [
        { value: 'ordersManagement', label: 'Orders management' },
        { value: 'invoicingBilling', label: 'Invoicing & Billing' },
        { value: 'paymentMethod', label: 'Payment method' },
    ];

    const [width, setWidth] = useState(window.innerWidth);

    function handleResize() {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <TabBaseLayout tabList={tabList}>
                <OrderManagement />
                <PaymentMethod width={width} />
                <Invoice width={width} />
            </TabBaseLayout>
        </>
    );
};

export default Billing;