import React from 'react'
import billingClass from './billing.module.css';
import classes from '../Button/button.module.css';
import { Box, Button, Group, TextInput, Text, Select } from '@mantine/core';
import { IoIosArrowDown } from 'react-icons/io';

const TaxDetailForm = () => {
    return (
        <Box>
            <Group spacing="lg" align="center" grow mb={20}>
                <TextInput styles={{ label: { fontWeight: "500", marginBottom: "10px" } }} label="Company Name" w="100%" value="" radius={6} placeholder="Enter your company name" />
                <TextInput styles={{ label: { fontWeight: "500", marginBottom: "10px" } }} label="Phone Number" w="100%" value="" radius={6} placeholder="Enter your phone number" />
            </Group>

            <TextInput styles={{ label: { fontWeight: "500", marginBottom: "10px" }, input: { marginBottom: "20px" } }} label="Recipient Email" w="100%" value="" radius={6} placeholder="Enter your email" />
            <TextInput styles={{ label: { fontWeight: "500", marginBottom: "10px" }, input: { marginBottom: "20px" } }} label="Address" w="100%" value="" radius={6} placeholder="Enter your address" />

            <Group spacing="lg" align="center" grow mb={20}>
                <TextInput styles={{ label: { fontWeight: "500", marginBottom: "10px" } }} label="Zip / Postal Code" w="100%" value="" radius={6} placeholder="Enter your Zip/Postal code" />
                <TextInput styles={{ label: { fontWeight: "500", marginBottom: "10px" } }} label="City / District" w="100%" value="" radius={6} placeholder="Enter your country" />
            </Group>

            <Box mb={24}>
                <Select
                    checkIconPosition="left"
                    label="Country"
                    data={['Rua das Couves', 'Rua das', 'das Couves', 'Couves']}
                    placeholder="Please select country"
                    rightSection={<IoIosArrowDown style={{ width: '22px', height: 'auto', marginRight: '5px' }} />}
                    styles={{
                        label: {
                            fontSize: '16px',
                            marginBottom: "10px",
                            lineHeight: "18px",
                            fontWeight: "600",
                            color: "#424242"
                        },
                        input: {
                            height: "40px",
                            fontSize: "14px",
                            padding: "12px",
                            lineHeight: "16px",
                            borderRadius: "6px",
                        },
                        error: {
                            fontSize: '14px',
                            marginTop: "5px",
                            lineHeight: "16px",
                        },
                        required: {
                            color: '#3354F4'
                        },
                        checkIconPosition: {
                            backgroundColor: "red"
                        }
                    }}
                />
            </Box>

            {/* Update Button */}
            <Box style={{ textAlign: "end" }}>
                <Button w={130} h={42} fw={500} styles={{ root: { borderRadius: "10px" } }}>Update Details</Button>
            </Box>
        </Box>
    )
}

export default TaxDetailForm;