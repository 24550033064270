import EmployStatus from "../components/EmployeeManagement/EmployStatus"

export const ORGANIZATION_SIZE = {
    '1 - 100': 1,
    '100 - 500': 2,
    '500 - 1500': 3,
    '1500 - 2500': 4
}

export const EMPLOYEES_AT_SITE_SIZE = {
    '1 - 20': 1,
    '20 - 40': 2,
    '40 - 60': 3,
    '60 - 80': 4,
    '80 - 100': 5
}

export const ORGANIZATION_TYPE = {
    'Type 1': 1,
    'Type 2': 2,
    'Type 3': 3,
    'Type 4': 4,
    'Type 5': 5,
}

export const ORGANIZATION_TYPE_CHOICES = [
    { value: '1', label: 'Type 1' },
    { value: '2', label: 'Type 2' },
    { value: '3', label: 'Type 3' },
    { value: '4', label: 'Type 4' },
    { value: '5', label: 'Type 5' },

]

export const ORGANIZATION_SIZE_CHOICES = [
    { value: '1', label: '1 - 100' },
    { value: '2', label: '100 - 500' },
    { value: '3', label: '500 - 1500' },
    { value: '4', label: '1500 - 2500' },
]

export const INDUSTRY_SERVICED = [
    { value: '1', label: 'Agriculture' },
    { value: '2', label: 'Aerospace' },
    { value: '3', label: 'Automotive' },
    { value: '4', label: 'Aviation' },
    { value: '5', label: 'Construction' },
    { value: '6', label: 'Defense' },
    { value: '7', label: 'Electrical' },
    { value: '8', label: 'Emergency Services' },
    { value: '9', label: 'Farming' },
    { value: '10', label: 'Food Manufacturing' },
    { value: '11', label: 'Gas Operations' },
    { value: '12', label: 'Healthcare' },
    { value: '13', label: 'Hospitality' },
    { value: '14', label: 'Laboratories' },
    { value: '15', label: 'Law Enforcement' },
    { value: '16', label: 'Manufacturing' },
    { value: '17', label: 'Maritime Operations' },
    { value: '18', label: 'Military' },
    { value: '19', label: 'Mining Operations' },
    { value: '20', label: 'Oil Operations' },
    { value: '21', label: 'Retail' },
    { value: '22', label: 'Shipping' },
    { value: '23', label: 'Transportation' },
    { value: '24', label: 'Utilities' },
    { value: '25', label: 'Warehousing' },
    { value: '26', label: 'Welding' },
    { value: '27', label: 'Other' },
];

export const ORGANIZATIONS_EMPLOYEES_AT_SITE_SIZE_CHOICES = [
    { value: '1', label: '1 - 20' },
    { value: '2', label: '20 - 40' },
    { value: '3', label: '40 - 60' },
    { value: '4', label: '60 - 80' },
    { value: '5', label: '80 - 100' },
]

export const COMPANY_SIZE_CHOICES = [
    { value: '1', label: '1 - 100' },
    { value: '2', label: '100 - 500' },
    { value: '3', label: '500 - 1500' },
    { value: '4', label: '1500 - 2500' },
]

export const PARTNER_COMPANIES_TABLE_HEADER = [
    { value: 'name', label: 'Company Name' },
    { value: 'site_name', label: 'Site name' },
    { value: 'email', label: 'Email admin member' },
    { value: 'company_id', label: 'Company ID' },
    { value: 'phone_number', label: 'Phone number' },
    { value: 'status', label: 'Status' },
    { value: 'action', label: 'Action' },
];

export const PARTNER_COMPANIES_STATUS_BLOCK = {
    '1': <EmployStatus status="Invited" className="invited" />,
    '2': <EmployStatus status="Error" className="error" />,
    '3': <EmployStatus status="Active" className="active" />
}