import { Box, Stack, Group, Title, Text, Button, TextInput, Loader, Modal, ActionIcon, Pagination, Flex, Checkbox } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import BaseLayout from "../BaseLayout";
import { useForm } from "@mantine/form";
import { yupResolver } from "mantine-form-yup-resolver";
import * as yup from 'yup';
import { addAdminToOrganizations } from "../../../middlewares/partner/partners.services"
import { notifications } from "@mantine/notifications";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import UploadExcelFileModal from "../../Modal/UploadExcelModal/UploadExcelFileModal";
import { read, utils } from 'xlsx'
import { IconDownload, IconX } from "@tabler/icons-react";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { asyncErrorHandler } from "../../../helpers/errorHandler";
import { InputField } from "../../ui/Input/Input";
import { PrimaryButton } from '../../ui/Button/Button';

const PartnerAdminAssignForm = ({ stepper, width }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({
        name: null,
        site_name: null,
        company_id: null,
        admin_email: null,
        phone_number: null,
    })
    const [opened, { open, close }] = useDisclosure()
    const labels = {
        name: {
            label: "Company name",
            placeholder: "Enter company name",
        },
        site_name: {
            label: "Site name",
            placeholder: "Enter site name",
        },
        company_id: {
            label: "Company ID",
            placeholder: "Enter company id",
        },
        admin_email: {
            label: "Email admin member",
            placeholder: "Enter admin email",
        },
        // first_name: {
        //     label: "Admin first name",
        //     placeholder: "Enter admin first name",
        // },
        // last_name: {
        //     label: "Admin last name",
        //     placeholder: "Enter admin last name",
        // },
        phone_number: {
            label: "Phone number",
            placeholder: "Enter admin phone number",
        },
    };
    const validateOrganizations = yup.object().shape({
        organizations: yup.array().of(
            yup.object().shape({
                name: yup.string().required('Company name is required').matches(/^(?=.*[A-Za-z])(?!^[\d\s-]+$)(?!^[^\w\s-]+$)[A-Za-z\d\s-]+$/, 'Please enter company name'),
                site_name: yup.string().required('Site url is required').matches(/^(https?|ftp):\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$|^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/i, 'Please enter a valid url'),
                company_id: yup.string().required('Company id is required').matches(/^[a-zA-Z0-9_]+$/, 'Please enter a valid company id'),
                admin_email: yup.string().required('Email is required').email('Invalid email'),
                // first_name: yup.string().required('First name is required'),
                // last_name: yup.string().required('Last name is required'),
                phone_number: yup.string().required('Phone number is required').matches(/^\+?[1-9]\d{0,2}(021|\d{1,4})\d{1,9}$/, 'Please enter a valid contact'),
            })
        )
    })

    const form = useForm({
        initialValues: {
            organizations: [
                {
                    id: 1,
                    admin_email: '',
                    name: '',
                    site_name: '',
                    company_id: '',
                    phone_number: '',
                },
            ]
        },
        validate: yupResolver(validateOrganizations)
    })

    const adminDetails = form.values.organizations.map((company, index) => {
        return (
            <Flex direction={width < 991 ? "column" : "row"} key={index} grow gap={12} wrap="nowrap" align="center" preventGrowOverflow={false}>
                <Checkbox
                    mt={25}
                    checked={company.isSelected || false}
                    styles={{ input: { cursor: "pointer" } }}
                    onChange={(e) => {
                        const newArr = [...form.values.organizations]
                        const updatedOrg = newArr.map((org) => {
                            if (company.id === org.id) {
                                return {
                                    ...org,
                                    isSelected: e.target.checked
                                }
                            } else {
                                return org
                            }
                        })
                        form.setFieldValue('organizations', updatedOrg)
                    }}
                />
                {Object.entries(company).map(([key, value]) => {
                    return (key === "isSelected" || key === "id") ? null : (
                        <InputField
                            label={<Text fz={16} fw={500}>{labels[key]?.label}</Text>}
                            placeholder={labels[key]?.placeholder}
                            key={form.key(`organizations.${index}.${key}`)}
                            {...form.getInputProps(`organizations.${index}.${key}`)}
                            error={form.errors[`organizations.${index}.${key}`] ? form.errors[`organizations.${index}.${key}`] : errors[key]}
                            withAsterisk={true}
                        />
                    )
                })}
                {form.values.organizations?.length > 1 && (
                    <ActionIcon mb={6} variant="transparent" onClick={() => form.removeListItem('organizations', index)}>
                        <IconX size='sm' color={"#3354F4"} />
                    </ActionIcon>
                )}
            </Flex>
        )
    })

    const addAnotherCompany = (organizationDetails = {}) => {
        if (organizationDetails && Object.keys(organizationDetails).length > 0) {
            organizationDetails.phone_number = String(organizationDetails.phone_number)
            form.insertListItem('organizations', organizationDetails);
        }
        else {
            form.insertListItem('organizations', {
                id: !form.values.organizations[form.values.organizations.length - 1]?.id ? 1 : form.values.organizations[form.values.organizations.length - 1]?.id + 1,
                name: '',
                site_name: '',
                company_id: '',
                admin_email: '',
                // first_name: '',
                // last_name: '',
                phone_number: '',
            });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (form.validate().hasErrors) {
            form.validate()
        }
        else {
            setErrors({
                name: null,
                site_name: null,
                company_id: null,
                admin_email: null,
                phone_number: null,
            })
            setLoading(true)
            const orgs = form.values.organizations.map((org) => ({
                ...org,
                site_name: org.site_name.includes("https://") ? org.site_name : `https://${org.site_name}`
            }))
            const res = await dispatch(addAdminToOrganizations({ organizations: orgs }))
            setLoading(false)
            if (addAdminToOrganizations.fulfilled.match(res)) {
                window.toast('You have registered organizations successfully')
                localStorage.setItem('inProcess', 'false')
                navigate('/partner/dashboard')
            } else if (addAdminToOrganizations.rejected.match(res)) {
                asyncErrorHandler(res, form)
            }
        }
    }

    const uploadExcelFile = async (excelFile) => {
        try {
            const bufferedArray = await excelFile.arrayBuffer()

            const workbook = read(bufferedArray)
            const worksheet = workbook.Sheets[workbook.SheetNames[0]] // getting the first sheet
            const data = utils.sheet_to_json(worksheet)

            if (data.length > 0) {
                const updatedOrganizations = form.values.organizations.filter(organization =>
                    Object.values(organization).some(value => value)
                );
                form.setFieldValue('organizations', updatedOrganizations);
                data.forEach((row) => { addAnotherCompany(row) })
            }

        }
        catch (err) {
            console.log("THIS IS ERROR", err)
        }

        close()
    }


    const downloadTemplate = () => {
        // Extract headers from all organization objects
        const allKeys = form.values.organizations.flatMap((obj) => Object.keys(obj).filter((k) => k !== "id"));
        const headers = Array.from(new Set(allKeys)); // Ensure unique headers
        // Prepare an array of objects representing Excel rows
        const excelData = form.values.organizations.map((company) => {
            return headers.reduce((row, key) => {
                row[key] = company[key] || ""; // Populate with value or empty string
                return row;
            }, {});
        });

        // Create a workbook and add a worksheet with the data
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(excelData, { header: headers });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'TemplateData');

        // Generate the Excel file as a Blob
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

        // Use FileSaver to trigger the download
        saveAs(blob, 'TemplateData.xlsx');
    };

    return (
        <>
            <BaseLayout stepper={stepper} width={width}>
                <UploadExcelFileModal opened={opened} close={close} onDrop={uploadExcelFile} />
                <Box ta="center">
                    <Text fz={24} fw={600} mb={6}>Send and invite to new company</Text>
                    <Text c="dimmed" fz={16}>Easily batch upload your companies using our Excel template</Text>
                </Box>
                <Box p={40} w="100%" maw={1375} mx="auto">
                    <form onSubmit={handleSubmit}>
                        <Stack gap={40}>
                            <Group justify="space-between" align="flex-start">
                                <Flex gap={12} direction={width < 593 ? "column" : "row"} w={width < 593 && "100%"}>
                                    <PrimaryButton
                                        bg="#099268"
                                        title="Upload (excel/sheets)"
                                        onClick={open}
                                        w={width < 593 && "100%"}
                                        size="md"
                                        ml='auto'
                                        leftSection={<IconDownload />}
                                    />
                                    <PrimaryButton
                                        title="Template"
                                        onClick={downloadTemplate}
                                        w={width < 593 && "100%"}
                                        size="md"
                                        ml='auto'
                                        leftSection={<IconDownload />}
                                    />
                                </Flex>
                                <Flex gap={12} direction={width < 593 ? "column" : "row"} w={width < 593 && "100%"}>
                                    {form.values.organizations.filter((org) => org.isSelected === true)?.length > 0 && (
                                        <PrimaryButton
                                            title="Delete company"
                                            className='danger-button'
                                            onClick={() => {
                                                const updatedOrg = form.values.organizations.filter((org) => !org.isSelected)
                                                console.log(updatedOrg);
                                                form.setFieldValue('organizations', updatedOrg)
                                            }}
                                        />
                                    )}
                                    <PrimaryButton
                                        title="Add Row"
                                        variant="light"
                                        c="#3354F4"
                                        bg="#DBE4FF"
                                        w={width < 593 ? "100%" : 139}
                                        onClick={() => addAnotherCompany()}
                                        size="md"
                                    />
                                </Flex>
                            </Group>
                            {adminDetails}
                            <Group justify='space-between' align='center' my={24}>
                                {form.values.organizations?.length > 8 && <Pagination total={3} withEdges mr="24px" />}
                                <PrimaryButton
                                    type="submit"
                                    title="Send Invite"
                                    disabled={loading}
                                    w={width < 593 && "100%"}
                                    size="md"
                                    ml='auto'
                                    fallback={loading && <Loader size="sm" type='dots' color='#ffff' />}
                                />
                            </Group>
                        </Stack>
                    </form>
                </Box>
            </BaseLayout>
        </>
    )
}

export default PartnerAdminAssignForm