import { notifications } from "@mantine/notifications";

export const asyncErrorHandler = (res, form, setImageError) => {
    const errorDetails = res.payload?.response?.data || res?.payload;    
    let networkError = errorDetails ? null : res.payload?.message;
    let message;
    if (networkError) {
        notifications.show({
            message: networkError,
            autoClose: 3000,
            color: 'red',
            style: {
                width: '400px',
                position: "fixed",
                bottom: "6rem",
                right: "2rem",
                zIndex: 1000000,
            },
        });
    } 
    if (errorDetails?.error?.message === "Integrity Error") {
        notifications.show({
            message: errorDetails?.error?.details || errorDetails?.error?.message,
            autoClose: 3000,
            color: 'red',
            style: {
                width: '400px',
                position: "fixed",
                bottom: "6rem",
                right: "2rem",
                zIndex: 1000000,
            },
        });
    }
    if (errorDetails?.error?.message === "Profile not found") {
        notifications.show({
            message: errorDetails?.error?.details?.detail || errorDetails?.error?.message,
            autoClose: 3000,
            color: 'red',
            style: {
                width: '400px',
                position: "fixed",
                bottom: "6rem",
                right: "2rem",
                zIndex: 1000000,
            },
        });
    }
    else {
        if (errorDetails?.error?.details) {
            if (typeof errorDetails?.error?.details === "string") {
                message = errorDetails?.error?.details;
                form.setFieldError("email", message);
            }
            if (Array.isArray(errorDetails?.error?.details) && errorDetails?.error?.details.length > 0) {
                message = errorDetails?.error?.details[0];
                form.setFieldError("email", message);
            }
            if (Array.isArray(errorDetails?.error?.details) === false && typeof errorDetails?.error?.details !== "string") {
                if (errorDetails?.error?.details?.admin_email) {
                    form.setFieldError('admin_email', errorDetails?.error?.details?.admin_email?.details);
                }
                else {
                    Object.entries(errorDetails?.error?.details).forEach(([key, value]) => {
                        if (key === "non_field_errors") {
                            form.setFieldError("email", Array.isArray(value) && value.length ? value[0] : value);
                        } else {
                            form.setFieldError(key, Array.isArray(value) && value.length ? value[0] : value);
                        }
                    });
                }
            }
            if (errorDetails?.error?.details?.image && setImageError) {
                setImageError(errorDetails?.error?.details?.image[0])
            }
            if (errorDetails?.error?.details?.logo && setImageError) {
                setImageError(errorDetails?.error?.details?.logo)
            }
            if (errorDetails?.error?.details?.organizations) {
                const errors = errorDetails?.error?.details?.organizations;
                errors.forEach((item, index) => {
                    Object.keys(item).forEach((key) => {
                        const value = item[key];
                        form.setFieldError(`organizations.${index}.${key}`, Array.isArray(value) && value.length > 0 ? value[0] : typeof value === "string" ? value : value?.details);
                    });
                });
            }
        } else {
            form.setFieldError("verification_code", errorDetails?.detail);
        }
    }
}