import React from 'react'
import TabBaseLayout from '../../components/Layout/TabBaseLayout/TabBaseLayout';
import Form from '../../components/SafetyAwards/Form';
import ManageParticipants from './pages/participants';
import AwardRequests from './pages/awardRequest';
import { Box, Text } from '@mantine/core';

const EditSafetyAwards = () => {

  const tabList = [
    { value: 'programInfo', label: 'Program info' },
    { value: 'rewards', label: 'Rewards' },
    { value: 'participants', label: 'Participants' },
    { value: 'requests', label: 'Requests' },
  ];

  return (
    <TabBaseLayout tabList={tabList}>
      <Form />
      <Box py={24} pr={24}>
        <Text fz={24} fw={600} c={'#212529'}>Rewards</Text>
      </Box>
      <ManageParticipants />
      <AwardRequests />
    </TabBaseLayout>
  )
}

export default EditSafetyAwards;