import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    employeeFilters: {
        tier: [],
        rx_non_rx: [],
        status: [],
    },
    searchEmployeeByName: ''
}

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setEmployeeFilters: (state, { payload }) => {
            state.employeeFilters = payload

        },
        setSearchEmployeeByName: (state, { payload }) => {
            state.searchEmployeeByName = payload
        }
    }
})

export const { setEmployeeFilters, setSearchEmployeeByName } = filterSlice.actions
export default filterSlice.reducer

