import React from 'react'
import { Box, Group, Radio, Text } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from "../../../components/ui/Button/Button";
import { useSelector } from 'react-redux';

const OrderSummary = ({ paymentTypes, setPaymentTypes, totalPrice }) => {

    const navigate = useNavigate()
    const cart = useSelector((state) => state.cart.shippingDetails.cart)
    const isSelected = cart?.some((item) => item.isSelected)

    return (
        <Box bd="2px solid #DEE2E6" p={32} style={{ borderRadius: "10px" }} h="100%" w={430}>
            <Text fz={24} fw="bold">Order Summary</Text>
            <Box mt={24}>
                <Text mb={12}>Select payment type</Text>
                {paymentTypes?.map((paymentType) => {
                    return (
                        <Box
                            onClick={() => {
                                setPaymentTypes((prevTypes) => {
                                    return prevTypes.map((type) => {
                                        if (type.id === paymentType.id) {
                                            return {
                                                ...type,
                                                selected: true
                                            }
                                        } else {
                                            return {
                                                ...type,
                                                selected: false
                                            }
                                        }
                                    })
                                })
                            }}
                            key={paymentType.id} bd="2px solid #DEE2E6" style={{ borderRadius: "10px", cursor: "pointer" }} mb={12} py={12} px={16}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Radio mr={16} checked={paymentType?.selected} color="#5C5F66" />
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                    <Text c={paymentType?.selected ? "#5C5F66" : "#A6A7AB"}>{paymentType.value}</Text>
                                    <Text c={paymentType?.selected ? "#5C5F66" : "#A6A7AB"}>{paymentType.points}</Text>
                                </div>
                            </div>
                        </Box>
                    )
                })}
                <Group justify="space-between" mb={16} mt={24}>
                    <Text c="#2C2E33" fz={20}>Estimated Tax</Text>
                    <Text c="#2C2E33" fz={20} fw={600}>$50</Text>
                </Group>
                <Group justify="space-between">
                    <Text c="#2C2E33" fz={20}>Total</Text>
                    <Text c="#2C2E33" fz={20} fw={600}>${totalPrice}</Text>
                </Group>
                <PrimaryButton
                    title="Checkout"
                    bg={isSelected ? "" : "#F1F3F5"}
                    c={isSelected ? "" : '#ADB5BD'}
                    disabled={isSelected ? false : true}
                    w="100%"
                    h={56}
                    mt={48}
                    onClick={() => navigate("/employee/checkout/select-prescription")}
                />
            </Box>
        </Box>
    )
}

export default OrderSummary