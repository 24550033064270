import React, { useState, useMemo } from 'react'
import { Box, Button, Checkbox, Flex, Grid, Group, Stack, Text, TextInput } from '@mantine/core'
import { DateInput } from '@mantine/dates';
import frame from '../../../assets/images/small-frame.png'
import { PrimaryButton } from '../../ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setStep } from '../../../store/employess/stepSlice';
import { setOrders } from '../../../store/employess/ordersSlice';

const Summary = ({ setActiveStep }) => {

    const paymentTypes = ['Credit Card', 'PayPal', 'PayPal Credit'];
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cartItems = useSelector((state) => state.cart.shippingDetails.selectedCartItems)
    const shippingDetails = useSelector((state) => state.cart.shippingDetails)
    const addresses = useSelector((state) => state.address.address)
    const [selectedType, setSelectedType] = useState("Credit Card");

    const handleCheckout = () => {
        const payload = {
            items: shippingDetails.selectedCartItems,
            prescription: shippingDetails.prescription,
            address: shippingDetails.address,
            status: 1,
            count: shippingDetails?.selectedCartItems?.length,
            createdAt: "November 28, 2015",
            orderId: Math.random(Math.ceil() * 10),
        }
        dispatch(setOrders(payload))
        window.toast("🚀 Order placed! Please check your email for confirmation and next steps.")
    }

    const totalPrice = useMemo(() => {
        return cartItems?.reduce((a, b) => a + (b.totalPrice || 0), 0) || 0;
    }, [cartItems]);

    return (
        <Box>
            <Grid gutter={12}>
                <Grid.Col span={{ md: 6, lg: 6 }}>
                    <Box bd="2px solid #D0D5DD" p={24} style={{ borderRadius: "20px", height: "100%" }}>
                        <Text fw={700} fz={20} mb={16} c="#141517">Summary</Text>
                        {cartItems?.map((item, index) => {
                            return (
                                <Box bg="#F8F9FA" p={16} style={{ borderRadius: "20px", cursor: "pointer" }} mb={index < index + 1 && 12}>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 20
                                    }}>
                                        <img style={{
                                            border: "2px solid #D0D5DD", borderRadius: "10px", width: "70px", height: "70px", objectFit: "contain"
                                        }} src={item?.frame_image} alt="" />
                                        <Box w="100%">
                                            <Group justify="space-between">
                                                <Text fw={700} fz={20} c="#344054">{item?.glass_brand?.frame_name}</Text>
                                                <Text fw={700} fz={18} c="#141517">${item?.totalPrice}</Text>
                                            </Group>
                                            <Group justify="space-between">
                                                <Box>
                                                    <Group>
                                                        <Text fw={500} fz={16} c="#667085">Color</Text>
                                                        <Text fw={500} fz={16} c="#D0D5DD">|</Text>
                                                        <Text fw={500} fz={16} c="#667085">Size</Text>
                                                        <Text fw={500} fz={16} c="#D0D5DD">|</Text>
                                                        <Text fw={500} fz={16} c="#667085">Name</Text>
                                                    </Group>
                                                </Box>
                                                <Text fw={700} fz={16} c="#667085">Oty: {item?.qty}</Text>
                                            </Group>
                                        </Box>
                                    </div>
                                </Box>
                            )
                        })}
                        <Text c="#545454" ff="SF-pro-display-medium" fw={500} mb={20}>Address</Text>
                        <Text c="#000000" ff="SF-pro-display-medium" fw={500} mb={28}>{addresses?.find((add) => add.isSelected)?.address || ""}</Text>
                        <Group justify="space-between">
                            <Text c="#000000" fwff="SF-pro-display-medium" fw={500} mb={20}>Subtotal</Text>
                            <Text c="#000000" fwff="SF-pro-display-medium" fw={500}>${totalPrice || 0}</Text>
                        </Group>
                        <Group justify="space-between">
                            <Text c="#545454" ff="SF-pro-display-medium" fw={500} mb={20}>Estimated Tax</Text>
                            <Text c="#545454" ff="SF-pro-display-medium" fw={500}>$0</Text>
                        </Group>
                        <Group justify="space-between">
                            <Text c="#545454" ff="SF-pro-display-medium" fw={500} mb={20}>Estimated shipping & Handling</Text>
                            <Text c="#545454" ff="SF-pro-display-medium" fw={500}>$0</Text>
                        </Group>
                        <Group justify="space-between">
                            <Text c="#000000" ff="SF-pro-display-medium" fw={500} mb={20}>Address</Text>
                            <Text c="#000000" ff="SF-pro-display-medium" fw={500}>${totalPrice || 0}</Text>
                        </Group>
                    </Box>
                </Grid.Col>
                <Grid.Col span={{ md: 6, lg: 6 }}>
                    <Box p={16} style={{ borderRadius: "20px", cursor: "pointer" }}>
                        <Text fz={20} mb={24} c="#141517" ff="SF-pro-display-bold">Payment</Text>
                        <Group gap={60}>
                            {paymentTypes.map((type, index) => {
                                const id = `${type}__${index}`
                                return (
                                    <Text
                                        onClick={() => setSelectedType(type)}
                                        key={id}
                                        ff="SF-pro-display-medium"
                                        fw={500}
                                        styles={{ root: { borderBottom: selectedType === type && "3px solid #000000" } }}>
                                        {type}
                                    </Text>
                                )
                            })}
                        </Group>
                        <Box mt={48}>
                            <Stack>
                                <TextInput styles={{ input: { height: "48px", border: "2px solid #CED4DA", borderRadius: "6px", color: "#A6A7AB", fontWeight: "500" } }} placeholder='Cardholder Name' />
                                <TextInput styles={{ input: { height: "48px", border: "2px solid #CED4DA", borderRadius: "6px", color: "#A6A7AB", fontWeight: "500" } }} placeholder='Card Number' />
                                <div style={{ display: "flex" }}>
                                    <DateInput placeholder="Exp.Date" w="100%" styles={{ input: { height: "48px", border: "2px solid #CED4DA", borderRadius: "6px", color: "#A6A7AB", fontWeight: "500" } }} />
                                    <TextInput ml={16} w="100%" styles={{ input: { height: "48px", border: "2px solid #CED4DA", borderRadius: "6px", color: "#A6A7AB", fontWeight: "500" } }} placeholder='CVV' />
                                </div>
                                <Group align="center" my={48}>
                                    <Checkbox />
                                    <Text ff="SF-pro-display-medium" fw={500}>Same as billing address</Text>
                                </Group>
                            </Stack>
                        </Box>
                        <Flex gap={40}>
                            <PrimaryButton w="100%" variant="outline" h={54} onClick={() => navigate("/employee/checkout/select-address")} title="Back" />
                            <PrimaryButton w="100%" h={54} title="Buy" onClick={handleCheckout} />
                        </Flex>
                    </Box>
                </Grid.Col>
            </Grid>
        </Box>
    )
}

export default Summary;