import { Stack, Text, Group } from "@mantine/core"
import { NavLink, useLocation } from "react-router-dom"
import { renderTabs } from "../../lib/routes"
import { useSelector, useDispatch } from "react-redux"
import { clearOrganizations2, setOrganizationList2 } from "../../store/organization/organizationSlice"
import './BaseSidebar.css'
import { useEffect } from "react"

const BaseSidebar = () => {

    const user = useSelector((state) => state.login?.user)
    const organizationList = useSelector((state) => state.organizations.organizationList);
    const dispatch = useDispatch()
    const location = useLocation()

    function showAllOrg() {
        dispatch(clearOrganizations2());
        dispatch(setOrganizationList2(organizationList));
    }

    const navigationTile = renderTabs(user?.role).map(navigation => {
        return (
            <NavLink
                key={navigation.name}
                to={navigation.link}
                className={({ isActive }) => isActive ? 'active' : 'navlink'}>
                <Group h={45} gap={8} py={12} pl={12} justify="flex-start" align="center" wrap="nowrap" >
                    {navigation.icon}
                    <Text fz={16} fw={500}>{navigation.name}</Text>
                </Group>
            </NavLink>
        )
    })

    useEffect(() => {
        if (location.pathname === "/partner/company-management") {
            showAllOrg()
        }
    }, [location.pathname])

    return (
        <Stack gap={4}>{navigationTile}</Stack>
    )
}
export default BaseSidebar