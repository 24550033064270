import React from 'react'
import { Box, Card, Group, Text } from '@mantine/core';
import classes from '../../Dashboard/card.module.css'
import { IconCarambola } from '@tabler/icons-react';

const Product = ({ item, onClick, navigate }) => {
  return (
    <Card p={0} radius={24} className={classes.card} key={item?.id} onClick={navigate}>
      <Box p={24}>
        <Group justify="end" mb={16}>
          <IconCarambola
            onClick={(e) => {
              e.stopPropagation()
              onClick()
            }}
            color="#909296"
            fill={item?.liked ? "#EDF2FF" : "white"}
            size={30}
          />
        </Group>
        <Group justify="center">
          <img style={{ width: "300px", height: "101px", objectFit: "cover" }} src={item?.frame_image} />
        </Group>
      </Box>
      <Box bg="#F1F3F5" p={16} c="#F1F3F5">
        <Text fw={600} mb={8} c="#5C5F66">{item?.glass_brand?.frame_name}</Text>
        <Text fw={600} c="#373A40">${item?.price || 0}</Text>
      </Box>
    </Card>
  )
}

export default Product