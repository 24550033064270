import React, { useEffect } from 'react';
import { Box, Grid, Group, Skeleton, Text } from '@mantine/core';
import { IoIosArrowBack } from 'react-icons/io';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ImageMagnifier from '../../../components/Employess/productDetails/imageMagnifier';
import ProductDetails from '../../../components/Employess/productDetails/productDetail';
import ProductReviews from '../../../components/Employess/productDetails/productReviews';
import { useDispatch, useSelector } from 'react-redux';
import Product from '../../../components/Employess/RFXNStore/product';
import { addToFavourites, deleteFromFavourites, fetchEyewearDetailById } from '../../../middlewares/employee/employee.service';
import { setProductDetail } from '../../../store/employess/productSlice';

const ProductDetail = () => {

    const dispatch = useDispatch()
    const variantDetail = useSelector((state) => state.product.productDetail);
    const loading = useSelector((state) => state.product.loading)
    const navigate = useNavigate()
    const { id } = useParams()

    async function handleAddToFavourite(item) {
        const updatedProducts = variantDetail?.related_items?.map((prod) => prod.id === item.id ? { ...prod, liked: !prod.liked } : prod);
        dispatch(setProductDetail({ ...variantDetail, related_items: updatedProducts }));
        if (item?.liked === false) {
            const res = await dispatch(addToFavourites({ glass_variant: item.id }))
            if (res.type === "addToFavourites/fulfilled") {
                window.toast(`Added to favourites successfully`)
            }
        } else {
            const res = await dispatch(deleteFromFavourites(item.id))
            if (res.type === "deleteFromFavourites/fulfilled") {
                window.toast(`Deleted from favourites successfully`)
            }
        }
    }

    useEffect(() => {
        dispatch(fetchEyewearDetailById({ id: id, type: "0" }))
    }, [id])

    return (
        <Box p={{ base: 12, md: 24 }} style={{ backgroundColor: "white" }}>
            <Group gap={6}>
                <IoIosArrowBack />
                <Link to="/employee/store" style={{ textDecoration: 'none' }}>
                    <Text c="#101113">Go Back</Text>
                </Link>
            </Group>
            <Box mt={30}>
                <Grid gutter="md">
                    <Grid.Col span={{ base: 12, md: 6 }}>
                        <ImageMagnifier />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6 }}>
                        {loading ? <Skeleton height="678.53px" width="100%" mb={24} /> : <ProductDetails />}
                    </Grid.Col>
                </Grid>
            </Box>
            <Box mt={50}>
                <ProductReviews />
            </Box>
            <Box mt={50}>
                <Grid gutter="md">
                    {loading ? (
                        <>
                            <Grid.Col span={{ md: 4, lg: 4 }}>
                                <Skeleton height="342.59px" width="528.33px" styles={{ root: { borderRadius: "22px", marginBottom: "5px" } }} />
                            </Grid.Col>
                            <Grid.Col span={{ md: 4, lg: 4 }}>
                                <Skeleton height="342.59px" width="528.33px" styles={{ root: { borderRadius: "22px", marginBottom: "5px" } }} />
                            </Grid.Col>
                            <Grid.Col span={{ md: 4, lg: 4 }}>
                                <Skeleton height="342.59px" width="528.33px" styles={{ root: { borderRadius: "22px", marginBottom: "5px" } }} />
                            </Grid.Col>
                        </>
                    ) : variantDetail?.related_items?.map((item) => (
                        <Grid.Col span={{ md: 4, lg: 4 }} key={item.id}>
                            <Product
                                item={item}
                                onClick={() => handleAddToFavourite(item)}
                                navigate={() => {
                                    navigate(`/employee/product/${item.id}`);
                                    dispatch(fetchEyewearDetailById({ id: item.id, type: "0" }));
                                }}
                            />
                        </Grid.Col>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default ProductDetail;