import { Box, Card, Group, Stack, Title, Text, Pagination, Container, Divider, Grid, Menu, Flex, rem, Button } from "@mantine/core"
import { IconChevronDown, IconCalendar, IconEdit } from "@tabler/icons-react"
import TableComp from "../../components/CompanyManagement/Partner/SubContractor/Components/Table"
import { EMPLOYEE_FRAME_AND_LENS, EMPLOYEE_STATUS_BLOCK } from "../../choices/employee.choice";
import { PARTNER_USERS } from "../../mock/partners/tables/headers/tableHeader";
import { PARTNERS_DASHBOARD_USERS } from '../../mock/partners/tables/data/tableData';
import { Calendar } from "@mantine/dates";
import { useMediaQuery } from "@mantine/hooks";
import { IoIosArrowDown } from "react-icons/io";
import { useState } from "react";
import { BsCalendarEvent } from "react-icons/bs";
import { FiFilter } from "react-icons/fi";

const PartnerDashboard = () => {

    const [showMenu, setShowMenu] = useState(false)
    const isSmallScreen = useMediaQuery("(max-width: 991px)");

    const data = PARTNERS_DASHBOARD_USERS.map((users) => {
        return {
            ...users,
            status: EMPLOYEE_STATUS_BLOCK[users?.status],
            checked: false,
            frameLens: EMPLOYEE_FRAME_AND_LENS[users.frameLens],
            actions:
                <>
                    <Group w="max-content" onClick={() => console.log(users.id)} gap={8} justify='center' align='center' c={'#868E96'} styles={{ root: { cursor: "pointer" } }}>
                        <IconEdit style={{ width: '16px', height: '16px' }} />
                        <p>Edit</p>
                    </Group>
                </>
        }
    })

    const mobileMenu = () => {
        return (
            <>
                <Group gap={5} justify="space-between">
                    <Flex align="center">
                        <Text span fw={600} fz={16} c="#5C5F66">AMAZON INC.</Text>
                        <IconChevronDown fontSize={24} color="#5C5F66" />
                    </Flex>
                    {/* <IoIosArrowDown
                        size={20}
                        color="#5C5F66"
                        style={{
                            transform: showMenu ? "rotate(0deg)" : "rotate(180deg)",
                            transition: "transform 0.3s ease"
                        }}
                        onClick={() => setShowMenu(!showMenu)}
                    /> */}
                    {/* {showMenu && (
                        <Group gap={20} w="100%" justify="space-between" mt={20}>
                            <Text span key="all" fw={400} fz={14} c="#5C5F66">All</Text>
                            <Text span key="today" fw={400} fz={14} c="#5C5F66">Today</Text>
                            <Text span key="lastWeek" fw={400} fz={14} c="#5C5F66">Last 7 Days</Text>
                            <Text span key="lastMonth" fw={400} fz={14} c="#5C5F66">Last 30 Days</Text>
                            <Group gap={12} wrap='nowrap'>
                                <IconCalendar size={14} color="#5C5F66" />
                                <Text span fw={400} fz={14} c="#5C5F66">Select date</Text>
                                <IconChevronDown size={14} color="#5C5F66" />
                            </Group>
                        </Group>
                    )} */}
                    <Menu shadow="md" width={200}>
                        <Menu.Target>
                            <Button style={{
                                backgroundColor: '#E9ECEF',
                                border: "2px solid #ADB5BD",
                                borderRadius: "6px",
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#343A40",
                            }}>
                                <FiFilter style={{ marginRight: 10, color: "#ADB5BD" }} /> Filter
                            </Button>
                        </Menu.Target>

                        <Menu.Dropdown>
                            <Menu.Item fw={400} fz={14} c="#5C5F66">All</Menu.Item>
                            <Menu.Item fw={400} fz={14} c="#5C5F66">Today</Menu.Item>
                            <Menu.Item fw={400} fz={14} c="#5C5F66">Last 7 Days</Menu.Item>
                            <Menu.Item fw={400} fz={14} c="#5C5F66">Last 30 Days</Menu.Item>
                            <Menu shadow="md">
                                <Menu.Target>
                                    <Text py={6} px={12} fw={400} fz={14} c="#5C5F66">Select date</Text>
                                    {/* <Group gap={12} wrap='nowrap'>
                                        <IconCalendar size={14} color="#5C5F66" />
                                        <Text span fw={400} fz={14} c="#5C5F66">Select date</Text>
                                        <IconChevronDown size={14} color="#5C5F66" />
                                    </Group> */}
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <Calendar />
                                </Menu.Dropdown>
                            </Menu>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </>
        )
    }

    const desktopMenu = () => {
        return (
            <>
                <Group gap={5}>
                    <Text span fw={600} fz={16} c="#5C5F66">AMAZON INC.</Text>
                    <IconChevronDown fontSize={24} color="#5C5F66" />
                </Group>
                <Group gap={20} justify="flex-end">
                    <Text span key="all" fw={400} fz={14} c="#5C5F66">All</Text>
                    <Text span key="today" fw={400} fz={14} c="#5C5F66">Today</Text>
                    <Text span key="lastWeek" fw={400} fz={14} c="#5C5F66">Last 7 Days</Text>
                    <Text span key="lastMonth" fw={400} fz={14} c="#5C5F66">Last 30 Days</Text>
                    <Menu shadow="md">
                        <Menu.Target>
                            <Group gap={12} wrap='nowrap'>
                                <IconCalendar size={14} color="#5C5F66" />
                                <Text span fw={400} fz={14} c="#5C5F66">Select date</Text>
                                <IconChevronDown size={14} color="#5C5F66" />
                            </Group>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Calendar />
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </>
        )
    }

    return (
        <Box style={{ height: '100%' }}>
            <Stack>
                <Group px={24} pt={18} pb={0} grow justify="space-between" align="center">
                    {isSmallScreen ? mobileMenu() : desktopMenu()}
                </Group>
                <Divider />
                <Stack px={24} pt={8} pb={24} style={{ height: '100%' }}>
                    <Grid grow gutter={12} type="container">
                        <Grid.Col span={{ sm: 12, md: 4, lg: 4, xl: "auto" }}>
                            <Card key="total_balance" h="100%" mih={98} px={24} py={20} bg="#212529" c='#FFFFFF' radius={16}>
                                <Stack gap={8}>
                                    <Title fz="md" fw={400}>Total Balance</Title>
                                    <Title fz="h3">$3140.72</Title>
                                </Stack>
                            </Card>
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 4, lg: 4, xl: "auto" }}>
                            <Card key="total_spending" h="100%" mih={98} px={24} py={20} withBorder radius={16}>
                                <Stack gap={8}>
                                    <Title fz="md" c="#5C5F66" fw={400}>Total Spending</Title>
                                    <Title fz="h3" c="#212529">$42.040</Title>
                                </Stack>
                            </Card>
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 4, lg: 4, xl: "auto" }}>
                            <Card key="total_spending2" h="100%" mih={98} px={24} py={20} withBorder radius={16}>
                                <Stack gap={8}>
                                    <Title fz="md" c="#5C5F66" fw={400}>Total Spending</Title>
                                    <Title fz="h3" c="#212529">$42.040</Title>
                                </Stack>
                            </Card>
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 4, lg: 4, xl: "auto" }}>
                            <Card key="total_spending3" h="100%" mih={98} px={24} py={20} withBorder radius={16}>
                                <Stack gap={8}>
                                    <Title fz="md" c="#5C5F66" fw={400}>Total Spending</Title>
                                    <Title fz="h3" c="#212529">$42.040</Title>
                                </Stack>
                            </Card>
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 4, lg: 4, xl: "auto" }}>
                            <Card key="reporting_and_analytics" h="100%" mih={98} px={24} py={20} withBorder radius={16}>
                                <Stack gap={8}>

                                    <Title fz="md" c="#5C5F66" fw={400}>Reports and analytics</Title>
                                    <Group display="flex" align="flex-end">
                                        <Title fz="h3" w={'max-content'}>40% <span style={{ fontSize: "13px", lineHeight: "16px", display: 'inline-block', width: 'max-content' }}>Purchases increased</span></Title>
                                    </Group>
                                </Stack>
                            </Card>
                        </Grid.Col>
                    </Grid>

                    {/* <Group grow gap={14} align="flex-start"> */}
                    {/* <Stack gap={26}> */}
                    {/* <Card withBorder mih={461} radius={16}> */}
                    {/* </Card> */}
                    {/* <Box mt='auto'>
                                <Group justify='space-between' align='center' my={24}>
                                <Text c="dimmed" fz={14} fw={400}>Showing 17/200 results</Text>
                                <Pagination size="sm" total={10} withEdges siblings={0} boundaries={1} defaultValue={1} />
                                </Group>
                                </Box> */}
                    {/* </Stack> */}
                    <Grid gutter={14}>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 8 }}>
                            <TableComp
                                tableHeaders={PARTNER_USERS}
                                data={data}
                                isCheckBox={true}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                            <Card withBorder mih={461} radius={16}></Card>
                        </Grid.Col>
                    </Grid>
                    {/* </Group> */}
                </Stack>
            </Stack>
        </Box>
    )
}

export default PartnerDashboard;