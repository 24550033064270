import React from 'react'
import { Box, Divider, Group, Text } from '@mantine/core'
import { CATEGORIES } from '../../../mock/employees/RFXNStore'

const Categories = () => {
    return (
        <Box bg="#F1F3F5" py={14} px={24} w="100%" bd="1px solid #DEE2E6">
            <Group>
                {CATEGORIES.map((category, index) => {
                    return (
                        <>
                            {category.icon}
                            <Text c="#A6A7AB" fw={600}>{category.category}</Text>
                            {index < CATEGORIES.length - 1 && <Divider orientation="vertical" color="#A6A7AB" />}
                        </>
                    )
                })}
            </Group>
        </Box>
    )
}

export default Categories