import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    organization: null,
    isLoading: false,
    organizationList: [],
    organizationList2: [],
    organizationAdmins: [],
    selectedOrganization: null,

    // sub-contractor form state
    subContractorForm: {
        logo: null || "",
        name: "",
        company_id: "",
        size: null,
        country: "",
        site_name: "",
        employees_at_site: null,
        company_type: null,
        address: "",
        zip_code: "",
        phone_number: "",
        admin_email: "",
    },
    organizationAdminForm: {
        image: null || "",
        organization_id: null,
        email: "",
        first_name: "",
        last_name: "",
        ethnicity: "",
        job_title: "",
        office_phone_number: "",
        mobile_phone_number: "",
        age: null,
        location: "",
        gender: null,
        address: "",
        date_of_birth: null,
        comments: "",
    },
    inviteLoading: false,
};

export const organizationSlice = createSlice({
    name: "Organization",
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.isLoading = payload
        },
        setInviteLoading: (state, { payload }) => {
            state.inviteLoading = payload
        },
        setOrganization: (state, { payload }) => {
            state.organization = payload
        },
        updateOrganizationDetail: (state, { payload }) => {
            state.organization = payload
        },
        setOrganizationList: (state, { payload }) => {
            state.organizationList = payload
        },
        setOrganizationList2: (state, { payload }) => {
            state.organizationList2 = payload
        },
        setOrganizationAdmins: (state, { payload }) => {
            state.organizationAdmins = payload
        },
        setSelectedOrganization: (state, { payload }) => {
            state.selectedOrganization = payload
        },
        deleteOrganizationFromList: (state, { payload }) => {
            state.organizationList = state.organizationList.filter(organization => organization.id !== payload)
            state.organizationList2 = state.organizationList2.filter(organization => organization.id !== payload)
        },
        clearOrganizations: (state, { payload }) => {
            state.organizationList = [];
        },
        clearOrganizations2: (state, { payload }) => {
            state.organizationList2 = [];
        },
    },
});

export const {
    setOrganization,
    updateOrganizationDetail,
    setOrganizationList,
    setOrganizationList2,
    setOrganizationAdmins,
    setSelectedOrganization,
    deleteOrganizationFromList,
    clearOrganizations,
    clearOrganizations2,
    setLoading,
    setInviteLoading,
} = organizationSlice.actions;
export default organizationSlice.reducer;