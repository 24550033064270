export const CHART_STATISTICS_DATA = [
    { date: '01 Aug', value: 60 },
    { date: '02 Aug', value: 70 },
    { date: '03 Aug', value: 65 },
    { date: '04 Aug', value: 75 },
    { date: '05 Aug', value: 85 },
    { date: '06 Aug', value: 90 },
    { date: '07 Aug', value: 91 },
    { date: '08 Aug', value: 80 },
    { date: '09 Aug', value: 70 },
    { date: '10 Aug', value: 65 },
    { date: '11 Aug', value: 78 },
    { date: '12 Aug', value: 72 },
    { date: '13 Aug', value: 60 },
    { date: '14 Aug', value: 68 },
    { date: '15 Aug', value: 64 },
    { date: '16 Aug', value: 75 },
];

export const CHART_MARKETING_DATA = [
    { date: 'Sales', value: 60 },
    { date: 'IT', value: 70 },
    { date: 'Marketing', value: 65 },
    { date: 'Legal', value: 75 },
    { date: 'API', value: 85 },
];

export const EMPLOYEES_STATISTICS_DATA = [
    { id: 1, count: 0, title: "Total Employees", average: `0 new employees added!` },
    { id: 2, count: 425, title: "On Time", average: "-10% Less than yesterday" },
    { id: 3, count: 425, title: "Absent", average: "+3% Increase than yesterday" },
    { id: 4, count: 425, title: "Late Arrival", average: "+3% Increase than yesterday" },
    { id: 5, count: 425, title: "Early Departures", average: "-10% Less than yesterday" },
    { id: 6, count: 425, title: "Time-off", average: "2% Increase than yesterday" },
];