import { Stack, Group, Title, Text, Button, TextInput, Modal, ActionIcon, Loader, useSafeMantineTheme, Flex } from "@mantine/core";
import { useForm } from "@mantine/form";
import { yupResolver } from "mantine-form-yup-resolver";
import * as yup from 'yup';
import { addAdminToOrganizations } from "../../../../../middlewares/partner/partners.services"
import { notifications } from "@mantine/notifications";
import { useDispatch } from "react-redux";
import UploadExcelFileModal from "../../../../Modal/UploadExcelModal/UploadExcelFileModal"
import { read, utils } from 'xlsx'
import { IconX, IconPlus } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";

import './AddCompanyModal.css'
import { asyncErrorHandler } from "../../../../../helpers/errorHandler";

const AddCompanyModal = ({ modalOpened = false, modalClose, title = "Upload the file and invite the company" }) => {

    const dispatch = useDispatch()
    const [width, setWidth] = useState(window.innerWidth)
    const [opened, { open, close }] = useDisclosure()
    const [loading, setLoading] = useState()
    const [errors, setErrors] = useState({
        name: null,
        site_name: null,
        company_id: null,
        admin_email: null,
        phone_number: null,
    })
    const labels = {
        name: {
            label: "Company name",
            placeholder: "Enter company name",
        },
        site_name: {
            label: "Site name",
            placeholder: "Enter site name",
        },
        company_id: {
            label: "Company ID",
            placeholder: "Enter company id",
        },
        admin_email: {
            label: "Email admin member",
            placeholder: "Enter admin email",
        },
        first_name: {
            label: "Admin first name",
            placeholder: "Enter admin first name",
        },
        last_name: {
            label: "Admin last name",
            placeholder: "Enter admin last name",
        },
        phone_number: {
            label: "Phone number",
            placeholder: "Enter admin phone number",
        },
    };
    const validateOrganizations = yup.object().shape({
        organizations: yup.array().of(
            yup.object().shape({
                name: yup.string().required('Company name is required').matches(/^(?=.*[A-Za-z])(?!^[\d\s-]+$)(?!^[^\w\s-]+$)[A-Za-z\d\s-]+$/, 'Please enter valid company name'),
                site_name: yup.string().required('Site url is required').matches(/^(https?|ftp):\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$|^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/i, 'Please enter a valid url'),
                company_id: yup.string().required('Company id is required').matches(/^[a-zA-Z0-9_]+$/, 'Please enter a valid company id'),
                admin_email: yup.string().required('Email is required').email('Invalid email'),
                first_name: yup.string().required('First name is required'),
                last_name: yup.string().required('Last name is required'),
                phone_number: yup.string().required('Phone number is required').matches(/^\+?[1-9]\d{0,2}(021|\d{1,4})\d{1,9}$/, 'Please enter a valid contact'),
            })
        )
    })

    const form = useForm({
        initialValues: {
            organizations: [
                {
                    name: '',
                    site_name: '',
                    company_id: '',
                    admin_email: '',
                    first_name: '',
                    last_name: '',
                    phone_number: '',
                },
            ]
        },
        validate: yupResolver(validateOrganizations)
    })

    const adminDetails = form.values.organizations.map((company, index) => {
        return (
            <Flex direction={width < 991 ? "column" : "row"} key={index} grow gap={12} wrap="nowrap" align={width < 768 ? "center" : "flex-end"} preventGrowOverflow={false}>
                {Object.entries(company).map(([key, value]) => {
                    return (
                        <TextInput
                            label={labels[key]?.label}
                            placeholder={labels[key]?.placeholder}
                            {...form.getInputProps(`organizations.${index}.${key}`)}
                            error={form.errors[`organizations.${index}.${key}`] ? form.errors[`organizations.${index}.${key}`] : errors[key]}
                            withAsterisk
                            // style={{position:"relative", minWidth:"175px"}}
                            styles={{
                                root: {
                                    width: width < 991 ? "100%" : "100%"
                                },
                                label: {
                                    fontSize: '16px',
                                    marginBottom: "10px",
                                    lineHeight: "18px",
                                    fontWeight: "600",
                                    color: "#424242"
                                },
                                input: {
                                    height: "40px",
                                    fontSize: "14px",
                                    padding: "12px",
                                    lineHeight: "16px",
                                    borderRadius: "6px",
                                    width: "100%"
                                },
                                error: {
                                    position: 'absolute',
                                },
                                required: {
                                    color: '#3354F4'
                                }
                            }}
                        />
                    )
                })}
                {form.values.organizations?.length > 1 && <ActionIcon mb={6} variant="transparent" onClick={() => { form.removeListItem('organizations', index) }}>
                    <IconX size='sm' color={"#3354F4"} />
                </ActionIcon>}
            </Flex>
        )
    })

    const addAnotherCompany = (organizationDetails = {}) => {
        if (organizationDetails && Object.keys(organizationDetails).length > 0) {
            organizationDetails.phone_number = String(organizationDetails.phone_number)
            form.insertListItem('organizations', organizationDetails);
        }
        else {
            form.insertListItem('organizations', {
                name: '',
                site_name: '',
                company_id: '',
                admin_email: '',
                first_name: '',
                last_name: '',
                phone_number: '',
            });
        }
    }

    const sendInvite = async () => {
        if (form.validate().hasErrors) {
            form.validate()
        }
        else {
            setErrors({
                name: null,
                site_name: null,
                company_id: null,
                admin_email: null,
                phone_number: null,
            })
            setLoading(loading => !loading)
            const orgs = form.values.organizations.map((org) => {
                return {
                    ...org,
                    site_name: org.site_name.includes("https://") ? org.site_name : `https://${org.site_name}`
                }
            })
            const res = await dispatch(addAdminToOrganizations({ organizations: orgs }))
            if (addAdminToOrganizations.fulfilled.match(res)) {
                setLoading(loading => !loading)
                window.toast(`You have registered organizations successfully`)
                onModalClose()
            } else if (addAdminToOrganizations.rejected.match(res)) {
                setLoading(false)
                asyncErrorHandler(res, form)
            }
        }
    }

    const uploadExcelFile = async (excelFile) => {
        try {
            const bufferedArray = await excelFile.arrayBuffer()

            const workbook = read(bufferedArray)
            const worksheet = workbook.Sheets[workbook.SheetNames[0]] // getting the first sheet
            const data = utils.sheet_to_json(worksheet)

            if (data.length > 0) {
                const updatedOrganizations = form.values.organizations.filter(organization =>
                    Object.values(organization).some(value => value)
                );
                form.setFieldValue('organizations', updatedOrganizations);
                data.forEach((row) => { addAnotherCompany(row) })
            }

        }
        catch (err) {
            console.log("THIS IS ERROR", err)
        }

        // closes upload execel file modal
        close()
    }

    const onModalClose = () => {
        form.reset()
        modalClose()
    }

    function handleResize() {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <Modal.Root opened={modalOpened} onClose={onModalClose} size="100%" centered={true}>
                <Modal.Overlay />
                <Modal.Content px={24} py={24}>
                    <Modal.Header>
                        <Modal.Title style={{ width: '100%' }}>
                            <Group justify="space-between" align="flex-start">
                                <Stack gap={12}>
                                    <Title order={3} >Send and invite company</Title>
                                    <Text c='dimmed'>You can invite up to 5/10 companies</Text>
                                </Stack>
                                <Group gap={12} ml="auto">
                                    <Button key="upload_excel_button" variant="filled" bg="#099268" size="sm" onClick={open}>Upload (excel/sheets)</Button>
                                    <Button key="send_invite" variant="filled" bg="#3354F4" size="sm" radius={6} onClick={sendInvite}>
                                        <Group spacing="xs" align='flex-end'>
                                            <Text span> Send invite</Text>
                                            {loading && <Loader size="sm" color="#adb5c1" type='dots' />}
                                        </Group>
                                    </Button>
                                    <Button key="insert_line" variant="light" className='light-button' size="sm" radius={6} onClick={() => { addAnotherCompany() }}>
                                        <Group gap={8} justify="center" align="center">
                                            <IconPlus size={16} />
                                            <Text span>Add Line</Text>
                                        </Group>
                                    </Button>
                                </Group>
                            </Group>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body mt={24} w="100%" style={{ overflowX: "scroll" }}>
                        {adminDetails}
                    </Modal.Body>
                </Modal.Content>
            </Modal.Root>
            <UploadExcelFileModal
                opened={opened}
                close={close}
                onDrop={uploadExcelFile}
            />
        </>
    )
}
export default AddCompanyModal
