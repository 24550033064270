import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    measurements: [],
    isLoading: false
};

const measurementSlice = createSlice({
    name: "measurements",
    initialState,
    reducers: {
        setMeasurements: (state, { payload }) => {
            state.measurements = payload
        },
        setLoading: (state, { payload }) => {
            state.isLoading = payload
        }
    },
});

export const { setMeasurements, setLoading } = measurementSlice.actions;
export default measurementSlice.reducer;