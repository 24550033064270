import { useSelector } from 'react-redux'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'


export const PrivateRoutes = ({ allowedRoles }) => {
    const auth = localStorage.getItem('access_token');
    const user = useSelector(state => state.login?.user);
    const inProcess = localStorage.getItem('inProcess');
    if (inProcess === 'true' || !auth) {
        return <Navigate to="/" replace />;
    }
    if (!allowedRoles.includes(user?.role)) {
        return <Navigate to={`${user?.role}/dashboard`} replace />;
    }
    return <Outlet />;
};

export const PublicRoutes = () => {
    const auth = localStorage.getItem('access_token')
    const inProcess = localStorage.getItem('inProcess')
    const user = useSelector(state => state.login?.user)
    if (!auth)
        return <Outlet />
    else if (auth && user?.role && inProcess === 'true')
        return <Outlet />
    else if (auth && user?.role && inProcess === 'false')
        return <Navigate to={`${user.role}/dashboard`} />
}