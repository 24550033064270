import { useState } from 'react';
import { Table, Checkbox, Stack, Pagination, Group, Text, Box, Title, Loader } from '@mantine/core';
import classes from './Table.module.css'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

function TableComp({
  type,
  tableHeaders,
  data = [],
  isFilterApplied,
  fetchEmployeeLoader,
  onChange,
  onChange2,
  setExportCSVData,
  totalRecords = 0,
  totalPages = 0,
  changePage,
  isCheckBox,
  showPagination,
  height,
  errorMessage2
}) {

  const tierList = useSelector((state) => state.tiers.tierList)

  const rows = data.map((row) => {

    const getNestedValue = (obj, path) => {
      return path.split('.').reduce((acc, key) => {
        if (acc && acc[key] !== undefined) {
          return acc[key];
        }
        else {
          return undefined;
        }
      }, obj);
    };

    const renderValue = (header, row) => {
      const value = getNestedValue(row, header.value);
      if (value === "" || value === null || value === undefined) {
        return "-";
      } else if (header.value === "tier") {
        return tierList.find((tier) => tier.id === value)?.name || "-";
      } else {
        return value;
      }
    };

    return (
      <Table.Tr key={row.id} className={classes.hoverRow}>
        {isCheckBox && (
          <Table.Td id={classes.tableValue}>
            <Checkbox aria-label="Select row" checked={row.checked || row.selected} onChange={(event) => {
              if (setExportCSVData) {
                setExportCSVData(row, event.currentTarget.checked)
                return;
              }
              if (onChange) {
                onChange(row.id, row?.pk, row.parent)
                return;
              }
            }} />
          </Table.Td>
        )}
        {tableHeaders.map((header) => {
          return (
            <Table.Td id={classes.tableValue} style={{ textAlign: 'center' }}>
              <Group
                justify={header.value === 'frame_and_lens' ? 'center' : 'flex-start'}
              >
                <p className={header.value === 'actions' ? classes.tableAction : ''}>
                  <Text fz={16} fw={500}>
                    {renderValue(header, row)}
                  </Text>
                </p>
              </Group>
            </Table.Td>
          )
        })}
      </Table.Tr>
    )
  })

  const NoEmployeesFound = () => {

    const employeesError = useSelector((state) => state.employee.employeesError)
    const errorMessage = employeesError?.error?.message

    return (
      <Box mx='auto' mt="152px">
        {fetchEmployeeLoader === true ? (
          <Stack align='center' justify='center' gap={12}>
            <Loader size="xl" color="#3354F4" type='dots' />
          </Stack>
        ) : errorMessage ? (
          <Stack align='center' justify='center' gap={6}>
            <Title size={24}>
              {
                errorMessage === "Network Error" ? "Network Error" :
                  errorMessage === "No Search Employees Found" ? "No Search Result!" :
                    errorMessage === "No Employees Found" ? "You don\'t have any added employees yet" : null
              }
            </Title>
            {errorMessage === "No Employees Found" ? (
              <Box>
                <Text c="dark.3" fz={16} fw={400} align="center">To get started, <Text span fw={600} style={{ color: '#3354F4' }}>upload a CSV file</Text> with employee</Text>
                <Text c="dark.3" fz={16} fw={400} align="center">information or add an employee <Text span fw={600} style={{ color: '#3354F4' }}>manually</Text></Text>
              </Box>
            ) : (
              <Text c="dark.3">Please try again</Text>
            )}
          </Stack>
        ) : null}
      </Box>
    )
  }

  return (
    <>
      <Stack
        justify='space-between'
        style={{ height: '100%' }}
      >
        <Box style={{
          overflow: 'auto',
          display: 'block',
          width: '100%',
          position: 'relative',
          height: type !== "employee" && `calc(100vh - ${height})`
        }}>
          <Table withColumnBorders highlightOnHover style={{ position: 'absolute', top: '0', left: '0' }}>
            <Table.Thead className={classes.tableHead} style={{ position: 'sticky', top: '-1px' }}>
              <Table.Tr>
                {isCheckBox && (
                  <Table.Th id={classes.tableHeader}>
                    <Checkbox
                      aria-label="Select row"
                      checked={data.length > 0 && data.every((row) => (row.checked || row.selected))}
                      onChange={(event) => {
                        if (setExportCSVData) {
                          setExportCSVData({}, false, event.currentTarget.checked)
                        }
                        onChange2(event.currentTarget.checked)
                      }}
                    />
                  </Table.Th>
                )}
                {tableHeaders.map((header) =>
                  <Table.Th key={header.value} id={classes.tableHeader}>
                    <Text fz={16} fw={600} c="#495057">
                      {header.label}
                    </Text>
                  </Table.Th>
                )}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data.length > 0 ? rows : null}
            </Table.Tbody>
          </Table>
          <NoEmployeesFound />
          {errorMessage2}
        </Box>
        {showPagination && (
          <Group justify='space-between' align='center' my={24}>
            <Text c="dimmed">Showing {data.length}/{totalRecords} results</Text>
            <Pagination total={totalPages} withEdges onChange={changePage} mr="24px" />
          </Group>
        )}
      </Stack>
    </>
  );
}

export default TableComp;