import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    summary: {
        prescription: null,
        address: null,
        items: [],
    }
};

const summarySlice = createSlice({
    name: "summary",
    initialState,
    reducers: {
        setSummary: (state, { payload }) => {
            state.summary = {
                ...state.summary,
                [payload.key]: payload.data
            }
        },
    },
});

export const { setSummary } = summarySlice.actions;
export default summarySlice.reducer;