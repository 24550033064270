import { Box, Container, Stack, Image } from '@mantine/core'
import refraxtionLogo from "../../assets/icons/sitrap-logo-4.svg"

const BaseLayout = ({ children, width }) => {
    return (
        <Container fluid style={{ height: '100%' }}>
            <Stack align="center" gap={37.95}>
                <Image
                    w={224}
                    h={58.5}
                    // mt={30}
                    // ml={width < 768 ? 0 : 30}
                    fit='contain'
                    src={refraxtionLogo}
                />
                {children}
            </Stack>
        </Container>
    )
}

export default BaseLayout