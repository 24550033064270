import { createSlice } from "@reduxjs/toolkit";
import frame1 from '../../assets/images/frame.png';
import frame2 from '../../assets/images/sa.jpg';
import frame3 from '../../assets/images/sa2.jpg';
import frame4 from '../../assets/images/sa3.jpg';
import reviewPerson from '../../assets/images/review-person.png'

const initialState = {
    loading: false,
    submitFeedbackLoading: false,
    products: [
        // {
        //     id: 1,
        //     title: "DEWALT DPG82-11 Concealer Clear Anti-Fog Dual Mold Safety Goggle, Clear Lens, 1 Pair",
        //     totalPrice: 0,
        //     discountPrice: 1399,
        //     actualPrice: 1499,
        //     images: [
        //         { id: 1, image: frame1, active: true, },
        //         { id: 2, image: frame2, active: false, },
        //         { id: 3, image: frame3, active: false, },
        //         { id: 4, image: frame4, active: false, },
        //     ],
        //     colors: ['#000000', '#781DBC', '#E10000', '#E1B000', '#E8E8E8'],
        //     sizes: ['XS', 'S', 'M', 'L', 'XL'],
        //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. more...",
        // },
    ],
    productDetail: {},
    reviews: [
        {
            id: 1,
            message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            productId: 1,
            user: {
                name: "Grace Carey",
                profile: reviewPerson
            },
            rating: 5,
            createdAt: "24 January, 2023",
        },
        {
            id: 2,
            message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            productId: 1,
            user: {
                name: "Sarah Johns",
                profile: reviewPerson
            },
            rating: 3,
            createdAt: "24 January, 2023",
        },
    ],
};

const productSlice = createSlice({
    name: "products",
    initialState,
    reducers: {
        setProductLoading: (state, { payload }) => {
            state.loading = payload
        },
        setProductDetail: (state, { payload }) => {
            state.productDetail = payload
        },
        setProducts: (state, { payload }) => {
            state.products = payload
        },
        setFeedbackLoading: (state, { payload }) => {
            state.submitFeedbackLoading = payload
        }
    },
});

export const { setProductLoading, setProducts, setProductDetail, setFeedbackLoading } = productSlice.actions;
export default productSlice.reducer;