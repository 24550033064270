import { createSlice } from "@reduxjs/toolkit";
import visa from '../../assets/icons/visa.svg';
import payPal from '../../assets/icons/payPal.svg';
import mastercard from '../../assets/icons/mastercard.svg';

const initialState = {
    error: null,
    success: null,
    loading: false,
    paymentMethods: [
        {
            id: 1,
            icon: visa,
            title: "",
            expDate: null,
            cvv: 123,
            cardNumber: "3234 **** **** 7628",
            companyName: 'Maya Singh',
            phoneNumber: '',
            recipientEmail: '',
            address: '',
            zipCode: null,
            city: '',
            country: '',
        },
        {
            id: 2,
            icon: payPal,
            title: "",
            expDate: null,
            cvv: 123,
            cardNumber: "3234 **** **** 7628",
            companyName: 'Maya Singh',
            phoneNumber: '',
            recipientEmail: '',
            address: '',
            zipCode: null,
            city: '',
            country: '',
        },
        {
            id: 3,
            icon: mastercard,
            title: "",
            expDate: null,
            cvv: 123,
            cardNumber: "3234 **** **** 7628",
            companyName: 'Maya Singh',
            phoneNumber: '',
            recipientEmail: '',
            address: '',
            zipCode: null,
            city: '',
            country: '',
        },
    ],
};

const billingSlice = createSlice({
    name: "Common",
    initialState,
    reducers: {
        setPaymentMethods: (state, { payload }) => {
            state.paymentMethods = [...state.paymentMethods, payload]
        },
        setError: (state, { payload }) => {
            state.error = payload;
        },
        setSuccess: (state, { payload }) => {
            state.success = payload;
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
    },
});

export const { setPaymentMethods, setError, setLoading, setSuccess } = billingSlice.actions;
export default billingSlice.reducer;