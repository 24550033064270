import React from 'react'
import classes from '../Dashboard/card.module.css'
import { Card, Group, Stack, Text, Title } from '@mantine/core'

const Tiers = () => {
    return (
        <Card className={classes.card} px={24} py={16} radius={12} styles={{ root: { border: "2px solid #DEE2E6" } }}>
            <Stack gap="5px">
                <Title mr='auto' order={3} c="#2C2E33">Gold tiers</Title>
                <Stack gap='1px'>
                    <Text c="#868E96">Your programm</Text>
                </Stack>
            </Stack>
        </Card>
    )
}

export default Tiers;