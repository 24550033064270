import React, { useEffect, useState } from 'react'
import { Image, Box, Stack, TextInput, PasswordInput, Button, Divider, Group, Loader, Title, Text, Checkbox, Flex } from "@mantine/core";
import partnerIcon from "../../assets/icons/us-veteran-logo-2.svg"
import sitrapLogo from "../../assets/images/sitrep-new-logo.svg"
import { IoEyeOutline, IoEyeOffOutline, } from "react-icons/io5";
import { GoogleButton } from '../Button/Button.js';
import { useForm } from '@mantine/form';
import { registerPartner, registerParterViaGoogleAuth } from '../../middlewares/partner/partners.services.js';
import { notifications } from '@mantine/notifications';
import { useDispatch } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';
import facebookIcon from '../../assets/icons/facebook.svg'
import { Link } from 'react-router-dom';
import { verifyOTP } from '../../middlewares/auth/auth.js';
import PrimaryButton from '../BaseComponents/Buttons/Button.js';
import { asyncErrorHandler } from '../../helpers/errorHandler.js';
import { InputField, PasswordInputField } from '../ui/Input/Input.js';
import { FaFacebook } from "react-icons/fa";

function GoogleIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid"
            viewBox="0 0 256 262"
            style={{ width: '1.2rem', height: '1.2rem' }}
            {...props}
        >
            <path
                fill="#4285F4"
                d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
            />
            <path
                fill="#34A853"
                d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
            />
            <path
                fill="#FBBC05"
                d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
            />
            <path
                fill="#EB4335"
                d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
            />
        </svg>
    );
}

const PartnerSignUpForm = ({ setStepper, setShowSplashScreen }) => {

    const [loading, setLoading] = useState(false)
    const [timer, setTimer] = useState(30);
    const [sendVerificationCode, setSendVerificationCode] = useState(false)
    const [agreeTerms, setAgreeTerms] = useState(false);
    const dispatch = useDispatch()

    const form = useForm({
        initialValues: {
            email: '',
            password: '',
        },
        validate: {
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
            password: (val) => (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_\-])[A-Za-z\d@$!%*?&_\-]{8,}$/.test(val) ? null : 'Password must be at least 8 characters long, contain at least one lowercase letter, one uppercase letter, one number, and one special character.'),
        }
    })

    const verificationCodeForm = useForm({
        initialValues: {
            verification_code: '',
        },
        validate: {
            verification_code: (val) => (/^\d{4}$/.test(val) ? null : 'Verification code must be 4 digits'),
        }
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (sendVerificationCode === true) {
            if (verificationCodeForm.validate().hasErrors) {
                verificationCodeForm.validate();
                return;
            }
            setLoading(true)
            const res = await dispatch(verifyOTP({ email: form.values.email, verification_code: verificationCodeForm.values.verification_code }))
            if (res.type === "verifyOTP/fulfilled") {
                setLoading(false)
                setSendVerificationCode(false)
                if (res.payload.data.user?.role) {
                    setStepper((prevStep) => prevStep + 1)
                    setShowSplashScreen(true)
                    return;
                }
                setStepper((prevStep) => prevStep)
            } else {
                setLoading(false)
                setSendVerificationCode(true)
                asyncErrorHandler(res, verificationCodeForm)
            }
        }
        else {
            if (form.validate().hasErrors) {
                form.validate()
                return;
            }
            localStorage.setItem('inProcess', 'true')
            setLoading(true)
            const res = await dispatch(registerPartner({ email: form.values.email, password1: form.values.password, password2: form.values.password }))
            if (res.type === "registerPartner/fulfilled") {
                setLoading(false)
                setSendVerificationCode(true)
                setTimer(30)
                window.toast(res.payload.data.message)
            } else {
                setLoading(false)
                setSendVerificationCode(false)
                asyncErrorHandler(res, form)
            }
        }
    }

    const login = useGoogleLogin({
        onSuccess: async (googleRes) => {
            const res = await dispatch(registerParterViaGoogleAuth({ access_token: googleRes.access_token }))
            if (registerParterViaGoogleAuth.fulfilled.match(res)) {
                notifications.show({ message: 'You have been registered successfully', autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            }
        }
    })

    const commonStyle = {
        label: {
            fontSize: '16px',
            marginBottom: "10px",
            lineHeight: "18px",
            fontWeight: "600",
            color: "#424242"
        },
        input: {
            height: "40px",
            fontSize: "14px",
            padding: "12px",
            lineHeight: "16px",
            borderRadius: "6px"
        },
        error: {
            fontSize: '14px',
            marginTop: "5px",
            lineHeight: "16px",
        },
        required: {
            color: '#3354F4'
        }
    }

    function renderContent() {
        return (
            <>
                <InputField
                    backgroundColor="transparent"
                    radius={6}
                    label={<Text fz={16} fw={600}>Email</Text>}
                    placeholder="Please enter your email"
                    key={form.key("email")}
                    {...form.getInputProps('email')}
                    disabled={sendVerificationCode}
                    withAsterisk={true}
                />
                {sendVerificationCode && (
                    <>
                        <InputField
                            backgroundColor="transparent"
                            type="number"
                            label={<Text fz={16} fw={600}>Secure login code</Text>}
                            placeholder="Paste login code"
                            size="sm"
                            withAsterisk={true}
                            {...verificationCodeForm.getInputProps('verification_code')}
                            onChange={(e) => {
                                const value = e.target.value.replace(/\D/g, '');
                                if (value.length <= 4) {
                                    verificationCodeForm.setFieldValue('verification_code', value);
                                }
                            }}
                        />
                        <Text fz={16} c={'#5C5F66'} mb={20}>
                            A secure login code has been emailed to your account * <b>Resend in {timer}s</b>
                        </Text>
                    </>
                )}
                {!sendVerificationCode && (
                    <PasswordInputField
                        backgroundColor="transparent"
                        withAsterisk={true}
                        label={<Text fz={16} fw={600}>Enter your password</Text>}
                        placeholder="Set Password"
                        key={form.key("password")}
                        {...form.getInputProps('password')}
                        visibilityToggleIcon={({ reveal }) =>
                            reveal ? (
                                <IoEyeOffOutline style={{ width: 'var(--psi-icon-size)', height: 'var(--psi-icon-size)' }} />
                            ) : (
                                <IoEyeOutline style={{ width: 'var(--psi-icon-size)', height: 'var(--psi-icon-size)' }} />
                            )
                        }
                    />
                )}
            </>
        )
    }

    useEffect(() => {
        if (sendVerificationCode) {
            let interval = null;
            if (timer > 0) {
                interval = setInterval(() => {
                    setTimer((prevTimer) => prevTimer - 1);
                }, 1000);
            } else {
                clearInterval(interval);
            }
            return () => clearInterval(interval);
        }
    }, [timer, sendVerificationCode])

    return (
        <Box style={{ height: "calc(100vh - 48px)", display: "flex", alignItems: 'center', justifyContent: 'center', flexDirection: "column" }}>
            <form style={{ maxWidth: '408px', height: "auto", width: "100%", marginTop: "auto" }} onSubmit={(e) => handleSubmit(e)}>
                <Image src={sitrapLogo} w={224} mb={40} mx="auto" display="block" />
                <Title ta="center" order={3} m={0} p={0}>Create your partner account</Title>
                <Text ta="center" fz={16} c={'#5C5F66'} mb={30} p={0}>Synergistic partnerships provide dynamic results</Text>
                <Stack gap={16}>
                    {renderContent()}
                </Stack>
                {!sendVerificationCode && (
                    <Checkbox
                        styles={{ input: { cursor: "pointer" } }}
                        checked={agreeTerms}
                        onChange={(event) => setAgreeTerms(event.currentTarget.checked)}
                        mt={23}
                        mb={36}
                        label="I agree to the Terms of Use and Privacy Policy"
                    />
                )}
                <PrimaryButton
                    styles={{ root: { backgroundColor: (!agreeTerms || (!verificationCodeForm.values.verification_code && sendVerificationCode)) ? "#E5E5E5" : "", color: (!agreeTerms || (!verificationCodeForm.values.verification_code && sendVerificationCode)) ? "#ADB5BD" : "" } }}
                    disabled={loading || (!verificationCodeForm.values.verification_code && sendVerificationCode) || (!agreeTerms ? true : false)}
                    loading={loading}
                    type={'submit'}
                    variant='filled'
                    fullWidth={true}
                    text='Continue'
                    fz={'lg'}
                    size={'lg'}
                />
                {!sendVerificationCode && (
                    <>
                        <Divider label="Or Continue With" labelPosition="center" color={'#ADB5BD'} my={12} styles={{ label: { color: "#CED4DA", fontWeight: "500" } }} />
                        <Flex gap={12}>
                            <GoogleButton w="100%" radius={6} h={48} onClick={login} leftSection={<GoogleIcon />} />
                            <GoogleButton w="100%" radius={6} h={48} onClick={login} leftSection={<FaFacebook size={27} color="#2943C3" />} />
                        </Flex>
                        <Image src={partnerIcon} w={224} display="block" m="auto" mt={48} />
                    </>
                )}
            </form>
            <Box w="100%" justify="center" gap={135} mt="auto">
                {sendVerificationCode && <Image src={partnerIcon} w={224} display="block" m="auto" />}
                <Group w="100%" justify="center" gap={135} mt={40}>
                    <Link style={{ color: "#909296", textDecoration: "none" }} fz={14}>About us</Link>
                    <Link style={{ color: "#909296", textDecoration: "none" }} fz={14}>Help</Link>
                    <Link style={{ color: "#909296", textDecoration: "none" }} fz={14}>Sign in</Link>
                </Group>
            </Box>
        </Box>

    )
}

export default PartnerSignUpForm 