import React from 'react';
import { Button, FileButton, Text } from '@mantine/core';
import classes from '../../EmployeeManagement/EmployeeManagement.module.css';

export const PrimaryButton = (props) => {
    return (
        <Button id={classes.addNew} onClick={props.onClick} {...props}>
            <Text fz={16} fw={props?.fw ? props?.fw : 500} mr={props?.fallback ? 10 : 0}>{props.title}</Text>
            {props?.fallback}
        </Button>
    )
}

export const PrimaryButton2 = (props) => {
    return (
        <Button id={classes.export} onClick={props.onClick} {...props}>
            <Text fz={16} fw={500}>{props.title}</Text>
            {props?.fallback}
        </Button>
    )
}

export const OutlineButton = (props) => {
    return (
        <Button variant="outline" onClick={props.onClick} {...props}>
            <Text fz={16} fw={500}>{props.title}</Text>
        </Button>
    )
}

export const FileUploadButton = (props) => {
    return (
        <FileButton {...props}>
            {(props) => (
                <Button variant="outline" {...props}>
                    <Text fz={16} fw={500}>{props.title}</Text>
                </Button>
            )}
        </FileButton>
    )
}

export const SecondaryButton = (props) => {
    return (
        <Button id={classes.filterButton} onClick={props.onClick} {...props}>
            <Text fz={16} fw={500}>{props.title}</Text>
        </Button>
    )
}

export const OutlinedButton = (props) => {
    return (
        <Button id={classes.addNew} onClick={props.onClick} {...props}>
            <Text fz={16} fw={500}>{props.title}</Text>
        </Button>
    )
}