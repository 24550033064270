import { createAsyncThunk } from "@reduxjs/toolkit";
import { setProfileCompletion, setUser, updateUserProfile } from "../../store/auth/authSlice";
import apiClient from "../../services/api"
import { setError, setLoading } from "../../store/common/commonSlice";
import { notifications } from "@mantine/notifications";
import { setPrescriptionError, setPrescriptionLoading, setPrescriptionLoading2, setPrescriptions } from "../../store/employess/prescription";
const apiInstance = apiClient()
const apiFormInstance = apiClient(true)

export const createPrescriptions = createAsyncThunk(
    "createPrescriptions",
    async (body, { dispatch, rejectWithValue, getState }) => {
        dispatch(setPrescriptionLoading(true))
        try {
            const res = await apiInstance.post('/api/employees/prescription/create/', body.payload)
            if (res.status === 200 || res.status === 201 || res.status === 204) {
                dispatch(setPrescriptionLoading(false))
                dispatch(setError(null))
                dispatch(setPrescriptions([
                    ...getState().prescription.prescriptionList,
                    { ...res.data }
                ]))
            }
            return res
        }
        catch (err) {
            dispatch(setPrescriptionLoading(false))
            dispatch(setError(null))
            return rejectWithValue(err)
        }
    }
);

export const updatePrescriptions = createAsyncThunk("updatePrescriptions", async (body, { dispatch, rejectWithValue, getState }) => {
    dispatch(setPrescriptionLoading(true))
    try {
        const res = await apiFormInstance.put(`/api/employees/prescription/${body.prescriptionId}/update/`, body.payload)
        const updatedPrescriptions = getState().prescription.prescriptionList.map((prescription, index) => {
            if (prescription.id === res.data.id) {
                return { ...res.data }
            } else {
                return prescription
            }
        })
        dispatch(setPrescriptionLoading(false))
        dispatch(setPrescriptions(updatedPrescriptions))
        return res
    }
    catch (err) {
        dispatch(setPrescriptionLoading(false))
        return rejectWithValue(err)
    }
})

export const getMyPrescriptions = createAsyncThunk("getMyPrescriptions", async (id, { dispatch, rejectWithValue, getState }) => {
    dispatch(setPrescriptionLoading(true))
    try {
        const res = await apiFormInstance.get(`/api/employees/prescription/list/`)
        if (res.status === 200 || res.status === 201) {
            dispatch(setPrescriptions(res.data))
            dispatch(setPrescriptionLoading(false))
            if (res.data?.length === 0) {
                dispatch(setPrescriptionError({ error: { message: "No prescription found please add prescriptions" } }))
            }
        }
        return res
    }
    catch (err) {
        if (err.code === "ERR_NETWORK") {
            dispatch(setPrescriptionError({ error: { message: err.message } }))
        }
        dispatch(setPrescriptionLoading(false))
        return rejectWithValue(err)
    }
})

export const deletePrescriptions = createAsyncThunk("deletePrescriptions", async (body, { dispatch, rejectWithValue, getState }) => {
    dispatch(setPrescriptionLoading2(true))
    try {
        const res = await apiFormInstance.delete(`/api/employees/prescription/${body.prescriptionId}/delete/`)
        const updatedPrescriptions = getState().prescription.prescriptionList.filter((prescription, index) => prescription.id !== body.prescriptionId)
        dispatch(setPrescriptionLoading2(false))
        dispatch(setPrescriptions(updatedPrescriptions))
        return res
    }
    catch (err) {
        dispatch(setPrescriptionLoading2(false))
        return rejectWithValue(err)
    }
})