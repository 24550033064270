import { Box, Text, Image, Card, BackgroundImage, Stack, Title, TextInput, PasswordInput, Group, Divider } from "@mantine/core"

import loginBackground from "../../assets/images/login-background.svg"
import safetyVisionLogo from "../../assets/icons/safety-vision.svg";
import usVeteranLogo from "../../assets/icons/us-veteran-logo.svg";
import srxLogo from "../../assets/icons/srx-logo.svg";
import bechtelLogo from "../../assets/icons/bechtel-corp-new.svg";
import hippaLogo from '../../assets/icons/hippa.svg'
import awsLogo from '../../assets/icons/aws-logo.svg'
import sbaLogo from '../../assets/icons/sba-logo.svg'
import partnersLogo from '../../assets/icons/sitrap-logo.svg'

import { useForm } from "@mantine/form";
import { yupResolver } from "@mantine/form";
import * as yup from 'yup';
import { resetPasswordConfirm } from "../../middlewares/auth/auth";
import { useDispatch } from "react-redux";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useViewportSize } from '@mantine/hooks';
import PrimaryButton from "../../components/BaseComponents/Buttons/Button";
import { CarouselSlider, CarouselSlider2 } from "../../components/Carousol/carousol";
import { notifications } from "@mantine/notifications";

const ResetPassword = () => {
    
    const { width } = useViewportSize()
    const [searchParams] = useSearchParams();
    const params = Object.fromEntries(searchParams.entries());
    const hasParams = Object.keys(params).length > 0;
    
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loginRole, setLoginRole] = useState(0);
    
    const passwordValidation = yup.object().shape({
        password: yup.string().required("Password is required"),
    })

    const passwordForm = useForm({
        initialValues: {
            password: '',
            token: '',
        },
        validate: yupResolver(passwordValidation)
    })

    const inputStyles = {
        label: {
            fontSize: width > 786 ? '16px' : '14px',
            marginBottom: "10px",
            lineHeight: "18px",
            fontWeight: "600",
            color: "#393B42"
        },
        input: {
            height: "40px",
            fontSize: width > 786 ? '14px' : '12px',
            padding: "12px",
            lineHeight: "16px",
            borderRadius: "6px",
            backgroundColor: '#F1F3F5'
        },
        error: {
            fontSize: width > 786 ? '14px' : '10px',
            marginTop: "5px",
            lineHeight: "16px",
        },
        required: {
            color: '#3354F4'
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (passwordForm.validate().hasErrors) {
            passwordForm.validate()
            return;
        }
        setLoading(true)
        const res = await dispatch(resetPasswordConfirm(passwordForm.values))
        if (res.type === "resetPasswordConfirm/fulfilled") {
            notifications.show({ message: 'Password change successfully', autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            setLoading(false)
            setTimeout(() => {
                passwordForm.reset()
                navigate("/")
            }, 1000);
        } else {
            if (res.payload.response?.status === 404) {
                const errorMessage = res.payload.response?.data?.error?.message
                passwordForm.setFieldError("password", errorMessage)
                // notifications.show({ message: errorMessage, autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            } else if (res.payload.code === "ERR_NETWORK") {
                notifications.show({ message: res.payload.message, autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            }
            setLoading(false)
            console.log(res);
        }
        return;
    }

    useEffect(() => {
        if (hasParams && params?.token) {
            passwordForm.setFieldValue("token", params?.token);
        }
    }, [hasParams]);

    const renderHeader = () => {
        if (loginRole === 0 && width < 768) {
            return <CarouselSlider />
        } else {
            return (
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box>
                        {loginRole === 0 ? (
                            <>
                                <Image src={safetyVisionLogo} w="100%" style={{ objectFit: "contain" }} />
                                <Box mt={20}>
                                    <Text style={{ color: "#5C5F66" }}>Powered By</Text>
                                    <img src={srxLogo} width="100%" alt='' style={{ marginBottom: '65.58px' }} />
                                </Box>
                            </>
                        ) : <img src={partnersLogo} width="100%" alt='' />}
                    </Box>
                    <Box>
                        <img src={loginRole === 0 ? bechtelLogo : safetyVisionLogo} width="100%" alt='' style={{ marginBottom: '65.58px' }} />
                    </Box>
                </Box>
            )
        }
    }

    return (
        <Box bg={"#212529"} h={'100vh'} w={'100vw'}>
            <BackgroundImage src={loginBackground} h={'100vh'} w={'100vw'} fit="contain" px={width > 786 ? 50 : 30} pt={30} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                <Stack gap={12} pos={'relative'} h="auto" mih="100%" justify="center">
                    {renderHeader()}
                    <form onSubmit={(e) => handleSubmit(e)}
                    >
                        <Stack>
                            <Card w={width > 768 ? 520 : "100%"} mx="auto" bg={'#F1F3F5'} px={width > 786 ? 40 : 30} py={width > 786 ? 35 : 30} radius={12} mb={30}>
                                <Stack gap={25}>
                                    <Stack gap={5} key="card_header">
                                        <Title order={width > 786 ? 3 : 4} >
                                            Create Password
                                        </Title>
                                        <Text fz={width > 786 ? 16 : 12} c={'#5C5F66'}>
                                            Securing Your Account with a New Password
                                        </Text>
                                    </Stack>
                                    <Stack key="card_body" gap={width > 786 ? 18 : 10}>
                                        <PasswordInput
                                            label={"Enter your password"}
                                            placeholder="Please enter your password"
                                            key={passwordForm.key("password")}
                                            {...passwordForm.getInputProps('password')}
                                            styles={inputStyles}
                                            size="sm"
                                        />
                                        <PrimaryButton type='submit' size='md' loading={loading} h={48} text='Create' />
                                    </Stack>

                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Link to="/admin/signup" style={{ textDecoration: "none" }}>
                                            <Text c="black" fz={width > 786 ? 16 : 12}>
                                                Not registered? <span><b>Sign up</b></span>
                                            </Text>
                                        </Link>
                                        <Text c="black" fz={width > 786 ? 16 : 12} styles={{ root: { cursor: "pointer" } }} onClick={() => setLoginRole(1)}>Login as a partner</Text>
                                    </div>
                                </Stack>
                            </Card>
                        </Stack>
                    </form>
                    <Group
                        w={'100%'}
                        justify={"center"}
                        pb={30}
                        mt={width > 768 ? "auto" : 20}
                        gap={40}
                        c={'#ADB5BD'}
                        style={{ marginTop: 'auto' }}
                    >
                        {loginRole === 1 ?
                            (
                                <>
                                    <img src={usVeteranLogo} alt="" />
                                    <Group w="100%" justify="center" gap={width > 768 ? 100 : 20}>
                                        <Link style={{ color: "#909296", textDecoration: "none" }} fz={14}>About us</Link>
                                        <Link style={{ color: "#909296", textDecoration: "none" }} fz={14}>Terms of use</Link>
                                        <Link style={{ color: "#909296", textDecoration: "none" }} fz={14}>Privacy Policy</Link>
                                        <Link style={{ color: "#909296", textDecoration: "none" }} fz={14}>Help</Link>
                                    </Group>
                                </>
                            ) : (
                                width > 991 ? (
                                    <>
                                        <Link style={{ color: "#909296", textDecoration: "none" }} fz={16} >About us</Link>
                                        <Link style={{ color: "#909296", textDecoration: "none" }} fz={16} >Terms of use</Link>
                                        <img src={hippaLogo} alt="" />
                                        <img src={awsLogo} alt="" />
                                        <img src={sbaLogo} alt="" />
                                        <Link style={{ color: "#909296", textDecoration: "none" }} fz={16} >Privacy Policy</Link>
                                        <Link style={{ color: "#909296", textDecoration: "none" }} fz={16} >Help</Link>
                                    </>
                                ) : (
                                    <>
                                        <CarouselSlider2 />
                                        <Group w="100%" justify="space-around">
                                            <Link style={{ color: "#909296", textDecoration: "none" }} fz={14}>About us</Link>
                                            <Link style={{ color: "#909296", textDecoration: "none" }} fz={14}>Terms of use</Link>
                                            <Link style={{ color: "#909296", textDecoration: "none" }} fz={14}>Privacy Policy</Link>
                                            <Link style={{ color: "#909296", textDecoration: "none" }} fz={14}>Help</Link>
                                        </Group>
                                    </>
                                )
                            )
                        }
                    </Group>
                </Stack>
            </BackgroundImage>
        </Box>
    )
}

export default ResetPassword;