import { Avatar, Box, Button, Checkbox, CloseButton, Divider, Grid, Group, Input, Loader, NumberInput, Select, Stack, Text, Textarea, Title } from "@mantine/core"
import { CommonDrawer } from '../../components/Drawer/Drawer'
import { useEffect, useState } from "react";
import InputBox from "../../components/BaseComponents/InputBox";
import { IconChevronDown, IconEdit } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { FaChevronDown, FaPlus, FaSearch } from "react-icons/fa";
import { PiTrash, PiTrashBold } from "react-icons/pi";
import { TbCalendarClock } from "react-icons/tb"

// Tier icons
import silverTier from '../../assets/icons/silverTier.svg';
import goldTier from '../../assets/icons/goldTier.svg';
import bronzeTier from '../../assets/icons/bronzeTier.svg';

// Tier icons
import frame1 from '../../assets/images/frame1.png';
import frame2 from '../../assets/images/frame2.png';
import frame3 from '../../assets/images/frame3.png';
import { LuRefreshCcw } from "react-icons/lu";
import { TierCard } from "../../components/SafetyAwards/Card";
import { createTier, deleteTier, getTiersList, updateTier } from "../../middlewares/common/common.services";
import { useDispatch, useSelector } from "react-redux";
import { notifications } from "@mantine/notifications";
import { asyncErrorHandler } from "../../helpers/errorHandler";
import { PrimaryButton, SecondaryButton } from "../../components/ui/Button/Button";
import { SelectInputField } from "../../components/ui/Input/Input";
import { TIER_ELIGIBILITY } from "../../choices/employee.choice";

const TierManagement = () => {

    const dispatch = useDispatch()
    const [openDrawer, setOpenDrawer] = useState(false);
    const [loading, setLoading] = useState(false)
    const [searchParticipants, setSearchParticipants] = useState("")
    const [selectedTierId, setSelectedTierId] = useState(null)
    const [selectedIcon, setSelectedIcon] = useState("1")
    const [selectedIconValue, setSelectedIconValue] = useState("1")

    const adminOrganization = useSelector((state) => state.organizations.organization)
    const selectedOrganization = useSelector((state) => state.organizations.selectedOrganization)
    const tierList = useSelector((state) => state.tiers.tierList)
    const tiersError = useSelector((state) => state.tiers.error)
    const tiersLoading = useSelector((state) => state.tiers.tierLoading)
    const tierLoading2 = useSelector((state) => state.tiers.tierLoading2)
    const user = useSelector((state) => state.login.user)

    const icons = {
        "1": bronzeTier,
        "2": silverTier,
        "3": goldTier,
    }
    const tierOptions = [
        { value: "1", label: "Bronze" },
        { value: "2", label: "Silver" },
        { value: "3", label: "Gold" },
    ];
    const iconPos = {
        "1": { icon: bronzeTier, text: "bronze" },
        "2": { icon: silverTier, text: "silver" },
        "3": { icon: goldTier, text: "gold" },
    };
    const [frames, setFrames] = useState([
        { id: 1, icon: frame1, text: "Safety Frames", isSelected: true },
        // { id: 2, icon: frame2, text: "Reading Frames", isSelected: false },
        // { id: 3, icon: frame3, text: "Sports Frames", isSelected: false },
    ])

    const textInputStyle = {
        label: {
            marginBottom: "10px",
            lineHeight: "18px",
            fontWeight: "600",
            color: "#495057"
        },
        error: {
            fontSize: '12px',
            marginTop: "5px",
            lineHeight: "16px",
        },
        required: {
            color: '#3354F4'
        }
    }

    const form = useForm({
        initialValues: {
            name: '',
            icon: { text: selectedIconValue, icon: icons[selectedIcon] },
            description: '',
            eligibility: null,
            frame_category: 1,
            participants: [],
        },
        validate: {
            name: (val) => {
                if (!val) {
                    return 'Tier name is required';
                }
                if (!/^[A-Za-z\d\s-]+$/.test(val)) {
                    return 'Tier name must contain only letters, numbers, spaces, and dashes';
                }
                return null;
            },
            icon: (val) => !val ? 'Icon is required' : null,
            description: (val) => val && val.length >= 10 ? null : 'Description should be at least 10 characters long.',
            // frame_category: (val) => !val ? 'Frame is required' : null,
            // participants: (val) => (!val || val.length === 0) ? 'Participants are required' : null,
            eligibility: (val) => !val ? 'Eligibility is required' : null
        },
    })

    const handleChange = (key, value) => {
        if (key === "frame_category") {
            const selectedFrame = frames.find((frame) => value.id === frame.id)
            form.setFieldValue(key, selectedFrame)
        }
        else {
            form.setFieldValue(key, value)
        }
    }

    const handleSubmit = async () => {
        if (form.validate().hasErrors) {
            form.validate()
        } else {
            const payload = {
                name: form.values.name,
                description: form.values.description,
                eligibility: form.values.eligibility,
                icon: form.values.icon.text,
                frame_category: form?.values?.frame_category?.id,
                organization: user?.role === "admin" ? adminOrganization?.id : selectedOrganization?.id
            }
            const res = selectedTierId ? await dispatch(updateTier({ id: selectedTierId, payload })) : await dispatch(createTier(payload))
            if (res.type === "createTier/rejected" || res.type === "updateTier/rejected") {
                asyncErrorHandler(res, form)
            } else {
                if (selectedTierId) {
                    notifications.show({ message: "Tier updated successfully", autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
                } else {
                    notifications.show({ message: "Tier created successfully", autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
                }
                setOpenDrawer(false)
                // setFrames((prevFrames) => {
                //     return prevFrames.map((frame) => ({ ...frame, isSelected: false }))
                // })
                form.reset()
                form.clearErrors()
                form.clearFieldError()
            }
        }
    }
    // const handleSendInvite = () => {
    //     if (searchParticipants) {
    //         const participants = form.values.participants
    //         const idNotExist = !participants[participants?.length - 1]?.id
    //         const id = idNotExist ? 1 : participants[participants?.length - 1]?.id + 1
    //         const user = { id: id, email: searchParticipants }
    //         form.setFieldValue("participants", [...participants, user])
    //         setSearchParticipants("")
    //     }
    // }

    const handleDelete = async () => {
        const res = await dispatch(deleteTier(selectedTierId))
        if (res.type === "deleteTier/rejected") {
            if (res.payload.code === "ERR_NETWORK") {
                notifications.show({ message: res.payload.message, autoClose: 3000, color: 'red', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem", zIndex: 100000 }, });
                return;
            }
            notifications.show({ message: "Something went wrong", autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
        } else {
            notifications.show({ message: "Tier deleted successfully", autoClose: 3000, color: '#3354F4', style: { width: '400px', position: "fixed", bottom: "6rem", right: "2rem" }, });
            setOpenDrawer(false)
        }
    }

    const handleOpenUpdate = (tier) => {
        setOpenDrawer(true)
        setSelectedIcon("")
        setSelectedTierId(tier.id)
        form.setValues({
            ...tier,
            icon: { text: tier.icon, icon: iconPos[tier.icon].icon }
        })
    }

    function renderContent() {
        return (
            (!selectedTierId && tiersLoading) ? (
                <Box mx='auto' mt="152px">
                    <Stack align='center' justify='center' gap={6}>
                        <Loader size="xl" color="#3354F4" type='dots' m="auto" />
                    </Stack>
                </Box>
            ) : (tierList?.length === 0 && (tiersError?.error === "No tiers found" || tiersError?.error === undefined)) ? (
                <Box mx='auto' mt="152px" style={{ height: "calc(100vh - 550px)", justifyContent: "center", alignItems: "center", flexDirection: "column" }} display="flex">
                    <Stack align='center' justify='center' gap={6}>
                        <Title size={20}>No tiers found please create tiers</Title>
                        <Text c="dark.3" fz={16} fw={400} align="center">To create tiers, click on <Text span fw={600} style={{ color: '#3354F4' }}>create new tier</Text> button</Text>
                        <Group onClick={() => setOpenDrawer(true)} mt={10}>
                            <Box w={40} h={40} bg="white" c="black" fw="lighter" bd="2px dashed #ADB5BD" style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%" }}>
                                <FaPlus size={20} color="#ADB5BD" />
                            </Box>
                        </Group>
                    </Stack>
                </Box>
            ) : (
                tierList?.map((tier) => {
                    return (
                        <Grid.Col key={tier.id} span={{ base: 12, md: 12, lg: 6 }}>
                            <TierCard
                                {...tier}
                                startDate="28/10/2012"
                                endDate="28/10/2012"
                                icons={iconPos}
                                handleOpenUpdate={() => handleOpenUpdate(tier)}
                            />
                        </Grid.Col>
                    )
                })
            )
        )
    }

    return (
        <Box p={24}>
            <CommonDrawer
                type=""
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                withCloseButton={false}
            >
                <Box px={24} py={12}>
                    <Group justify="space-between" align="center">
                        <Title order={4}>{selectedTierId ? "Edit Tier" : "Create New Tier"}</Title>
                        {/* <Button h={32} size="sm" bg="#E9ECEF" c="black" fw="lighter" bd="2px solid #ADB5BD" radius={6} onClick={() => setOpenDrawer(false)}>Cancel</Button> */}
                        <SecondaryButton title="Cancel" onClick={() => setOpenDrawer(false)} />
                    </Group>
                </Box>
                <Divider orientation="horizontal" />
                <Box p={24}>
                    <Title order={4}>Main info</Title>
                    <Stack gap={20} mt={20}>
                        <Grid gutter={20}>
                            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                <InputBox {...form.getInputProps('name')} onChange={(value) => handleChange("name", value)} label="Tier name" rightIcon={<IconEdit />} value={form.values.name} placeHolder="Please enter name" error={form.errors.name} />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                <Select
                                    {...form.getInputProps('icon')}
                                    onChange={(value) => {
                                        setSelectedIconValue(value)
                                        form.setFieldValue("icon", { text: value, icon: icons[value] })
                                    }}
                                    placeholder={"Select tier icon"}
                                    value={form.values.icon?.text}
                                    label="Choose Icon"
                                    data={tierOptions}
                                    leftSection={<img src={form.values.icon !== null ? form.values.icon.icon : icons[selectedIconValue]} />}
                                    rightSection={<FaChevronDown />}
                                    renderOption={(item) => {
                                        return (
                                            <Box style={{ width: "100%" }}>
                                                <Group gap="xs" align="center">
                                                    <img src={icons[item.option.value]} alt={`${item.option.label} icon`} />
                                                    <Text ml={8}>{item.option.label}</Text>
                                                </Group>
                                                {item.option.label !== "Gold" && <Divider style={{ margin: "18px 0 8px 0", backgroundColor: '#E5E5E5' }} />}
                                            </Box>
                                        );
                                    }}
                                    style={{ position: 'relative' }}
                                    styles={{
                                        ...textInputStyle,
                                        input: {
                                            height: "40px",
                                            fontSize: "14px",
                                            padding: "12px",
                                            lineHeight: "16px",
                                            borderRadius: "6px",
                                            backgroundColor: 'transparent',
                                            paddingLeft: 40
                                        },
                                        dropdown: {
                                            padding: "10px",
                                            borderRadius: "10px"
                                        }
                                    }}
                                />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                <Textarea
                                    maxLength={500}
                                    {...form.getInputProps('description')}
                                    onChange={(value) => handleChange("description", value.target.value)}
                                    label="Description"
                                    value={form.values.description}
                                    placeHolder="Enter your message here"
                                    error={form.errors.description}
                                    styles={{
                                        ...textInputStyle,
                                        input: {
                                            height: "93px"
                                        },
                                    }}
                                />
                                <p style={{ marginTop: '5px', fontSize: '14px', color: '#999' }}>
                                    {form.values.description.length}/{500} characters
                                </p>
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                <SelectInputField
                                    {...form.getInputProps('eligibility')}
                                    data={TIER_ELIGIBILITY}
                                    leftSection={<TbCalendarClock />}
                                    rightSection={<IconChevronDown style={{ width: '16px', height: 'auto', marginRight: '2px' }} />}
                                    label="Eligibility"
                                    style={{ position: 'relative' }}
                                    styles={{
                                        ...textInputStyle,
                                    }}
                                />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                <Title order={4} mb={20}>Frames</Title>
                                {frames.map((frame) => {
                                    return (
                                        <Group onClick={() => {
                                            const selectedFrame = frames.map((fr) => {
                                                return {
                                                    ...fr,
                                                    isSelected: fr.id === frame.id
                                                }
                                            })
                                            setFrames(selectedFrame)
                                            handleChange("frame_category", frame)
                                        }} key={frame.id} justify="space-between" align="center" bd={`1px solid ${frame.isSelected ? "#3354F4" : "#DEE2E6"}`} px={12} py={8} mb={8} styles={{ root: { cursor: "pointer", borderRadius: "10px" } }}>
                                            <Group justify="space-between" align="center">
                                                <img src={frame.icon} alt="" />
                                                <Text c="#373A40" fw={600}>{frame.text}</Text>
                                            </Group>
                                            <Checkbox checked={frame.isSelected} />
                                        </Group>
                                    )
                                })}
                                {form.errors?.frame && (
                                    <p className="m_8f816625 mantine-InputWrapper-error mantine-Select-error" id="mantine-z2l0z2mpu-error" style={{ fontSize: "12px", marginTop: "5px", lineHeight: "16px" }}>Frame is required</p>
                                )}
                            </Grid.Col>
                            {/* <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                                <Title order={4} mb={20}>Add participants</Title>
                                <Group justify="space-between" align="center">
                                    <Input
                                        value={searchParticipants}
                                        onChange={(e) => setSearchParticipants(e.target.value)}
                                        leftSection={<FaSearch />}
                                        placeHolder="Search"
                                        w="530px"
                                        rightSection={
                                            <CloseButton
                                                aria-label="Clear input"
                                                onClick={() => setSearchParticipants('')}
                                                style={{ display: searchParticipants ? undefined : 'none' }}
                                            />
                                        }
                                    />
                                    <Button h={38} size="sm" bg="#E9ECEF" c="black" fw="lighter" bd="2px solid #ADB5BD" radius={6} onClick={handleSendInvite}>Send invite</Button>
                                </Group>
                                <Box mt={20}>
                                    {form.values.participants?.map((participant) => {
                                        return (
                                            <Group key={participant.id} justify="space-between" align="center" mb={20}>
                                                <Box w="430px" bd="2px solid #CECDC4" px={12} py={8} style={{ borderRadius: "10px", }}>
                                                    <Text c="#373A40" fw={500}>{participant.email}</Text>
                                                </Box>
                                                <Group>
                                                    <Button h={38} size="sm" bg="#FFE9D7" c="orange" fw="lighter" radius={6}>invited</Button>
                                                    <Box w={40} h={38} bg="white" size="sm" c="black" fw="lighter" bd="2px solid #ADB5BD" style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "6px" }}>
                                                        <LuRefreshCcw size={20} color="#ADB5BD" />
                                                    </Box>
                                                    <Box w={40} h={38} bg="white" size="sm" c="black" fw="lighter" bd="2px solid #ADB5BD" style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "6px" }} onClick={() => handleDelete(participant)}>
                                                        <PiTrashBold size={20} color="#ADB5BD" />
                                                    </Box>
                                                </Group>
                                            </Group>
                                        )
                                    })}
                                </Box>
                            </Grid.Col> */}
                        </Grid>
                        <Group justify="end">
                            {selectedTierId && (
                                <Button variant="light" color="#C92A2A" radius="md" style={{ background: '#FFC9C9' }} onClick={handleDelete}>
                                    <Group justify='center' align="center">
                                        <Text fw={600}>Delete Tier</Text>
                                        {tierLoading2 ? <Loader size="sm" color="black" type='dots' /> : null}
                                    </Group>
                                </Button>
                            )}
                            {/* <Button onClick={handleSubmit} h={32} size="sm" bg="#3354F4" c="#ffffff" radius={6}>
                                <Group justify='center' align="center">
                                    <Text fw={600}>{selectedTierId ? "Save changes" : "Create"}</Text>
                                    {tiersLoading ? <Loader size="sm" color="white" type='dots' /> : null}
                                </Group>
                            </Button> */}
                            <PrimaryButton
                                onClick={handleSubmit}
                                title={selectedTierId ? "Save changes" : "Create"}
                                disabled={tiersLoading}
                                fallback={tiersLoading ? <Loader size="sm" color="white" type='dots' /> : null}
                            />
                        </Group>
                    </Stack>
                </Box>
            </CommonDrawer>
            <Group justify="space-between" align="center">
                <Title order={3}>Tier Management Page</Title>
                <PrimaryButton
                    onClick={() => {
                        setOpenDrawer(true)
                        form.reset()
                        form.clearErrors()
                        form.clearFieldError()
                    }}
                    title="Create New Tier"
                />
            </Group>
            <Grid gutter={24} mt={24}>
                {renderContent()}
            </Grid>
        </Box>
    )
}

export default TierManagement;