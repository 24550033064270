import React from 'react'
import { Box, Group, Text } from '@mantine/core'
import { PiWarningCircleFill } from "react-icons/pi";

const PrescriptionMessage = () => {
    return (
        <Box bg="#FFF4E6" px={24} py={20} style={{ borderRadius: "10px" }} bd="2px solid #FD7E14">
            <Group>
                <Box bg="#FFD8A8" style={{ borderRadius: "100%", width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <PiWarningCircleFill size={40} color="#D9480F" />
                </Box>
                <Box ml={10}>
                    <Text c="#E8590C" fw={600}>Need additional info!</Text>
                    <Text c="#2C2E33">Please go through the Scan again in the mobile app.</Text>
                </Box>
            </Group>
        </Box>
    )
}

export default PrescriptionMessage