import { IconUser } from "@tabler/icons-react";
import { TbRulerMeasure } from "react-icons/tb";

export const PrescripitionTabs = [
    {
        name: 'My Prescription',
        icon: <IconUser size={20} />,
        indicator: false,
        value: 'myPrescription',
        disabled: false
    },
    {
        name: 'Frame & Lens options',
        icon: <IconUser size={20} />,
        indicator: false,
        value: 'frame&Lens',
        disabled: false
    },
    {
        name: 'Safety Awards',
        icon: <IconUser size={20} />,
        indicator: false,
        value: 'safetyAwards',
        disabled: false
    },
    {
        name: 'Measurements',
        icon: <TbRulerMeasure size={20} />,
        indicator: false,
        value: 'measurements',
        disabled: false
    },
]