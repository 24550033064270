import React, { useEffect, useState } from 'react';
import { Text, Box, Group } from '@mantine/core';
import classes from '../pages.module.css'
import logo from "../../assets/icons/safety-vision.svg";
import Steps from '../../components/Steps/Steps';
import { AuthenticationForm, AuthenticationForm3, AuthenticationForm4, AuthenticationForm5, AuthenticationForm6 } from '../../components/Form/Forms';
import safetyVisionLogo from '../../assets/icons/safety-vision.svg'
import hippaLogo from '../../assets/icons/hippa.svg'
import sideFoooterBg from '../../assets/icons/side-footer-bg.svg'
import srxLogo from '../../assets/icons/srx-logo-3.svg'
import { LuUser } from "react-icons/lu";
import { FaCheck } from "react-icons/fa6";
import { FiUserPlus } from "react-icons/fi";
import { RiBuilding4Line } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { IoRocketOutline } from "react-icons/io5";
import { useLocation, useNavigate } from 'react-router-dom';
import { setUser } from '../../store/auth/authSlice';
import { useDispatch } from 'react-redux';

const Registration = () => {

  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [step, setStep] = useState(location.state?.step ? location.state?.step : 1)
  const [sendVerificationCode, setSendVerificationCode] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password1: '',
    password2: ''
  })
  const [personalDetails, setPersonalDetails] = useState({
    image: null,
    unFormatted: null,
    first_name: "",
    last_name: "",
    job_title: "",
    email_address: "",
    office_phone_number: "",
    mobile_phone_number: "",
    comments: "",
  })
  const [companyDetails, setCompanyDetails] = useState({
    name: '',
    company_type: '',
    site_name: '',
    employees_at_site: '',
    logo: null
  })
  const [teamDetails, setTeamDetails] = useState({
    file: null,
  })
  const [steps, setSteps] = useState([
    { id: 1, Icon: LuUser, active: false, step: "Your details", desc: "Provide an email" },
    { id: 2, Icon: FiUserPlus, active: false, step: "Enter your personal info", desc: "Provide an email and password" },
    { id: 3, Icon: RiBuilding4Line, active: false, step: "Enter your company info", desc: "Start collaborating with your team" },
    { id: 4, Icon: FiUsers, active: false, step: "Invite your team", desc: "Start collaborating with your team" },
    { id: 5, Icon: IoRocketOutline, active: false, step: "Welcome to RFXN!", desc: "Start collaborating with your team" },
  ]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (step > 1) {
      window.history.pushState({ step }, '', window.location.href);
    }
  }, [step]);

  useEffect(() => {
    window.history.replaceState({ step: 1 }, '', window.location.href);
    const handlePopState = (event) => {
      if (event.state?.step) {
        if (event.state.step < step) {
          if (step > 1) {
            navigate("/")
          }
        } else {
          setStep(event.state.step);
        }
      }
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [step]);

  console.log(step);

  return (
    <div style={{ overflow: "hidden", display: "flex", height: "100%", flexDirection: width < 768 ? "column" : "row" }}>
      <div className={classes.bg} style={{ padding: "0", overflow: 'hidden', width: width < 768 ? "100%" : width < 1025 ? "655px" : "645px", height: "100%", minHeight: width < 991 ? "fit-content" : "100vh" }}>
        <Box py={30} px={width < 991 ? 30 : 60} style={{ position: 'relative', height: width < 768 ? "fit-content" : "100vh" }}>
          <div>
            <img src={logo} alt='' style={{ marginBottom: '30px' }} />
          </div>
          <Steps activeStep={step} steps={steps} setSteps={setSteps} width={width} />
          <Box pos={width < 768 ? "" : "absolute"} bottom={20} style={{ width: width < 768 ? "" : "calc(100% - 60px)", gap: '10px', zIndex: '2', display: 'flex', justifyContent: 'space-between' }} px={0} left={width < 991 ? '20px' : '30px'}>
            <img src={srxLogo} alt="" />
            <img src={hippaLogo} alt="" />
          </Box>
          <img width="100%" src={sideFoooterBg} alt="" style={{ display: width < 768 && "none", position: 'absolute', bottom: '0', left: '0', zIndex: '1' }} />
        </Box>
      </div>
      <div style={{ paddingTop: "54px", paddingBottom: '25px', height: width < 768 ? '100%' : '100vh', gap: '0', overflowY: "auto", display: 'flex', position: "relative", alignItems: 'center', flexDirection: "column", marginTop: width < 768 ? "0" : "0", marginBottom: width < 768 ? "60px" : "0", width: "100%" }}>
        {step <= 5 && <img src={safetyVisionLogo} alt="" />}
        {step === 1 && <AuthenticationForm width={width} sendVerificationCode={sendVerificationCode} setSendVerificationCode={setSendVerificationCode} setStep={setStep} formFields={loginDetails} setFormFields={setLoginDetails} setUser={setPersonalDetails} />}
        {step === 2 && <AuthenticationForm3 width={width} setStep={setStep} formFields={personalDetails} setFormFields={setPersonalDetails} />}
        {step === 3 && <AuthenticationForm4 width={width} setStep={setStep} formFields={companyDetails} setFormFields={setCompanyDetails} />}
        {step === 4 && <AuthenticationForm5 width={width} setStep={setStep} formFields={teamDetails} setFormFields={setTeamDetails} />}
        {step === 5 && <AuthenticationForm6 width={width} />}
        <Group justify="space-between" mt="auto" w="100%" px="lg" maw={'450px'} >
          <Text c="#5C5F66" fw="bold" style={{ cursor: 'pointer' }}>About us</Text>
          {step > 3 && step < 5 && <Text c="#5C5F66" fw="bold" onClick={() => setStep((step) => step + 1)} style={{ cursor: 'pointer' }}>Skip now</Text>}
          <Text c="#5C5F66" fw="bold" style={{ cursor: 'pointer' }}>Help</Text>
        </Group>
      </div>
    </div>
  );
};

export default Registration;