import { Menu, Button, Stack, Group, Text, Box, Input, Flex, Select, Checkbox, MultiSelect } from '@mantine/core';
import { IconBell } from '@tabler/icons-react';
import NotificationComp from '../Notification/Notification';
import { FiFilter } from 'react-icons/fi';
import AccordionComp from './Accordion';
import { useSelector } from 'react-redux';
import { DateInput } from '@mantine/dates';
import { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { TbCurrencyDollar } from 'react-icons/tb';
import { TbCalendarStats } from "react-icons/tb";

// Tier icons
import silverTier from '../../assets/icons/silverTier.svg';
import goldTier from '../../assets/icons/goldTier.svg';
import bronzeTier from '../../assets/icons/bronzeTier.svg';

function Profile() {
    return <div style={{
        width: "32px",
        height: "32px",
        borderRadius: "6px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #FF922B",
        cursor: "pointer"
    }}>
        <IconBell color="#FF922B" size={20} />
    </div>
}

function Dropdown() {
    return (
        <Menu width={200}>
            <Menu.Target>
                <Button bg="none" px={0}>
                    <Profile />
                </Button>
            </Menu.Target>
            <Menu.Dropdown style={{ border: "2px solid #FF922B", width:"400px", borderRadius: "8px", right: 35, left: "initial", padding: "10px" }}>
                {[...new Array(2)].map(() => {
                    return (
                        <Menu.Item style={{ margin: 0, padding: 0 }}>
                            <NotificationComp />
                        </Menu.Item>
                    )
                })}
            </Menu.Dropdown>
        </Menu>
    );
}

function FilterDropdown({ applyFilter, clearFilters }) {

    const employeeFilters = useSelector(state => state.filters.employeeFilters)
    let appliedEmployeeFilters = []
    const createEmployeeFilter = (appliedFilters) => {
        appliedEmployeeFilters = appliedFilters
    }

    const clearEmployeeFilter = () => {
        clearFilters({
            tier: [],
            rx_non_rx: [],
            status: []
        })
    }

    return (
        <Menu position="bottom-end">
            <Menu.Target>
                <Button style={{
                    backgroundColor: '#E9ECEF',
                    border: "1px solid #ADB5BD",
                    borderRadius: "6px",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#343A40",
                }}>
                    <FiFilter style={{ marginRight: 10, color: "#ADB5BD" }} /> Filter
                </Button>
            </Menu.Target>
            <Menu.Dropdown style={{ width: "321px", border: "1px solid #ADB5BD", borderRadius: "8px", padding: "16px" }}>
                <Stack>
                    <AccordionComp createEmployeeFilter={createEmployeeFilter} employeeFilterState={employeeFilters} />
                    <Group justify='space-between'>
                        <Text onClick={clearEmployeeFilter} style={{ cursor: 'pointer' }} c="#868E96">Clear all</Text>
                        <Button color="#3354F4" fw={500} onClick={() => applyFilter(appliedEmployeeFilters)}>Apply Filter</Button>
                    </Group>
                </Stack>
            </Menu.Dropdown>
        </Menu>
    );
}

function FilterDropdown2({ applyFilter, clearFilters }) {

    const employeeFilters = useSelector(state => state.filters.employeeFilters)
    let appliedEmployeeFilters = []
    const createEmployeeFilter = (appliedFilters) => {
        appliedEmployeeFilters = appliedFilters
    }

    const clearEmployeeFilter = () => {
        clearFilters({
            tier: [],
            rx_non_rx: [],
            status: []
        })
    }

    const [value, setValue] = useState(new Date());
    const [selectedIcon, setSelectedIcon] = useState("")

    const icons = {
        "gold": goldTier,
        "bronze": bronzeTier,
        "silver": silverTier,
    }
    const tierOptions = [
        { value: "gold", label: "Gold" },
        { value: "bronze", label: "Bronze" },
        { value: "silver", label: "Silver" },
    ];

    const locationOptions = [
        { value: "california", label: "California" },
        { value: "colorado", label: "Colorado" },
        { value: "connecticut", label: "Connecticut" },
        { value: "connecticut2", label: "Connecticut" },
        { value: "connecticut3", label: "Connecticut" },
        { value: "connecticut4", label: "Connecticut" },
    ];

    return (
        <Menu position="bottom-end">
            <Menu.Target>
                <Button style={{
                    backgroundColor: '#E9ECEF',
                    border: "1px solid #ADB5BD",
                    borderRadius: "6px",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#343A40",
                }}>
                    <FiFilter style={{ marginRight: 10, color: "#ADB5BD" }} /> Filter
                </Button>
            </Menu.Target>
            <Menu.Dropdown style={{ width: "370px", border: "1px solid #ADB5BD", borderRadius: "8px", padding: "16px" }}>
                <Stack>
                    <Text c="#495057" fz={18} fw={600} mb={-5}>Date Range</Text>
                    <Flex justify="space-between" gap={10}>
                        <Box>
                            <DateInput
                                leftSection={<TbCalendarStats />}
                                styles={{ label: { color: "#868E96" } }}
                                value={value}
                                onChange={setValue}
                                label="From:"
                                placeholder="Date input"
                            />
                        </Box>
                        <Box>
                            <DateInput
                                leftSection={<TbCalendarStats />}
                                styles={{ label: { color: "#868E96" } }}
                                value={value}
                                onChange={setValue}
                                label="To:"
                                placeholder="Date input"
                            />
                        </Box>
                    </Flex>
                    <Text c="#495057" fz={18} fw={600} mb={-5}>Location</Text>
                    <MultiSelect
                        checkIconPosition="left"
                        data={locationOptions}
                        placeholder="London, UK"
                        rightSection={<IoIosArrowDown style={{ width: '22px', height: 'auto', marginRight: '5px' }} />}
                        renderOption={(item) => {
                            return (
                                <Box style={{ width: "100%" }}>
                                    <Group gap="xs" align="center">
                                        <Checkbox />
                                        <Text>{item.option.label}</Text>
                                    </Group>
                                </Box>
                            );
                        }}
                        styles={{
                            input: {
                                fontSize: "14px",
                                padding: "7px 12px",
                                lineHeight: "16px",
                                borderRadius: "7px",
                                height: 40
                            },
                            dropdown: {
                                backgroundColor: "#F1F3F5",
                                border: "1px solid #CED4DA",
                                borderRadius: "7px"
                            }
                        }}
                    />
                    <Text c="#495057" fz={18} fw={600} mb={-5}>Status</Text>
                    <Select
                        checkIconPosition="left"
                        data={['PENDING', 'APPROVED', 'DECLINE']}
                        placeholder="Please select status"
                        rightSection={<IoIosArrowDown style={{ width: '22px', height: 'auto', marginRight: '5px' }} />}
                        styles={{
                            input: {
                                height: '40px',
                                fontSize: '14px',
                                padding: '12px',
                                lineHeight: '16px',
                                borderRadius: "7px"
                            },
                            option: {
                                color: "#FD7E14"
                            }
                        }}
                    />
                    <Text c="#495057" fz={18} fw={600} mb={-5}>Tier assignment</Text>
                    <Select
                        data={tierOptions}
                        leftSection={<img src={selectedIcon ? icons[selectedIcon] : goldTier} />}
                        rightSection={<IoIosArrowDown style={{ width: '22px', height: 'auto', marginRight: '5px' }} />}
                        renderOption={(item) => {
                            return (
                                <Box style={{ width: "100%" }}>
                                    <Group gap="xs" align="center">
                                        <img src={icons[item.option.value]} alt={`${item.option.label} icon`} />
                                        <Text>{item.option.label}</Text>
                                    </Group>
                                </Box>
                            );
                        }}
                        style={{ position: 'relative' }}
                        styles={{
                            label: {
                                marginBottom: "10px",
                                lineHeight: "18px",
                                fontWeight: "600",
                                color: "#495057"
                            },
                            input: {
                                height: "40px",
                                fontSize: "14px",
                                padding: "12px",
                                lineHeight: "16px",
                                borderRadius: "7px",
                                backgroundColor: 'transparent',
                                paddingLeft: 40
                            },
                        }}
                    />
                    <Text c="#495057" fz={18} fw={600} mb={-5}>Amount</Text>
                    <Flex justify="space-between" gap={10}>
                        <Box>
                            <Input
                                rightSection={<TbCurrencyDollar />}
                                styles={{ label: { color: "#868E96" } }}
                                label="From:"
                                placeholder="0"
                            />
                        </Box>
                        <Box>
                            <Input
                                rightSection={<TbCurrencyDollar />}
                                styles={{ label: { color: "#868E96" } }}
                                label="To:"
                                placeholder="0"
                            />
                        </Box>
                    </Flex>
                    <Group justify='space-between' mt={15}>
                        <Text onClick={clearEmployeeFilter} style={{ cursor: 'pointer' }} c="#868E96">Clear all</Text>
                        <Button color="#3354F4" fw={500} onClick={() => applyFilter(appliedEmployeeFilters)}>Apply Filter</Button>
                    </Group>
                </Stack>
            </Menu.Dropdown>
        </Menu>
    );
}

export {
    Dropdown,
    FilterDropdown,
    FilterDropdown2
}