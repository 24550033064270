import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    step: 0
};

const stepSlice = createSlice({
    name: "steps",
    initialState,
    reducers: {
        setStep: (state, { payload }) => {
            if (payload.type === 0) {
                state.step = state.step + 1
            } else {
                state.step = state.step - 1
            }
        },
    },
});

export const { setStep } = stepSlice.actions;
export default stepSlice.reducer;