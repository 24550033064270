import React from "react";
import { Tabs, Divider } from "@mantine/core"
import { useMediaQuery, useViewportSize } from '@mantine/hooks';
import { useLocation } from "react-router-dom";
import './TabBaseLayout.css'

const TabBaseLayout = ({ tabList = [], children }) => {

    const { height } = useViewportSize();
    const isSmallScreen = useMediaQuery("(max-width: 768px)");
    const isTabletScreen = useMediaQuery('(min-width: 768px) and (max-width: 1024px)');
    const isMediumScreen = useMediaQuery("(max-width: 1024px)");
    const location = useLocation()

    const serializedTabList = tabList.map(tab => <Tabs.Tab h={40} fw="500" key={tab.value} value={tab.value}>{tab.label}</Tabs.Tab>)
    const serializedTabPanels = React.Children.map(children, (child, index) => <Tabs.Panel style={{ overflowY: "auto" }} key={tabList[index].value} value={tabList[index].value} className='panel'>{child}</Tabs.Panel>)

    return (
        <Tabs
            orientation={isSmallScreen ? "horizontal" : "vertical"}
            defaultValue={tabList.length > 0 ? tabList[0].value : ''}
            color='#E9ECEF'
            variant="pills"
            h={!isMediumScreen && height - 60}
            className='tab'
            styles={(theme) => ({
                tab: {
                    color: theme.colors.dark[7],
                    '&[dataActive]': {
                        color: theme.black,
                        backgroundColor: '#E9ECEF',
                    },
                },
            })}
        >
            <Tabs.List pl={12} pr={6} pt={24} style={{
                display: (isTabletScreen && location.pathname === "/admin/notification") ? "none" : "flex",
                justifyContent: isSmallScreen ? "space-around" : "flex-start",
                flexDirection: isSmallScreen ? "row" : "column",
                width: !isSmallScreen && "193px",
            }}>
                {serializedTabList}
            </Tabs.List>
            <Divider orientation={isSmallScreen ? "horizontal" : "vertical"} h={isSmallScreen ? "auto" : "93vh"} ml={!isSmallScreen && 12} mr={!isSmallScreen && 24} my={isSmallScreen && 15} />
            {serializedTabPanels}
        </Tabs>
    )
}

export default TabBaseLayout;