import { Box, Group, Stack, Text, Flex, Button, Title, Loader, Grid, FileButton } from "@mantine/core"
import { useForm } from "@mantine/form"
import { INDUSTRY_SERVICED } from "../../choices/organizationChoices"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import BaseLayout from "./BaseLayout"
import ImageResizer from "../PartnerSignup/ImageResizer"
import { asyncErrorHandler } from "../../helpers/errorHandler"
import { DateInputField, InputField, SelectInputField } from "../ui/Input/Input"
import ProfileUpload from "./profileUpload"
import { IoIosArrowDown } from "react-icons/io"
import { PrimaryButton, PrimaryButton2 } from "../ui/Button/Button"
import { updateUserData } from "../../middlewares/auth/auth"
import { useDisclosure } from "@mantine/hooks"
import ModalComp from "../ui/modal/modal"
import './PartnerOrganization.css'
import classes from "../Form/form.module.css"
import { dateFormatter } from "../../helpers/dateFormatter"

const PersonalDetails = ({ setStepper, isMobile, width }) => {

    const dispatch = useDispatch()
    const [imageError, setImageError] = useState("")
    const [loading, setLoading] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [opened, { open, close }] = useDisclosure(false);

    const labelStyle = {
        color: "#424242",
        fontSize: "16px",
        fontWeight: "600",
    }

    const form = useForm({
        initialValues: {
            first_name: "",
            last_name: "",
            citizenship: "",
            date_of_birth: null,
            image: null,
            office_phone_number: "",
            mobile_phone_number: "",
        },
        validate: {
            image: (val) => val ? null : 'Image is required',
            first_name: (val) => (/^[A-Za-zÀ-ÖØ-öø-ÿ' -]+$/.test(val) ? null : 'First name is required'),
            last_name: (val) => (/^[A-Za-zÀ-ÖØ-öø-ÿ' -]+$/.test(val) ? null : 'Last name is required'),
            citizenship: (val) => val ? null : 'Citizenship is required',
            mobile_phone_number: (val) => (/^\+?\d{7,15}$/.test(val) ? null : 'Phone number is required'),
            date_of_birth: (val) => {
                if (!val) return "Date of birth is required";
                else return null
            }
        }
    });

    const DOB = dateFormatter(form.values.date_of_birth)

    const profileImageURL = form.values.image && typeof form.values.image === "string" ? form.values.image :
        form.values.image && form.values.image instanceof File ?
            URL.createObjectURL(form.values.image) : null

    const createUserProfile = async (formData) => {
        setLoading(true)
        const res = await dispatch(updateUserData(formData))
        if (updateUserData.fulfilled.match(res)) {
            window.toast("Profile created successfully")
            setLoading(false)
            setTimeout(() => {
                setStepper(3)
            }, 500);
        }
        else if (updateUserData.rejected.match(res)) {
            setLoading(false)
            asyncErrorHandler(res, form, setImageError)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (form.validate().hasErrors) {
            setImageError(form.validate().errors.image)
            form.validate()
        }
        else {
            const formData = new FormData()
            Object.entries(form.values).forEach(([key, value]) => {
                if (key === "date_of_birth") {
                    formData.append("date_of_birth", DOB)
                } else {
                    formData.append(key, value)
                }
            })
            createUserProfile(formData)
        }
    }

    function setResizedImage(file) {
        form.setFieldValue('image', file)
    }

    useEffect(() => {
        form.reset()
    }, [])

    console.log(form.errors);    

    return (
        <BaseLayout width={width}>
            {opened && <ModalComp
                title={<Text fz={20} fw={600}>Upload photo</Text>}
                opened={opened}
                onClose={() => {
                    close()
                    form.setFieldValue('image', null)
                }}
                radius={20}
                size="lg"
                p={24}
                padding={24}
            >
                <Grid>
                    <Grid.Col span={{ xs: 12, sm: 12, md: 9, lg: 9 }}>
                        <Flex
                            w="100%"
                            h={400}
                            align="center"
                            justify="center"
                            direction="column"
                            style={{
                                border: "2px dashed #D1D5DB",
                                borderRadius: "12px",
                            }}
                        >
                            {profileImageURL ? <img width="100%" height="100%" style={{ objectFit: "contain", borderRadius: "12px" }} src={profileImageURL} alt="" /> : (
                                <ProfileUpload
                                    updateImage={(image) => form.setFieldValue('image', image)}
                                    setUploading={setUploading}
                                />
                            )}
                        </Flex>
                        <ImageResizer
                            image={form.values.image && form.values.image instanceof File ? form.values.image : null}
                            setFile={setResizedImage}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 12, md: 3, lg: 3 }}>
                        <Flex
                            w="100%"
                            h={120}
                            align="center"
                            justify="center"
                            style={{
                                border: "2px dashed #D1D5DB",
                                borderRadius: "12px",
                            }}
                        >
                            {uploading ? <Loader color="blue" size={50} /> : !profileImageURL ? null : <img style={{ width: "100%", height: "120px", borderRadius: "8px", objectFit: "cover" }} src={profileImageURL} alt="" />}
                        </Flex>
                    </Grid.Col>
                </Grid>
                <Flex align="center" justify="space-between" mt={20}>
                    <Box>
                        <PrimaryButton2
                            title="Reset changes"
                            h={40}
                            fw={600}
                            fz={'lg'}
                            onClick={() => form.setFieldValue('image', null)}
                        />
                    </Box>
                    <Flex gap={16}>
                        <PrimaryButton2
                            title="Cancel"
                            h={40}
                            fw={600}
                            fz={'lg'}
                            onClick={() => {
                                close()
                                form.setFieldValue('image', null)
                            }}
                        />
                        <PrimaryButton
                            title="Save photo"
                            h={40}
                            fw={600}
                            fz={'lg'}
                            onClick={close}
                        />
                    </Flex>
                </Flex>
            </ModalComp>}
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <Box ta="center">
                    <Text fz={24} fw={600} mb={6}>Enter your personal details</Text>
                    <Text c="dimmed" fz={16}>Strong relationships create strong partnerships.</Text>
                </Box>
                <Box mt={30} mb={20}>
                    <Text styles={{ root: { ...labelStyle, marginBottom: "10px" } }}>Profile photo</Text>
                    <Flex>
                        <div className={classes.uploadImg2}>
                            {uploading ? <Loader color="blue" size={50} /> : !profileImageURL ? null : <img style={{ width: "40px", height: "40px", borderRadius: "8px", objectFit: "cover" }} src={profileImageURL} alt="" />}
                        </div>
                        <Button onClick={open} w={168} h={40} size="xl" style={{ borderRadius: "8px", backgroundColor: "transparent", border: "2px solid #CED4DA", height: '38px' }}>
                            <Group gap="6px" align='center' justify="center">
                                <Text fz={16} fw={600} c="#424242">{profileImageURL ? "Change photo" : "Upload"}</Text>
                            </Group>
                        </Button>
                    </Flex>
                    {imageError && <p style={{ fontSize: "14px", color: "#FA5252", marginTop:"10px", width:"474px" }}>{imageError}</p>}
                </Box>
                <Stack gap={20}>
                    <Grid>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <InputField
                                withAsterisk={true}
                                label={<Text styles={{ root: labelStyle }}>First name</Text>}
                                placeholder="Please enter first name"
                                key={form.key("first_name")}
                                style={{ position: 'relative', width: width < 768 && "100%" }}
                                backgroundColor="transparent"
                                {...form.getInputProps('first_name')}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
                            <InputField
                                withAsterisk={true}
                                label={<Text styles={{ root: labelStyle }}>Last name</Text>}
                                placeholder="Please enter last name"
                                key={form.key("last_name")}
                                style={{ position: 'relative', width: width < 768 && "100%" }}
                                backgroundColor="transparent"
                                {...form.getInputProps('last_name')}
                            />
                        </Grid.Col>
                    </Grid>
                    <Grid>
                        <Grid.Col span={{ sm: 12, md: 12, lg: 12 }}>
                            <SelectInputField
                                withAsterisk={true}
                                label={<Text styles={{ root: labelStyle }}>Citizenship</Text>}
                                placeholder="Please select citizenship"
                                backgroundColor="transparent"
                                rightSection={<IoIosArrowDown style={{ width: '22px', height: 'auto', marginRight: '5px' }} />}
                                data={INDUSTRY_SERVICED}
                                key={form.key("citizenship")}
                                {...form.getInputProps("citizenship")}
                            />
                        </Grid.Col>
                    </Grid>
                    <Grid>
                        <Grid.Col span={{ sm: 12, md: 12, lg: 12 }}>
                            <InputField
                                withAsterisk={true}
                                label={<Text styles={{ root: labelStyle }}>Phone</Text>}
                                placeholder="Please enter phone number"
                                key={form.key("mobile_phone_number")}
                                style={{ position: 'relative', width: width < 768 && "100%" }}
                                backgroundColor="transparent"
                                {...form.getInputProps("mobile_phone_number")}
                            />
                        </Grid.Col>
                    </Grid>
                    <Grid>
                        <Grid.Col span={{ sm: 12, md: 12, lg: 12 }}>
                            <DateInputField
                                withAsterisk={true}
                                label={<Text styles={{ root: labelStyle }}>Date of birth</Text>}
                                placeholder="Please enter DOB"
                                key={form.key("date_of_birth")}
                                style={{ position: 'relative', width: width < 768 && "100%" }}
                                backgroundColor="transparent"
                                {...form.getInputProps("date_of_birth")}
                            />
                        </Grid.Col>
                    </Grid>
                    <Grid mt={15}>
                        <Grid.Col span={{ sm: 12, md: 12, lg: 12 }}>
                            <PrimaryButton
                                title="Next Step"
                                type='submit'
                                bg="#3354F4"
                                h={48}
                                fw={600}
                                fz={'lg'}
                                disabled={loading}
                                fullWidth
                                fallback={loading && <Loader size="sm" type='dots' color='#ffff' />}
                            />
                        </Grid.Col>
                    </Grid>
                </Stack>
            </form>
        </BaseLayout>
    )
}

export default PersonalDetails;