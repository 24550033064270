import { useForm } from '@mantine/form';
import { Box, TextInput, Text, Group, Button, Divider, Stack, PasswordInput, Image, Flex, Card, Progress, Select, Checkbox, Textarea, Grid, Loader } from '@mantine/core';
import { Dropzone, MS_EXCEL_MIME_TYPE } from '@mantine/dropzone';
import { GoogleButton, SocialButton } from "../../components/Button/Button"
import whiteLogo from '../../assets/icons/white-logo.svg'
import classes from '../Form/form.module.css'
import { FaRegTrashAlt } from "react-icons/fa";
import { useEffect, useState } from 'react';
import { IoEyeOutline, IoEyeOffOutline, IoPauseOutline, } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import onboarding from "../../assets/images/onboarding-finish.svg"
import uploadDocumentIcon from "../../assets/icons/upload-document.svg"
import excelLogo from "../../assets/icons/excel-logo.svg"
import { useSelector, useDispatch } from 'react-redux';
import ImageResizer from '../ImageResizer/ImageResizer';
import { createOrganization, updateOrganization } from "../../middlewares/organization/organization"
import { uploadEmlpoyeeTemplate } from "../../middlewares/employee/employee.service"
import { updateUserData, asyncRegister, verifyOTP } from "../../middlewares/auth/auth"
import { ORGANIZATION_SIZE, EMPLOYEES_AT_SITE_SIZE, INDUSTRY_SERVICED, ORGANIZATIONS_EMPLOYEES_AT_SITE_SIZE_CHOICES } from "../../choices/organizationChoices"
import { NavLink, useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { googleLogin } from '../../middlewares/auth/auth';
import ProfileImageUploader from "../BaseComponents/ProfileImageUploader"
import PrimaryButton from '../BaseComponents/Buttons/Button'
import { PrimaryButton as PrimaryButtonn } from '../ui/Button/Button'
import idMeIcon from '../../assets/icons/idmelogo.svg'
import facebookIcon from '../../assets/icons/fb.svg'
import { useLocalStorage, useViewportSize } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { asyncErrorHandler } from '../../helpers/errorHandler';
import { InputField, PasswordInputField, SelectInputField, TextAreaField } from '../ui/Input/Input';
import * as XLSX from 'xlsx';
import { IconDownload } from '@tabler/icons-react';
import { BsPause } from 'react-icons/bs';

function formatFileSize(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

    return parseFloat((bytes / Math.pow(1024, i)).toFixed(decimals)) + ' ' + sizes[i];
}

function GoogleIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid"
            viewBox="0 0 256 262"
            style={{ width: '1.2rem', height: '1.2rem' }}
            {...props}
        >
            <path
                fill="#4285F4"
                d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
            />
            <path
                fill="#34A853"
                d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
            />
            <path
                fill="#FBBC05"
                d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
            />
            <path
                fill="#EB4335"
                d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
            />
        </svg>
    );
}

export function AuthenticationForm({ width, setStep, formFields, setFormFields, setUser }) {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [agreeTerms, setAgreeTerms] = useState(false)
    const [timer, setTimer] = useState(30);
    const [sendVerificationCode, setSendVerificationCode] = useState(false)

    const form = useForm({
        initialValues: {
            email: formFields.email,
            password: formFields.password,
        },
        validate: {
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
            password: (val) => (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_\-])[A-Za-z\d@$!%*?&_\-]{8,}$/.test(val) ? null : 'Password must be at least 8 characters long, contain at least one lowercase letter, one uppercase letter, one number, and one special character.'),
        },
    });

    const verificationCodeForm = useForm({
        initialValues: {
            verification_code: '',
        },
        validate: {
            verification_code: (val) => (/^\d{4}$/.test(val) ? null : 'Verification code must be 4 digits'),
        }
    })

    async function handleSubmit(e) {
        e.preventDefault();
        if (sendVerificationCode === true) {
            if (verificationCodeForm.validate().hasErrors) {
                verificationCodeForm.validate();
                return;
            }
            setLoading(true)
            const res = await dispatch(verifyOTP({ email: form.values.email, verification_code: verificationCodeForm.values.verification_code }))
            if (res.type === "verifyOTP/fulfilled") {
                setLoading(false)
                setSendVerificationCode(false)
                setStep((step) => step + 1)
            } else {
                setLoading(false)
                setSendVerificationCode(true)
                asyncErrorHandler(res, verificationCodeForm)
            }
        }
        else {
            if (form.validate().hasErrors) {
                form.validate()
                return;
            }
            setLoading(true)
            localStorage.setItem('inProcess', 'true')
            const res = await dispatch(asyncRegister(form.values))
            if (res.type === "auth/register/fulfilled") {
                setLoading(false)
                setSendVerificationCode(true)
                setTimer(30)
                window.toast(res.payload.data.message)
            } else {
                setLoading(true)
                localStorage.setItem('inProcess', 'true')
                const res = await dispatch(asyncRegister(form.values))
                if (asyncRegister.fulfilled.match(res)) {
                    setSendVerificationCode(true)
                    setFormFields({ ...form.values })
                    setLoading((loading) => loading = false)
                    setStep((step) => step + 1);
                }
                else if (asyncRegister.rejected.match(res)) {
                    setSendVerificationCode(false)
                    setLoading((loading) => loading = false)
                    asyncErrorHandler(res, form)
                }
            }
        }
    }

    const login = useGoogleLogin({
        onSuccess: async (googleRes) => {
            const res = await dispatch(googleLogin({ access_token: googleRes.access_token }))
            if (googleLogin.fulfilled.match(res)) {
                const user = res.payload.user
                setUser({
                    name: user?.profile.name,
                    age: user?.profile.age ? user.age : '',
                    image: null,
                    unFormatted: null
                })
                setStep((step) => step + 2)
            }
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    function renderContent() {
        return (
            <>
                <InputField
                    label={<Text fz={16} fw={500}>Email</Text>}
                    placeholder="hello@refraxtion.com"
                    {...form.getInputProps("email")}
                    key={form.key("email")}
                    disabled={sendVerificationCode}
                    withAsterisk={true}
                />
                {!sendVerificationCode && (
                    <>
                        <PasswordInputField
                            label={<Text fz={16} fw={500}>Enter your password</Text>}
                            placeholder="Set Password"
                            {...form.getInputProps("password")}
                            key={form.key("password")}
                            withAsterisk={true}
                            visibilityToggleIcon={({ reveal }) =>
                                reveal ? (
                                    <IoEyeOffOutline style={{ width: 'var(--psi-icon-size)', height: 'var(--psi-icon-size)' }} />
                                ) : (
                                    <IoEyeOutline style={{ width: 'var(--psi-icon-size)', height: 'var(--psi-icon-size)' }} />
                                )
                            }
                        />
                        <Checkbox
                            styles={{ input: { cursor: "pointer" } }}
                            value={agreeTerms}
                            onChange={(e) => setAgreeTerms(e.target.checked)}
                            label="I agree to the Terms of Use and Privacy Policy"
                        />
                    </>
                )}
                {sendVerificationCode && (
                    <>
                        <InputField
                            withAsterisk={true}
                            type="number"
                            label={<Text fz={16} fw={500}>Login Code</Text>}
                            placeholder="Paste login code"
                            {...verificationCodeForm.getInputProps('verification_code')}
                            maxLength={4}
                            onChange={(e) => {
                                const value = e.target.value.replace(/\D/g, '');
                                if (value.length <= 4) {
                                    verificationCodeForm.setFieldValue('verification_code', value);
                                }
                            }}
                        />
                        <Text fz={16} c={'#5C5F66'}>
                            We sent a code to your inbox * <b>Resend in {timer < 10 ? '0' + timer : timer}s</b>
                        </Text>
                    </>
                )}
            </>
        )
    }

    useEffect(() => {
        if (sendVerificationCode) {
            let interval = null;
            if (timer > 0) {
                interval = setInterval(() => {
                    setTimer((prevTimer) => prevTimer - 1);
                }, 1000);
            } else {
                clearInterval(interval);
            }
            return () => clearInterval(interval);
        }
    }, [timer, sendVerificationCode])

    return (
        <Box radius="md" py={20} px="lg" mb={50} style={{ width: "100%", maxWidth: "450px" }}>
            <div className={classes.formTitleContainer}>
                <Text size="lg" fw={700} style={{ fontSize: "24px", marginBottom: "12px", lineHeight: "32px", color: "#212529", textAlign: "center" }}>Create your bussiness account</Text>
                <Text size="lg" fw={400} style={{ fontSize: "16px", lineHeight: "18px", color: "#5C5F66", textAlign: "center" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
            </div>
            <form onSubmit={handleSubmit} style={{ marginTop: "25px" }}>
                <Stack gap={30}>
                    <Stack gap={20}>
                        {renderContent()}
                    </Stack>
                    <Stack gap={12}>
                        <PrimaryButton
                            styles={{ root: { backgroundColor: (!agreeTerms || (!verificationCodeForm.values.verification_code && sendVerificationCode)) ? "#E5E5E5" : "", color: (!agreeTerms || (!verificationCodeForm.values.verification_code && sendVerificationCode)) ? "#ADB5BD" : "" } }}
                            disabled={loading || (!verificationCodeForm.values.verification_code && sendVerificationCode) || (!agreeTerms ? true : false)}
                            loading={loading}
                            type={'submit'}
                            variant='filled'
                            fullWidth={true}
                            text='Continue'
                            fz={'lg'}
                            size={'lg'}
                        />
                        {!sendVerificationCode && (
                            <Stack gap={12}>
                                <Divider label="Or Continue With" labelPosition="center" color={'#ADB5BD'} />
                                <div style={{ width: "100%", display: "flex" }}>
                                    <GoogleButton w="100%" radius={6} h={45} leftSection={<GoogleIcon />} />
                                    <SocialButton w="100%" ml={8} h={45} leftSection={<img src={facebookIcon} alt="" />} color="#0866FF" />
                                </div>
                            </Stack>
                        )}
                        <div style={{ marginTop: "25px", display: "flex", justifyContent: "center", gap: '10px', flexDirection: width < 992 ? "column" : "row" }}>
                            <Text ta="center" c="#909296">
                                Already have an account? <NavLink to="/" style={{ textDecoration: "none", color: "#909296", fontWeight: "bold" }}>
                                    Sign in
                                    <Text ta="center" c="#909296">
                                        <NavLink to="/partner/signup" style={{ textDecoration: "none", color: "#909296", fontWeight: "bold" }}>Sign up as a partner</NavLink>
                                    </Text>
                                </NavLink>
                            </Text>
                        </div>
                    </Stack>
                </Stack>
            </form>
        </Box>
    );
}

export function AuthenticationForm3({ width, setStep, formFields, setFormFields }) {

    const [loading, setLoading] = useState(false)
    const user = useSelector((state) => state.login.user)
    const [imageError, setImageError] = useState("")
    const dispatch = useDispatch()

    const form = useForm({
        initialValues: {
            unFormatted: formFields.unFormatted,
            image: formFields.image,
            first_name: formFields.first_name,
            last_name: formFields.last_name,
            job_title: formFields.job_title,
            email_address: user?.email,
            office_phone_number: formFields.office_phone_number,
            mobile_phone_number: formFields.mobile_phone_number,
            comments: formFields.comments,
        },
        validate: {
            first_name: (val) =>
                /^[A-Za-z][A-Za-z\s-]*[A-Za-z]?$/.test(val) ? null : 'Please enter a valid first name.',
            last_name: (val) =>
                /^[A-Za-z][A-Za-z\s-]*[A-Za-z]?$/.test(val) ? null : 'Please enter a valid last name.',
            job_title: (val) =>
                val.trim() !== '' ? null : 'Please enter a valid job title.',
            email_address: (val) =>
                /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val) ? null : 'Please enter a valid email address.',
            office_phone_number: (val) =>
                /^\+?[0-9]{7,15}$/.test(val) ? null : 'Please enter a valid office phone number.',
            mobile_phone_number: (val) =>
                /^\+?[0-9]{7,15}$/.test(val) ? null : 'Please enter a valid mobile phone number.',
            comments: (val) =>
                val.trim().length >= 5 ? null : 'Comments should be at least 5 characters long.',
        },
    });

    async function handleSubmit(e) {
        e.preventDefault();
        if (form.validate().hasErrors) {
            form.validate()
        }
        else {
            const formData = new FormData()
            Object.entries(form.values).forEach(([key, value]) => {
                if (key !== 'unFormatted' && key !== 'image') {
                    formData.append(key, value)
                }
                else if (key === 'image') {
                    if (value) {
                        formData.append(key, value)
                    }
                }
            })
            setLoading(true)
            const res = await dispatch(updateUserData(formData))
            if (updateUserData.fulfilled.match(res)) {
                setFormFields(form.values)
                setLoading(false)
                setStep((step) => step + 1);
            }
            else {
                setLoading(false)
                asyncErrorHandler(res, form, setImageError)
                console.log(res);
            }
        }
    }

    function setResizedImage(file) {
        form.setFieldValue('image', file)
    }

    return (
        <Box radius="md" p={20} mb={50} style={{ width: "90%", maxWidth: "755px" }}>
            {/* <img src={whiteLogo} style={{ display: "block", width: '78px', margin: "auto", marginBottom: "20px" }} alt="logo" /> */}
            <div className={classes.formTitleContainer}>
                <Text size="lg" fw={600} style={{ fontSize: "24px", marginBottom: "12px", lineHeight: "32px", color: "#212529", textAlign: "center" }}>Enter your personal info</Text>
                <Text size="lg" fw={400} style={{ fontSize: "16px", lineHeight: "18px", color: "#5C5F66", textAlign: "center" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
            </div>
            <form onSubmit={handleSubmit} style={{ marginTop: "40px", }}>
                <Grid grow gutter={{ sm: 20, md: 20, lg: 30, xl: 40 }}>
                    <Grid.Col span={{ sm: 12, md: 6, lg: 5, xl: 5 }}>
                        <Flex justify="center" direction="column" align="center">
                            <ProfileImageUploader
                                labelText="Your profile picture"
                                profileImageURL={form.values.unFormatted ? URL.createObjectURL(form.values.unFormatted) : null}
                                updateImage={(image) => {
                                    form.setFieldValue('image', image);
                                    form.setFieldValue('unFormatted', image)
                                    setImageError("")
                                }}
                                deleteImage={() => {
                                    form.setFieldValue('unFormatted', null);
                                    form.setFieldValue('image', null)
                                    setImageError("")
                                }}
                            />
                            <ImageResizer image={form.values.unFormatted} setFile={setResizedImage} />
                            {imageError && <p style={{ fontSize: "14px", color: "#FA5252" }}>{imageError}</p>}
                        </Flex>
                    </Grid.Col>
                    <Grid.Col span={{ sm: 12, md: 6, lg: 7, xl: 7 }}>
                        <Grid grow gutter={14}>
                            <Grid.Col span={{ xs: 6, md: 12, lg: 6 }}>
                                <InputField
                                    withAsterisk={true}
                                    label={<Text fz={16} fw={500}>Enter your first name</Text>}
                                    placeholder="First name"
                                    key={form.key('first_name')}
                                    {...form.getInputProps('first_name')}
                                />
                            </Grid.Col>

                            <Grid.Col span={{ xs: 6, md: 12, lg: 6 }}>
                                <InputField
                                    withAsterisk={true}
                                    label={<Text fz={16} fw={500}>Enter your last name</Text>}
                                    placeholder="Last name"
                                    key={form.key('last_name')}
                                    {...form.getInputProps('last_name')}
                                />
                            </Grid.Col>

                            <Grid.Col span={{ xs: 6, md: 12, lg: 6 }}>
                                <InputField
                                    withAsterisk={true}
                                    label={<Text fz={16} fw={500}>Job title</Text>}
                                    placeholder="Job title"
                                    key={form.key('job_title')}
                                    {...form.getInputProps('job_title')}
                                />
                            </Grid.Col>

                            <Grid.Col span={{ xs: 6, md: 12, lg: 6 }}>
                                <InputField
                                    withAsterisk={true}
                                    label={<Text fz={16} fw={500}>Email Address</Text>}
                                    placeholder="Email Address"
                                    key={form.key('email_address')}
                                    {...form.getInputProps('email_address')}
                                    disabled={true}
                                />
                            </Grid.Col>

                            <Grid.Col span={{ xs: 12, md: 12, lg: 12 }}>
                                <InputField
                                    withAsterisk={true}
                                    label={<Text fz={16} fw={500}>Office phone</Text>}
                                    placeholder="(XXX) XXX-XXXX Ext. XXXXX"
                                    key={form.key('office_phone_number')}
                                    {...form.getInputProps('office_phone_number')}
                                />
                            </Grid.Col>

                            <Grid.Col span={{ xs: 12, md: 12, lg: 12 }}>
                                <InputField
                                    withAsterisk={true}
                                    label={<Text fz={16} fw={500}>Mobile phone</Text>}
                                    placeholder="(XXX) XXX-XXXX"
                                    key={form.key('mobile_phone_number')}
                                    {...form.getInputProps('mobile_phone_number')}
                                />
                            </Grid.Col>

                            <Grid.Col span={{ xs: 12, md: 12, lg: 12 }}>
                                <TextAreaField
                                    withAsterisk={true}
                                    maxLength={500}
                                    label={<Text fz={16} fw={500}>Comments</Text>}
                                    placeholder="Enter your comment here"
                                    key={form.key('comments')}
                                    {...form.getInputProps('comments')}
                                    styles={{
                                        label: {
                                            marginBottom: "10px",
                                            display: "flex",
                                            alignItems: 'baseline',
                                            fontSize: "20px",
                                            marginBottom: "10px"
                                        },
                                        input: {
                                            height: "115px",
                                            fontSize: "14px",
                                            padding: "12px",
                                            lineHeight: "16px",
                                            borderRadius: "6px"
                                        },
                                        required: { color: '#3354F4' }
                                    }}
                                />
                                <p style={{ marginTop: '5px', fontSize: '14px', color: '#999' }}>
                                    {form.values.comments.length}/{500} characters
                                </p>
                            </Grid.Col>

                            <Grid.Col span={12}>
                                <PrimaryButton loading={loading} type={'submit'} fullWidth={true} styles={{ root: { maxWidth: "100%" } }} text="Continue" className={classes.loginBtn} />
                            </Grid.Col>

                            {/* </Stack> */}
                        </Grid>
                    </Grid.Col>
                </Grid>
            </form>
        </Box>
    );
}

export function AuthenticationForm4({ setStep, formFields, setFormFields }) {

    const [loading, setLoading] = useState(false)
    const [imageError, setImageError] = useState("")
    const dispatch = useDispatch()
    const selectInputIcon = <IoIosArrowDown style={{ width: '22px', height: 'auto', marginRight: '5px' }} />;

    const organization = useSelector(state => state.organizations.organization)

    const form = useForm({
        initialValues: {
            name: formFields.name,
            company_type: formFields.company_type,
            site_name: formFields.site_name,
            employees_at_site: formFields.employees_at_site,
            logo: formFields.logo
        },
        validate: {
            name: (val) => (/^(?=.*[A-Za-z])(?!^[\d\s-]+$)(?!^[^\w\s-]+$)[A-Za-z\d\s-]+$/.test(val) ? null : 'Please enter a valid company name'),
            company_type: (val) => (/^\d{1,5}$/.test(val) ? null : 'Company size is required'),
            employees_at_site: (val) => (typeof val === 'string' && val.trim() !== '' ? null : 'Number of employees is required'),
            site_name: (val) => (/^(https?|ftp):\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$|^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/i.test(val) ? null : 'Please enter a valid site URL'),
        },
    });

    async function handleSubmit(e) {
        e.preventDefault();
        if (form.validate().hasErrors) {
            form.validate()
        }
        else {
            // if (organization && organization.id) {
            //     setLoading((loading) => loading = true)
            //     const res = await dispatch(updateOrganization(form.values))
            //     if (updateOrganization.fulfilled.match(res)) {
            //         setFormFields(form.values)
            //         setLoading((loading) => loading = false)
            //         setStep((step) => step + 1)
            //     }
            //     else {
            //         setLoading((loading) => loading = false)
            //     }
            // } else {
            setLoading((loading) => loading = true)
            const res = await dispatch(createOrganization({
                ...form.values,
                site_name: form.values.site_name.includes("https://") ? form.values.site_name : `https://${form.values.site_name}`
            }))
            if (createOrganization.fulfilled.match(res)) {
                setFormFields(form.values)
                setLoading((loading) => loading = false)
                setStep((step) => step + 1)
            }
            else {
                setLoading((loading) => loading = false)
                asyncErrorHandler(res, form, setImageError)
            }
        }
    }

    const commonStyle = {
        label: {
            fontSize: '16px',
            marginBottom: "10px",
            lineHeight: "18px",
            fontWeight: "600",
            color: "#424242"
        },
        input: {
            height: "40px",
            fontSize: "14px",
            padding: "12px",
            lineHeight: "16px",
            borderRadius: "6px",
        },
        error: {
            fontSize: '14px',
            marginTop: "5px",
            lineHeight: "16px",
        },
        required: {
            color: '#3354F4'
        },
        checkIconPosition: {
            backgroundColor: "red"
        }
    }

    return (
        <Box radius="md" py={20} px="lg" mb={50} style={{ width: "100%", maxWidth: "450px" }}>
            <div className={classes.formTitleContainer}>
                <Text size="lg" fw={700} style={{ fontSize: "24px", marginBottom: "12px", lineHeight: "32px", color: "#212529", textAlign: "center" }}>Enter your company info</Text>
                <Text size="lg" fw={400} style={{ fontSize: "16px", lineHeight: "18px", color: "#5C5F66", textAlign: "center" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
            </div>
            <form onSubmit={handleSubmit} style={{ marginTop: "40px" }}>
                <Stack gap={18} mb={40} >
                    <ProfileImageUploader
                        labelText="Upload logo company"
                        isCompany={true}
                        profileImageURL={form.values.logo ? URL.createObjectURL(form.values.logo) : null}
                        updateImage={(image) => {
                            form.setFieldValue('logo', image)
                            setImageError("")
                        }}
                        deleteImage={() => {
                            form.setFieldValue('logo', null)
                            setImageError("")
                        }}
                    />
                    {imageError && <p style={{ fontSize: "14px", color: "#FA5252" }}>{imageError}</p>}
                    <Group display={'flex'} flexDirection='row' align="baseline" wrap='nowrap'>
                        <InputField
                            withAsterisk={true}
                            label={<Text fz={16} fw={500}>Company Name</Text>}
                            placeholder="Please enter company name"
                            key={form.key("name")}
                            {...form.getInputProps('name')}
                        />
                        <InputField
                            withAsterisk={true}
                            label={<Text fz={16} fw={500}>Company Website</Text>}
                            placeholder="Please enter site url"
                            key={form.key("site_name")}
                            {...form.getInputProps('site_name')}
                        />
                    </Group>
                    <SelectInputField
                        withAsterisk={true}
                        checkIconPosition="left"
                        data={INDUSTRY_SERVICED}
                        label={<Text fz={16} fw={500}>Company Type</Text>}
                        key={form.key("company_type")}
                        {...form.getInputProps('company_type')}
                        placeholder="Please select company size"
                        rightSection={selectInputIcon}
                        defaultValue={formFields.company_type}
                    />
                    <SelectInputField
                        withAsterisk={true}
                        checkIconPosition="left"
                        data={ORGANIZATIONS_EMPLOYEES_AT_SITE_SIZE_CHOICES}
                        label={<Text fz={16} fw={500}>Number of employees at site</Text>}
                        key={form.key("employees_at_site")}
                        {...form.getInputProps('employees_at_site')}
                        placeholder="Please select number of employees"
                        rightSection={selectInputIcon}
                        defaultValue={formFields.employees_at_site}
                    />
                </Stack>
                <PrimaryButton loading={loading} type={'submit'} fullWidth={true} text='Continue' variant='filled' fz={'lg'} size={'lg'} />
            </form>
        </Box>
    );
}

export function AuthenticationForm5({ setStep, formFields, setFormFields }) {

    const [progress, setProgress] = useState(0)
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const form = useForm({
        initialValues: {
            file: formFields.file
        },
        validate: {
            file: (val) => (val === null ? 'File is required please upload file!' : null),
        },
    });

    const startTimer = () => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 90) {
                    clearInterval(interval)
                    return prevProgress
                }
                else {
                    return prevProgress + 10
                }
            })
        }, 1000);
        return interval;
    }

    async function handleSubmit(e) {
        e.preventDefault();
        if (form.validate().hasErrors) {
            form.validate()
            return;
        }
        else {
            const formData = new FormData()
            Object.entries(form.values).forEach(([key, value]) => {
                formData.append(key, value)
            })
            const timer = startTimer()
            setLoading(true)
            const res = await dispatch(uploadEmlpoyeeTemplate(formData))
            if (uploadEmlpoyeeTemplate.fulfilled.match(res)) {
                clearInterval(timer)
                setFormFields(form.values)
                setLoading(false)
                setProgress(100)
                setStep((step) => step + 1);
            } else if (uploadEmlpoyeeTemplate.rejected.match(res)) {
                console.log(res);
                setLoading(false)
                clearInterval(timer)
                setProgress(0)
                // if (res?.payload?.response?.data?.errors && Array.isArray(res?.payload?.response?.data?.errors)) {
                //     res?.payload?.response?.data?.errors.forEach((k) => {
                //         if (!Array.isArray(k?.tier)) {
                //             window.toast(`${k?.tier} in this uploaded file`, 'error')
                //         }
                //     })
                //     return;
                // }
                if (res?.payload?.response?.data?.error) {
                    const errorString = res?.payload?.response?.data?.error;
                    const match = errorString.match(/string='(.*?)', code='(.*?)'/);
                    const errorObj = {
                        string: match[1],
                        code: match[2]
                    };
                    window.toast(`${errorObj?.string} in this uploaded file`, 'error')
                    return;
                } else {
                    window.toast("Error uploading file", 'error')
                    return;
                }
            }

        }
    }

    const setFiles = (file) => {
        if (!file || file.length === 0) {
            form.setFieldValue('file', null);
            setProgress(0);
            setFormFields(form.values);
            return;
        }
        const reader = new FileReader();
        reader.onload = (event) => {
            try {
                const data = event.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                if (jsonData.length > 0) {
                    const headers = jsonData[0];
                    const nameIndex = headers.indexOf("name");
                    const emailIndex = headers.indexOf("email");

                    if (nameIndex === -1 || emailIndex === -1) {
                        console.error("Error: 'name' and 'email' columns are required.");
                        alert("Error: The uploaded file must include 'name' and 'email' columns.");
                        return;
                    }

                    const dataRows = jsonData.slice(1); // Exclude headers
                    const invalidRows = dataRows.filter((row, index) => {
                        const nameValue = row[nameIndex]?.toString().trim();
                        const emailValue = row[emailIndex]?.toString().trim();

                        // Only validate name and email, ignore other fields and empty rows
                        const isRowEmpty = row.every((cell) => !cell?.toString().trim()); // Check if the entire row is empty
                        if (isRowEmpty) {
                            return false; // Ignore empty rows
                        }

                        console.log(`Row ${index + 1}:`, { nameValue, emailValue });
                        return !nameValue || !emailValue; // Return true if name or email is empty
                    });

                    if (invalidRows.length > 0) {
                        console.error("Invalid rows found:", invalidRows);
                        form.setFieldValue('file', null);
                        form.setFieldError(
                            "file",
                            "The uploaded file contains rows with empty 'name' or 'email' fields. Please fill in the missing information and re-upload the corrected file."
                        );
                        return;
                    }

                    console.log("Valid file uploaded.");
                    form.setFieldValue('file', file[0]);
                    setProgress(0);
                    setFormFields(form.values);

                } else {
                    console.error('The file is empty or has no headers.');
                    alert("Error: The uploaded file is empty or invalid.");
                }
            } catch (error) {
                console.error('Error reading file:', error);
            }
        };

        reader.onerror = () => {
            console.error('File could not be read.');
        };

        reader.readAsBinaryString(file[0]); // Read the first file
    };

    function downloadTemplate() {
        const headers = [
            'name',
            'age',
            'email',
            'address',
            'gender',
        ];
        const emptyData = [
            ["", "", "", "", ""],
        ];
        const worksheetData = [headers, ...emptyData];
        // Create a new worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

        // Apply bold styling to the header row (first row)
        const headerStyle = {
            font: { bold: true, sz: 20 },  // Bold font and large font size (sz: 14 corresponds to 'lg')
            fill: { patternType: 'solid', fgColor: { rgb: 'red' } },  // Grey background (light grey)
        };
        for (let col = 0; col < headers.length; col++) {
            worksheet[XLSX.utils.encode_cell({ r: 0, c: col })].s = headerStyle;
        }

        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

        // Write the workbook and trigger download
        XLSX.writeFile(workbook, "data_template.xlsx");

    }

    return (
        <Box radius="md" p="xl" style={{ width: "100%", maxWidth: "34rem" }}>
            {/* <img src={whiteLogo} style={{ display: "block", width: '78px', margin: "auto", marginBottom: "20px" }} alt="logo" /> */}
            <div className={classes.formTitleContainer} style={{ marginBottom: "20px" }}>
                <Text size="lg" fw={700} style={{ fontSize: "24px", marginBottom: "12px", lineHeight: "32px", color: "#212529", textAlign: "center" }}>Invite your team</Text>
                <Text size="lg" fw={400} style={{ fontSize: "16px", color: "#5C5F66", textAlign: "center", }}>To invite employees, download the employee upload template, fill it out, and upload it to this form.</Text>
            </div>
            <form onSubmit={handleSubmit}>
                <Stack gap={40}>
                    <Stack gap={1}>
                        <div style={{ backgroundColor: "#fff" }}>
                            <div className={classes.uploadDocument}>
                                <Dropzone
                                    onDrop={setFiles}
                                    onReject={(files) => console.log('rejected files', files)}
                                    maxSize={80 * 1024 ** 2}
                                    accept={MS_EXCEL_MIME_TYPE}
                                >
                                    <Group justify="center" gap="xl" mih={220} style={{ pointerEvents: 'none' }}>
                                        <Stack>
                                            <Dropzone.Idle mx="auto" >
                                                <Image src={uploadDocumentIcon} w={90} fit="contain" />
                                            </Dropzone.Idle>

                                            <div>
                                                <Text c="#5C5F66" size="14px" inline>
                                                    <strong>Drag and drop</strong> files here <strong> or click here</strong>
                                                </Text>
                                                <Text c="#5C5F66" size="14px" inline mt={7}>
                                                    and select a file from your computer
                                                </Text>
                                                <Text c="#5C5F66" size="14px" inline mt={17} ta="center">
                                                    Max size - 80MB <span style={{ display: "inline-block", margin: "4px 5px", width: "4px", height: "4px", borderRadius: "100%", backgroundColor: "#5C5F66" }}></span> Format: xlsx
                                                </Text>
                                            </div>
                                        </Stack>
                                    </Group>
                                </Dropzone>
                            </div>
                        </div>
                        {form?.errors && <p style={{ fontSize: "14px", color: "#FA5252", marginTop: "10px" }}>{form?.errors?.file}</p>}
                        <div>
                            {form.values.file ? (
                                <Card withBorder={true} mt="12" radius="8" p={12}>
                                    <Stack>
                                        <Flex
                                            justify="flex-start"
                                            align="center"
                                        >
                                            <Group justify="flex-start" gap="6px">
                                                <Image src={excelLogo} w={36} h={36} fit="contain"></Image>
                                                <Stack>
                                                    <Text size="14px" mb={-6}>{form.values.file.name}</Text>
                                                    <Text size="12px" c="dimmed">{formatFileSize(form.values.file.size)}</Text>
                                                </Stack>
                                            </Group>
                                            <Group ml="auto" gap={6}>
                                                {/* <Button variant="filled" color="rgba(241, 243, 245)" className={classes.uploadedFileActionButton}>
                                                    <IoPauseOutline color='#000' size={14} />
                                                </Button> */}
                                                <Button variant="filled" color="rgba(241, 243, 245)" className={classes.uploadedFileActionButton} onClick={() => setFiles(null)} >
                                                    <FaRegTrashAlt color='#000' size={14} />
                                                </Button>
                                            </Group>
                                        </Flex>
                                    </Stack>
                                    <Box style={{ position: 'relative' }} my={8}>
                                        <Progress w="94%" mt="10" mb="" value={progress}></Progress>
                                        <Text
                                            style={{
                                                position: 'absolute',
                                                top: '75%',
                                                left: '98%',
                                                transform: 'translate(-50%, -50%)',
                                            }}
                                            fw={500}
                                            fz={16}
                                            c="#696969"
                                        >
                                            {progress}%
                                        </Text>
                                    </Box>
                                </Card>
                            ) : null}
                        </div>
                    </Stack>
                    {/* <Flex gap={10}> */}
                    {form.values.file ? (
                        <PrimaryButtonn
                            fullWidth={true}
                            disabled={loading}
                            onClick={handleSubmit}
                            title="Invite your team"
                            fw={600}
                            styles={{ root: { borderRadius: "7px", gap: 10 } }}
                            h={42}
                            fallback={loading ? <Loader size="sm" color="white" type='dots' /> : null}
                        />
                    ) : (
                        <>
                            {/* <PrimaryButton fullWidth={true} variant={'light'} type={'submit'} text='Employee Upload Template' size={'md'} styles={{ root: { borderStyle: 'solid', borderColor: '#3354F4' } }} /> */}
                            <PrimaryButtonn
                                fullWidth={true}
                                title="Download Template"
                                onClick={downloadTemplate}
                                leftSection={<IconDownload />}
                                fw={600}
                                styles={{ root: { borderRadius: "7px" } }}
                                h={42}
                            />
                        </>
                    )}
                    {/* </Flex> */}
                </Stack>
            </form>
        </Box >
    );
}

export function AuthenticationForm6() {

    const navigate = useNavigate()
    const form = useForm({
        initialValues: {
            email: '',
            name: '',
            password: '',
            terms: true,
        },
        validate: {
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
            password: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
        },
    });

    return (
        <Box radius="md" p="xl" style={{ width: "100%", maxWidth: "32rem" }}>
            {/* <img src={whiteLogo} style={{ display: "block", width: '78px', margin: "auto", marginBottom: "20px" }} alt="logo" /> */}
            <div className={classes.formTitleContainer}>
                <Text size="lg" fw={700} style={{ fontSize: "24px", marginBottom: "12px", lineHeight: "32px", color: "#212529", textAlign: "center" }}>Welcome to RFXN!</Text>
                <Text size="lg" fw={400} style={{ fontSize: "16px", lineHeight: "18px", color: "#5C5F66", textAlign: "center" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Text>
                <Image src={onboarding} w={248} style={{ margin: "40px auto" }} />
            </div>
            <form onSubmit={form.onSubmit(() => { })}>
                <PrimaryButton fullWidth={true} size='md' text='Go to dashboard' onClick={() => {
                    localStorage.setItem('inProcess', 'false')
                    navigate('/admin/dashboard')
                }} />
            </form>
        </Box>
    );
}