import React, { useEffect } from 'react'
import { Grid, Stack } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { setSummary } from '../../../store/employess/summarySlice'
import { PrescriptionCard2 } from '../prescriptionCard'
import { setPrescriptions } from '../../../store/employess/prescription'
import { setSelectedPrescription } from '../../../store/employess/cartSlice'

const SelectPrescription = () => {

    const prescriptions = useSelector((state) => state.prescription.prescriptionList)
    const dispatch = useDispatch()

    const handleSelect = (item) => {
        dispatch(setSummary({ key: "prescription", data: item }))
    }

    useEffect(() => {
        dispatch(setSelectedPrescription(prescriptions.find((prescription) => prescription.isSelected)))
    }, [prescriptions])

    return (
        <Grid w="100%" gutter={12}>
            {prescriptions.map((prescription) => {
                return (
                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }}
                        onClick={() => {
                            handleSelect(prescription)
                            const selectedPrescription = prescriptions.map((pres, index) => {
                                return { ...pres, isSelected: pres.id === prescription.id ? true : false }
                            })
                            dispatch(setPrescriptions(selectedPrescription))
                        }}>
                        <Stack gap={30}>
                            <PrescriptionCard2 prescription={prescription} isGroup={false} isCheckbox={true} />
                        </Stack>
                    </Grid.Col>
                )
            })}
        </Grid>
    )
}

export default SelectPrescription